<form [formGroup]="formGroup" (change)="modelChange.emit(formGroup.value)">
    <div class="row">
        <pcg-control-group class="col-md-12" label="Form Types">
            <pcg-select
                [sendFormData]='false' 
                pcgControlRef 
                [multiple]='true' 
                class="h-100"
                dataSource='api/Select/FacilityFormTypes' 
                formControlName='formTypeIds'
                placeholder="Select forms..."
                [selectedItemIds]="formGroup.controls.formTypeIds.value"
            ></pcg-select>
        </pcg-control-group>
    </div>
</form>