// tslint:disable: quotemark
export enum LoginTypeEnum {
	Traditional = 1,
	SSO = 2,
	Both = 3
}

export namespace LoginTypeEnum {
	export function toDescription(val: LoginTypeEnum) {
		switch (val) {
			case 1:
				return "Traditional";
			case 2:
				return "SSO";
			case 3:
				return "Both";
		}
		return null;
	}
	export function toDisplay(val: LoginTypeEnum) {
		switch (val) {
			case 1:
				return "Traditional";
			case 2:
				return "SSO";
			case 3:
				return "Both";
		}
		return null;
	}
}
