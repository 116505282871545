import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { ChangePasswordForm } from 'app/core/pages/change-password/change-password.form';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';

@Component({
    selector: 'pcg-get-password',
    templateUrl: './get-password.component.html',
    styleUrls: ['./get-password.component.scss'],
    standalone: false
})
export class GetPasswordComponent {

	message = 'In order to download this file, it must be password protected.';
	title = 'Choose a Password';
	promptForUsersPassword = false;
	changePasswordForm: UntypedFormGroup = null;
	password: string;
	func: (password: string) => any;

	constructor(public activeModal: NgbActiveModal, http: HttpClient) {
		const changePasswordForm = new ChangePasswordForm(http, false);
		this.changePasswordForm = changePasswordForm.formGroup;
	}

	setPassword() {
		if (this.promptForUsersPassword) {
			this.activeModal.dismiss();
			this.func(this.password);
			return true;
		}
		if (this.changePasswordForm.valid) {
			this.activeModal.dismiss();
			this.func(this.changePasswordForm.value.password);
		} else { validateForm(this.changePasswordForm); }
		
		return this.changePasswordForm.valid;
	}
}
