<div class="tooltip-visibility" >
  <label 
      class="for" 
      [style.font-size]="labelFontSize" 
      ngbTooltip="" 
      [ngbTooltip]="tooltip" 
      container="body"
  >{{ labelText }}</label>
</div>
<div class="icon-tooltip-visibility" >
  <label 
      class="for" 
      [style.font-size]="labelFontSize"
  >
      {{ labelText }}
      <fa-icon 
          *ngIf="
              tooltip 
              && labelText
          " 
          class="icon-tooltip" 
          [icon]="faIconName.faInfoCircle" 
          ngbTooltip="" 
          [ngbTooltip]="tooltip" 
          triggers="click" 
          container="body" 
          [placement]="tooltipPlacement || 'auto'"
      ></fa-icon>	
  </label>
</div>