import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';
import { DynamicFormFieldVm, DynamicFormFieldVmFromFg } from '../../../Models/DynamicFormFieldVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditUserVm {
	userId: number;
	firstName?: string;
	lastName?: string;
	userName?: string;
	phoneNumber?: string;
	email?: string;
	emailConfirmed: boolean;
	isEmailBounced: boolean;
	isEmailSpamReported: boolean;
	isActive: boolean;
	isLockedOut: boolean;
	inactivityTimeout: boolean;
	isInternalBphpUser: boolean;
	fields?: DynamicFormFieldVm[];
	customFields?: any;
	mode?: string;
	userPin?: string;
	friendlyId?: string;
	inventoryIds?: number[];
	inventories?: string[];
	facilityIds?: number[];
	facilities?: string[];
	organizationIds?: number[];
	organizations?: string[];
	systemInventoryIds?: number[];
	updateFacilities: boolean;
	updateOrgs: boolean;
	businessArea?: any;
	biometricFingerprintVersion: number;
	organizationId?: number;
	organizationName?: string;
	orgLoginType?: any;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'firstName': new UntypedFormControl(null, [...(validators['firstName'] ?? []), ...[Validators.required]]),
			'lastName': new UntypedFormControl(null, [...(validators['lastName'] ?? []), ...[Validators.required]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[Validators.required]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.required, Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.required, Validators.email]]),
			'emailConfirmed': new UntypedFormControl(false, [...(validators['emailConfirmed'] ?? []), ...[]]),
			'isEmailBounced': new UntypedFormControl(false, [...(validators['isEmailBounced'] ?? []), ...[]]),
			'isEmailSpamReported': new UntypedFormControl(false, [...(validators['isEmailSpamReported'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isLockedOut': new UntypedFormControl(false, [...(validators['isLockedOut'] ?? []), ...[]]),
			'inactivityTimeout': new UntypedFormControl(false, [...(validators['inactivityTimeout'] ?? []), ...[]]),
			'isInternalBphpUser': new UntypedFormControl(false, [...(validators['isInternalBphpUser'] ?? []), ...[]]),
			'fields': new UntypedFormArray([]),
			'customFields': new UntypedFormControl(null, [...(validators['customFields'] ?? []), ...[]]),
			'mode': new UntypedFormControl(null, [...(validators['mode'] ?? []), ...[]]),
			'userPin': new UntypedFormControl(null, [...(validators['userPin'] ?? []), ...[Validators.maxLength(4)]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'inventoryIds': new UntypedFormControl([], [...(validators['inventoryIds'] ?? []), ...[]]),
			'inventories': new UntypedFormControl([], [...(validators['inventories'] ?? []), ...[]]),
			'facilityIds': new UntypedFormControl([], [...(validators['facilityIds'] ?? []), ...[]]),
			'facilities': new UntypedFormControl([], [...(validators['facilities'] ?? []), ...[]]),
			'organizationIds': new UntypedFormControl([], [...(validators['organizationIds'] ?? []), ...[]]),
			'organizations': new UntypedFormControl([], [...(validators['organizations'] ?? []), ...[]]),
			'systemInventoryIds': new UntypedFormControl([], [...(validators['systemInventoryIds'] ?? []), ...[]]),
			'updateFacilities': new UntypedFormControl(false, [...(validators['updateFacilities'] ?? []), ...[]]),
			'updateOrgs': new UntypedFormControl(false, [...(validators['updateOrgs'] ?? []), ...[]]),
			'businessArea': new UntypedFormControl(null, [...(validators['businessArea'] ?? []), ...[]]),
			'biometricFingerprintVersion': new UntypedFormControl(0, [...(validators['biometricFingerprintVersion'] ?? []), ...[Validators.required]]),
			'organizationId': new UntypedFormControl(null, [...(validators['organizationId'] ?? []), ...[]]),
			'organizationName': new UntypedFormControl(null, [...(validators['organizationName'] ?? []), ...[]]),
			'orgLoginType': new UntypedFormControl(null, [...(validators['orgLoginType'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditUserVmFromFg {
		return new EditUserVmFromFg(fg);
	}
}

export class EditUserVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get firstName(): string {
		return this.fg.get('firstName').value;
	}
	set firstName(val) {
		this.fg.get('firstName').setValue(val);
	}

	get lastName(): string {
		return this.fg.get('lastName').value;
	}
	set lastName(val) {
		this.fg.get('lastName').setValue(val);
	}

	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get emailConfirmed(): boolean {
		return this.fg.get('emailConfirmed').value;
	}
	set emailConfirmed(val) {
		this.fg.get('emailConfirmed').setValue(val);
	}

	get isEmailBounced(): boolean {
		return this.fg.get('isEmailBounced').value;
	}
	set isEmailBounced(val) {
		this.fg.get('isEmailBounced').setValue(val);
	}

	get isEmailSpamReported(): boolean {
		return this.fg.get('isEmailSpamReported').value;
	}
	set isEmailSpamReported(val) {
		this.fg.get('isEmailSpamReported').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isLockedOut(): boolean {
		return this.fg.get('isLockedOut').value;
	}
	set isLockedOut(val) {
		this.fg.get('isLockedOut').setValue(val);
	}

	get inactivityTimeout(): boolean {
		return this.fg.get('inactivityTimeout').value;
	}
	set inactivityTimeout(val) {
		this.fg.get('inactivityTimeout').setValue(val);
	}

	get isInternalBphpUser(): boolean {
		return this.fg.get('isInternalBphpUser').value;
	}
	set isInternalBphpUser(val) {
		this.fg.get('isInternalBphpUser').setValue(val);
	}

	get customFields(): any {
		return this.fg.get('customFields').value;
	}
	set customFields(val) {
		this.fg.get('customFields').setValue(val);
	}

	get mode(): string {
		return this.fg.get('mode').value;
	}
	set mode(val) {
		this.fg.get('mode').setValue(val);
	}

	get userPin(): string {
		return this.fg.get('userPin').value;
	}
	set userPin(val) {
		this.fg.get('userPin').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get updateFacilities(): boolean {
		return this.fg.get('updateFacilities').value;
	}
	set updateFacilities(val) {
		this.fg.get('updateFacilities').setValue(val);
	}

	get updateOrgs(): boolean {
		return this.fg.get('updateOrgs').value;
	}
	set updateOrgs(val) {
		this.fg.get('updateOrgs').setValue(val);
	}

	get businessArea(): any {
		return this.fg.get('businessArea').value;
	}
	set businessArea(val) {
		this.fg.get('businessArea').setValue(val);
	}

	get biometricFingerprintVersion(): number {
		return this.fg.get('biometricFingerprintVersion').value;
	}
	set biometricFingerprintVersion(val) {
		this.fg.get('biometricFingerprintVersion').setValue(val);
	}

	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get organizationName(): string {
		return this.fg.get('organizationName').value;
	}
	set organizationName(val) {
		this.fg.get('organizationName').setValue(val);
	}

	get orgLoginType(): any {
		return this.fg.get('orgLoginType').value;
	}
	set orgLoginType(val) {
		this.fg.get('orgLoginType').setValue(val);
	}
}
