import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getPharmacyInventoryNav: () => NavRoute = () => {
	return {
		name: 'Pharmacy Inventory'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Inventory ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
			}
		]
		, children: getInventoryAreas()
	};
};

function getInventoryAreas(): NavRoute[] {
    var id: string = "";
	var url = window.location.href?.split('?')[0];

	if (url.indexOf("pharmacy/inventories/products/") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
    if (url.indexOf("pharmacy/inventories/transaction-history") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
    if (url.indexOf("pharmacy/inventories/reconciliation/") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
    if (url.indexOf("pharmacy/inventories/replenishment/") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
    if (url.indexOf("pharmacy/inventories/fulfillment/") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
    if (url.indexOf("pharmacy/inventories/transfers/") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }
    if (url.indexOf("pharmacy/inventories/invoices/") > -1) { id = url.substring(url.lastIndexOf("/") + 1); }

	if (id.includes("?")) { id = FormatHelperService.replaceAfterChar(id, '?', ""); }
	
	return [
		{
			name: 'Products'
			, path: `pharmacy/inventories/products/list/${id}`
			, activeNavLink: 'pharmacy/inventories/products'
			, secondaryNav: ['Pharmacy Inventory', 'Products']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Transaction History'
			, path: `pharmacy/inventories/transaction-history/${id}`
			, activeNavLink: 'pharmacy/inventories/transaction-history'
			, secondaryNav: ['Pharmacy Inventory', 'Transaction History']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Reconciliation'
			, path: `pharmacy/inventories/reconciliation/list/${id}`
			, activeNavLink: 'pharmacy/inventories/reconciliation'
			, secondaryNav: ['Pharmacy Inventory', 'Reconciliation']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Replenishment'
			, path: `pharmacy/inventories/replenishment/list/${id}`
			, activeNavLink: 'pharmacy/inventories/replenishment'
			, secondaryNav: ['Pharmacy Inventory', 'Replenishment']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Rx Fulfillment'
			, path: `pharmacy/inventories/fulfillment/list/${id}`
			, activeNavLink: 'pharmacy/inventories/fulfillment'
			, secondaryNav: ['Pharmacy Inventory', 'Rx Fulfillment']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Transfers'
			, path: `pharmacy/inventories/transfers/list/${id}`
			, activeNavLink: 'pharmacy/inventories/transfers'
			, secondaryNav: ['Pharmacy Inventory', 'Transfers']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
		, {
			name: 'Invoices'
			, path: `pharmacy/inventories/invoices/list/${id}`
			, activeNavLink: 'pharmacy/inventories/invoices'
			, secondaryNav: ['Pharmacy Inventory', 'Invoices']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, exact: true
		}
	];
}
