import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { faMessages, faComments, faPen, faTrash, faSave, faCancel, faXmark, faPaperPlaneTop, faMessagePlus } from '@fortawesome/pro-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { GlobalService } from 'app/shared/services/global.service';
import { UserJsVm } from '../generated/Administration/Models/Users/UserJsVm';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { GenericCommentVm } from '../generated/Models/GenericCommentVm';
import { SelectItem } from 'app/shared/models/select-item';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';

@Component({
    selector: 'pcg-conversations',
    templateUrl: './conversations.component.html',
    styleUrls: ['./conversations.component.scss'],
    standalone: false
})
export class ConversationsComponent implements OnInit, AfterViewInit {

    @Input() parentId: number;
    @Input() getUrl = "";
    @Input() saveUrl = "";
    @Input() deleteUrl = "";
    @Input() isShowingConvo = true;
    @Input() header = "Conversation";
    @Input() useTemplateResponses = false;
    @Input() templateResponseApi = "";
    @Input() isAssignee = false;
    @Input() usesSidenav = false;
    @Input() showCommenterInfo = false;
    @Input() isHeros = false;
    @Input() herosId = null;
    @Input() herosPrimary: boolean = null;
    @Input() herosSecondary: boolean = null;
    @Input() herosTertiary: boolean = null;
    @Input() fromFulfillment: boolean = false;
    @Input() pfsFormId: number;
    @Input() statusClosed: boolean = false;
    @Input() autoRefresh: boolean = false;
    @Input() autoRefreshInterval = 10000;

    @Output() closeConvo = new EventEmitter<boolean>();
    @Output() successDataReturn = new EventEmitter<any>();
    @Output() deleteReturn = new EventEmitter<any>();

    subscriptions = new Subscription();
    user: UserJsVm;
    commentList: GenericCommentVm[];
    templateItems: SelectItem[] = [];
    
    isCommentError = false;
    isMobile = false;

    commentErrorString = "";
    newCommentText = "";
    editCommentText = "";
    newTemplateId = "";
    isSysAdmin: boolean;
    
    faIconName = { 
        faMessages
        , faComments
        , faPen
        , faTrash
        , faSave
        , faCancel
        , faXmark
        , faPaperPlaneTop
        , faMessagePlus 
    };

    xDown = null;
	yDown = null;

    source = interval(this.autoRefreshInterval);
    subscribe = this.source.subscribe({
        next: () => { this.getComments(); }
        , complete: () => { this.getComments(); }
    });

    constructor(
        private sec: SecurityService
        , private httpClient: HttpClient
        , public sm: SystemMessageService
        , public router: Router
    ) { }

    @HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

    ngOnInit() {
        this.isMobile = GlobalService.setIsMobile(window.innerWidth);
        this.isShowingConvo = !this.isMobile;
        this.user = this.sec.getUser();
        this.isSysAdmin = this.sec.hasModuleAccess(
            [ BusinessAreaEnum.HelpDesk ]
            , SecurityService.setMinRole(PermissionRole.SystemAdmin)
        );
        this.getComments();
        if (!this.isSysAdmin) { this.useTemplateResponses = false; }
        if (
            this.useTemplateResponses === true 
            && this.templateResponseApi !== ""
        ) { this.getTemplateItems(); }
        //event listeners for swiping away side nav in mobile
		document.addEventListener('touchstart', (evt) => this.handleTouchStart(evt), false);        
		document.addEventListener('touchmove', (evt) => this.handleTouchMove(evt), false);
        if (this.autoRefresh === true) { this.subscribe; }
    }

    refreshConvo() {
        interval(this.autoRefreshInterval).pipe()
            .subscribe({
                next: () => { this.getComments(); }
                , complete: () => { this.getComments(); }
            });
    }

    ngAfterViewInit() { this.autoScroll(); }

    autoScroll() {
        setTimeout(() => {
            let elem = document.getElementById('divConvoMessages');
            if (elem !== null) { elem.scrollTop = elem.scrollHeight; }
        }, 100);
    }

    getComments() {
        this.httpClient.get(`${this.getUrl}?id=${this.parentId}`)
            .subscribe((list: GenericCommentVm[]) => { this.commentList = list; });      
    }

    getTemplateItems() {
        this.subscriptions.add(this.httpClient.get(`${this.templateResponseApi}`)
            .subscribe((items: SelectItem[]) => { this.templateItems = items; }));
    }

    templateChange(value) {
        if (value !== "") {            
            var findItem = this.templateItems.find(o => o.value.toString() === value);
            this.newCommentText = findItem?.additionalData?.responseMessage; 
        }
    }

    openTemplateDropdown() {
        var event = new MouseEvent('mousedown');
        document.getElementById("template-select").dispatchEvent(event);
    }

    saveNewComment() {
        this.isCommentError = false;
        this.commentErrorString = "";

        if (this.newCommentText === "") {
            this.isCommentError = true;
            this.commentErrorString = "Message is required";
            return;
        }

        let form = new GenericCommentVm();
        form.parentId = this.parentId;
        form.commentText = this.insertLinkTags(this.newCommentText);
        if (this.pfsFormId != null) { form.pfsFormID = this.pfsFormId };
        if (!this.isHeros) {
            this.httpClient.post<any>(`${this.saveUrl}`, form)
                .subscribe((sm: SystemMessage) => { 
                    if (sm.isSuccessful) {
                        this.commentList = sm.model; 
                        this.newCommentText = "";
                        this.newTemplateId = "";
                        this.autoScroll();
                        this.successDataReturn.emit(sm.value);
                    } else {
                        this.isCommentError = true;
                        this.commentErrorString = sm.message;
                    }                
                }
            );
        } else {
            const url = `${this.saveUrl}?id=${this.herosId}` +
            `&message=${encodeURIComponent(form.commentText)}` +
            `&orderPlaced=${null}` +
            `&commentId=${null}` +
            `&primary=${this.herosPrimary}` +
            `&secondary=${this.herosSecondary}` +
            `&tertiary=${this.herosTertiary}`;
            this.httpClient.get<any>(url).subscribe((sm: SystemMessage) => { 
                if (sm.isSuccessful) {
                    this.commentList = sm.model; 
                    this.newCommentText = "";
                    this.newTemplateId = "";
                    this.autoScroll();
                    this.successDataReturn.emit(sm.value);
                } else {
                    this.isCommentError = true;
                    this.commentErrorString = sm.message;
                }                
            });
        }
    }

    deleteComment(row: GenericCommentVm) { 
        row.isDelete = true; 
        this.subscribe.unsubscribe();
    }

    noDeleteClick(row: GenericCommentVm) { 
        row.isDelete = false; 
        this.source.subscribe({
            next: () => { this.getComments(); }
            , complete: () => { this.getComments(); }
        });
    }

    yesDeleteClick(row: GenericCommentVm) {
        this.isCommentError = false;
        this.commentErrorString = "";

        this.sm.getHttpObservable(this, `${this.deleteUrl}?id=${row.id}`)
            .subscribe((sm: SystemMessage) => {
                if (sm.isSuccessful) { 
                    this.commentList = sm.model; 
                    this.autoScroll();
                    this.deleteReturn.emit(sm);
                } else { 
                    this.isCommentError = true;
                    this.commentErrorString = sm.message;
                }
            }
        );

        this.source.subscribe({
            next: () => { this.getComments(); }
            , complete: () => { this.getComments(); }
        });
    }

    editComment(row: GenericCommentVm) {
        row.isEdit = true; 
        this.editCommentText = row.commentText;
        this.subscribe.unsubscribe();
    }

    cancelEditComment(row: GenericCommentVm) {
        row.isEdit = false; 
        row.commentText = this.editCommentText;
        this.editCommentText = "";
        this.source.subscribe({
            next: () => { this.getComments(); }
            , complete: () => { this.getComments(); }
        });
    }

    saveEditComment(row: GenericCommentVm) {
        this.isCommentError = false;
        this.commentErrorString = "";

        if (!this.isHeros) {
            this.httpClient.post<any>(`${this.saveUrl}`, row)
                .subscribe((sm: SystemMessage) => { 
                    if (sm.isSuccessful) {
                        this.commentList = sm.model; 
                        this.newCommentText = "";
                        this.autoScroll();
                        this.successDataReturn.emit(sm.value);
                    } else {
                        this.isCommentError = true;
                        this.commentErrorString = sm.message;
                    }                
                }
            );
        } else {
            this.httpClient.get<any>(
                `${this.saveUrl}
                ?id=${this.herosId}
                &message=${row.commentText}
                &orderPlaced=${null}
                &commentId=${row.id}
                &primary=${this.herosPrimary}
                &seconday=${this.herosSecondary}
                &tertiary=${this.herosTertiary}`
            ).subscribe((sm: SystemMessage) => { 
                if (sm.isSuccessful) {
                    this.commentList = sm.model; 
                    this.newCommentText = "";
                    this.autoScroll();
                    this.successDataReturn.emit(sm.value);
                } else {
                    this.isCommentError = true;
                    this.commentErrorString = sm.message;
                }                
            });
        }

        this.source.subscribe({
            next: () => { this.getComments(); }
            , complete: () => { this.getComments(); }
        });
    }

    toggleConversationsMenu() {
        this.isShowingConvo = !this.isShowingConvo;
        if (!this.isShowingConvo) { this.closeConvo.emit(false); }
    }

    handleTouchStart(evt) {
		if (
            window.innerWidth < 993 
            && this.isShowingConvo === true
        ) {
			let firstTouch = evt.touches || evt.originalEvent.touches;       
			this.xDown = firstTouch[0].clientX;                                      
			this.yDown = firstTouch[0].clientY; 
		}			                                   
	}; 

    handleTouchMove(evt) {
		if (
            this.isMobile 
            && this.isShowingConvo === true
        ) {
			if ( !this.xDown || !this.yDown ) { return; }
            
			let xUp = evt.touches[0].clientX;                                    
			let yUp = evt.touches[0].clientY;
			let xDiff = this.xDown - xUp;
			let yDiff = this.yDown - yUp;
																				
			if ( Math.abs(xDiff) > Math.abs(yDiff) ) {/*most significant*/
				if (xDiff > 8) { /* right swipe */ } 
                else { 
                    /* left swipe */ 
                    this.isShowingConvo = false;
					this.closeConvo.emit(false);
                }                       
			} else {
				if (yDiff > 8) { /* down swipe */ } 
				else { /* up swipe */ }                                                                 
			}
			/* reset values */
			this.xDown = null;
			this.yDown = null;  
		}
	}

    insertLinkTags(txt: string) {
        let stringWithLinkTag = "";
        if (txt.includes("https://")) { 
            var words = txt.split(" ")
            for (var i = 0; i < words.length; i++) {
                if (words[i].includes("https://")) {
                    words[i] = `<a href="${words[i]}" target="_blank">${words[i]}</a>`;
                }
            }
            stringWithLinkTag = words.join(" ");
        } else { stringWithLinkTag = txt; }
        return stringWithLinkTag;
    }

    ngOnDestroy() { this.subscribe.unsubscribe(); }
}
