import { Component, Input, OnInit, signal } from "@angular/core";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"
;
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { getAdminNav } from "app/core/nav-areas/admin-nav";
import { getCiRepackNav } from "app/core/nav-areas/ci-repack-nav";
import { getHEROSNav } from "app/core/nav-areas/heros-nav";
import { getInventoryNav } from "app/core/nav-areas/inventory-nav";
import { getMetabolicFormulaNav } from "app/core/nav-areas/metabolic-formula-nav";
import { getNdcDirectoryNav } from "app/core/nav-areas/ndc-directory-nav";
import { getPharmacyNav } from "app/core/nav-areas/pharmacy-nav";
import { SecurityService } from "app/core/security/security.service";
import { NavRoute } from "app/shared/navigation/nav.route.interface";
import { UserRoleInfoServiceService } from "../../user-role-info.service";

@Component({
    selector: "pcg-user-role-info",
    templateUrl: "./user-role-info.component.html",
    styleUrls: ["../../user-role-info-modal.component.scss"],
    standalone: false
})
export class UserRoleInfoComponent implements OnInit {

	@Input() module: BusinessAreaEnum;

	permissionRole = PermissionRole;
	roles: PermissionRole[] = SecurityService.getMinBaseRoles(this.permissionRole.User);
	businessAreaEnum = BusinessAreaEnum;
	routes: NavRoute[] = [];

	faCheck = faCheck;
	secAccess: any[];
	useDefault: boolean;
	
	loading = signal(true);

	constructor(
		public roleInfoService: UserRoleInfoServiceService
		, public http: HttpClient
		, private router: Router
		, public activeModal: NgbActiveModal
	) { }

	ngOnInit() {
		this.useDefault = !this.secAccess?.length;
		if (this.useDefault) { this.getRoutes(); }
	}

	// We may be able to remove this now that permission details can be added within the application.
	getRoutes() {
		switch (this.module) {
			case BusinessAreaEnum.HEROS:
				this.routes = getHEROSNav().children;
				break;
			case BusinessAreaEnum.PharmLog:
				this.routes = getPharmacyNav().children;
				break;
			case BusinessAreaEnum.NDC:
				this.routes = getNdcDirectoryNav().children;
				break;
			case BusinessAreaEnum.MetabolicFormula:
				this.routes = getMetabolicFormulaNav().children;
				break;
			case BusinessAreaEnum.CI:
				this.routes = getCiRepackNav().children;
				break;
			case BusinessAreaEnum.Admin:
				this.routes = getAdminNav().children;
				break;
			case BusinessAreaEnum.SysInv:
				this.routes = getInventoryNav().children;
				break;
			default:
				break;
		}
		this.secAccess = this.routes;
	}

	hasRoleAccess = (role: PermissionRole, sec): boolean =>
		this.useDefault
			? (
				sec?.moduleAccess?.modules == this.module 
				&& role >= (sec?.moduleAccess?.permissionRoles ?? 0)
			) == true
			: Object.entries(sec).find((o) => o[0]?.toLowerCase() == PermissionRole[role].toLowerCase())?.[1] == true;

	addModuleAccessDetails() {
		this.router.navigate([
			`/admin/permissions-info/${this.convertModuleNameToRoute(this.module)}/list/${this.module}`,
		]);
        this.activeModal.close();
	}

	// New nav changed route to use the enum name in route, replaces spaces with -s and lower cases enum
	convertModuleNameToRoute = (mod: BusinessAreaEnum) => {
		return BusinessAreaEnum.toDescription(mod).split(' ').join('-').toLowerCase();
	}
}
