import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class OrganizationFacilityListVm {
	facilityId: number;
	facilityName?: string;
	facilityIdentifier?: string;
	city?: string;
	county?: string;
	isActive?: string;
	canDelete?: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'facilityName': new UntypedFormControl(null, [...(validators['facilityName'] ?? []), ...[]]),
			'facilityIdentifier': new UntypedFormControl(null, [...(validators['facilityIdentifier'] ?? []), ...[]]),
			'city': new UntypedFormControl(null, [...(validators['city'] ?? []), ...[]]),
			'county': new UntypedFormControl(null, [...(validators['county'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(null, [...(validators['isActive'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(null, [...(validators['canDelete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['facilityId', {"orderId":0,"sortColumn":"facilityId","searchColumn":"facilityId","includeInExcel":true,"exportColumns":null,"displayName":"Navigate to Edit Facility","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['facilityName', {"orderId":1,"sortColumn":"facilityName","searchColumn":"facilityName","includeInExcel":true,"exportColumns":null,"displayName":"Facility","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['facilityIdentifier', {"orderId":2,"sortColumn":"facilityIdentifier","searchColumn":"facilityIdentifier","includeInExcel":true,"exportColumns":null,"displayName":"Identifier","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['city', {"orderId":3,"sortColumn":"city","searchColumn":"city","includeInExcel":true,"exportColumns":null,"displayName":"City","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['county', {"orderId":4,"sortColumn":"county","searchColumn":"county","includeInExcel":true,"exportColumns":null,"displayName":"County","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['isActive', {"orderId":5,"sortColumn":"isActive","searchColumn":"isActive","includeInExcel":true,"exportColumns":null,"displayName":"Is Active?","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['canDelete', {"orderId":6,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":true,"exportColumns":null,"displayName":"Delete","isVisible":false,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): OrganizationFacilityListVmFromFg {
		return new OrganizationFacilityListVmFromFg(fg);
	}
}

export class OrganizationFacilityListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get facilityName(): string {
		return this.fg.get('facilityName').value;
	}
	set facilityName(val) {
		this.fg.get('facilityName').setValue(val);
	}

	get facilityIdentifier(): string {
		return this.fg.get('facilityIdentifier').value;
	}
	set facilityIdentifier(val) {
		this.fg.get('facilityIdentifier').setValue(val);
	}

	get city(): string {
		return this.fg.get('city').value;
	}
	set city(val) {
		this.fg.get('city').setValue(val);
	}

	get county(): string {
		return this.fg.get('county').value;
	}
	set county(val) {
		this.fg.get('county').setValue(val);
	}

	get isActive(): string {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get canDelete(): boolean {
		return this.fg.get('canDelete').value;
	}
	set canDelete(val) {
		this.fg.get('canDelete').setValue(val);
	}
}
