import { faPrescriptionBottlePill } from '@fortawesome/pro-solid-svg-icons';

import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';
import { getPharmacyInventoryNav } from './pharmacy-inventory-nav';

export const getPharmacyNav: () => NavRoute = () => {

	const user = getUser();

	if ((user?.inventorySites?.length ?? 0) !== 0) {
		// Always show the View All Sites link, regardless of site paging
		const pharmacyNavItems: NavRoute[] = [];

		pharmacyNavItems.push({
			name: 'Rx Fulfillment'
			, exact: true
			, path: 'pharmacy/fulfillment/list'
			, activeNavLink: 'pharmacy/fulfillment'
			, secondaryNav: ['Pharmacy', 'Rx Fulfillment']
		});

		pharmacyNavItems.push({
			name: 'Inventories'
			, path: 'pharmacy/inventories/list'
			, childNav: getPharmacyInventoryNav()
			, activeNavLink: 'pharmacy/inventories/list'
			, secondaryNav: ['Pharmacy', 'Inventories']
		});

		pharmacyNavItems.push({
			name: 'Pharm Log'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
				}
			]
			, exact: true
			, path: 'pharmacy/pharm-log/list'
			, activeNavLink: 'pharmacy/pharm-log'
			, secondaryNav: ['Pharmacy', 'Pharm Log']
		});

		pharmacyNavItems.push({
			name: 'Inventory Changes'
			, href: 'pharmacy/inventory-changes'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
				}
			]
			, exact: true
			, path: 'pharmacy/inventory-changes'
			, activeNavLink: 'pharmacy/inventory-changes'
		});

		if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.Inventory)) {
			pharmacyNavItems.push({
				name: 'Reports'
				, href: 'pharmacy/reports/fulfillment-turn-around'
				, children: [
					{
						name: 'Rx Turnaround'
						, exact: true
						, path: 'pharmacy/reports/fulfillment-turn-around'
						, activeNavLink: 'pharmacy/reports/fulfillment-turn-around'
						, secondaryNav: ['Reports', 'Rx Turnaround']
					}
					, {
						name: 'Overage Shortage'
						, exact: true
						, path: 'pharmacy/reports/overage-shortage'
						, activeNavLink: 'pharmacy/reports/overage-shortage'
						, secondaryNav: ['Reports', 'Over / Short']
					}
					, {
						name: 'QC Check'
						, exact: true
						, path: 'pharmacy/reports/qccheck'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.Inventory ]
								, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
							}
							, {
								modules: [ BusinessAreaEnum.Admin ]
								, permissionRoles: [ PermissionRole.SystemAdmin ]
							}
						]
						, requireAll: true
						, activeNavLink: 'pharmacy/reports/qccheck'
						, secondaryNav: ['Reports', 'QC Check']
					}
					, {
						name: 'Reorder'
						, exact: true
						, path: 'pharmacy/reports/reorder'
						, activeNavLink: 'pharmacy/reports/reorder'
						, secondaryNav: ['Reports', 'Reorder']
					}
					, {
						name: 'Inventory Change'
						, exact: true
						, path: 'pharmacy/reports/inventory-change'
						, activeNavLink: 'pharmacy/reports/inventory-change'
						, secondaryNav: ['Pharmacy', 'Reports', 'Inventory Change']
					}
					, {
						name: 'Rx Tracking'
						, exact: true
						, path: 'pharmacy/reports/rx-tracking-number'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.Inventory ]
								, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
							}
						]
						, activeNavLink: 'pharmacy/reports/rx-tracking-number'
						, secondaryNav: ['Reports', 'Rx Tracking']
					}
					, {
						name: 'Rx Deletion'
						, exact: true
						, path: 'pharmacy/reports/fulfillment-deletion'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.Inventory ]
								, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
							}
						]
						, activeNavLink: 'pharmacy/reports/fulfillment-deletion'
						, secondaryNav: ['Reports', 'Rx Deletion']
					}
				]
			});
		}

		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.Inventory)) {
			pharmacyNavItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ErrorReport ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
				, exact: true
				, path: `error-report/error-reports/pharmacy/list/${BusinessAreaEnum.Inventory}`
				, activeNavLink: 'error-report/error-reports/pharmacy'
				, secondaryNav: ['Pharmacy', 'Error Reporting']
			});
		}

		pharmacyNavItems.push({
			name: 'Admin'
			, moduleAccess: [
				{ 
					modules: [ 
						BusinessAreaEnum.Admin
						, BusinessAreaEnum.Inventory
					]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist) 
				}
			]
			, href: 'pharmacy/admin/inventory-actions/list'
			, activeNavLink: 'pharmacy/admin/inventory-actions'
			, secondaryNav: ['Pharmacy', 'Admin', 'Inventory Actions']
			, children: [
				{
					name: 'Inventory Actions'
					, exact: true
					, path: 'pharmacy/admin/inventory-actions/list'
					, activeNavLink: 'pharmacy/admin/inventory-actions'
					, secondaryNav: ['Pharmacy', 'Admin', 'Inventory Actions']
				}
				, { 
					name: 'Programs'
					, exact: true
					, path: 'pharmacy/admin/programs'
					, activeNavLink: 'pharmacy/admin/programs'
					, secondaryNav: ['Admin', 'Programs']
				}
				, {
					name: 'Departments'
					, exact: true
					, path: 'pharmacy/admin/departments'
					, activeNavLink: 'pharmacy/admin/departments'
					, secondaryNav: ['Admin', 'Departments']
				}
				, {
					name: 'Script Status'
					, exact: true
					, path: 'pharmacy/admin/script-status'
					, activeNavLink: 'pharmacy/admin/script-status'
					, secondaryNav: ['Admin', 'Script Status']
				}
				, {
					name: 'Non Rx Types'
					, exact: true
					, path: 'pharmacy/admin/non-rx-types'
					, activeNavLink: 'pharmacy/admin/non-rx-types'
					, secondaryNav: ['Admin', 'Non Rx Types']
				}
				, {
					name: 'Verification Settings'
					, exact: true
					, path: 'pharmacy/admin/verification-settings'
					, moduleAccess: [
						{
							modules: [ 
								BusinessAreaEnum.Admin
								, BusinessAreaEnum.Inventory 
							]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist) 
						}
					]
					, activeNavLink: 'pharmacy/admin/verification-settings'
					, secondaryNav: ['Admin', 'Verification Settings']
				}
			]
		});

		return {
			name: 'Pharmacy'
			, href: 'pharmacy/fulfillment/list'
			, activeNavLink: 'pharmacy/fulfillment'
			, secondaryNav: ['Pharmacy', 'Rx Fulfillment']
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
			, children: pharmacyNavItems
			, icon: faPrescriptionBottlePill
			, area: 'pharmacy'
			, module: BusinessAreaEnum.Inventory
		};
	}

	const pharmacyNavItems: NavRoute[] = [];

	pharmacyNavItems.push({
		name: 'Rx Fulfillment'
		, exact: true
		, path: 'pharmacy/fulfillment/list'
		, activeNavLink: 'pharmacy/fulfillment'
		, secondaryNav: ['Pharmacy', 'Rx Fulfillment']
	});

	pharmacyNavItems.push({
		name: 'Inventories'
		, exact: true
		, path: 'pharmacy/inventories/list'
		, activeNavLink: 'pharmacy/inventories/list'
		, secondaryNav: ['Pharmacy', 'Inventories']
	});

	pharmacyNavItems.push({
		name: 'Pharm Log'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Inventory ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
			}
		]
		, exact: true
		, path: 'pharmacy/pharm-log/list'
		, activeNavLink: 'pharmacy/pharm-log'
		, secondaryNav: ['Pharmacy', 'Pharm Log']
	});

	pharmacyNavItems.push({
		name: 'Inventory Changes'
		, href: 'pharmacy/inventory-changes'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Inventory ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
			}
		]
		, exact: true
		, path: 'pharmacy/inventory-changes'
		, activeNavLink: 'pharmacy/inventory-changes'
		, secondaryNav: ['Pharmacy', 'Inventory Changes']
	});

	if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.Inventory)) {
		pharmacyNavItems.push({
			name: 'Reports'
			, href: 'pharmacy/reports/fulfillment-turn-around'
			, children: [
				{
					name: 'Rx Turnaround'
					, exact: true
					, path: 'pharmacy/reports/fulfillment-turn-around'
					, activeNavLink: 'pharmacy/reports/fulfillment-turn-around'
					, secondaryNav: ['Reports', 'Rx Turnaround']
				} 
				, {
					name: 'Over / Short'
					, exact: true
					, path: 'pharmacy/reports/overage-shortage'
					, activeNavLink: 'pharmacy/reports/overage-shortage'
					, secondaryNav: ['Reports', 'Over / Short']
				}
				, {
					name: 'QC Check Report'
					, exact: true
					, path: 'pharmacy/reports/qccheck'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.Inventory ]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
						}
						, {
							modules: [ BusinessAreaEnum.Admin ]
							, permissionRoles: [ PermissionRole.SystemAdmin ]
						}
					]
					, requireAll: true
					, activeNavLink: 'pharmacy/reports/qccheck'
					, secondaryNav: ['Reports', 'QC Check']
				}
				, {
					name: 'Reorder'
					, exact: true
					, path: 'pharmacy/reports/reorder'
					, activeNavLink: 'pharmacy/reports/reorder'
					, secondaryNav: ['Reports', 'Reorder']
				}
				, {
					name: 'Inventory Change'
					, exact: true
					, path: 'pharmacy/reports/inventory-change'
					, activeNavLink: 'pharmacy/reports/inventory-change'
					, secondaryNav: ['Pharmacy', 'Reports', 'Inventory Change']
				}
				, {
					name: 'Rx Tracking'
					, exact: true
					, path: 'pharmacy/reports/rx-tracking-number'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.Inventory ]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
						}
					]
					, activeNavLink: 'pharmacy/reports/rx-tracking-number'
					, secondaryNav: ['Reports', 'Rx Tracking']
				}
				, {
					name: 'Rx Deletion'
					, exact: true
					, path: 'pharmacy/reports/fulfillment-deletion'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.Inventory ]
							, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
						}
					]
					, activeNavLink: 'pharmacy/reports/fulfillment-deletion'
					, secondaryNav: ['Reports', 'Rx Deletion']
				}
			]
		});
	}

	if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.Inventory)) {
		pharmacyNavItems.push({
			name: 'Error Reporting'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.ErrorReport ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, exact: true
			, path: `error-report/error-reports/list/${BusinessAreaEnum.Inventory}`
			, activeNavLink: 'error-report/error-reports/list'
			, secondaryNav: ['Pharmacy', 'Error Reporting']
		});
	}

	return {
		name: 'Pharmacy'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Inventory ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
			}
		]
		, children: pharmacyNavItems
		, icon: faPrescriptionBottlePill
		, area: 'pharmacy'
		, module: BusinessAreaEnum.Inventory
	};
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
