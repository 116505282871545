import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';
import { GenericFilesVm, GenericFilesVmFromFg } from '../../../Models/GenericFilesVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class MedicalDirectorVm {
	medicalDirectorId: number;
	fullName?: string;
	licenseNumber?: string;
	expirationDate?: Date;
	expirationDateStr?: string;
	lastUpdatedDate?: Date;
	lastUpdatedDateStr?: string;
	lastUpdatedUserId?: number;
	lastUpdatedBy?: string;
	pfsId?: number;
	friendlyId?: string;
	uploadFiles?: any;
	medicalDirectorFiles?: GenericFilesVm[];
	licenseFileName?: string;
	actions?: string;
	actionsSelected?: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'medicalDirectorId': new UntypedFormControl(0, [...(validators['medicalDirectorId'] ?? []), ...[Validators.required]]),
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[Validators.required, Validators.maxLength(256)]]),
			'licenseNumber': new UntypedFormControl(null, [...(validators['licenseNumber'] ?? []), ...[Validators.required, Validators.maxLength(256)]]),
			'expirationDate': new UntypedFormControl(null, [...(validators['expirationDate'] ?? []), ...[Validators.required]]),
			'expirationDateStr': new UntypedFormControl(null, [...(validators['expirationDateStr'] ?? []), ...[]]),
			'lastUpdatedDate': new UntypedFormControl(null, [...(validators['lastUpdatedDate'] ?? []), ...[]]),
			'lastUpdatedDateStr': new UntypedFormControl(null, [...(validators['lastUpdatedDateStr'] ?? []), ...[]]),
			'lastUpdatedUserId': new UntypedFormControl(null, [...(validators['lastUpdatedUserId'] ?? []), ...[]]),
			'lastUpdatedBy': new UntypedFormControl(null, [...(validators['lastUpdatedBy'] ?? []), ...[]]),
			'pfsId': new UntypedFormControl(null, [...(validators['pfsId'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'uploadFiles': new UntypedFormControl([], [...(validators['uploadFiles'] ?? []), ...[]]),
			'medicalDirectorFiles': new UntypedFormArray([]),
			'licenseFileName': new UntypedFormControl(null, [...(validators['licenseFileName'] ?? []), ...[]]),
			'actions': new UntypedFormControl(null, [...(validators['actions'] ?? []), ...[]]),
			'actionsSelected': new UntypedFormControl(null, [...(validators['actionsSelected'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['actions', {"orderId":0,"sortColumn":"actions","searchColumn":"actions","includeInExcel":true,"exportColumns":null,"displayName":"Actions","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['fullName', {"orderId":1,"sortColumn":"fullName","searchColumn":"fullName","includeInExcel":true,"exportColumns":null,"displayName":"Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['licenseNumber', {"orderId":2,"sortColumn":"licenseNumber","searchColumn":"licenseNumber","includeInExcel":true,"exportColumns":null,"displayName":"License #","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['expirationDateStr', {"orderId":3,"sortColumn":"expirationDate","searchColumn":"expirationDateStr","includeInExcel":true,"exportColumns":null,"displayName":"Expiration Date","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['licenseFileName', {"orderId":4,"sortColumn":"licenseFileName","searchColumn":"licenseFileName","includeInExcel":true,"exportColumns":null,"displayName":"License Upload","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['lastUpdatedDateStr', {"orderId":5,"sortColumn":"lastUpdatedDate","searchColumn":"lastUpdatedDateStr","includeInExcel":true,"exportColumns":null,"displayName":"Updated Date","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['lastUpdatedBy', {"orderId":6,"sortColumn":"lastUpdatedBy","searchColumn":"lastUpdatedBy","includeInExcel":true,"exportColumns":null,"displayName":"Updated By","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): MedicalDirectorVmFromFg {
		return new MedicalDirectorVmFromFg(fg);
	}
}

export class MedicalDirectorVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get medicalDirectorId(): number {
		return this.fg.get('medicalDirectorId').value;
	}
	set medicalDirectorId(val) {
		this.fg.get('medicalDirectorId').setValue(val);
	}

	get fullName(): string {
		return this.fg.get('fullName').value;
	}
	set fullName(val) {
		this.fg.get('fullName').setValue(val);
	}

	get licenseNumber(): string {
		return this.fg.get('licenseNumber').value;
	}
	set licenseNumber(val) {
		this.fg.get('licenseNumber').setValue(val);
	}

	get expirationDate(): Date {
		return this.fg.get('expirationDate').value;
	}
	set expirationDate(val) {
		this.fg.get('expirationDate').setValue(val);
	}

	get expirationDateStr(): string {
		return this.fg.get('expirationDateStr').value;
	}
	set expirationDateStr(val) {
		this.fg.get('expirationDateStr').setValue(val);
	}

	get lastUpdatedDate(): Date {
		return this.fg.get('lastUpdatedDate').value;
	}
	set lastUpdatedDate(val) {
		this.fg.get('lastUpdatedDate').setValue(val);
	}

	get lastUpdatedDateStr(): string {
		return this.fg.get('lastUpdatedDateStr').value;
	}
	set lastUpdatedDateStr(val) {
		this.fg.get('lastUpdatedDateStr').setValue(val);
	}

	get lastUpdatedUserId(): number {
		return this.fg.get('lastUpdatedUserId').value;
	}
	set lastUpdatedUserId(val) {
		this.fg.get('lastUpdatedUserId').setValue(val);
	}

	get lastUpdatedBy(): string {
		return this.fg.get('lastUpdatedBy').value;
	}
	set lastUpdatedBy(val) {
		this.fg.get('lastUpdatedBy').setValue(val);
	}

	get pfsId(): number {
		return this.fg.get('pfsId').value;
	}
	set pfsId(val) {
		this.fg.get('pfsId').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get licenseFileName(): string {
		return this.fg.get('licenseFileName').value;
	}
	set licenseFileName(val) {
		this.fg.get('licenseFileName').setValue(val);
	}

	get actions(): string {
		return this.fg.get('actions').value;
	}
	set actions(val) {
		this.fg.get('actions').setValue(val);
	}

	get actionsSelected(): boolean {
		return this.fg.get('actionsSelected').value;
	}
	set actionsSelected(val) {
		this.fg.get('actionsSelected').setValue(val);
	}
}
