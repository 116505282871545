import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SystemMessage } from "app/core/system-message/system-message-service";
import { FacilityInventoryVm } from "app/shared/generated/Administration/Models/Facilities/Inventories/FacilityInventoryVm";
import { IFacilityInventoryService } from "./facility-inventory.interface.service";

@Injectable({
    providedIn: 'root'
  })
  export class FacilityInventoryService implements IFacilityInventoryService {
    
    constructor(private http: HttpClient) {
  
    }
  
    getInventory = (id: number) => 
      this.http.get<FacilityInventoryVm>(`api/Facility/Inventory/GetInventory?inventoryId=${id}`);
  
    activateDeactivate = (id: number) => 
      this.http.get<SystemMessage>(`api/Facility/Inventory/ActivateDeactivate?inventoryId=${id}`);
}