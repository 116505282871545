import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: "[inputAutofocus]",
    standalone: false
})
export class InputAutofocusDirective implements AfterViewInit 
{
    private focus = true;

    constructor(private el: ElementRef){}

    ngAfterViewInit() {
        if (this.focus)
            {
                //Otherwise Angular throws error: Expression has changed after it was checked.
                window.setTimeout(() =>
                {
                    this.el.nativeElement.focus(); //For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
                });
        }    
    }

    @Input() set inputAutofocus(condition: boolean)
    {
        this.focus = condition !== false;
    }
}