import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { FacilityListVm } from 'app/shared/generated/Administration/Models/Facilities/FacilityListVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-shared-facilities-list',
    templateUrl: './facilities-list.component.html',
    styleUrls: ['./facilities-list.component.scss'],
    standalone: false
})
export class FacilitiesListComponent implements OnInit {

    @ViewChild(TableComponent, { static: true }) table: TableComponent<FacilityListVm>;

    @Input() businessArea: BusinessAreaEnum;
    @Input() header: string;
    @Input() filterMap = {
		"isActive": "Is Active"
        , "businessArea": "Business Area"
        , "medicalDirectorId": "Medical Director"
        , "organizationId": "Organization"
	};

    filters = {
        isActive: true
        , businessArea: null
        , medicalDirectorId: null
        , organizationId: null
    };

    initFilters = {
        isActive: true
        , businessArea: null
        , medicalDirectorId: null
        , organizationId: null
    };
    baseEditRoles = [ 
        PermissionRole.UserAdmin
        , ...SecurityService.setMinRole(PermissionRole.Manager)
    ];

    canAdd = false;
    canDelete = false;
    showBusinessArea = true;
    columns = FacilityListVm.ColumnDefs;

    hasFacilities = true;

    constructor(
        private formatHelper: FormatHelperService
        , public btnFilterService: ButtonFilterService
        , public security: SecurityService
        , public router: Router
    ) { }

    ngOnInit(): void {
        this.filters.businessArea = this.businessArea;
        this.initFilters.businessArea = this.businessArea; 
        if (this.businessArea && this.businessArea != BusinessAreaEnum.Admin) { this.showBusinessArea = false; }
        this.canAdd = this.security.hasModuleAccess(
            [
                BusinessAreaEnum.Admin
                , BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [ PermissionRole.SystemAdmin ]
        );
        this.canDelete = this.security.hasModuleAccess(
            [ this.businessArea ]
            , [ PermissionRole.SystemAdmin ]
        );
        this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters(); });
    }

    onTableReturn(tableData: boolean) { this.hasFacilities = tableData; }

    resetFilters() {
		this.filters = Object.assign(this.filters, this.initFilters);
		this.reloadTable();
	}

    canEditFacility = (): boolean => this.security.hasModuleAccess(
        [ this.businessArea ]
        , [
            PermissionRole.UserAdmin
            , ...SecurityService.setMinRole(PermissionRole.Manager)
        ]) 
        || this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ ...SecurityService.setMinRole(PermissionRole.User) ]
        );

    editFacilityLink(): string {
        switch(this.businessArea) {
            case BusinessAreaEnum.CI: return '/ci-repack/facilities/edit/';
            case BusinessAreaEnum.MetabolicFormula: return '/metabolic-formula/facilities/edit/';
            default: return '/admin/organizations/facilities/edit/';
        }
    }

    reloadTable() {
	    this.table.hideShowColumn('isActive', this.formatHelper.GetIsNully(this.filters.isActive));
        this.table.ajaxReload();
    }
}
