<form 
    [formGroup]="formGroup" 
    (ngSubmit)="submitForms();"
>
    <div class="section-ctnr">
        <input type='hidden' formControlName='facilityId' />
        <input type='hidden' formControlName='countyName' />

        <div class="row">
            <pcg-control-group 
                class="col-md-4" 
                label="Facility Name"
            >
                <input 
                    pcgControlRef 
                    formControlName="facilityName" 
                    class="form-control" 
                    type="text" 
                    maxlength="50" 
                />
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-2" 
                label="Identifier"
                labelTooltip='The facility identifier is the commercial store number OR the public facility unique identifier.'
            >
                <input 
                    pcgControlRef 
                    formControlName="facilityIdentifier" 
                    class="form-control" 
                    type="text" 
                />
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-2" 
                label="DAU Code" 
                labelTooltip='This field is used to match this facility in HOMER with the record for the corresponding Facility in HMS. The DAU is a 
                6-character code that is used for DOH facilities throughout the State.'
            >
                <input 
                    pcgControlRef 
                    formControlName="dauCode" 
                    class="form-control" 
                    type="text" 
                    maxlength="6" 
                />
            </pcg-control-group>

            <button *ngIf="facilityId != 0" type="button" class="btn btn-outline-dark m-4" (click)="navToFacilityDetail()">
                <fa-icon [icon]="faIconName.faExternalLink"></fa-icon>
                Go to Facility
            </button>
            
            <div *ngIf="canDeactivate" class="ml-auto align-self-center" id="activation">
                <div class="activate">
                    <p class="mt-2" [class.text-danger]="!model.isActive"
                        [class.d-none]="model.isActive">
                        This Facility is deactivated.</p>
                    <a *ngIf="canEdit && model.facilityId !== 0"
                        [class.btn-outline-danger]="model.isActive" [class.btn-outline-success]="!model.isActive"
                        class="btn btn-md ml-2"
                        (click)="activateDeactivate(); model.isActive = !model.isActive;">
                        {{ model.isActive ? 'Deactivate' : 'Reactivate' }}
                    </a>
                    <button #submit [hidden]="true"></button>
                </div>
            </div>
        </div>

        <div class="row align-items-end">
            <pcg-control-group 
                class="col-md-2" 
                label="GLN" 
                labelTooltip="Global Location Number"
            >
                <input 
                    pcgControlRef 
                    class='form-control' 
                    type='text' 
                    autocomplete='off'
                    pcgNoSpecialChars='noSpecialChars' 
                    formControlName='gln' 
                />
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-2" 
                label="Phone"
            >
                <input 
                    pcgPhoneExt 
                    pcgControlRef 
                    class='form-control' 
                    type='text' 
                    formControlName="phoneNumber" 
                />
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-2 adjust-padding" 
                label="Reconciliation Counts" 
                labelTooltip="Determines whether one or two counts are required during reconciliations for all inventories associated to this facility."
            >
                <pcg-select 
                    #select 
                    [sendFormData]='false' 
                    pcgControlRef 
                    [multiple]='false'
                    dataSource='api/Select/NumberOfCountsRequired' 
                    formControlName='reconCountsRequired'
                    placeholder="Select ..."
                ></pcg-select>
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-2 adjust-padding" 
                label="Facility Type" 
                [labelTooltip]="facilityTypeTooltip()"
            >
                <pcg-select 
                    #facilityTypeSelect 
                    [sendFormData]='false' 
                    pcgControlRef [multiple]='false'
                    dataSource='api/Select/GetSysInvFacilityTypes' 
                    formControlName='facilityTypeId'
                    placeholder="Select ..."
                ></pcg-select>
            </pcg-control-group>

            <div>
                <button class="btn btn-outline-dark m-4" type="button" (click)="openChartModal()" 
                [ngbTooltip]="!formGroup?.controls?.facilityTypeId?.value ? 'Select a facility type to see program and account associations' : null"
                [disabled]="!formGroup?.controls?.facilityTypeId?.value">
                    <fa-icon [icon]="faIconName.faDiagramNested"></fa-icon>
                    Account Map
                </button>
            </div>

            <button *ngIf='canEdit' class='ml-auto btn btn-save btn-lg align-self-center' (click)="validateCountyId()">
                {{ facilityId === 0 ? "Create": "Save" }}
            </button>


            <!-- Use to toggle hidden fields for testing -->
            <!-- <div
                class="btn btn-success toggle mt-4"
                (click)="showHiddenFields = !showHiddenFields">Toggle Fields
            </div> -->
        </div>
    </div>

    <div>
        <!-- The following inputs are inherited from the parent Organization and are currently always hidden. -->
        <!-- Leaving this here in case we decide to give users the ability to edit these fields at the child facility level within the org workflow -->
        <div [class.d-none]="!showHiddenFields">
            <div class="row">
                <pcg-control-group 
                    class="col-md-4" 
                    label="Medical Director"
                >
                    <pcg-select 
                        #select 
                        [sendFormData]='false' 
                        pcgControlRef 
                        [multiple]='false'
                        dataSource='api/Select/MedicalDirectors' 
                        formControlName='medicalDirectorId'
                        placeholder="Select ..." 
                        (change)="medDirectorChange(select)"
                    ></pcg-select>
                </pcg-control-group>

                <div class="col-md-2">
                    <label class="for">License</label>
                    {{ model?.medicalDirectorLicense }}
                </div>

                <div class="col-md-2">
                    <label class="for">Exp Date</label>
                    {{ model?.medicalDirectorExpDate }}
                </div>
            </div>

            <div class="container my-3">
                <h3>Address</h3>
                <hr />

                <div class="row">
                    <pcg-control-group 
                        class="col-md-8" 
                        label="Address"
                        labelTooltip="When searching apartment complexes be sure to use Apt after the street number and name to see the list of 
                        apartment numbers. For example, 2000 N Meridian Rd Apt will bring up a list of the apartments for that complex."
                    >
                        <pcg-smarty 
                            pcgControlRef 
                            formControlName="smartyAddress" 
                            [showAllStates]="false"
                            [canManualEntry]="canManualEntry" 
                            [getFullZip]="true" 
                            (change)='updateShippingInfo()'
                            [countyApi]="api"
                        ></pcg-smarty>
                    </pcg-control-group>

                    <div class="col-md-2 d-flex flex-column">
                        <label class="for">County</label>
                        <span>{{ this.formGroup?.controls?.countyName?.value }}</span>
                    </div>
                </div>
            </div>

            <div class="container mb-3">
                <h3>Shipping Address</h3>
                <hr />

                <div class="row">
                    <pcg-control-group 
                        class="col-md-4" 
                        label="Shipping Name"
                    >
                        <input 
                            [hidden]="shipAddressDisabled" 
                            pcgControlRef 
                            formControlName="shipFacilityName"
                            class="form-control" 
                            type="text" 
                        />
                        <span [hidden]="!shipAddressDisabled">{{ this.formGroup?.controls?.shipFacilityName?.value }}</span>
                    </pcg-control-group>

                    <pcg-control-group 
                        class="col-md-6" 
                        label="Address"
                    >
                        <pcg-smarty 
                            pcgControlRef 
                            formControlName="shipSmartyAddress" 
                            [showAllStates]="false"
                            [hidden]="shipAddressDisabled" 
                            [canManualEntry]="canManualEntry" 
                            [countyApi]="api"
                        ></pcg-smarty>
                        <span [hidden]="!shipAddressDisabled">{{ this.formGroup?.controls?.shipSmartyAddress?.value?.formattedAddress }}</span>
                    </pcg-control-group>

                    <pcg-control-group 
                        class="col-md-1 form-check-label" 
                        label="Is Same?"
                        labelTooltip="Verification that facility address is same as shipping address."
                    >
                        <ui-switch 
                            pcgControlRef 
                            #isSameAddress 
                            color='#007bff' 
                            (change)="updateShippingInfo()"
                            defaultBgColor='#EEE' 
                            class='ml-2 mt-2' 
                            size='small'
                            formControlName='isSameAddress'
                        ></ui-switch>
                    </pcg-control-group>

                    <pcg-control-group 
                        class="col-md-1 form-check-label" 
                        label="Is Validated?"
                    >
                        <ui-switch 
                            pcgControlRef 
                            #isShipAddressValidated 
                            color='#007bff' 
                            defaultBgColor='#EEE'
                            class='ml-2 mt-2' 
                            size='small' 
                            formControlName='isShipAddressValidated'
                        ></ui-switch>
                    </pcg-control-group>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Custom system Message -->
<div 
    id="system-messages-wrapper" 
    *ngIf="showReconErrorMessage" 
    [ngStyle]="getCenteredStyles()"
	(click)="closeSystemMessage()"
>
	<div class="{{firstLoad ? '': 'd-none'}}">
		<span 
            aria-hidden="true" 
            id="sys-message-closer"
        > X </span>
		<div 
            role="alert" 
            aria-live="polite" 
            aria-atomic="true" 
            id="system-messages" 
            class="error"
        >
			<span id="sys-message-header">Error!</span>
			The Reconciliation Counts Required setting cannot be changed while an inventory has pending reconciliations.
			<br class="py-4" />
			Click to go to
			<button 
                *ngFor="let recon of pendingRecons" 
                class="btn btn-outline-cancel btn-sm m-1"
				[routerLink]="'/inv/invs/reconciliations/' + recon.sysInvReconciliationId + '/' + recon.sysInvInventoryId"
            >{{ recon.reconciliationName }}</button>
			<br>
		</div>
	</div>
</div>

<ng-template #infographicModal let-modal>
    <div class="modal-header d-flex bg-secondary text-white">
        <h5 class="modal-title">{{facilityTypeName}}</h5>
        <button 
            type="button" 
            class="close text-white" 
            aria-label="Close" 
            (click)="modal.dismiss();"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <pcg-programs-accounts-infographic [facilityTypeId]="formGroup.controls?.facilityTypeId?.value"></pcg-programs-accounts-infographic>
    </div>    
</ng-template>