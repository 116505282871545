import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
} from "@angular/core";
import { Title } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { GlobalService } from "app/shared/services/global.service";
import { NavRoute } from "../nav.route.interface";
import { NavigationService } from "../navigation.service";

@Component({
    selector: "pcg-top-secondary-nav",
    templateUrl: "./top-secondary-nav.component.html",
    styleUrls: ["./top-secondary-nav.component.scss"],
    standalone: false
})
export class TopSecondaryNavComponent implements OnInit, OnChanges {

	@Input() navRoutes: NavRoute[] = [];
	@Input() rootNavId: string;
	@Input() routeNavId: string;
	@Input() forceSelect: string;
	@Input() tabPanel: any;

	@Output() clickedNav = new EventEmitter<boolean>();

	@ViewChildren("navLink", { read: ElementRef }) navLinks: QueryList<ElementRef>;

	loopNavRoutes: NavRoute[] = [];
	hrefLocation: string = "";

	faIconName = { faArrowLeft };

	isMobile: boolean;
	@HostListener("window:resize")
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	constructor(
		public navService: NavigationService,
		public router: Router,
		private title: Title
	) {
		this.setTitle();
	}

	ngOnInit() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.loopNavRoutes = this.getNavRoutes();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.getActiveLink();
		const chng = changes["navRoutes"];
		const cur = chng?.currentValue;
		const prev = chng?.previousValue;
		if (cur !== prev) {
			this.loopNavRoutes = this.getNavRoutes();				
			this.focusNewlySelectedNav();
			this.setTitle();
		}
	}

	// Set a descriptive title for the page instead of the default "HOMER" title when one is not set.
	private setTitle() {
		// If the string is null or whitespace, use 'HOMER'
		const checkTitle = (s) => FormatHelperService.GetIsNullyOrWhitespace(s) ? 'HOMER' : s;
		
		// Check the title of the page
		if (checkTitle(this.title.getTitle()) === "HOMER") {
			// Check the title of the nav menu
			let title = checkTitle(this.navService.dynamicNavTitle());
			if (title == 'HOMER') {
				// Check the title of the currently open secondary nav menu
				title = checkTitle(this.navService.currOpenSecondaryNavMenu()?.name);
			}
			this.title.setTitle(title);
		}
	}

	private focusNewlySelectedNav() {
		setTimeout(() => {
			if (this.navLinks && this.navLinks.length > 0) {
				const selectedNav = this.navLinks.find((link) =>
					link.nativeElement.classList.contains("link-active")
				);

				if (selectedNav) {
					selectedNav.nativeElement.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
					selectedNav.nativeElement.focus();
				}
			}
		}, 0);
	}

	// Show or hide submenu based on dropdown click
	clickDropdown(childMenu: NavRoute) {
		if (childMenu?.childNav) {
			childMenu = childMenu.parentNav;
		}
		this.navService.currNav.set(childMenu);
		return false;
	}

	redirect(route) {
		if (route.openNewTab) { this.openInNewTab(route.path); } 
		return true;
	}

	openInNewTab(namedRoute: string) {
		let newRelativeUrl = this.router.createUrlTree([namedRoute]);
		let baseUrl = window.location.href.replace(this.router.url, "");
		window.open(baseUrl + newRelativeUrl, "_blank");
	}

	getActiveLink() {
		// parse URL for active link in subnav
		let urlSegments = window.location.href.split(document.getElementsByTagName("base")[0].href)[1].split("/");
		if (
			!isNaN(Number(urlSegments[urlSegments?.length - 1])) 
			&& !isNaN(Number(urlSegments[urlSegments?.length - 2]))
		) { this.hrefLocation = urlSegments.slice(0, urlSegments?.length - 2).join("/"); } 
		else if (
			!isNaN(Number(urlSegments[urlSegments?.length - 1])) 
			&& isNaN(Number(urlSegments[urlSegments?.length - 2]))
		) { this.hrefLocation = urlSegments.slice(0, urlSegments?.length - 1).join("/");
		} else { this.hrefLocation = urlSegments.join("/"); }
	}

	getNavRoutes() {
		const routeIndex = this.navService.getCurrRouteIndex(this.navRoutes);
		return (
			this.navRoutes
				?.map((o, i) => { return { ...o, index: i }; })
				.filter((route) =>
					!route?.shouldDisplay 
					|| route.ellipseAfter 
					|| route.ellipseBefore 
					|| route?.shouldDisplay(routeIndex)
				) ?? []
		);
	}
}
