import { Component, OnDestroy, OnInit } from "@angular/core";
import { SecurityService } from "app/core/security/security.service";
import { SendgridApiService } from "app/core/sendgrid-api/sendgrid-api.service";
import { Subscription } from "rxjs";
import { UserJsVm } from "app/shared/generated/Administration/Models/Users/UserJsVm";
import { SendgridBounceVm, SendgridUserBounceResponse } from 'app/shared/models/SendgridUserBounceResponse';

@Component({
    selector: "pcg-bounce-alert",
    templateUrl: "./bounce-alert.component.html",
    styleUrls: ["./bounce-alert.component.scss"],
    standalone: false
})
export class BounceAlertComponent implements OnInit, OnDestroy {
	user: UserJsVm;
	bouncedEmailData: SendgridBounceVm;
	userIsBounced: boolean = false;
	errorMsg: string;
	subscriptions: Subscription = new Subscription();

	constructor(
		private sendgridApiService: SendgridApiService,
		private securityService: SecurityService
	) {}

	ngOnInit(): void {
		this.getActiveUser();
		if (this.user != null) {
			this.getBouncedEmailMessages(this.user.email);
		}
	}

	getBouncedEmailMessages(username: string) {
		this.sendgridApiService
			.getIsBouncedResponse(username)
			.subscribe((response: SendgridUserBounceResponse) => {
				if (response.isBounced) {
					this.userIsBounced = true;
					this.bouncedEmailData = response.sendgridBounceInfo;
				}
			});
	}

	getActiveUser() {
		this.subscriptions.add(
			this.securityService.user$.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			})
		);
	}

	removeFromBounceList() {
		this.subscriptions.add(
			this.sendgridApiService
				.removeBouncedUser(this.user.email)
				.subscribe((resp) => {
					if (resp === 204) {
						this.userIsBounced = false;
					} else {
						this.errorMsg =
							"Unable to remove user from the bounce list at this time. Please try again later.";
					}
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
