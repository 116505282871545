import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class OrganizationUsersVm {
	id: number;
	name?: string;
	email?: string;
	phoneNumber?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['name', {"orderId":0,"sortColumn":"name","searchColumn":"name","includeInExcel":true,"exportColumns":null,"displayName":"Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['email', {"orderId":1,"sortColumn":"email","searchColumn":"email","includeInExcel":true,"exportColumns":null,"displayName":"Email","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['phoneNumber', {"orderId":2,"sortColumn":"phoneNumber","searchColumn":"phoneNumber","includeInExcel":true,"exportColumns":null,"displayName":"Phone","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): OrganizationUsersVmFromFg {
		return new OrganizationUsersVmFromFg(fg);
	}
}

export class OrganizationUsersVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get name(): string {
		return this.fg.get('name').value;
	}
	set name(val) {
		this.fg.get('name').setValue(val);
	}

	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}
}
