import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserModulesVm } from 'app/shared/generated/Administration/Models/Security/UserModulesVm';
import { toObservable } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class EditUserService {

  http = inject(HttpClient);

  moduleAccessesSignal = signal<UserModulesVm>(null);
  moduleAccesses$ = toObservable(this.moduleAccessesSignal);

  setInitialSignal = (id: number) => {
    this.getModuleAccesses(id).subscribe(
      (r: UserModulesVm) => this.moduleAccessesSignal.set(r) 
    );
  }

  getModuleAccesses = (id: number) => {
    return this.http.get(`api/Administration/ModuleAccess/GetModuleAccessByUser?userId=${id}`)
  }

  setModuleAccesses = (fg: FormGroup) => {
    return this.http.post(`api/Administration/ModuleAccess/SaveModule/`, fg.value)
  }
}
