import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { HolidaysVm } from 'app/shared/generated/Models/HolidaysVm';

@Component({
    selector: 'pcg-holidays',
    templateUrl: './holidays.component.html',
    styleUrls: ['./holidays.component.scss'],
    standalone: false
})
export class HolidaysComponent implements OnInit {

	subscriptions = new Subscription();
	holidays: HolidaysVm[] = [];

	currentYear: number = new Date().getFullYear();

	constructor(private modalService: NgbModal, public modal: NgbActiveModal, private httpClient: HttpClient) { }

	ngOnInit() {
		this.subscriptions.add(this.httpClient.get(`api/Home/GetHolidaysByYear/?year=${this.currentYear}`)
			.subscribe((list: HolidaysVm[]) => {
				this.holidays = list;
			}));
	}
}
