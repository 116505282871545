import { Component, OnInit, Input } from '@angular/core';

import { LoginHistoryVm } from 'app/shared/generated/Administration/Models/LoginHistoryVm';

@Component({
    selector: 'pcg-user-login-history',
    templateUrl: './user-login-history.component.html',
    styleUrls: ['./user-login-history.component.scss'],
    standalone: false
})
export class UserLoginHistoryComponent implements OnInit {

	columns = LoginHistoryVm.ColumnDefs;
	@Input() id = 0;

	constructor() { }

	ngOnInit() { }
}
