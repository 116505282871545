import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { pcgSettings } from 'app/shared/generated/pcg-settings';

@UntilDestroy()
@Component({
    selector: 'meta-pixel',
    templateUrl: './meta-pixel.component.html',
    standalone: false
})

//Documentation link for Facebook Pixel:
//https://developers.facebook.com/docs/facebook-pixel
//https://developers.facebook.com/docs/meta-pixel/get-started
export class MetaPixelComponent implements AfterViewInit {

	constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private document: Document) { }

	ngAfterViewInit() {
		if (pcgSettings.mode == 'Production') {
			let script = this.renderer2.createElement('script');
			script.type = `application/javascript`;
			script.text = `				
                !function(f,b,e,v,n,t,s)
				{
					if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)
				};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '974545107058270');
				fbq('track', 'PageView');
			`;

			let noscript = this.renderer2.createElement('noscript');
			noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=974545107058270&ev=PageView&noscript=1"/>`;

			this.renderer2.appendChild(this.document.body, this.renderer2.createComment('Start Facebook Pixel Code'));
			this.renderer2.appendChild(this.document.body, script);
			this.renderer2.appendChild(this.document.body, noscript);
			this.renderer2.appendChild(this.document.body, this.renderer2.createComment('End Facebook Pixel Code'));
		}
	}

}
