<section class="stepper-body">
	<header class="stepper-navigation-bar">
		<ng-container *ngFor="let step of steps; let i = index; last as isLast">
			<!-- Completed Step -->
			<div *ngIf="(selectedIndex > i - 1) || (i < currentStep || i === currentStep)" class="d-flex">				
				<!-- Step Number -->
				<div class="stepper-number-active mr-1" [ngClass]="{ 'stepper-active': selectedIndex > i - 1 }">
					<span class="center-number" *ngIf="isChild">{{ parentStep }}.{{ i + 1 }}</span>
					<span class="center-number" *ngIf="!isChild">{{ i + 1 }}</span>
				</div>

				<!-- Step Label -->
				<button class="step-active" (click)="manualClick(i)">
					<span [class.active-btn]="i === selectedIndex">{{ step.label }}</span>
				</button>
			</div>
			
			<!-- Upcoming Step -->
			<div *ngIf="!(selectedIndex > i - 1) && (i > currentStep)" class="d-flex">
				<!-- Step Number -->
				<div class="stepper-number-inactive mr-1" [ngClass]="{ 'stepper-active': selectedIndex > i - 1 }">
					<span class="center-number" *ngIf="isChild">{{ parentStep }}.{{ i + 1 }}</span>
					<span class="center-number" *ngIf="!isChild">{{ i + 1 }}</span>
				</div>

				<!-- Step Label -->
				<button class="step-inactive" (click)="manualClick(i)">
					{{ step.label }}
				</button>
			</div>
			<hr *ngIf="(!isLast)" class="divider" />
		</ng-container>
	</header>
	<hr />
	<div class="content-container">
		<div
			class="stepper-content"
			*ngFor="let step of steps; let i = index"
			[style.display]="stepDisplay[i]"
			[@stepTransition]="_getAnimationDirection(i)"
			(@stepTransition.done)="_animationDone.next($event)"
			[attr.tabindex]="selectedIndex === i ? 0 : null"
			[id]="_getStepContentId(i)"
			[attr.aria-labelledby]="_getStepLabelId(i)"
			[attr.aria-expanded]="selectedIndex === i"
		>
			<ng-container *ngIf="selectedIndex === i || !destroyOnHide">
				<ng-container [ngTemplateOutlet]="step.content"> </ng-container>
			</ng-container>
		</div>
	</div>
</section>
