<mat-sidenav-container class="knowledge-base-container">
    <!-- Pop-out documents -->
    <mat-sidenav 
        mode="over" 
        [opened]="docOpened" 
        position="end" 
        class="knowledge-base-doc-popout"
        [ngClass]="isFixed ? 'fixed-nav' : null"
    >
        <!-- View Document -->
        <div class="doc-header">
            <div class="doc-title">
                <h3 *ngIf="!isEditDoc && !isCreateDoc">{{ currentDocument?.title }}</h3>
                <h3 *ngIf="isEditDoc && !isCreateDoc">Edit Document</h3>
                <h3 *ngIf="isCreateDoc && !isEditDoc">Create Document</h3>
            </div>
            <div class="doc-actions">
                <button 
                    *ngIf="
                        canEdit
                        && !isCreateDoc 
                        && !isEditDoc
                    " 
                    type="button" 
                    class="doc-action-btn edit-btn" 
                    (click)="editDocument()"
                >Edit</button>
                <button 
                    *ngIf="
                        canEdit
                        && !isCreateDoc 
                        && !isEditDoc
                    "
                    type="button" 
                    class="doc-action-btn delete-btn" 
                    (click)="deleteDocument()"
                >Delete</button>
                &nbsp;&nbsp;&nbsp;
                <fa-icon 
                    [icon]="faIconName.faXmark" 
                    class="fa-xl" 
                    (click)="closeDocument()"
                ></fa-icon>
            </div>
        </div>        
        <hr>

        <div 
            *ngIf="
                !isCreateDoc 
                && !isEditDoc
            " 
            class="doc-view"
        >
            <p>{{ currentDocument?.description }}</p>
            <div 
                *ngIf="
                    currentDocument?.description !== null 
                    && currentDocument?.description !== ''
                " 
                class="blue-line"
            ></div>
            <div 
                class="trix-content" 
                [innerHTML]="currentDocument?.content"
            ></div>
            <div 
                *ngIf="videoUrl" 
                class="embed-responsive embed-responsive-16by9 mb-3"
            >
			    <iframe 
                    class="embed-responsive-item" 
                    [src]="videoUrl | safe" 
                    allowfullscreen
                ></iframe>
		    </div>
        </div>
        
        <!-- Create and Edit Document -->
        <form 
            *ngIf="
                isCreateDoc 
                || isEditDoc
            " 
            [formGroup]="formGroup" 
            class="edit-document"
        >
            <div class="form-row">
                <pcg-control-group 
                    class="doc-module-ctnr required-field" 
                    label="Title"
                >
                    <input 
                        type="text" 
                        placeholder="Add a Title..." 
                        class="form-control"
                        formControlName="title"
                        pcgControlRef
                        maxlength="50"
                    />
                </pcg-control-group>

                <pcg-control-group 
                    label="Video ID"
					labelTooltip="Input the Vimeo video ID here. This ID can be found in the published Vimeo video URL."
                    placement="right"
                >
					<input 
						pcgControlRef 
						formControlName="videoId" 
						class="form-control" 
						type="text" 
					/>
				</pcg-control-group>
            </div>

            <div class="form-row">
                <pcg-control-group 
                    class="doc-module-ctnr required-field" 
                    label="Module"
                >
                    <pcg-select 
                        pcgControlRef 
                        [multiple]='false' 
                        [items]="knowledgeBaseModules"
                        formControlName='module' 
                        placeholder="Select a Module"
                        (change)="onModuleChange()" 
                    ></pcg-select>
                </pcg-control-group>

                <pcg-control-group
                    class="doc-module-ctnr required-field"
                    label="Minimum Permission"
                    labelTooltip="Only users with access to this module and with this permission level or higher will be able to view this document."
                    placement="right"
                >
                    <pcg-select 
                        pcgControlRef 
                        [multiple]='false' 
                        [items]="permissionItems"
                        formControlName='permissionRole' 
                        placeholder="Select a Permission Role"
                    ></pcg-select>
                </pcg-control-group>
            </div>

            <div class="form-row">
                <pcg-control-group label="Description">
                    <textarea 
                        placeholder="Add a Description..." 
                        class="form-control" 
                        formControlName="description"
                        pcgControlRef 
                        maxlength="500"
                    ></textarea>
                </pcg-control-group>
            </div>

            <div 
                *ngIf="editorString !== null" 
                class="form-row"
            >
                <pcg-control-group label="Content">
                    <pcg-text-editor
                        [editorValue]="editorString"
                        [imageFolder]="'KnowledgeBase'"
                        [minHeight]="'10em'"
                        (pcgFileChange)="setImageInFormGroup($event)"
                    ></pcg-text-editor>
                </pcg-control-group>  
            </div>

            <pcg-fixed-buttons>
                <div 
                    *ngIf="isError" 
                    class="alert alert-danger text-center"
                >{{ errorStr }}</div>
                <button 
                    *ngIf="isEditDoc"
                    type="button" 
                    class="btn btn-md btn-cancel mr-2" 
                    (click)="cancelDocumentEdit()"
                >Cancel</button>
                <button 
                    type="button" 
                    class="btn btn-md btn-save" 
                    (click)="onSave()"
                >Save Changes</button>
            </pcg-fixed-buttons>            
        </form>
    </mat-sidenav>

    <!-- Rest of the page -->
    <mat-sidenav-content>
        <div class="file-nav">
            <h3>Knowledge Base</h3>
            <div class="search-bar">
                <input 
                    type="search" 
                    class="form-control form-control-sm file-search" 
                    placeholder="Search Documents..."
                    (keyup)="searchDocuments($event)"
                    (search)="clearSearch()"
                />
                <button 
                    *ngIf="
                        knowledgeBaseModules.length > 0 
                        && !isCreateDoc 
                        && !isEditDoc
                    "
                    class="btn btn-sm btn-save " 
                    (click)="createDocument()"                     
                >New Document</button>
            </div>
            <hr>

            <!-- File navigation -->
            <mat-nav-list>
                <p *ngIf="!hasSearchResults">No results found.</p>
                <!-- Admin -->
                <mat-list-item 
                    *ngIf="adminDocList?.length > 0"
                    class="parent"
                    (click)="toggleAdminSubMenu()"
                >
                    <fa-icon 
                        [icon]="showAdminSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>Admin</span>
                </mat-list-item>                
                <div 
                    cdkDropList
                    *ngIf="showAdminSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 10)"
                >
                    <div *ngFor="let adminDoc of adminDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(adminDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ adminDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- CI Repack -->
                <mat-list-item 
                    *ngIf="ciRepackDocList?.length > 0"
                    class="parent"
                    (click)="toggleCiRepackSubMenu()"
                >
                    <fa-icon 
                        [icon]="showCiRepackSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>CI Repack</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showCiRepackSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 6)"
                >
                    <div *ngFor="let ciRepackDoc of ciRepackDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(ciRepackDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ ciRepackDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- HEROS -->
                <mat-list-item 
                    *ngIf="herosDocList?.length > 0"
                    class="parent"
                    (click)="toggleHerosSubMenu()"
                >
                    <fa-icon 
                        [icon]="showHerosSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>HEROS</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showHerosSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 1)"
                >
                    <div *ngFor="let herosDoc of herosDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(herosDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ herosDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- Metabolic Formula -->
                <mat-list-item 
                    *ngIf="metabolicFormulaDocList?.length > 0"
                    class="parent"
                    (click)="toggleMetabolicSubMenu()"
                >
                    <fa-icon 
                        [icon]="showMetabolicSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>Metabolic Formula</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showMetabolicSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 5)"
                >
                    <div *ngFor="let metabolicFormulaDoc of metabolicFormulaDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(metabolicFormulaDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ metabolicFormulaDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- NDCs -->
                <mat-list-item 
                    *ngIf="ndcsDocList?.length > 0"
                    class="parent"
                    (click)="toggleNdcSubMenu()"
                >
                    <fa-icon 
                        [icon]="showNdcSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>NDCs</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showNdcSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 3)"
                >
                    <div *ngFor="let ndcsDoc of ndcsDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(ndcsDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ ndcsDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- PFS -->
                <mat-list-item 
                    *ngIf="pfsDocList?.length > 0"
                    class="parent"
                    (click)="togglePfsSubMenu()"
                >
                    <fa-icon 
                        [icon]="showPfsSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>PFS</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showPfsSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 13)"
                >
                    <div *ngFor="let pfsDoc of pfsDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(pfsDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ pfsDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- Pharmacy -->
                <mat-list-item 
                    *ngIf="pharmacyDocList?.length > 0"
                    class="parent"
                    (click)="togglePharmacySubMenu()"
                >
                    <fa-icon 
                        [icon]="showPharmacySubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>Pharmacy</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showPharmacySubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 4)"
                >
                    <div *ngFor="let pharmacyDoc of pharmacyDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(pharmacyDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ pharmacyDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- Rx Connects -->
                <mat-list-item 
                    *ngIf="rxConnectsDocList?.length > 0"
                    class="parent"
                    (click)="toggleRxConnectsSubMenu()"
                >
                    <fa-icon 
                        [icon]="showRxConnectsSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>Rx Connects</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showRxConnectsSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 11)"
                >
                    <div *ngFor="let rxConnectsDoc of rxConnectsDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(rxConnectsDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ rxConnectsDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- Support -->
                <mat-list-item 
                    *ngIf="supportDocList?.length > 0"
                    class="parent"
                    (click)="toggleSupportSubMenu()"
                >
                    <fa-icon 
                        [icon]="showSupportSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>Support</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showSupportSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 12)"
                >
                    <div *ngFor="let supportDoc of supportDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(supportDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ supportDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>

                <!-- Other -->
                <mat-list-item 
                    *ngIf="otherDocList?.length > 0"
                    class="parent"
                    (click)="toggleOtherSubMenu()"
                >
                    <fa-icon 
                        [icon]="showOtherSubMenu 
                            ? faIconName.faFolderOpen 
                            : faIconName.faFolder
                        "
                    ></fa-icon>
                    <span>Other</span>
                </mat-list-item>
                <div 
                    cdkDropList
                    *ngIf="showOtherSubMenu"
                    class="submenu" 
                    (cdkDropListDropped)="drop($event, 14)"
                >
                    <div *ngFor="let otherDoc of otherDocList">
                        <mat-list-item 
                            cdkDrag 
                            (click)="openDocument(otherDoc)"
                        >
                            <fa-icon [icon]="faIconName.faFile"></fa-icon>
                            <span>{{ otherDoc?.title }}</span>
                        </mat-list-item>
                    </div>
                </div>
            </mat-nav-list>
        </div>
        <div
            *ngIf="!isMobile" 
            class="unselected-doc"
        >
            <fa-icon [icon]="faIconName.faBook"></fa-icon>	
            <p>Select an item to read</p>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>