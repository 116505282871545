import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SecurityService } from 'app/core/security/security.service';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { ChangePasswordForm } from './change-password.form';
import { UserGuidVm } from 'app/shared/generated/Models/UserGuidVm';


@Component({
    selector: 'pcg-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

	// Our form group
	changePasswordForm: UntypedFormGroup = null;

	// Change password subscription
	changePasswordSub: Subscription = new Subscription();

	// Fields sent to server with form
	userGUID = '0';
	userId = '0';
	code = '';
	isExpired = false;
	errorMessage = "";

	constructor(private route: ActivatedRoute, private ms: SystemMessageService,
		private sec: SecurityService, private router: Router, private http: HttpClient) {
			const changePasswordForm = new ChangePasswordForm(http);
			this.changePasswordForm = changePasswordForm.formGroup;
	}

	// Get query parameters on init
	ngOnInit() {
		this.userGUID = this.route.snapshot.params.id;
		this.code = this.route.snapshot.queryParams.code;

		if (this.userGUID != null) {
			this.changePasswordSub.add(
				this.http.get(`api/Account/GetUserGuidVm?guid=${this.userGUID}`)
					.subscribe((vm: UserGuidVm) => {
						if (vm.isExpired === false) {
							this.userId = vm.id.toString();
							this.isExpired = vm.isExpired;
						} else if (vm.message === "This email has expired because it has already been used to reset a password or a newer email has been sent. Please use the latest email or contact an administrator for assistance.") { 
							this.isExpired = vm.isExpired; 
							this.errorMessage = vm.message;
						} else if (vm.message === "This email has expired because it has been more than 7 days since it was sent." && vm.isExpired) {
							this.isExpired = vm.isExpired; 
							this.errorMessage = vm.message;
						}
					})
			);
		} else { this.ms.setSystemMessage("There was a problem processing your change password request. Please contact an administrator.", "Error"); }
		
	}

	onSubmit() {
		if (this.changePasswordForm.valid) {
			this.changePasswordSub.add(
				this.ms.getHttpObservable(this, 'api/Account/ChangePassword', this.changePasswordForm,
					{ userId: this.userId, code: this.code })
					.subscribe(msg => {
						if (msg && msg.isSuccessful) {
							this.router.navigate(['/']);
						}
					})
			);
		} else { validateForm(this.changePasswordForm); }
	}

	ngOnDestroy() { this.changePasswordSub.unsubscribe(); }
}
