import { Component } from '@angular/core';

@Component({
    selector: 'pcg-metabolic-business-area',
    templateUrl: './metabolic-business-area.component.html',
    styleUrls: ['./metabolic-business-area.component.scss'],
    standalone: false
})
export class MetabolicBusinessAreaComponent {

}
