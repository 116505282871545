import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[pcgAbbreviation]',
    standalone: false
})
export class AbbreviationDirective {
	shiftKeyCode = 16;

	constructor(private elRef: ElementRef, @Optional() private control: NgControl) {
		
	}

	@HostListener('keyup', ['$event'])
	keyEvent() { if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); } }

	@HostListener('change', ['$event'])
	changeEvent() { if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); } }

	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		// If the key pressed is not [A-Za-z], backspace/delete, 
		// decimal/period, or arrow keys deny key event.
		if (
			!(
				// Regex test for letters.
				(/^[a-zA-Z]$/.test(e.key))
				|| (e.keyCode > 36 && e.keyCode < 41)
				|| e.keyCode === 8
				|| e.keyCode === 9
				// Allows user to shift and type letters
				|| e.keyCode === this.shiftKeyCode
				|| e.keyCode === 46
				|| e.keyCode === 110
				// Allows ability to select all with Ctrl/Cmd + A
				|| ((e.ctrlKey || e.metaKey) && e.key === 'a')
				// Allows ability to copy
				|| ((e.ctrlKey || e.metaKey) && e.key === 'c')
				// Allows ability to paste
				|| ((e.ctrlKey || e.metaKey) && e.key === 'v')
			)
		) { return false; }
	}
}
