import { Component, OnInit, TemplateRef, ViewChild, Input, inject } from '@angular/core';
import { faScannerGun } from '@fortawesome/pro-solid-svg-icons';

import { WorkstationService } from 'app/ci-repack/admin/workstations/workstation.service';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { CiDashboardActionsComponent } from '../ci-dashboard-actions/ci-dashboard-actions.component';
import { GlobalService } from 'app/shared/services/global.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pcg-workstation-banner',
    templateUrl: './workstation-banner.component.html',
    styleUrls: ['./workstation-banner.component.scss'],
    standalone: false
})
export class WorkstationBannerComponent implements OnInit {

    // Keeps track if nav is docked or not
    gs = inject(GlobalService);
    isFixed = false;
    navFixedSubscription: Subscription;

    @ViewChild('ciDashboardComponent') ciDashboardComponent : TemplateRef<CiDashboardActionsComponent>;
    
    user: UserJsVm
    workStationName: string
    getModelRetries: number = 0;
    noWorkstation = false;
    dashboardActionsClicked = false;    
    isOpen = false;

    faIcons = { faScannerGun };

    constructor(
        private workStationService: WorkstationService
        , private sec: SecurityService
    ) { this.user = this.sec.getUser(); }

    ngOnInit(): void { this.getModel();         
        // Adjust margin-right for scan button depending if nav is fixed or not
        this.navFixedSubscription = this.gs.isNavFixed.subscribe(
            r => { this.isFixed = r; }
        );
        this.isFixed = localStorage.getItem('isNavFixed') === "true";        
     }
    
    ngOnDestory = () => { this.navFixedSubscription?.unsubscribe() }

    getModel() {
        this.workStationService.getModel(this.user?.ciWorkstationId).subscribe(() => {
            setTimeout(() => {
                this.workStationName = this.workStationService.workStationName;
                this.noWorkstation = this.workStationService.noWorkstation;
                // Call this method again if getting name fails, maximum of 5 retries.
                if (!this.workStationName && this.getModelRetries <= 5) { 
                    this.getModelRetries++;
                    this.getModel();
                } else { this.getModelRetries = 0; }
            }, 100)
        });
    }

    openCiWorkstationModal() {
        this.workStationService.openCiWorkstationModal();
        // When a user closes the modal to select their workstation,
        // an event will be emitted to update the data on the UI.
        this.workStationService.update.subscribe(() => { this.getModel(); });
    }
}
