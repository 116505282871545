@if (mustActivateMfaAndInactivity) {
	<div class="alert alert-danger text-center">
		The area you are trying to access contains protected health information. 
		You must enroll a multi-factor authentication device or fingerprint to continue.
	</div>
}

<pcg-page-title
	primaryTitle="{{formGroup.value.firstName }} {{ formGroup.value.lastName}}"
	[backBtnLink]="canManageUsers ? '/admin/users/user/list' : null"
	backBtnTooltip="Return to Global User List"
></pcg-page-title>

<mat-tab-group 
	pcgTabs 
	mat-stretch-tabs='false' 
	mat-align-tabs='start' 
	animationDuration='200ms' 
	[preserveContent]='true'
	class="show-overflow"
>
	<!-- Details -->
	<mat-tab label="Details">
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<form 
					pcgSubmitUrl="api/Administration/Users/SaveUser" 
					(messageReceive)="saveUser($event)"
					[formGroup]="formGroup" 
				>
					<div>
						<input type="hidden" formControlName="userId"/>

						<div class="activation-ctnr">
							<!-- Bounced Email button -->
							@if (
								model.isEmailBounced
								&& id > 0
							) {
								<div
									class="unlock-btn mr-2" 
									(click)="bounceEmail()"
									ngbTooltip="This user is has a bounced email. Click here to remove it from the bounce list."
								>
									<fa-icon 
										[icon]="faIconName.faTennisBall" 
										class="unlock-icon" 
									></fa-icon>	
									<p>Unbounce Email</p>
								</div>
							}

							<!-- Invalid Email button -->
							@if (
								!model.emailConfirmed 
								&& id > 0
							) {
								<div
									class="unlock-btn mr-2" 
									(click)="validateEmail()"
									ngbTooltip="This user is has an invalid email. Click here to validate the email."
								>
									<fa-icon 
										[icon]="faIconName.faEnvelope" 
										class="unlock-icon" 
									></fa-icon>	
									<p>Validate Email</p>
								</div>
							}

							<!-- Locked Out button -->
							 @if (
								isLockedOut 
								&& canUnlock
								&& id > 0
							 ) {
								<div 
									class="unlock-btn" 
									(click)="unlockUser()"
									ngbTooltip="This user is locked out. Click here to unlock the user and send them an email with login instructions."
								>
									<fa-icon 
										[icon]="faIconName.faUnlock" 
										class="unlock-icon" 
									></fa-icon>	
									<p>Unlock</p>
								</div>
							}

							<!-- Spam Report button -->
							 @if (
								model.isEmailSpamReported 
								&& id > 0
							 ) {
								<div 
									class="unlock-btn mr-2" 
									(click)="undoSpamReport()"
									ngbTooltip="This user marked us as spam. Click here to undo their spam report."
								>
									<fa-icon 
										[icon]="faIconName.faEnvelopesBulk" 
										class="unlock-icon" 
									></fa-icon>	
									<p>Undo Spam</p>
								</div>
							}

							<!-- Activate/Deactivate button -->
							@if (
								(
									!isCurrentUser 
									&& canEdit
								) 
								&& id !== 0
							) {
								<div 
									class="unlock-btn"
									[class.btn-danger]="model.isActive"
									[class.btn-save]="!model.isActive"
									(click)="
										activateDeactivate(); 
										model.isActive = !model.isActive;
									"
								>
									{{ model.isActive ? 'Deactivate' : 'Reactivate' }}
									<button #submit [hidden]="true"></button>
								</div>
							}
							<p 
								[class.text-danger]="!model.isActive" 
								[class.d-none]="model.isActive"
								class="ml-2"
							>This user is deactivated.</p>

							@if (isCurrentUser) {
								<div 
									class="unlock-btn" 
									(click)="logout()"
									ngbTooltip="Logout"
								>
									<fa-icon 
										[icon]="faIconName.faPowerOff" 
										class="unlock-icon" 
									></fa-icon>	
									<p>Logout</p>
								</div>
							}
						</div>
						@if (isError) {
							<div class="alert alert-danger text-left break-line mt-2">
								{{ strError }}
								@if (showValidateEmail) {
									<div>
										If you know this is a valid email address click the validate button.
										<button 
											class="btn btn-save btn-md space-validate-button"
											(click)="overrideAndValidate()"
										>Validate</button>
									</div>
								}
							</div>
						}

						<div class="row">
							<pcg-control-group 
								class="col-md-4" 
								label="First Name"
							>
								<input 
									pcgControlRef 
									class="form-control" 
									type="text" 
									formControlName="firstName"
								/>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-4" 
								label="Last Name"
							>
								<input 
									pcgControlRef 
									class="form-control" 
									type="text" 
									formControlName="lastName"
								/>
							</pcg-control-group>

							@if (model.userId === 0) {
								<pcg-control-group 
									class="col-md-4" 
									label="Username"
								>
									<input 
										pcgControlRef 
										class="form-control" 
										type="text" 
										formControlName="userName"
									/>
								</pcg-control-group>
							}
						</div>

						<div class="row">
							<pcg-control-group 
								class="col-md-4" 
								label="Email"
							>
								<input
									pcgControlRef 
									class="form-control email" 
									type="text" 
									formControlName="email"
									(change)="emailChange()"
								/>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-4" 
								label="Phone" 
								[validations]="phoneValidation"
							>
								<input 
									pcgPhoneExt 
									pcgControlRef 
									class="form-control" 
									type="text" 
									formControlName="phoneNumber"
								/>
							</pcg-control-group>

							@if (canSeePasswordButtons) {
								<div class="col-md-4">
									@if (canSendWelcomeEmail) {
										<input
											type="button"
											value="Send Welcome Email"
											id="btnSendWelcomeMessage"
											class="btn btn-save btn-sm"
											ngbTooltip="Send user a welcome email with their username and the ability to choose a new password."
											(click)="resetPassword(true)"
										/>
									}
								</div>
							}
						</div>
					</div>

					<div>
						<h4>Security</h4>
						<hr />

						<div class="row">
							<pcg-control-group 
								class="col-md-12" 
								label="Inventories" 
								labelTooltip="Users with no Inventory associations can see information for all Inventories.
								Users with Inventory associations can only see information for their own Inventories."
							>
								<pcg-select 
									[sendFormData]="false" 
									pcgControlRef 
									[multiple]="true"
									dataSource="api/Select/Inventories" 
									formControlName="inventoryIds"
									itemsShowLimit="3"
									[selectedItemIds]="formGroup.controls.inventoryIds.value"								
								></pcg-select>
							</pcg-control-group>
						</div>
					</div>

					@if (customFieldsCount > 0) {
						<div class="container">
							<br /><br />
							<h4>Custom Fields</h4>
							<hr />
							<pcg-dynamic-form 
								[fields]="fields" 
								[fg]="formGroup" 
								fgName="customFields"
							></pcg-dynamic-form>
						</div>
					}

					<pcg-fixed-buttons class="mt-3">
						<button class="btn btn-save btn-lg">{{ id === 0 ? "Create" : "Save" }}</button>
					</pcg-fixed-buttons>
				</form>
			</div>
		</ng-template>
	</mat-tab>

	@if (id !== 0) {
		<!-- Organizations -->
		<mat-tab label="Organizations">
			<ng-template matTabContent>
				<div class="mat-tab-container">
					<pcg-user-organizations [userId]="id"></pcg-user-organizations>
				</div>
			</ng-template>
		</mat-tab>

		<!-- Facilities -->
		<mat-tab label="HEROS Agencies">
			<ng-template matTabContent>
				<div class="mat-tab-container">
					<pcg-user-facilities
						[id]="id"
						[editUserVm]="model"
					></pcg-user-facilities>
				</div>
			</ng-template>
		</mat-tab>

		<!-- Security -->
		<mat-tab label="Security">
			<ng-template matTabContent>
				<div class="mat-tab-container">
					<!-- This component is in the user-multi-factor-authentication directory under the edit-user component's directory -->
					<pcg-user-multi-factor-authentication
						[id]="id"
						[pin]="pin"
						[editUserVm]="model"
						[formGroup]="formGroup"
						[isCurrentUser]="isCurrentUser"
						[canManageUsers]="canManageUsers"
						[canResetPassword]="canResetPassword"
						[inactivityTimeoutFg]="inactivityTimeoutFg"
						[isInternalBphpUserFg]="isInternalBphpUserFg"
						[orgLoginType]="orgLoginTypeFg"
					></pcg-user-multi-factor-authentication>
				</div>
			</ng-template>
		</mat-tab>

		<!-- Notifications -->
		<mat-tab label="Notifications">
			<ng-template matTabContent>
				<div class="mat-tab-container">
					<pcg-user-email-settings [id]="id"></pcg-user-email-settings>
				</div>
			</ng-template>
		</mat-tab>

		<!-- Security Roles -->
		@if (canViewSecurity) {
			<mat-tab label="Module Access">
				<ng-template matTabContent>
					<div class="mat-tab-container">
						<pcg-user-module-access [id]="id"></pcg-user-module-access>
					</div>
				</ng-template>
			</mat-tab>
		}

		<!-- Login History -->
		@if (canViewLoginHistory) {
			<mat-tab label="Login History">
				<ng-template matTabContent>
					<div class="mat-tab-container">
						<!-- This component is in the user-login-history directory under the edit-user component's directory -->
						<pcg-user-login-history [id]="id"></pcg-user-login-history>
					</div>
				</ng-template>
			</mat-tab>
		}

		<!-- Audit History -->
		@if (canViewAuditHistory) {
			<mat-tab label="Audit History">
				<ng-template matTabContent>
					<div class="mat-tab-container">
						<pcg-user-audit-history [id]="id"></pcg-user-audit-history>
					</div>
				</ng-template>
			</mat-tab>
		}

		<!-- RxConnects -->
		@if (hasRxPermsSignal()) {
			<mat-tab label="RxConnects">
				<ng-template matTabContent>
					<div class="mat-tab-container">
						<pcg-user-rxconnects-settings [id]="id"></pcg-user-rxconnects-settings>
					</div>
				</ng-template>
			</mat-tab>
		}
	}
</mat-tab-group>