import { Injectable, signal } from '@angular/core';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';

@Injectable({
    providedIn: 'root'
})
export class UserStateService {

    constructor() {}

    private readonly userState = signal<UserJsVm | null>(
        // Initialize from localStorage if exists
        localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    );

    readonly user = this.userState.asReadonly();

    static getCurrentUser(): UserJsVm | null {
        return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    }

    updateUser(user: UserJsVm): void {
        // Update signal and localStorage simultaneously
        this.userState.set(user);
        localStorage.setItem('user', JSON.stringify(user));
    }

    clearUser(): void {
        this.userState.set(null);
        localStorage.removeItem('user');
    }
}