@if (!loading()) {
    @if (secAccess?.length) {
        <div class="row p-2">
            <span class="col-md-6"></span>
            @for (role of roles; track role;) {
                <div class="col-md text-center mt-auto">{{ permissionRole.toDisplay(role) }}</div>
            }            
        </div>
    }

    @for (sec of secAccess; track sec.name) {
        <div class="d-flex flex-row p-2 border-bottom">
            <div 
                class="d-flex flex-nowrap font-weight-bold"
                [class]="module === businessAreaEnum.HelpDesk ? 'col-md-5' : 'col-md-2'"
            >
                <span [innerHTML]="sec?.name"></span>
            </div>
            
            <div 
                class="d-flex flex-nowrap font" 
                [class]="module === businessAreaEnum.HelpDesk ? 'col-md-1' : 'col-md-4'"
                style="color:#808080"
            >
                <span [innerHTML]="sec?.descr"></span>
            </div>

            <div class="col-md-6 d-flex flex-nowrap px-0">
                @for (role of roles; track role;) {
                    <div class="col-md text-center my-auto">
                        @if (hasRoleAccess(role, sec)) { <fa-icon [icon]="faCheck"></fa-icon> }
                    </div>
                }                
            </div>
        </div>
    } @empty {
        <button 
            class="alert alert-warning text-center w-100" 
            (click)='addModuleAccessDetails()'
        >
            <h3>Module Access details missing. Click here to add.</h3>
        </button>
    }
} @else {
    <h3 class="text-center align-top">
        <div class="spinner-grow" role="status">
            <span class="sr-only"></span>
        </div>
        <span class='ml-2'>Loading...</span> 
    </h3>
}