import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';

export interface FileHandle {
    file: File;
    count: number;
}

@Directive({
    selector: '[pcgDragDrop]',
    standalone: false
})
export class DragDropDirective {

    @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();
    @HostBinding("style.background") private background = "#eee";

    constructor() { }

    @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "#999";
    }
    
    @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "#eee";
    }
    
    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#eee';
      
        let fileList = evt.dataTransfer.files;
        let files: FileHandle[] = [];

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const count = i + 1;
            files.push({ file, count });
        }
        if (files.length > 0) { this.files.emit(files); }
    }
}
