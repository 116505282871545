import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { CollapsibleTableService } from 'app/core/collapsible-table/collapsible-table.service';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { detailExpand } from 'app/shared/animations/shared-animations';
import { OrganizationListVm } from 'app/shared/generated/Administration/Models/Organizations/OrganizationListVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { PcgTableResult } from "app/shared/table/interfaces/pcg-table-result.interface";
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-shared-organization-list',
    templateUrl: './organizations-list.component.html',
    styleUrls: ['./organizations-list.component.scss'],
    animations: [detailExpand],
    standalone: false
})
export class OrganizationsListComponent implements OnInit {

    @ViewChild(TableComponent, { static: false}) table: TableComponent<OrganizationListVm>;

    @Input() businessArea: BusinessAreaEnum = BusinessAreaEnum.Admin;
    @Input() header: string;
    @Input() filterMap = {
		"isActive": "Is Active"
        , "medicalDirectorId": "Medical Director"
        , "facilityId": "Facility"
	};
    @Input() groupByCounty = false;
    @Input() showByCounty = false;

    filters = {
        isActive: true
        , medicalDirectorId: null
        , facilityId: null
    };

    initFilters = {
        isActive: true
        , medicalDirectorId: null
        , facilityId: null
    };
    baseEditRoles = [ 
        PermissionRole.UserAdmin
        , ...SecurityService.setMinRole(PermissionRole.Manager)
    ];

    hasOrgs = true;
    tableReceiveCount = 0;
    canAdd = false;
    canDelete = false;
    hasData = false;
    columns = OrganizationListVm.ColumnDefs;
    orgList: OrganizationListVm[] = [];

    constructor(
        private formatHelper: FormatHelperService
        , public btnFilterService: ButtonFilterService
        , public security: SecurityService
        , public router: Router
        , private httpClient: HttpClient
        , public collapsibleTableService: CollapsibleTableService
    ) { }

    ngOnInit(): void {
        this.canAdd = this.security.hasModuleAccess(
            [
                BusinessAreaEnum.Admin
                , BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [ PermissionRole.SystemAdmin ]
        );
        this.canDelete = this.security.hasModuleAccess(
            [ this.businessArea ]
            , [ PermissionRole.SystemAdmin ]
        );
        this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters(); });

        if (
            this.showByCounty 
            && this.groupByCounty
        ) { this.getGroupedByCountyList(); }
    }

    getGroupedByCountyList() {
        this.groupByCounty = !this.groupByCounty;
        if (this.groupByCounty === true) {
            this.httpClient.get(`api/Administration/Organization/GetOrganizationList` 
                + `?isActive=${this.filters.isActive}`
                + `&facilityId=${this.filters.facilityId}`
                + `&medicalDirectorId=${this.filters.medicalDirectorId}`
            ).subscribe((lst: OrganizationListVm[]) => { this.orgList = lst; });
        }
    }

    resetFilters() {
		this.filters = Object.assign(this.filters, this.initFilters);
		this.reloadTable();
	}

    tableCallback = (tableResult: PcgTableResult) => {
        // Only set hasOrgs on page load. Ignore repeat ajaxReloads
        if (
            tableResult.recordsTotal === 0 
            && this.tableReceiveCount < 1
        ) { this.hasOrgs = false; }
        this.tableReceiveCount++;
    }

    canEditOrganization = (): boolean => this.security.hasModuleAccess(
        [ this.businessArea ]
        , [
            PermissionRole.UserAdmin
            , ...SecurityService.setMinRole(PermissionRole.Manager)
        ]
    ) 
    || this.security.hasModuleAccess(
        [ BusinessAreaEnum.Admin ]
        , [ ...SecurityService.setMinRole(PermissionRole.User) ]
    );

    editOrganizationLink(): string {
        switch(this.businessArea) {
            case BusinessAreaEnum.CI: return '/ci-repack/organizations/edit/';
            case BusinessAreaEnum.MetabolicFormula: return '/metabolic-formula/organizations/edit/';
            default: return '/admin/organizations/orgs/edit/';
        }
    }
    
    navigateToNewOrganization(): void { this.router.navigateByUrl(`admin/organizations/orgs/edit/0`); }

    reloadTable() {
	    this.table.hideShowColumn('isActive', this.formatHelper.GetIsNully(this.filters.isActive));
        this.table.ajaxReload();
    }

    filterParents() { 
		const lst = this.orgList.filter(o => o.isParent === true); 
		if (lst.length > 0) { this.hasData = true; }
		return lst; 
	}

    filterChildren(row: OrganizationListVm) {
        return this.orgList.filter(o => 
            o.isParent === false 
            && o.countyId === row.countyId 
        ); 
    }
}
