<div 
    *ngIf="model$ | async as fg" 
    class="p-3 request"
>
    <!-- In other component, this is an A tag w/ href -->
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <!-- Font Awesome Icons - 2 col -->
                    <div class="col-md-2">
                        @if (!isAccountList) {
                            <div 
                                class="text-center" 
                                (click)="toggleModal()"
                            >
                                <fa-stack 
                                    class="" 
                                    ngbTooltip="Back to Request Queue"
                                >
                                    <fa-icon 
                                        stackItemSize='1x' 
                                        [icon]="faIconName.faArrowLeft" 
                                        style="color:#000000"
                                    ></fa-icon>
                                    <br />
                                    <span class="small">Back</span>
                                </fa-stack>
                            </div>
                        }
                    </div>

                    <!-- Account Info - 8 col -->
                    <div class="col-md-8">
                        <div class="row">
                            <div class="container mb-2">
                                <div class="row justify-content-center">
                                    <small class="font-weight-bold">Organization: <u>{{ fg.organizationName }}</u></small>
                                </div>

                                <div class="row justify-content-center">
                                    <small class="font-weight-bold">Address: {{ fg.organizationAddress }}</small>
                                </div>

                                <div class="row justify-content-center">
                                    <small class="font-weight-bold">Requested By: {{ fg.requestBy.firstName + ' ' + fg.requestBy.lastName }}</small>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <form 
                                    [formGroup]="form" 
                                    (ngSubmit)="onSubmit()"
                                    class="row"
                                >
                                    <!-- Old  -->
                                     @if (!isAccountApproval) {
                                        <div 
                                            class="col-md-6 border-right" 
                                            [ngClass]="{ 'border-right' : !isAccountApproval }"
                                        >
                                            <div class="row justify-content-center">
                                                <small><b><i>Current</i></b></small>
                                            </div>
                                            <hr class="mt-0 mb-2" />

                                            <div>
                                                <div class="row">
                                                    <pcg-control-group label="Account Name">
                                                        <span>{{ fg.oldFacilityAccountName.name }}</span>
                                                    </pcg-control-group>
                                                </div>

                                                <div class="row pt-2">
                                                    <pcg-control-group 
                                                        label="Account Number" 
                                                        class="flex-fill"
                                                    >
                                                        <span>{{ fg.oldAccountNumber }}</span>
                                                    </pcg-control-group>
                                                </div>

                                                <div class="row pt-3 pb-1">
                                                    <pcg-control-group 
                                                        [ngClass]="{ 'pb-4': fg.oldExpirationDate === null}" 
                                                        label="Expiration Date" class="col-md-5 p-0"
                                                    >
                                                        <span>{{ fg.oldExpirationDate | date: 'shortDate' }}</span>
                                                    </pcg-control-group>                     
                                                </div>

                                                <div class="row pt-4">                   
                                                    <pcg-control-group 
                                                        label="Grace Period" 
                                                        class="col-md-6 p-0" 
                                                        [ngClass]="{ 'pt-1': fg.oldExpirationDate === null}"
                                                    >
                                                        <ui-switch 
                                                            pcgControlRef 
                                                            color='#007bff' 
                                                            defaultBgColor='#EEE' 
                                                            class='ml-2 mt-2' 
                                                            size='small' 
                                                            formControlName='oldIsGracePeriod'
                                                        ></ui-switch>
                                                    </pcg-control-group>

                                                    <pcg-control-group label="Is Active?">
                                                        <span [style.color]="fg.oldIsActive ? 'green' : 'red' ">
                                                            {{ fg.oldIsActive ? 'Activated' : 'Inactivated' }}
                                                        </span>
                                                    </pcg-control-group>
                                                </div>

                                                <div class="row">
                                                    <pcg-control-group label="Related Documents">
                                                        @if (form.controls.oldUploadFilePath.value !== '') {
                                                            <div class="item">
                                                                <a 
                                                                    target="_blank" 
                                                                    [href]="form.controls.oldUploadFilePath.value"
                                                                >{{form.controls.oldFileName.value}}</a>
                                                            </div>
                                                        } @else { <div><span>No related files were found.</span></div> }
                                                    </pcg-control-group>
                                                </div>

                                                <div class="row">
                                                    <pcg-control-group label="Notes">
                                                        @if (!isEmpty(fg.OldNotes)) { <span>{{ fg.oldNotes }}</span> }
                                                    </pcg-control-group>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <!-- New -->
                                    <div [ngClass]="{ 'border-left col-md-6' : !isAccountApproval, 'col-md-12' : isAccountApproval}">
                                        <div class="row justify-content-center">
                                            <small><b><i>Proposed</i></b></small>
                                        </div>
                                        <hr class="mt-0 mb-2" />

                                        <div>
                                            <div class="row pl-4">
                                                <pcg-control-group label="Account Name">
                                                    <span>{{ fg.newFacilityAccountName.name }}</span>
                                                </pcg-control-group>
                                            </div>

                                            <div class="row pl-4">
                                                <pcg-control-group 
                                                    label="Account Number" 
                                                    class="flex-fill"
                                                >
                                                    <input 
                                                        pcgControlRef    
                                                        type="text" 
                                                        class="form-control" 
                                                        formControlName="newAccountNumber"
                                                        maxlength="50"
                                                        pattern="[A-Za-z0-9 ]*"
                                                    />
                                                </pcg-control-group>
                                            </div>

                                            <div class="row pl-4">
                                                <pcg-control-group 
                                                    label="Expiration Date" 
                                                    class="flex-fill" 
                                                    [ngClass] = "{'required-field': expDateRequired}"
                                                >
                                                    <input 
                                                        pcgControlRef
                                                        type="date" 
                                                        class="form-control" 
                                                        formControlName="newExpirationDate"
                                                        [ngModel]="fg.newExpirationDate | date:'yyyy-MM-dd'"
                                                    />
                                                </pcg-control-group>                                            
                                            </div>

                                            <div class="row pl-4 justify-content-around">
                                                <pcg-control-group 
                                                    label="Grace Period" 
                                                    class="col-md-4 p-0"
                                                >
                                                    <ui-switch 
                                                        pcgControlRef 
                                                        color='#007bff' 
                                                        defaultBgColor='#EEE' 
                                                        class='ml-2 mt-2' 
                                                        size='small' 
                                                        formControlName='newIsGracePeriod'
                                                    ></ui-switch>
                                                </pcg-control-group>

                                                <pcg-control-group label="Is Active">
                                                    <ui-switch 
                                                        pcgControlRef 
                                                        color='#007bff' 
                                                        defaultBgColor='#EEE' 
                                                        class='ml-2 mt-2' 
                                                        size='small' 
                                                        formControlName='newIsActive'
                                                    ></ui-switch>
                                                </pcg-control-group>
                                            </div>

                                            <div class="row pl-4">
                                                <pcg-control-group label="Related Documents">
                                                    <pcg-drag-drop-file-upload 
                                                        *ngIf="!hasFile"
                                                        [fileList]="organizationFile"
                                                        [(formGroup)]="form"
                                                        [formGroupControlName]="'uploadFiles'"
                                                        [maxFiles]="maxFiles"
                                                        [minHeightPixels]="200"
                                                        [minWidthPixels]="200"
                                                        [allowedFilesMessage]="'(Optional: up to 1 image or pdf)'"
                                                        [allowedFiles]="'pdf, images:'"
                                                    ></pcg-drag-drop-file-upload>
                                                    @if (hasFile) {
                                                        <div class="item">
                                                            <a 
                                                                target="_blank" 
                                                                [href]="form.controls.newUploadFilePath.value"
                                                            >{{ form.controls.newFileName.value }}</a>
                                                            <span>&nbsp;&nbsp;&nbsp;</span>
                                                            <div 
                                                                class='btn btn-delete btn-sm' 
                                                                (click)="clearFile()"
                                                            >X</div>
                                                            <input 
                                                                type="hidden" 
                                                                formControlName="newUploadFilePath" 
                                                            />
                                                        </div>
                                                    }
                                                </pcg-control-group>
                                            </div>

                                            <div class="row pl-4">
                                                <pcg-control-group 
                                                    label="Notes" 
                                                    class="flex-fill"
                                                >
                                                    <textarea 
                                                        type="text" 
                                                        formControlName="newNotes" 
                                                        class="form-control"
                                                    ></textarea>
                                                </pcg-control-group>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 d-flex justify-content-center">
                                        <div 
                                            class="row justify-content-center text-center"
                                            [ngClass]="{ 'col-md-8' : !isAccountApproval, 'flex-fill p-0 pl-4' : isAccountApproval }"
                                        >
                                            <pcg-control-group 
                                                label="Denial Explanation" 
                                                class="flex-fill my-2"
                                            >
                                                <textarea 
                                                    type="text" 
                                                    class="form-control" 
                                                    formControlName="denialExplanation"
                                                ></textarea>
                                            </pcg-control-group>
                                        </div>
                                    </div>

                                    <!-- Approve/Deny buttons -->
                                     @if (!readOnly) {
                                        <div class="col-md-12 d-flex justify-content-center">
                                            <input formControlName="submitButton" hidden>
                                            <button 
                                                type="submit" 
                                                class="btn btn-success buttons m-1" 
                                                (click)="submitButton(true)"
                                            >
                                                <div class="d-flex align-items-center">
                                                    <fa-icon 
                                                        class="checkIcon fa-lg faIcon mr-2" 
                                                        [icon]="faIconName.faCheck"
                                                    ></fa-icon>
                                                    <div>Approve</div>
                                                </div>
                                            </button>

                                            <button 
                                                type="submit" 
                                                class="btn btn-danger buttons m-1" 
                                                (click)="submitButton(false)"
                                            >
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <fa-icon 
                                                        class="denyIcon fa-lg faIcon mr-2" 
                                                        [icon]="faIconName.faTimes"
                                                    ></fa-icon>
                                                    <div>Deny</div>
                                                </div>
                                            </button>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- Buttons - 2 col -->
                    <div class="col-md-2">
                        @if (
                            isAccountList 
                            && !calledFromNav
                        ) {
                            <button 
                                type="button" 
                                class="close" 
                                aria-label="Close" 
                                (click)="modal.dismiss()"
                            ><span aria-hidden="true">&times;</span></button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>