// tslint:disable: quotemark
export enum VerificationTypeEnum {
	Fido = 0,
	Fingerprint = 1,
	Pin = 2,
	Credentials = 3
}

export namespace VerificationTypeEnum {
	export function toDescription(val: VerificationTypeEnum) {
		switch (val) {
			case 0:
				return "FIDO device without biometrics";
			case 1:
				return "Biometric identification";
			case 2:
				return "PIN";
			case 3:
				return "Credentials";
		}
		return null;
	}
	export function toDisplay(val: VerificationTypeEnum) {
		switch (val) {
			case 0:
				return "Device";
			case 1:
				return "SecuGen";
			case 2:
				return "PIN";
			case 3:
				return "Credentials";
		}
		return null;
	}
}
