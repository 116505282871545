import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { EditFacilityInventoryDetailVm } from 'app/shared/generated/Administration/Models/Facilities/BusinessAreas/EditFacilityInventoryDetailVm';

@Component({
    selector: 'pcg-inventory-business-area',
    templateUrl: './inventory-business-area.component.html',
    styleUrls: ['./inventory-business-area.component.scss'],
    standalone: false
})
export class InventoryBusinessAreaComponent implements OnInit {
  @Input() model: EditFacilityInventoryDetailVm;
  @Output() modelChange = new EventEmitter<EditFacilityInventoryDetailVm>();

  formGroup = EditFacilityInventoryDetailVm.Form;
  canEdit: boolean;

  constructor(private sec: SecurityService) { }

  ngOnInit() {
    this.formGroup.patchValue(this.model);
    this.canEdit = this.sec.hasModuleAccess([BusinessAreaEnum.Admin, BusinessAreaEnum.CI],
      [...SecurityService.setMinRole(PermissionRole.UserAdmin)]);
  }
}
