import { combine, generateUUID, toServerDuration, relativeToClocks, assign, createTaskQueue } from '@datadog/browser-core';
import { RumPerformanceEntryType, createPerformanceObservable } from '../../browser/performanceObservable';
import { createSpanIdentifier } from '../tracing/identifier';
import { matchRequestResourceEntry } from './matchRequestResourceEntry';
import { computeResourceEntryDetails, computeResourceEntryDuration, computeResourceEntryType, computeResourceEntrySize, computeResourceEntryProtocol, computeResourceEntryDeliveryType, isResourceEntryRequestType, isLongDataUrl, sanitizeDataUrl } from './resourceUtils';
import { retrieveInitialDocumentResourceTiming } from './retrieveInitialDocumentResourceTiming';
export function startResourceCollection(lifeCycle, configuration, pageStateHistory, taskQueue, retrieveInitialDocumentResourceTimingImpl) {
  if (taskQueue === void 0) {
    taskQueue = createTaskQueue();
  }
  if (retrieveInitialDocumentResourceTimingImpl === void 0) {
    retrieveInitialDocumentResourceTimingImpl = retrieveInitialDocumentResourceTiming;
  }
  lifeCycle.subscribe(7 /* LifeCycleEventType.REQUEST_COMPLETED */, function (request) {
    handleResource(function () {
      return processRequest(request, configuration, pageStateHistory);
    });
  });
  var performanceResourceSubscription = createPerformanceObservable(configuration, {
    type: RumPerformanceEntryType.RESOURCE,
    buffered: true
  }).subscribe(function (entries) {
    var _loop_1 = function (entry) {
      if (!isResourceEntryRequestType(entry)) {
        handleResource(function () {
          return processResourceEntry(entry, configuration);
        });
      }
    };
    for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
      var entry = entries_1[_i];
      _loop_1(entry);
    }
  });
  retrieveInitialDocumentResourceTimingImpl(configuration, function (timing) {
    handleResource(function () {
      return processResourceEntry(timing, configuration);
    });
  });
  function handleResource(computeRawEvent) {
    taskQueue.push(function () {
      var rawEvent = computeRawEvent();
      if (rawEvent) {
        lifeCycle.notify(11 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, rawEvent);
      }
    });
  }
  return {
    stop: function () {
      performanceResourceSubscription.unsubscribe();
    }
  };
}
function processRequest(request, configuration, pageStateHistory) {
  var matchingTiming = matchRequestResourceEntry(request);
  var startClocks = matchingTiming ? relativeToClocks(matchingTiming.startTime) : request.startClocks;
  var tracingInfo = computeRequestTracingInfo(request, configuration);
  if (!configuration.trackResources && !tracingInfo) {
    return;
  }
  var type = request.type === "xhr" /* RequestType.XHR */ ? "xhr" /* ResourceType.XHR */ : "fetch" /* ResourceType.FETCH */;
  var correspondingTimingOverrides = matchingTiming ? computeResourceEntryMetrics(matchingTiming) : undefined;
  var duration = computeRequestDuration(pageStateHistory, startClocks, request.duration);
  var resourceEvent = combine({
    date: startClocks.timeStamp,
    resource: {
      id: generateUUID(),
      type: type,
      duration: duration,
      method: request.method,
      status_code: request.status,
      protocol: matchingTiming && computeResourceEntryProtocol(matchingTiming),
      url: isLongDataUrl(request.url) ? sanitizeDataUrl(request.url) : request.url,
      delivery_type: matchingTiming && computeResourceEntryDeliveryType(matchingTiming)
    },
    type: "resource" /* RumEventType.RESOURCE */,
    _dd: {
      discarded: !configuration.trackResources
    }
  }, tracingInfo, correspondingTimingOverrides);
  return {
    startTime: startClocks.relative,
    rawRumEvent: resourceEvent,
    domainContext: {
      performanceEntry: matchingTiming,
      xhr: request.xhr,
      response: request.response,
      requestInput: request.input,
      requestInit: request.init,
      error: request.error,
      isAborted: request.isAborted,
      handlingStack: request.handlingStack
    }
  };
}
function processResourceEntry(entry, configuration) {
  var startClocks = relativeToClocks(entry.startTime);
  var tracingInfo = computeResourceEntryTracingInfo(entry, configuration);
  if (!configuration.trackResources && !tracingInfo) {
    return;
  }
  var type = computeResourceEntryType(entry);
  var entryMetrics = computeResourceEntryMetrics(entry);
  var resourceEvent = combine({
    date: startClocks.timeStamp,
    resource: {
      id: generateUUID(),
      type: type,
      url: entry.name,
      status_code: discardZeroStatus(entry.responseStatus),
      protocol: computeResourceEntryProtocol(entry),
      delivery_type: computeResourceEntryDeliveryType(entry)
    },
    type: "resource" /* RumEventType.RESOURCE */,
    _dd: {
      discarded: !configuration.trackResources
    }
  }, tracingInfo, entryMetrics);
  return {
    startTime: startClocks.relative,
    rawRumEvent: resourceEvent,
    domainContext: {
      performanceEntry: entry
    }
  };
}
function computeResourceEntryMetrics(entry) {
  var renderBlockingStatus = entry.renderBlockingStatus;
  return {
    resource: assign({
      duration: computeResourceEntryDuration(entry),
      render_blocking_status: renderBlockingStatus
    }, computeResourceEntrySize(entry), computeResourceEntryDetails(entry))
  };
}
function computeRequestTracingInfo(request, configuration) {
  var hasBeenTraced = request.traceSampled && request.traceId && request.spanId;
  if (!hasBeenTraced) {
    return undefined;
  }
  return {
    _dd: {
      span_id: request.spanId.toString(),
      trace_id: request.traceId.toString(),
      rule_psr: configuration.rulePsr
    }
  };
}
function computeResourceEntryTracingInfo(entry, configuration) {
  var hasBeenTraced = entry.traceId;
  if (!hasBeenTraced) {
    return undefined;
  }
  return {
    _dd: {
      trace_id: entry.traceId,
      span_id: createSpanIdentifier().toString(),
      rule_psr: configuration.rulePsr
    }
  };
}
function computeRequestDuration(pageStateHistory, startClocks, duration) {
  return !pageStateHistory.wasInPageStateDuringPeriod("frozen" /* PageState.FROZEN */, startClocks.relative, duration) ? toServerDuration(duration) : undefined;
}
/**
 * The status is 0 for cross-origin resources without CORS headers, so the status is meaningless, and we shouldn't report it
 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/responseStatus#cross-origin_response_status_codes
 */
function discardZeroStatus(statusCode) {
  return statusCode === 0 ? undefined : statusCode;
}
