import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/interfaces/pcg-table-column.interface';
import { EditFacilityCiDetailVm, EditFacilityCiDetailVmFromFg } from '../../../../Administration/Models/Facilities/BusinessAreas/EditFacilityCiDetailVm';
import { EditFacilityInventoryDetailVm, EditFacilityInventoryDetailVmFromFg } from '../../../../Administration/Models/Facilities/BusinessAreas/EditFacilityInventoryDetailVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class FacilityBusinessAreaVm {
	facilityId: number;
	facilityAccountInfoId: number;
	hasCiAccess: boolean;
	hasInventoryAccess: boolean;
	hasMetabolicAccess: boolean;
	facilityBusinessAreaEnums?: any;
	businessAreas?: string[];
	ciDetailsVm?: EditFacilityCiDetailVm;
	inventoryDetailsVm?: EditFacilityInventoryDetailVm;
	friendlyId?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'facilityAccountInfoId': new UntypedFormControl(0, [...(validators['facilityAccountInfoId'] ?? []), ...[Validators.required]]),
			'hasCiAccess': new UntypedFormControl(false, [...(validators['hasCiAccess'] ?? []), ...[]]),
			'hasInventoryAccess': new UntypedFormControl(false, [...(validators['hasInventoryAccess'] ?? []), ...[]]),
			'hasMetabolicAccess': new UntypedFormControl(false, [...(validators['hasMetabolicAccess'] ?? []), ...[]]),
			'facilityBusinessAreaEnums': new UntypedFormControl([], [...(validators['facilityBusinessAreaEnums'] ?? []), ...[]]),
			'businessAreas': new UntypedFormControl([], [...(validators['businessAreas'] ?? []), ...[]]),
			'ciDetailsVm': new UntypedFormControl(null, [...(validators['ciDetailsVm'] ?? []), ...[]]),
			'inventoryDetailsVm': new UntypedFormControl(null, [...(validators['inventoryDetailsVm'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): FacilityBusinessAreaVmFromFg {
		return new FacilityBusinessAreaVmFromFg(fg);
	}
}

export class FacilityBusinessAreaVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get facilityAccountInfoId(): number {
		return this.fg.get('facilityAccountInfoId').value;
	}
	set facilityAccountInfoId(val) {
		this.fg.get('facilityAccountInfoId').setValue(val);
	}

	get hasCiAccess(): boolean {
		return this.fg.get('hasCiAccess').value;
	}
	set hasCiAccess(val) {
		this.fg.get('hasCiAccess').setValue(val);
	}

	get hasInventoryAccess(): boolean {
		return this.fg.get('hasInventoryAccess').value;
	}
	set hasInventoryAccess(val) {
		this.fg.get('hasInventoryAccess').setValue(val);
	}

	get hasMetabolicAccess(): boolean {
		return this.fg.get('hasMetabolicAccess').value;
	}
	set hasMetabolicAccess(val) {
		this.fg.get('hasMetabolicAccess').setValue(val);
	}

	get facilityBusinessAreaEnums(): any {
		return this.fg.get('facilityBusinessAreaEnums').value;
	}
	set facilityBusinessAreaEnums(val) {
		this.fg.get('facilityBusinessAreaEnums').setValue(val);
	}

	get ciDetailsVm(): EditFacilityCiDetailVmFromFg {
		return new EditFacilityCiDetailVmFromFg(this.fg.get('ciDetailsVm') as UntypedFormGroup);
	}
	set ciDetailsVm(val) {
		(this.fg.get('ciDetailsVm') as UntypedFormGroup).setValue(val);
	}

	get inventoryDetailsVm(): EditFacilityInventoryDetailVmFromFg {
		return new EditFacilityInventoryDetailVmFromFg(this.fg.get('inventoryDetailsVm') as UntypedFormGroup);
	}
	set inventoryDetailsVm(val) {
		(this.fg.get('inventoryDetailsVm') as UntypedFormGroup).setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}
}
