import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faBuildingColumns } from '@fortawesome/pro-solid-svg-icons';

import { AuditHistoryVm } from 'app/shared/generated/Models/AuditHistoryVm';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-shared-organization-audit-history',
    templateUrl: './organization-audit-history.component.html',
    styleUrls: ['./organization-audit-history.component.scss'],
    standalone: false
})
export class OrganizationAuditHistoryComponent implements OnInit {

    @ViewChild(TableComponent, { static: false }) table: TableComponent<AuditHistoryVm>;
	columns = AuditHistoryVm.ColumnDefs;
	
    organizationId: number;
    organizationName: string = null;

    filters = { organizationId: null };
    faIconName = { faBuildingColumns };

	constructor(
        title: Title
        , private activatedRoute: ActivatedRoute
    ) { title.setTitle('Edit Organization'); }

	ngOnInit() { this.organizationId = this.filters.organizationId = this.activatedRoute.snapshot.params['id']; }
}
