<div class="modal-header bg-white rounded">
    <h5 
		class="modal-title" 
		id="modal-basic-title"
	>Comments</h5>
    <button 
		type="button" 
		class="close" 
		aria-label="Close" 
		(click)="modal.dismiss()"
	><span aria-hidden="true">&times;</span></button>
</div>
<pcg-conversations
	[parentId]="orderId"
	[getUrl]="'api/' + area + '/' + controller + '/GetComments'"
	[saveUrl]="'api/' + area + '/' + controller + '/SaveComment'"
	[deleteUrl]="'api/' + area + '/' + controller + '/DeleteComment'"
	[showCommenterInfo]="true"
></pcg-conversations>