<div class="wrapper">
    <div class="modal-header justify-content-center bg-warning" [class.bg-danger]="error">
        <h5 class="modal-title" [class.text-white]="error" [innerHTML]="title"></h5>
    </div>
    <div class="modal-body" [style.width]="width">
        <span [innerHTML]="confirmationMessage"></span>
    </div>
    <div class="modal-footer justify-content-center">
       <button class="btn btn-outline-danger" (click)="cancel()">Cancel</button>
       <button [hidden]="!showConfirmBtn || error" class="btn btn-success" (click)="confirm()">Confirm</button>
    </div>
</div>
