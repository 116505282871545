@if (!infoOpened) {
    <form [formGroup]="formGroup">
        <input type="hidden" formControlName="organizationId" />
        <input type="hidden" formControlName="organizationName" />
        <input type="hidden" formControlName="smartyAddress" />
        <input type="hidden" formControlName="hasSmarty" />
        <input type="hidden" formControlName="phoneNumber" />
        <input type="hidden" formControlName="isActive" />
        <input type="hidden" formControlName="isSameAddress" />
        <input type="hidden" formControlName="shipOrganizationName" />
        <input type="hidden" formControlName="shipSmartyAddress" />
        <input type="hidden" formControlName="isShipAddressValidated" />
        <input type="hidden" formControlName="isSingleFacility" />
        <input type="hidden" formControlName="facilityIds" />
    
        <br />
        <div class="row">
            <pcg-control-group 
                class="col-md-6" 
                label="Login Type"
            >
                @if (canEdit) {
                    <pcg-select 
                        #loginTypes
                        [multiple]="false"
                        placeholder="Select..."
                        [sendFormData]="false"
                        pcgControlRef
                        dataSource="api/Select/LoginTypes"
                        formControlName="loginTypeEnum"
                        (change)="changeLoginType()"
                    ></pcg-select>
                } @else { <span>{{ model?.loginType }}</span> }
            </pcg-control-group>
    
            @if (showSsoType === true) {
                <div class="col-md-6">
                    <label class="for">
                        SSO Type
                        @if (model?.ssoTypeEnum === 1) { 
                            &nbsp;
                            <a 
                                (click)="openInfo()" 
                                style="color: blue;"
                            >View Setup Instructions</a> 
                        }
                    </label>
                    @if (canEdit) {
                        <pcg-select 
                            #ssoTypes
                            [multiple]="false"
                            placeholder="Select..."
                            [sendFormData]="false"
                            pcgControlRef
                            dataSource="api/Select/SsoTypes"
                            formControlName="ssoTypeEnum"
                        ></pcg-select>
                    } @else { <span>{{ model?.ssoType }}</span> }
                </div>
            }
        </div>
    
        @if (
            showSsoType === true
            && canEdit
        ) { 
            <div class="row">
                <pcg-control-group 
                    class="col-md-6" 
                    label="Entra Identifier URL"
                >
                    <input 
                        pcgControlRef 
                        formControlName="entraIdentifierUrl" 
                        class="form-control" 
                        type="text"  
                        maxlength="500"
                    />
                </pcg-control-group>
    
                <pcg-control-group class="col-md-6">
                    <label 
                        class="for"
                        ngbTooltip="This URL must end in ?SAMLRequest="
                    >Entra Login URL</label>
                    <input 
                        pcgControlRef 
                        formControlName="entraLoginUrl" 
                        class="form-control" 
                        type="text"  
                        maxlength="500"
                    />
                </pcg-control-group>
            </div>
        }
    
        <pcg-fixed-buttons>
            @if (canEdit) {
                <button 
                    class="btn btn-save" 
                    (click)="onSave()"
                >Save</button>
            }
        </pcg-fixed-buttons>
    </form>
} @else {
    <div>
        <div class="info-hdr">
            <div class="info-hdr-branding">
                <h2>Single-Sign-On Microsoft Active Directory Entra Setup</h2>
            </div>
            <div class="close-btn">
                <fa-icon 
                    [icon]="faIconName.faXmark" 
                    class="fa-xl" 
                    style="cursor: pointer"
                    (click)="closeInfo()"
                ></fa-icon>
            </div>
        </div>
        <hr />

        <div class="info-body">
            <ol>
                <li>
                    On the Entra Admin Center page, go to Applications > Enterprise applications and click ‘+ New application’ at the top of the screen.
                    <br />
                    <img 
                        [src]="'assets/images/sso-entra/01.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                </li>
                <li>
                    On the page that pops up, click ‘Create your own application’ and enter a name in the input box that shows up. 
                    Check the radio button ‘integrate any other application you don’t…’
                    <br />
                    <img 
                        [src]="'assets/images/sso-entra/02.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                </li>
                <li>
                    Click ‘Single sign-on’ in the side nav of the application created in the last step then click into the button called ‘Saml’
                    <br />
                    <img 
                        [src]="'assets/images/sso-entra/03.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                </li>
                <li>
                    Click the edit button in the first card on the page. 
                    Set the entity Id to the URL ‘https://auth.paulconsulting.com/akpQU7kCvy9XvOI10SqipBRyLx’ but change the last 
                    string of letters to a random, unique value. Set the Reply Url to ‘https://emit.fdepcs.com/api/account/SsoRedirect’
                    <br />
                    <img 
                        [src]="'assets/images/sso-entra/04.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                    <img 
                        [src]="'assets/images/sso-entra/05.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                    <img 
                        [src]="'assets/images/sso-entra/06.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                </li>
                <li>
                    Set the Logout URL at the bottom of the edit page to ‘https://emit.fdepcs.com/login’ and then click save
                    <br />
                    <img 
                        [src]="'assets/images/sso-entra/07.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                </li>
                <li>
                    Go to ‘Users and groups’ in the side. Click ‘Add user/group’ and add users that will be allowed to login
                    <br />
                    <img 
                        [src]="'assets/images/sso-entra/08.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                </li>
                <li>
                    Copy the Entity Id and the Login Url and add them to your organization details.
                    <br />
                    <img 
                        [src]="'assets/images/sso-entra/09.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                    <img 
                        [src]="'assets/images/sso-entra/10.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                    <img 
                        [src]="'assets/images/sso-entra/11.png'" 
                        [class]="isMobile ? 'sso-img' : ''" 
                    />
                    <br /><br />
                </li>
            </ol>
        </div>
    </div>
}