import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { faSquareQuestion } from '@fortawesome/pro-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { InventoryService } from '../../inventory/services/inventory.service';
import { ProductBarcode } from '../../inventory/services/product-barcode';
import { ConfirmationModalComponent } from '../form-elements/components/confirmation-modal/confirmation-modal.component';

@UntilDestroy()
@Component({
    selector: 'pcg-scan-product',
    templateUrl: './scan-product.component.html',
    styleUrls: ['./scan-product.component.scss'],
    standalone: false
})
export class ScanProductComponent implements OnInit {

	@Input() autofocus = false;
	@Input() includeProductInfo = false;
	@Input() inputPlaceholder = 'Scan Barcode';
	@Input() inventorySiteId: number;
	@Input() ndc: string;
	@Input() isDisabled = false;
	@Input() isFulfillment = true;
	@Input() isCiReceiving = false;
	@Input() isCiRepack = false;
	@Input() scanDetection = false; // When enabled, this will detect any scans on the the page.
	@Input() showHelp = true;

	@Output() scanSuccess = new EventEmitter<ProductBarcode>();
	@Output() scanError = new EventEmitter<ProductBarcode>();
	@Output() scan = new EventEmitter<ProductBarcode>();

	@ViewChild('txtBarcode', { static: true }) txtBarcode: ElementRef;
	@ViewChild('helpModal', { static: true }) helpModal: TemplateRef<any>;

	faIcon = { faSquareQuestion };

	// These variables are used for the scanning sounds
	beepSuccess = new Audio();
	beepError = new Audio();
	isControlDown = false;
	isProcessing = false;

	// These variables are used for scan detection.
	private timeoutId: NodeJS.Timeout; 	// Used for scan detection to differentiate between scans and manual input based on how quickly the events are fired.
	private _input: string = ''; 		// _input will be populated with barcode to be emited when scan detection is enabled.
	private _oldValue: string = ''; 	// _oldValue is used to retain an inputs value if a scan is detected while focused on an input element other than the scan. 

	/** Handle control up and down and scan detection */
	@HostListener('document:keydown', ['$event'])
	controlDown(event: KeyboardEvent) {
		if (event.key === 'Control') { this.isControlDown = true; return; }
		// If scan detection has been enabled.
		if (this.scanDetection) { this.scanDetectionProcessing(event); }
	}

	@HostListener('document:keyup', ['$event'])
	controlUp(event: KeyboardEvent) { if (event.key === 'Control') { this.isControlDown = false; } }

	constructor(
		private inventoryService: InventoryService
		, private toastr: ToastrService
		, public modalService: NgbModal
	) { }

	ngOnInit() {
		const basePath = document.getElementsByTagName('base')[0].href;
		this.beepSuccess.src = basePath + 'assets/sounds/GreenScan.mp3';
		this.beepSuccess.load();
		this.beepError.src = basePath + 'assets/sounds/RedScan.mp3';
		this.beepError.load();
		console.log("scan-product: autofocus", this.autofocus);
		if (this.autofocus) { this.txtBarcode.nativeElement.focus(); }
	}

	ngOnChanges() { 
		console.log("scan-product: ngOnChanges() active element", document.activeElement);
		if (this.autofocus) { this.txtBarcode.nativeElement.focus(); } 
	}

	focus() { setTimeout(() => { this.txtBarcode.nativeElement.focus(); }, 50); }

	/**
	 * Hitting F8 and Ctrl + ] will add a double arrow, which can be used as a delimiter.
	 * New Zebra Scanners will add a delimeter with the Alt key.
	 * Hitting Enter will process the barcode.
	 * @param event The key down event
	 */
	keyDown(event) {
		console.log("scan-product: keydown", event.key, event.defaultPrevented);
		if (
			event.key === 'F8' 
			|| (
				this.isControlDown 
				&& event.key === ']'
			) 
			|| event.key === 'Alt'
		) { 
			this.txtBarcode.nativeElement.value += '↔'; 
			console.log("scan-product: keydown", this.txtBarcode.nativeElement.value);
		} 
		else if (event.key === 'Enter') {
			console.log("scan-product: keydown.enter - active element", document.activeElement);
			event.preventDefault();
			this.scanBarcode();
		}
	}

	/** This function gets fired when enter is pressed in the textbox or the scan button is pressed */
	scanBarcode() {
		this.isProcessing = true; // Disable scan input.
		console.log("scan-product: scanBarcode() isProcessing line 113", this.isProcessing);
		try {
			if (!FormatHelperService.GetIsNullyOrWhitespace(this.txtBarcode?.nativeElement?.value)) {
				console.log("scan-product: scanBarcode() in try block - this.txtBarcode.nativeElement.value", this.txtBarcode.nativeElement.value);
				const barcodeStr: string = this.txtBarcode.nativeElement.value;
				this.txtBarcode.nativeElement.value = '';
				// Get the barcode info
				let barcode: ProductBarcode = null;
				// Standard barcode, can only get the NDC
				if (barcodeStr.length === 12) {
					console.log("hit if barcodeStr.length === 12");
					barcode = {
						ndc10: this.inventoryService.parseNdc10FromGtin(barcodeStr)
						, lotNumber: null
						, serialNumber: null
						, expirationDate: null
						, lotExpId: null
					};
				} else if (
					barcodeStr?.split(" ")?.length === 2 
					|| barcodeStr?.includes(";")
				) {
					console.log("hit barcodeStr?.split()?.length === 2 || barcodeStr?.includes(;)");
					if (
						!this.ndc 
						&& !barcodeStr?.includes(";")
					) {
						console.log(" hit !this.ndc && !barcodeStr?.includes");
						this.toastr.error('Please select an Rx Number before scanning product.', null, { positionClass: 'toast-top-right', timeOut: 2000 });
						return this.emitScan(null);
					} 	
					console.log("about to call getFastPackProductInfo");				
					this.inventoryService.getFastPackProductInfo(
						this
						, this.inventorySiteId
						, this.ndc
						, barcodeStr
					).subscribe(productInfo => {
						if (productInfo) {
							console.log("inside getFastPackProductInfo if productInfo", productInfo);
							barcode = {
								ndc10: productInfo.ndc10
								, lotNumber: barcodeStr?.includes(";") 
									? barcodeStr?.split(";")[2].toUpperCase() 
									: barcodeStr?.split(" ")[1].toUpperCase()
								, serialNumber: null
								, expirationDate: barcodeStr?.includes(";") 
									? new Date(barcodeStr?.split(";")[3]) 
									: new Date(barcodeStr?.split(" ")[0])
								, lotExpId: productInfo.lotExpId
								, productInfo: productInfo
								, quantity: barcodeStr?.includes(";") 
									? this.parseAtp2Qty(barcodeStr?.split(";")[4]) 
									: null
								, isBulkLot: productInfo.isBulkLot
								, currentStock: productInfo?.currentStock
							};
							console.log("inside getFastPackProductInfo if !productInfo", productInfo);
							// Pass in optional param 'barcodeStr' to check if NDCs match (scanned NDC vs expected)
							this.emitScan(barcode, barcodeStr);
						} else {
							if (
								barcodeStr?.includes(";") 
								&& this.isCiRepack
							) { //For Ci Repack ATP2 Scans
								barcode = {
									ndc10: barcodeStr?.split(";")[1]
									, lotNumber: barcodeStr?.split(";")[2].toUpperCase()
									, expirationDate: new Date(barcodeStr?.split(";")[3])
									, quantity: this.parseAtp2Qty(barcodeStr?.split(";")[4])
								};
								return this.emitScan(barcode);
							}
							if (
								!this.ndc 
								&& barcodeStr?.includes(";")
							) { this.toastr.error('Please select an Rx Number before scanning product.', null, { positionClass: 'toast-top-right', timeOut: 2000 }); }
							else { 
								this.toastr.error(`Product scanned does not match prescribed product. Please check the Rx Number and try again. 
									If this is an ATP label, ensure that the product has "Is Bulk Lot?" and "Use Parata Lot Number" 
									toggled within the product detail of the current inventory.`, null, { positionClass: 'toast-top-right', timeOut: 8000 });
								console.log("scan-product: scanBarcode() - product not active in inventory - line 186"); 
							}
							return this.emitScan(null);
						}
					});
				} else {
					console.log("hit else - scanGs1Barcode");
					// Fancy GS1 barcode, can get all data
					barcode = this.inventoryService.scanGs1Barcode(barcodeStr);
				}
				console.log("scan-product: scanBarcode() - barcodeStr: ", barcodeStr);
				// Emit the scan events and get the product info, if desired
				if (
					this.includeProductInfo 
					&& barcode !== null 
					&& (
						this.isFulfillment 
						|| this.isCiReceiving
					)
				) {
					console.log("hit this.includeProductInfo  && barcode !== null  && ( this.isFulfillment  || this.isCiReceiving");
					this.inventoryService.getNdcProductInfo(
						this
						, barcode.ndc10
						, this.inventorySiteId
					).subscribe(productInfo => {
						if (productInfo) {
							console.log("inside getNdcProductInfo if productInfo", productInfo);
							barcode.productInfo = productInfo;
							if (
								!barcode.productInfo.isInventoryProductActive 
								&& this.isFulfillment
							) {
								this.toastr.error('The product scanned is not active in your current inventory.', null, { positionClass: 'toast-top-right', timeOut: 2000 });
								console.log("scan-product: scanBarcode() - product not active in inventory - line 222");
								this.beepError.play();
								this.scanError.emit(barcode);
								this.isProcessing = false;
								console.log("scan-product: scanBarcode() isProcessing line 226", this.isProcessing);
								return;
							}
						} else if (!this.isCiReceiving) { 
							console.log("else if (!this.isCiReceiving)");
							barcode = null; 
							console.log("The product scanned does not exist in the GlobalNDC table.");
							this.toastr.error('The product scanned does not exist in the GlobalNDC table.', null, { positionClass: 'toast-top-right', timeOut: 2000 });
							this.beepError.play();
							this.scanError.emit(barcode);
							this.isProcessing = false;
							return;
						}
						this.emitScan(barcode);		
					});
				} else { console.log("final else emitScan(barcode)"); this.emitScan(barcode); }
			}
			this.isProcessing = false; // ensure scan is re-enabled.
			console.log("scan-product: scanBarcode() isProcessing line 235", this.isProcessing);
		} catch (error) {
			this.isProcessing = false; // ensure scan is re-enabled.
			console.log("scan-product: scanBarcode() isProcessing line 238", this.isProcessing);
			console.log(error);
			throw error;
		}
	}

	/**
	 * This function parses the Quantity from the ATP2 Barcode 
	 * which might have partial pills
	 * @param quantity 
	 * @returns Quantity parsed
	 */
	parseAtp2Qty(quantity: string) {
		if (quantity.includes('x')) {
			let wholeNumber = parseInt(quantity?.split('x')[0]);
			let fraction = quantity?.split('x')[1];
			let dividend = parseInt(fraction?.split('/')[0]);
			let divisor = parseInt(fraction?.split('/')[1]);
			let finalNumber = wholeNumber * (dividend / divisor);
			return finalNumber;
		} else { return parseInt(quantity); }
	}

	/**
	 * This function emits the scan events that can be used by other components
	 * @param barcode The ProductBarcode that was parsed
	 */
	emitScan(
		barcode: ProductBarcode
		, barcodeStr: string = null
	) {
		console.log("scan-product: emitScan()", barcode, barcodeStr);
		this.isProcessing = false; // ensure scan is re-enabled.
		console.log("scan-product: emitScan() isProcessing line 271", this.isProcessing);
		let scannedNdc = null;
		if (barcodeStr !== null) 
			scannedNdc = FormatHelperService.FormatNdc(barcodeStr.split(";")[1]);
		if (barcode !== null) {
			// If scanned NDC does not match the product NDC for the selected Rx, open confirmation modal
			if (
				scannedNdc !== null 
				&& scannedNdc !== this.ndc
			) {	this.confirmIncorrectNdc(barcode, scannedNdc); }
			else {
				// Check if the inventory product is active, if not return an error message.
				console.log("scan-product: emitScan() scanSuccess.emit(barcode)", barcode);
				this.beepSuccess.play();
				this.scanSuccess.emit(barcode);
			}
		} else {
			this.beepError.play();
			console.log("scan-product: emitScan() scanError.emit(barcode)", barcode);
			this.scanError.emit(barcode);
		}
		console.log("scan-product: emitScan() scan.emit(barcode)", barcode);
		this.scan.emit(barcode);
	}

	scanDetectionProcessing(event: KeyboardEvent) {
		console.log("scan-product: scanDetectionProcessing()", event.key);		
		let target = (event.target as HTMLFormElement);
		// When focused in the scanner, return to speed things up.
		if (target.id === this.txtBarcode.nativeElement.id) { return; }
		// Since the scan happens so rapidly, timeoutId having value should indicate a scan is in progress.
		if (this.timeoutId) {
			// Prevent the timeout function below from happening. timeoutId will be the for the last time this was fired in the last 60 milliseconds.
			clearTimeout(this.timeoutId);
			// Getting here means that a scan is most likey in progress. If the user is currently focused on another input element, we want to retain the elements original value. 
			target.value = this._oldValue;
		}
		// Save the inputs original value. 
		else { this._oldValue = target?.value; }
		// The following should only process on manual inputs, or when the last character of a scan has been entered.
		this.timeoutId = setTimeout(() => {
			// reset these variables after manual entry or the last character of a scan has been entered.
			this._input = '';
			this.timeoutId = null;
			// On 'Enter' trigger the key down, validation in this function will take over from here.
			if (event.key === 'Enter') { this.keyDown(event); }
		}, 30); // May need to up the timeout if the browser cannot process quickly enough.
		// The last character on a scan should be 'Enter', once input set the scan value.
		if (event.key === 'Enter') { this.txtBarcode.nativeElement.value = this._input; }
		// Capture delimeter appropriately.
		else if (
			event.key === 'F8' 
			|| (
				this.isControlDown 
				&& event.key === ']'
			)
			|| event.key === 'Alt'
		) { this._input += '↔'; }
		// Ignore complex keys.
		else if (event.key?.length > 1) { return; }
		// Adding the event key to the '_input' variable here since scans should finish procesessing before the value is reset in the timeout function above.
		else { this._input += event.key; }
	}

	// Opens confirmation modal if the scanned NDC != NDC passed into this component from the selected Rx
	confirmIncorrectNdc(
		barcode: ProductBarcode
		, scannedNdc: string
	) {
		const modal = this.modalService.open(ConfirmationModalComponent);
		// Passes modal reference into component to give us the ability to close it.
		modal.componentInstance.modalRef = modal;
		// Instantiates properties in the component.
		modal.componentInstance.confirmationMessage = 
		`<p class="text-center">The scanned product NDC (${scannedNdc}) does not match the selected Rx product NDC (${this.ndc}).</p>
		<p class="text-center">Are you sure you want to continue?</p>`;

		modal.result.then((emittedValue) => {
			if(emittedValue) {
				this.beepSuccess.play();
				this.scanSuccess.emit(barcode);
			}
		}).catch(err => {
			// Prevents Uncaught (in promise) error when user  
			// closes modal from clicking outside modal.     
		});
	}

	helpButtonClicked() { this.modalService.open(this.helpModal); }
}
