import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class GenericCommentVm {
	id: number;
	parentId: number;
	commentorInfo?: string;
	commentDate: Date;
	commentDateStr?: string;
	commentText?: string;
	commentUserId?: number;
	isEdit: boolean;
	isDelete: boolean;
	showEditDelete: boolean;
	showCommentDate: boolean;
	friendlyId?: string;
	hasSeen: boolean;
	pfsFormID?: number;
	pfsUrl?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'parentId': new UntypedFormControl(0, [...(validators['parentId'] ?? []), ...[Validators.required]]),
			'commentorInfo': new UntypedFormControl(null, [...(validators['commentorInfo'] ?? []), ...[Validators.maxLength(500)]]),
			'commentDate': new UntypedFormControl('', [...(validators['commentDate'] ?? []), ...[Validators.required]]),
			'commentDateStr': new UntypedFormControl(null, [...(validators['commentDateStr'] ?? []), ...[]]),
			'commentText': new UntypedFormControl(null, [...(validators['commentText'] ?? []), ...[Validators.maxLength(3000)]]),
			'commentUserId': new UntypedFormControl(null, [...(validators['commentUserId'] ?? []), ...[]]),
			'isEdit': new UntypedFormControl(false, [...(validators['isEdit'] ?? []), ...[]]),
			'isDelete': new UntypedFormControl(false, [...(validators['isDelete'] ?? []), ...[]]),
			'showEditDelete': new UntypedFormControl(false, [...(validators['showEditDelete'] ?? []), ...[]]),
			'showCommentDate': new UntypedFormControl(false, [...(validators['showCommentDate'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'hasSeen': new UntypedFormControl(false, [...(validators['hasSeen'] ?? []), ...[]]),
			'pfsFormID': new UntypedFormControl(null, [...(validators['pfsFormID'] ?? []), ...[]]),
			'pfsUrl': new UntypedFormControl(null, [...(validators['pfsUrl'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): GenericCommentVmFromFg {
		return new GenericCommentVmFromFg(fg);
	}
}

export class GenericCommentVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get parentId(): number {
		return this.fg.get('parentId').value;
	}
	set parentId(val) {
		this.fg.get('parentId').setValue(val);
	}

	get commentorInfo(): string {
		return this.fg.get('commentorInfo').value;
	}
	set commentorInfo(val) {
		this.fg.get('commentorInfo').setValue(val);
	}

	get commentDate(): Date {
		return this.fg.get('commentDate').value;
	}
	set commentDate(val) {
		this.fg.get('commentDate').setValue(val);
	}

	get commentDateStr(): string {
		return this.fg.get('commentDateStr').value;
	}
	set commentDateStr(val) {
		this.fg.get('commentDateStr').setValue(val);
	}

	get commentText(): string {
		return this.fg.get('commentText').value;
	}
	set commentText(val) {
		this.fg.get('commentText').setValue(val);
	}

	get commentUserId(): number {
		return this.fg.get('commentUserId').value;
	}
	set commentUserId(val) {
		this.fg.get('commentUserId').setValue(val);
	}

	get isEdit(): boolean {
		return this.fg.get('isEdit').value;
	}
	set isEdit(val) {
		this.fg.get('isEdit').setValue(val);
	}

	get isDelete(): boolean {
		return this.fg.get('isDelete').value;
	}
	set isDelete(val) {
		this.fg.get('isDelete').setValue(val);
	}

	get showEditDelete(): boolean {
		return this.fg.get('showEditDelete').value;
	}
	set showEditDelete(val) {
		this.fg.get('showEditDelete').setValue(val);
	}

	get showCommentDate(): boolean {
		return this.fg.get('showCommentDate').value;
	}
	set showCommentDate(val) {
		this.fg.get('showCommentDate').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get hasSeen(): boolean {
		return this.fg.get('hasSeen').value;
	}
	set hasSeen(val) {
		this.fg.get('hasSeen').setValue(val);
	}

	get pfsFormID(): number {
		return this.fg.get('pfsFormID').value;
	}
	set pfsFormID(val) {
		this.fg.get('pfsFormID').setValue(val);
	}

	get pfsUrl(): string {
		return this.fg.get('pfsUrl').value;
	}
	set pfsUrl(val) {
		this.fg.get('pfsUrl').setValue(val);
	}
}
