import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ShipmentListVm {
	shipmentId: number;
	createdBy?: string;
	shipmentServiceTypeID?: any;
	shipmentTypeStr?: string;
	masterTrackingNumber?: string;
	weightPounds?: number;
	packageStatus?: string;
	dateCreated: Date;
	dateCreatedStr?: string;
	dateDelivered?: Date;
	dateDeliveredStr?: string;
	rxNumberCount: number;
	fulfillmentStatus?: string;
	deliveryType?: string;
	isRejected: boolean;
	prescriptionCount: number;
	canDelete: boolean;
	isNotActive?: boolean;
	pfsLink?: string;
	inventory?: string;
	inventoryId?: number;
	isOverdue1: boolean;
	isOverdue2: boolean;
	hasFulfillmentBeenOpened: boolean;
	isOpen: boolean;
	openUserTooltip?: any;
	commentCount: number;
	lastCommentDate?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'shipmentId': new UntypedFormControl(0, [...(validators['shipmentId'] ?? []), ...[Validators.required]]),
			'createdBy': new UntypedFormControl(null, [...(validators['createdBy'] ?? []), ...[]]),
			'shipmentServiceTypeID': new UntypedFormControl(null, [...(validators['shipmentServiceTypeID'] ?? []), ...[]]),
			'shipmentTypeStr': new UntypedFormControl(null, [...(validators['shipmentTypeStr'] ?? []), ...[]]),
			'masterTrackingNumber': new UntypedFormControl(null, [...(validators['masterTrackingNumber'] ?? []), ...[]]),
			'weightPounds': new UntypedFormControl(null, [...(validators['weightPounds'] ?? []), ...[]]),
			'packageStatus': new UntypedFormControl(null, [...(validators['packageStatus'] ?? []), ...[]]),
			'dateCreated': new UntypedFormControl('', [...(validators['dateCreated'] ?? []), ...[Validators.required]]),
			'dateCreatedStr': new UntypedFormControl(null, [...(validators['dateCreatedStr'] ?? []), ...[]]),
			'dateDelivered': new UntypedFormControl(null, [...(validators['dateDelivered'] ?? []), ...[]]),
			'dateDeliveredStr': new UntypedFormControl(null, [...(validators['dateDeliveredStr'] ?? []), ...[]]),
			'rxNumberCount': new UntypedFormControl(0, [...(validators['rxNumberCount'] ?? []), ...[Validators.required]]),
			'fulfillmentStatus': new UntypedFormControl(null, [...(validators['fulfillmentStatus'] ?? []), ...[]]),
			'deliveryType': new UntypedFormControl(null, [...(validators['deliveryType'] ?? []), ...[]]),
			'isRejected': new UntypedFormControl(false, [...(validators['isRejected'] ?? []), ...[]]),
			'prescriptionCount': new UntypedFormControl(0, [...(validators['prescriptionCount'] ?? []), ...[Validators.required]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]]),
			'isNotActive': new UntypedFormControl(null, [...(validators['isNotActive'] ?? []), ...[]]),
			'pfsLink': new UntypedFormControl(null, [...(validators['pfsLink'] ?? []), ...[]]),
			'inventory': new UntypedFormControl(null, [...(validators['inventory'] ?? []), ...[]]),
			'inventoryId': new UntypedFormControl(null, [...(validators['inventoryId'] ?? []), ...[]]),
			'isOverdue1': new UntypedFormControl(false, [...(validators['isOverdue1'] ?? []), ...[]]),
			'isOverdue2': new UntypedFormControl(false, [...(validators['isOverdue2'] ?? []), ...[]]),
			'hasFulfillmentBeenOpened': new UntypedFormControl(false, [...(validators['hasFulfillmentBeenOpened'] ?? []), ...[]]),
			'isOpen': new UntypedFormControl(false, [...(validators['isOpen'] ?? []), ...[]]),
			'openUserTooltip': new UntypedFormControl(null, [...(validators['openUserTooltip'] ?? []), ...[]]),
			'commentCount': new UntypedFormControl(0, [...(validators['commentCount'] ?? []), ...[Validators.required]]),
			'lastCommentDate': new UntypedFormControl(null, [...(validators['lastCommentDate'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['fulfillmentStatus', {"orderId":0,"sortColumn":"fulfillmentStatus","searchColumn":"fulfillmentStatus","includeInExcel":true,"exportColumns":null,"displayName":"Fulfillment Status","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['inventory', {"orderId":1,"sortColumn":"inventory","searchColumn":"inventory","includeInExcel":true,"exportColumns":null,"displayName":"Inventory","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['deliveryType', {"orderId":2,"sortColumn":"deliveryType","searchColumn":"deliveryType","includeInExcel":true,"exportColumns":null,"displayName":"Delivery Type","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['commentCount', {"orderId":3,"sortColumn":"commentCount","searchColumn":"commentCount","includeInExcel":true,"exportColumns":null,"displayName":"Comments","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['dateCreatedStr', {"orderId":4,"sortColumn":"dateCreated","searchColumn":"dateCreatedStr","includeInExcel":true,"exportColumns":null,"displayName":"Created By","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['rxNumberCount', {"orderId":5,"sortColumn":"rxNumberCount","searchColumn":"rxNumberCount","includeInExcel":true,"exportColumns":null,"displayName":"Rx #s","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['prescriptionCount', {"orderId":6,"sortColumn":"prescriptionCount","searchColumn":"prescriptionCount","includeInExcel":true,"exportColumns":null,"displayName":"Product Count","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['shipmentTypeStr', {"orderId":7,"sortColumn":"shipmentTypeStr","searchColumn":"shipmentTypeStr","includeInExcel":true,"exportColumns":null,"displayName":"Shipment Info","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['canDelete', {"orderId":8,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":true,"exportColumns":null,"displayName":"Delete","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): ShipmentListVmFromFg {
		return new ShipmentListVmFromFg(fg);
	}
}

export class ShipmentListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get shipmentId(): number {
		return this.fg.get('shipmentId').value;
	}
	set shipmentId(val) {
		this.fg.get('shipmentId').setValue(val);
	}

	get createdBy(): string {
		return this.fg.get('createdBy').value;
	}
	set createdBy(val) {
		this.fg.get('createdBy').setValue(val);
	}

	get shipmentServiceTypeID(): any {
		return this.fg.get('shipmentServiceTypeID').value;
	}
	set shipmentServiceTypeID(val) {
		this.fg.get('shipmentServiceTypeID').setValue(val);
	}

	get shipmentTypeStr(): string {
		return this.fg.get('shipmentTypeStr').value;
	}
	set shipmentTypeStr(val) {
		this.fg.get('shipmentTypeStr').setValue(val);
	}

	get masterTrackingNumber(): string {
		return this.fg.get('masterTrackingNumber').value;
	}
	set masterTrackingNumber(val) {
		this.fg.get('masterTrackingNumber').setValue(val);
	}

	get weightPounds(): number {
		return this.fg.get('weightPounds').value;
	}
	set weightPounds(val) {
		this.fg.get('weightPounds').setValue(val);
	}

	get packageStatus(): string {
		return this.fg.get('packageStatus').value;
	}
	set packageStatus(val) {
		this.fg.get('packageStatus').setValue(val);
	}

	get dateCreated(): Date {
		return this.fg.get('dateCreated').value;
	}
	set dateCreated(val) {
		this.fg.get('dateCreated').setValue(val);
	}

	get dateCreatedStr(): string {
		return this.fg.get('dateCreatedStr').value;
	}
	set dateCreatedStr(val) {
		this.fg.get('dateCreatedStr').setValue(val);
	}

	get dateDelivered(): Date {
		return this.fg.get('dateDelivered').value;
	}
	set dateDelivered(val) {
		this.fg.get('dateDelivered').setValue(val);
	}

	get dateDeliveredStr(): string {
		return this.fg.get('dateDeliveredStr').value;
	}
	set dateDeliveredStr(val) {
		this.fg.get('dateDeliveredStr').setValue(val);
	}

	get rxNumberCount(): number {
		return this.fg.get('rxNumberCount').value;
	}
	set rxNumberCount(val) {
		this.fg.get('rxNumberCount').setValue(val);
	}

	get fulfillmentStatus(): string {
		return this.fg.get('fulfillmentStatus').value;
	}
	set fulfillmentStatus(val) {
		this.fg.get('fulfillmentStatus').setValue(val);
	}

	get deliveryType(): string {
		return this.fg.get('deliveryType').value;
	}
	set deliveryType(val) {
		this.fg.get('deliveryType').setValue(val);
	}

	get isRejected(): boolean {
		return this.fg.get('isRejected').value;
	}
	set isRejected(val) {
		this.fg.get('isRejected').setValue(val);
	}

	get prescriptionCount(): number {
		return this.fg.get('prescriptionCount').value;
	}
	set prescriptionCount(val) {
		this.fg.get('prescriptionCount').setValue(val);
	}

	get canDelete(): boolean {
		return this.fg.get('canDelete').value;
	}
	set canDelete(val) {
		this.fg.get('canDelete').setValue(val);
	}

	get isNotActive(): boolean {
		return this.fg.get('isNotActive').value;
	}
	set isNotActive(val) {
		this.fg.get('isNotActive').setValue(val);
	}

	get pfsLink(): string {
		return this.fg.get('pfsLink').value;
	}
	set pfsLink(val) {
		this.fg.get('pfsLink').setValue(val);
	}

	get inventory(): string {
		return this.fg.get('inventory').value;
	}
	set inventory(val) {
		this.fg.get('inventory').setValue(val);
	}

	get inventoryId(): number {
		return this.fg.get('inventoryId').value;
	}
	set inventoryId(val) {
		this.fg.get('inventoryId').setValue(val);
	}

	get isOverdue1(): boolean {
		return this.fg.get('isOverdue1').value;
	}
	set isOverdue1(val) {
		this.fg.get('isOverdue1').setValue(val);
	}

	get isOverdue2(): boolean {
		return this.fg.get('isOverdue2').value;
	}
	set isOverdue2(val) {
		this.fg.get('isOverdue2').setValue(val);
	}

	get hasFulfillmentBeenOpened(): boolean {
		return this.fg.get('hasFulfillmentBeenOpened').value;
	}
	set hasFulfillmentBeenOpened(val) {
		this.fg.get('hasFulfillmentBeenOpened').setValue(val);
	}

	get isOpen(): boolean {
		return this.fg.get('isOpen').value;
	}
	set isOpen(val) {
		this.fg.get('isOpen').setValue(val);
	}

	get openUserTooltip(): any {
		return this.fg.get('openUserTooltip').value;
	}
	set openUserTooltip(val) {
		this.fg.get('openUserTooltip').setValue(val);
	}

	get commentCount(): number {
		return this.fg.get('commentCount').value;
	}
	set commentCount(val) {
		this.fg.get('commentCount').setValue(val);
	}

	get lastCommentDate(): string {
		return this.fg.get('lastCommentDate').value;
	}
	set lastCommentDate(val) {
		this.fg.get('lastCommentDate').setValue(val);
	}
}
