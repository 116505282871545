<mat-sidenav-container class="nav-container">
	<!--#region Navigation menu -->
	@if (isLoggedOn()) {
		<mat-sidenav 
			#sidenav 
			[mode]="
				navService.isNavFixed() 
				&& !navService.isMobile() ? 'side' : 'over'
			"
			[(opened)]="navService.isNavOpen"
			(openedChange)="navService.toggleNavOpen($event)"
			(mouseleave)="navService.toggleNavOpen(navService.isNavFixed())" 
			class="no-print"
			autosize
		>
			<pcg-mat-side-nav 
				[user]="user" 
				id="pcgSideNav" 
				class="no-print" 
			></pcg-mat-side-nav>
		</mat-sidenav>
	}
	<!--#endregion-->
		
	<!--#region Rest of the site -->
	<mat-sidenav-content [class.darker]='isLoggedOn() && navService?.isNavOpen()'>
		<!--#region Nav drawer -->
		@if (
			!navService.isMobile()
			&& !navService.isNavFixed()
			&& isLoggedOn()
			&& navService.navRoutes()
		) {
			<nav class="nav-drawer no-print">
				<div class="nav-drawer-image mb-2">
					<button 
						class="d-flex align-items-center justify-content-center mt-2 fixed-toggle-btn"
						(click)="navService.toggleNavFixed(true)" 
					>
						<fa-icon 
							[icon]="faIconName.faArrowRight" 
							class="fa-lg"
						></fa-icon>
					</button>
				</div>
		
				@for (nav of navService.navRoutes(); track nav;) {
					@if (
						navService.canViewNav(nav)
						&& nav.icon
					) {
						<a 
							class="drawer-icon pointer" 
							[routerLink]="nav.href ?? nav.path"
						>
							<fa-icon 
								[icon]="nav.icon" 
								class="drawer-icon fa-sm"
								[class.active-drawer-item]="nav.name === navService.selectedMainNav()"
							></fa-icon>
						</a>
					}
				}
		
				<fa-icon 
					[icon]="faIconName.faUser" 
					class="drawer-icon pointer"
					[class.active-drawer-item]='navService.selectedMainNav() === "profile"'
					(click)="takeToMyProfile()"
				></fa-icon>
			</nav>
		}
		<!--#endregion-->
		
		<!--#region Everything else -->
		<div [ngClass]="!isLoggedOn() ? '' : navService.isNavFixed() ? 'left-shift-fixed' : 'left-shift'">
			@if (isLoggedOn()) {
				<pcg-header 
					id='mainTopHeader' 
					class="main-header" 
					(hamburgerClick)="navService.toggleNavOpen($event)"
					[tabPanel]="tabPanel"
				></pcg-header>
			}

			<div 
				[ngClass]="!isLoggedOn() ? '' : navService.isNavFixed() ? 'top-shift-fixed' : 'top-shift'"
				class="mb-3" 
				(mouseover)="navService.toggleNavOpen(false)"
			>
				<pcg-push-notification></pcg-push-notification>
				<div 
					role="main" 
					class="main-container"
				>
					<mat-tab-nav-panel 
						#tabPanel 
						class="container fix-sticky-table"
					>
						<router-outlet></router-outlet>
					</mat-tab-nav-panel>
				</div>
				<pcg-system-message></pcg-system-message>
				<pcg-spinner></pcg-spinner>
			</div>
		</div>
		<!--#endregion-->

		<!--#region Footer -->
		<div class="footer-ctnr no-print">
			<div [class]="navService?.selectedMainNav() === 'dashboard' ? 'dashboard-footer' : 'footer'">
				<span id="versionHistory">
					<span>
						System by
						<a 
							target="_blank" 
							rel="noreferrer noopener" 
							href="http://www.paulconsultinggroup.com"
						>Paul Consulting Group</a>
					</span>
					@if (isLoggedOn()) {
						&nbsp;|&nbsp;
						<a 
							onclick="$router.navigateByUrl('/support/web-release-notes'); return false;"
							href="support/web-release-notes"
						>Release Notes</a>
					}
				</span>
			</div>
		</div>
		<!--#endregion-->
	</mat-sidenav-content>
	<!--#endregion-->
</mat-sidenav-container>
