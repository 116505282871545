<div class="dual-list">
	<div class="listbox-container">
		<label *ngIf="leftHeader" class='for'>{{ leftHeader }}</label>
		<form *ngIf="filter" class="filter input-group">
			<input placeholder="Search..." class="form-control form-control-sm" name="filterSource" [(ngModel)]="available.picker"
				(ngModelChange)="onFilter(available)">
			<div class='input-group-append'>
				<button (click)="moveAll()" class="btn btn-primary btn-sm">&gt;&gt;</button>
			</div>
		</form>
		<div class="listbox">
			<p>{{sourceName}}</p>
			<div class="record-picker">
				<ul [ngStyle]="{'max-height': height, 'min-height': height}">
					<li *ngFor="let item of available.sift; let idx=index;" draggable="false">
						<a href="#" (click)="moveTo(available, confirmed, item)">{{item._name}}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="centerbox">
		<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAQAAAADHm0dAAABSklEQVQoz82SzyvDcRjHX5/PvjbjTFKUmgMpktz8AxwddloSR2nKQYhxQomychDaYWsiUeLi4MaB61yUlbloSNa+5vvd5+Ow77DUOHqeenrqedXzfn7A/7bQ5OJGMZflUXeDb3hp70+owoWvf/kUQJRUPNGsWwBox4WoRKO4uwp2laLeg6wH/YkqctjYKDSpM6NITfVW1IeixbYZTHJYCCQSSV7dnzjoTKAzcrtL5PrQXQ2ZV2VrBFDRXNshsOzLga1YARzd10d6ZefnWMG5TR02/T0ABsyF2mZd2Hj7FpICiaAQtTXWasjMc7z7/AZAzK+1jLhQvGB+KgOJRDFU1duUfEikCx0M2S7Ik8ZGOM637DjxJUYgp2N1foXk/entUTpoQcB4J7lS7WJwYjUf1vPr5S8nAb29cBGw7N9ex9lrPGamGmv++Zt/AB+/c8kobWKbAAAAAElFTkSuQmCC" />
	</div>

	<div class="listbox-container">
		<label *ngIf="rightHeader" class='for' [class.required-field]='required'>{{ rightHeader }}</label>
		<form *ngIf="filter" class="filter input-group">
			<input placeholder="Search..." class="form-control-sm form-control" name="filterSource" [(ngModel)]="confirmed.picker"
				(ngModelChange)="onFilter(confirmed)">
			<div class='input-group-append'>
				<button (click)="removeAll()" class="btn btn-primary btn-sm">&lt;&lt;</button>
			</div>
		</form>
		<div class="listbox">
			<p>{{targetName}}</p>
			<div class="record-picker">
				<ul [ngStyle]="{'max-height': height, 'min-height': height}">
					<li *ngFor="let item of confirmed.sift; let idx=index;" draggable="false">
						<a href="#" (click)="moveTo(confirmed, available, item)">{{item._name}}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
