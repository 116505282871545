import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class IcqFeedbackVm {
	id: number;
	icqReviewId: number;
	rating: number;
	comment?: string;
	facility?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'icqReviewId': new UntypedFormControl(0, [...(validators['icqReviewId'] ?? []), ...[Validators.required]]),
			'rating': new UntypedFormControl(0, [...(validators['rating'] ?? []), ...[Validators.required]]),
			'comment': new UntypedFormControl(null, [...(validators['comment'] ?? []), ...[]]),
			'facility': new UntypedFormControl(null, [...(validators['facility'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): IcqFeedbackVmFromFg {
		return new IcqFeedbackVmFromFg(fg);
	}
}

export class IcqFeedbackVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get icqReviewId(): number {
		return this.fg.get('icqReviewId').value;
	}
	set icqReviewId(val) {
		this.fg.get('icqReviewId').setValue(val);
	}

	get rating(): number {
		return this.fg.get('rating').value;
	}
	set rating(val) {
		this.fg.get('rating').setValue(val);
	}

	get comment(): string {
		return this.fg.get('comment').value;
	}
	set comment(val) {
		this.fg.get('comment').setValue(val);
	}

	get facility(): string {
		return this.fg.get('facility').value;
	}
	set facility(val) {
		this.fg.get('facility').setValue(val);
	}
}
