import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'app/shared/models/select-item';

import { CustomFieldMaskEnum } from 'app/core/enums/generated/CustomFieldMaskEnum';
import { CustomFieldTypeEnum } from 'app/core/enums/generated/CustomFieldTypeEnum';
import { DynamicFormFieldVm } from 'app/shared/generated/Models/DynamicFormFieldVm';

@Component({
    selector: 'pcg-dynamic-form-field',
    templateUrl: './dynamic-form-field.component.html',
    styleUrls: ['./dynamic-form-field.component.css'],
    standalone: false
})
export class DynamicFormFieldComponent implements OnInit {

	@Input() field: DynamicFormFieldVm;
	@Input() fg: UntypedFormGroup;
	@Input() fgName: string;

	items: SelectItem[];

	CustomFieldTypeEnum = CustomFieldTypeEnum;
	CustomFieldMaskEnum = CustomFieldMaskEnum;

	constructor() { }

	ngOnInit(): void {
		if (this.field?.selectItemJson) {
			this.items = JSON.parse(this.field?.selectItemJson);
			// Placeholder doesnt show when select is initilized so set it to null
			if (this.fg.get(['customFields', this.field?.key]).value === '') {
				this.fg.get(['customFields', this.field?.key]).setValue(null);
			}
		}
	}
}
