// tslint:disable: quotemark
export enum InventoryTypeEnum {
	Bulk = 0,
	Issuance = 1,
	Both = 2
}

export namespace InventoryTypeEnum {
	export function toDescription(val: InventoryTypeEnum) {
		switch (val) {
			case 0:
				return "Bulk";
			case 1:
				return "Issuance";
			case 2:
				return "Both";
		}
		return null;
	}
	export function toDisplay(val: InventoryTypeEnum) {
		switch (val) {
			case 0:
				return "Bulk";
			case 1:
				return "Issuance";
			case 2:
				return "Both";
		}
		return null;
	}
}
