@if (canAccess) {
<ng-container>
    <pcg-page-title [primaryTitle]='formGroup.value.organizationName' backBtnLink='/admin/organizations/orgs/list'
        backBtnTooltip='Return to Organizations List'></pcg-page-title>

    <mat-tab-group pcgTabs mat-stretch-tabs='false' mat-align-tabs='start' animationDuration='200ms'
        [preserveContent]='true'>
        @if (model$ | async) {
        <div>
            <!-- Details -->
            <mat-tab label="Details">
                <ng-template matTabContent>
                    <div class="mat-tab-container">
                        <form [formGroup]="formGroup" (ngSubmit)="submitForms();">
                            @if (model.facilityId === 0) {
                            <pcg-page-info>In order to create a Organization you will need to enter the following
                                information.</pcg-page-info>
                            }

                            <div class="mx-0">
                                <input type='hidden' formControlName='organizationId' />
                                <input type='hidden' formControlName='countyName' />

                                <div class="row">
                                    <pcg-control-group class="col-md-4" label="Organization Name">
                                        <input pcgControlRef formControlName="organizationName" class="form-control"
                                            type="text" maxlength="50" />
                                    </pcg-control-group>

                                    <pcg-control-group class="col-md-2" label="Phone">
                                        <input pcgPhoneExt pcgControlRef class='form-control' type='text'
                                            formControlName="phoneNumber" />
                                    </pcg-control-group>

                                    <pcg-control-group class="col-md-2" label="GLN"
                                        labelTooltip="Global Location Number">
                                        <input pcgControlRef class='form-control' type='text' autocomplete='off'
                                            pcgNoSpecialChars='noSpecialChars' formControlName='gln' />
                                    </pcg-control-group>

                                    <!-- This toggle is only present when creating new organizations -->
                                    <pcg-control-group [class.d-none]="id !== 0" class="col-md-2 form-check-label"
                                        label="Single Facility"
                                        labelTooltip="Turn this feature on if only one facility will be associated to this organization. 
                                                Saving this organization will also create a facility and sync the data between the organization and facility">
                                        <ui-switch pcgControlRef #isSingleFacility color='#007bff' defaultBgColor='#EEE'
                                            class='ml-2 mt-2' size='small' formControlName='isSingleFacility'
                                            (click)="onSingleFacilityChange()"></ui-switch>
                                    </pcg-control-group>

                                    @if (canDeactivate) {
                                    <div class="col-md-2 offset-md-2 pt-3" id="activation">
                                        <div class="activate">
                                            <p class="mt-2" [class.text-danger]="!model.isActive"
                                                [class.d-none]="model.isActive">
                                                This Organization is deactivated.
                                            </p>
                                            @if (
                                            canEdit
                                            && model.organizationId !== 0
                                            ) {
                                            <a [class.btn-danger]="model.isActive" [class.btn-save]="!model.isActive"
                                                class="btn btn-md ml-2" (click)="
                                                                    activateDeactivate(); 
                                                                    model.isActive = !model.isActive;
                                                                ">{{ model.isActive ? 'Deactivate' : 'Reactivate'
                                                }}</a>
                                            }
                                            <button #submit [hidden]="true"></button>
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div class="row">
                                    <pcg-control-group class="col-md-4" label="Medical Director">
                                        <pcg-select #select [sendFormData]='false' pcgControlRef [multiple]='false'
                                            dataSource='api/Select/MedicalDirectors' formControlName='medicalDirectorId'
                                            placeholder="Select ..." (change)="medDirectorChange(select)"
                                            [notFoundTemplate]='missingMedicalDirector'></pcg-select>
                                    </pcg-control-group>

                                    <ng-template #missingMedicalDirector>
                                        <button id='pcg-button-filter' class="btn text-white bg-success rounded-0 w-100"
                                            (click)="addMedicalDirector(select?.select?.searchTerm)">Click to add
                                            Medical Director</button>
                                    </ng-template>

                                    <div class="col-md-2">
                                        <label class="for">License</label>
                                        {{ model?.medicalDirectorLicense }}
                                    </div>

                                    <div class="col-md-2">
                                        <label class="for">Exp Date</label>
                                        {{ model?.medicalDirectorExpDate }}
                                    </div>
                                </div>

                                <div class="row">
                                    <pcg-control-group class="col-md-8" label="Address" labelTooltip="When searching apartment complexes be sure to use Apt after the street number 
                                                and name to see the list of apartment numbers. For example, 2000 N Meridian Rd Apt will bring 
                                                up a list of the apartments for that complex.">
                                        <pcg-smarty pcgControlRef formControlName="smartyAddress"
                                            [showAllStates]="false" [canManualEntry]="canManualEntry"
                                            [getFullZip]="true" (change)='updateShippingInfo()'
                                            [countyApi]="api"></pcg-smarty>
                                    </pcg-control-group>

                                    <div class="col-md-2 d-flex flex-column">
                                        <label class="for">County</label>
                                        <span>{{ this.formGroup?.controls?.countyName?.value }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <pcg-control-group class="col-md-4" label="Shipping Name">
                                        <input [hidden]="shipAddressDisabled" pcgControlRef
                                            formControlName="shipOrganizationName" class="form-control" type="text" />
                                        <span [hidden]="!shipAddressDisabled">{{
                                            this.formGroup?.controls?.shipOrganizationName?.value }}</span>
                                    </pcg-control-group>

                                    <pcg-control-group class="col-md-6" label="Shipping Address">
                                        <pcg-smarty pcgControlRef formControlName="shipSmartyAddress"
                                            [showAllStates]="false" [hidden]="shipAddressDisabled"
                                            [canManualEntry]="canManualEntry" [countyApi]="api"></pcg-smarty>
                                        <span [hidden]="!shipAddressDisabled">{{
                                            this.formGroup?.controls?.shipSmartyAddress?.value?.formattedAddress
                                            }}</span>
                                    </pcg-control-group>

                                    <pcg-control-group class="col-auto form-check-label" label="Is Same?"
                                        labelTooltip="Verification that facility address is same as shipping address.">
                                        <ui-switch pcgControlRef #isSameAddress color='#007bff'
                                            (change)="updateShippingInfo()" defaultBgColor='#EEE' class='ml-2 mt-2'
                                            size='small' formControlName='isSameAddress'></ui-switch>
                                    </pcg-control-group>

                                    <pcg-control-group class="col-auto form-check-label" label="Is Validated?">
                                        <ui-switch pcgControlRef #isShipAddressValidated color='#007bff'
                                            defaultBgColor='#EEE' class='ml-2 mt-2' size='small'
                                            formControlName='isShipAddressValidated'></ui-switch>
                                    </pcg-control-group>
                                </div>
                            </div>

                            <div class="container section-ctnr" [class.d-none]="!model.isSingleFacility">
                                <div class="section-head">
                                    <h1>
                                        <fa-icon [icon]="faIconName.faBuilding" class="mr-3"></fa-icon>
                                        Single Facility
                                    </h1>
                                </div>
                                <hr />

                                <div class="row">
                                    <pcg-control-group class="col-md-2" label="Identifier"
                                        labelTooltip='The facility identifier is the commercial facility store number OR the public facility unique identifier.'>
                                        <input pcgControlRef formControlName="facilityIdentifier" class="form-control"
                                            type="text" />
                                    </pcg-control-group>

                                    <pcg-control-group class="col-md-2" label="DAU Code"
                                        labelTooltip='This field is used to match this Facility in HOMER with the record for this Facility in HMS. 
                                                The DAU is a 6-character code that is used for DOH facilities throughout the State.'>
                                        <input pcgControlRef formControlName="dauCode" class="form-control" type="text"
                                            maxlength="6" />
                                    </pcg-control-group>
                                </div>
                            </div>

                            <div class='bottom-buttons'>
                                @if (canEdit) {
                                <button class='btn btn-save btn-lg' (click)="validateCountyId()">{{ id === 0 ? "Create"
                                    : "Save" }}</button>
                                }
                            </div>
                        </form>
                    </div>
                </ng-template>
            </mat-tab>

            @if (
            id !== 0
            && (model$ | async)
            ) {
            <!-- Login Types -->
            <mat-tab label="Login Types">
                <ng-template matTabContent>
                    <div class="mat-tab-container mat-table-container">
                        <pcg-shared-organization-logins [vm]="viewModel"></pcg-shared-organization-logins>
                    </div>
                </ng-template>
            </mat-tab>

            <!-- Facilities -->
            <mat-tab label="Facilities">
                <ng-template matTabContent>
                    <div class="mat-tab-container mat-table-container">
                        <pcg-shared-organization-facility-list [businessArea]="businessArea"
                            [id]="id"></pcg-shared-organization-facility-list>
                    </div>
                </ng-template>
            </mat-tab>

            <!-- Organization Accounts -->
            <mat-tab label="Organization Accounts">
                <ng-template matTabContent>
                    <div class="mat-tab-container mat-table-container">
                        <pcg-shared-organization-accounts [id]='id'
                            [businessArea]="businessArea"></pcg-shared-organization-accounts>
                    </div>
                </ng-template>
            </mat-tab>

            <!-- Associated Users -->
            <mat-tab label="Associated Users">
                <ng-template matTabContent>
                    <div class="mat-tab-container mat-table-container">
                        <pcg-associated-users [orgId]="id" [facilityIds]="facilityIds"
                            [businessArea]="businessArea"></pcg-associated-users>
                    </div>
                </ng-template>
            </mat-tab>
            }

            <!-- Audit History -->
            @if (
            id !== 0
            && canViewReports
            ) {
            <mat-tab label="Audit History">
                <ng-template matTabContent>
                    <div class="mat-tab-container mat-table-container">
                        <pcg-shared-organization-audit-history [id]='id'></pcg-shared-organization-audit-history>
                    </div>
                </ng-template>
            </mat-tab>
            }
        </div>
        }
    </mat-tab-group>
</ng-container>
}

<ng-template #addNewMD let-modal>

    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Add Medical Director</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <!-- <div class="my-3 pb-2 bold text-center border-bottom">Add Medical Director</div> -->

        <form (submit)='saveNewMedicalDirector()' [formGroup]='mdFormGroup'>
            <input type='hidden' formControlName='medicalDirectorId' />

            <div class="row justify-content-center">
                <div class="col-md-3 text-right "><label class="required-field">Name</label></div>
                <pcg-control-group class="col-md-6">
                    <input pcgControlRef formControlName="fullName" class='form-control' type='text' />
                </pcg-control-group>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-3 text-right"><label class="required-field">License #</label></div>
                <pcg-control-group class="col-md-6">
                    <input pcgControlRef formControlName="licenseNumber" class='form-control' type='text' />
                </pcg-control-group>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-3 text-right"><label class="required-field">Exp Date</label></div>
                <pcg-control-group class="col-md-6">
                    <input type="date" pcgControlRef formControlName="expirationDate" placeholder="MM/dd/yyyy"
                        class="form-control" maxlength="10"
                        [ngModel]="mdFormGroup.controls.expirationDate.value | date:'yyyy-MM-dd'" />
                </pcg-control-group>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-3 text-right text-nowrap"><label>License File</label></div>
                <pcg-control-group class="col-md-6 text-left mb-0">
                    <pcg-drag-drop-file-upload #dragDrop [parentId]="mdFormGroup.controls.medicalDirectorId.value"
                        [fileList]="medicalDirectorFiles" [(formGroup)]="mdFormGroup"
                        [formGroupControlName]="'uploadFiles'" [maxFiles]="1" [minHeightPixels]="200"
                        [minWidthPixels]="200" [deleteFileUrl]="'api/Administration/MedicalDirector/DeleteFile'"
                        [allowedFilesMessage]="'1 file upload permitted (pdf)'" [allowedFiles]="'pdf.'"
                        [navRoute]="'admin/organizations/medical-directors'"
                        [useFullNavRoute]="true"></pcg-drag-drop-file-upload>
                </pcg-control-group>
            </div>
            <br/>

            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Cancel</button>
                <input type="submit" class="btn btn-save btn-lg" value="Save" />
            </div>
        </form>
    </div>
</ng-template>