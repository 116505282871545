<span class="pcgFaNumIcon">
    <fa-icon 
        [icon]="iconName" 
        [style.color]="iconColorName" 
        class="mr-2" 
        size="lg"
    ></fa-icon>
    <pcg-fa-count 
        [num]='num' 
        [numColor]='numColor' 
        [isNumVisible]='isNumVisible' 
        [numPosition]='numPosition'
    ></pcg-fa-count>
</span>
