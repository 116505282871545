import { NavRoute } from '../shared/navigation/nav.route.interface';
import { getAdminNav } from './nav-areas/admin-nav';
import { getBANav } from './nav-areas/ba-nav';
import { getCiRepackNav } from './nav-areas/ci-repack-nav';
import { getErrorReportNav } from './nav-areas/error-report-nav';
import { getHelpDeskNav } from './nav-areas/help-desk-nav';
import { getHEROSNav } from './nav-areas/heros-nav';
import { getIcqNav } from './nav-areas/icq-nav';
import { getInventoryNav } from './nav-areas/inventory-nav';
import { getMetabolicFormulaNav } from './nav-areas/metabolic-formula-nav';
import { getNdcDirectoryNav } from './nav-areas/ndc-directory-nav';
import { getPcgNav } from './nav-areas/pcg-nav';
import { getPharmacyNav } from './nav-areas/pharmacy-nav';
import { getReportingNav } from './nav-areas/reporting-nav';
import { getRxConnectsNav } from './nav-areas/rxconnects-nav';

/**
 * Retrieves the main navigation routes for the application.
 * These routes are used to build the main navigation menu in the application.
 * Addition configuration may be needed for edge cases or special routes such as in inventory, pharmacy, or other custom areas.
 * These instances may require additional logic to be added in the parseUrl function in the navigation service, ctrl+shift+f 'getPharmacyInventoryNav' for an example. 
 * @returns An array of `NavRoute` objects representing the main navigation routes.
 */
export const getMainNavRoutes = (): NavRoute[] => [
	getHEROSNav()
	, getPharmacyNav()
	, getCiRepackNav()
	, getNdcDirectoryNav()
	, getMetabolicFormulaNav()
	, getIcqNav()
	, getHelpDeskNav()
	, getReportingNav()
	, getErrorReportNav()
	, getInventoryNav()
	, getBANav()
	, getRxConnectsNav()
	, getAdminNav()
	, getPcgNav()
];
