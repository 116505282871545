import { Component, Input} from '@angular/core';
import { faPalette } from '@fortawesome/pro-solid-svg-icons';
import { TableTopLegendVm } from './table-top-legendVm';

@Component({
    selector: 'pcg-table-top-legend',
    templateUrl: './table-top-legend.component.html',
    styleUrls: ['./table-top-legend.component.scss'],
    standalone: false
})
export class TableTopLegendComponent {
	@Input() legendVms: TableTopLegendVm[];

	faIconName = { faPalette };

}