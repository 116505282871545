import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

//used to mask part number input fields for CI repack
@Directive({
    selector: '[pcgComponentNumber]',
    standalone: false
})
export class ComponentNumberDirective {

	constructor(private elRef: ElementRef, @Optional() private control: NgControl) {
	}

	@HostListener('keyup', ['$event'])
	keyEvent() { if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); } }

	@HostListener('change', ['$event'])
	changeEvent() { if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); } }

	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		if (!((/^[a-zA-Z0-9()-]$/.test(e.key)) || this.isAllowedCharacter(e))) {
			return false;
		}
	}
	
	isAllowedCharacter(e: any): boolean {
		return (e.keyCode > 36 && e.keyCode < 41)
			|| e.keyCode === 8
			|| e.keyCode === 9
			|| e.keyCode === 46
			|| e.keyCode === 110
			// Allows ability to select all with Ctrl/Cmd + A
			|| ((e.ctrlKey || e.metaKey) && e.key === 'a')
			// Allows ability to copy
			|| ((e.ctrlKey || e.metaKey) && e.key === 'c')
			// Allows ability to paste
			|| ((e.ctrlKey || e.metaKey) && e.key === 'v')
			// Allows ability to press enter
			|| (e.key === 'Enter');
	}
}
