import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'pcg-tooltip-label',
    templateUrl: './tooltip-label.component.html',
    styleUrls: ['./tooltip-label.component.scss'],
    standalone: false
})
export class TooltipLabelComponent implements OnInit {

    @Input() tooltip: string;
    @Input() labelText: string;
    @Input() tooltipPlacement: string;
    @Input() labelFontSize: string;

    faIconName = { faInfoCircle };

    constructor() { }

    ngOnInit() { }
}
