<div class="container pt-4">
	<div class="row ">
		<div class="col-md-2 text-center pl-0">
			<img src="\assets\images\logos\dohLogo.jpg" class="img-logo">
		</div>
		<div class="col-md-8">
			<h2 class="d-flex flex-column justify-content-center align-items-center header">PRESCRIPTION DRUG DONATION PROGRAM</h2>
			<h4 class="d-flex flex-column justify-content-center align-items-center header">PATIENT APPLICATION</h4>
		</div>
	</div>
</div>
<div class="container">
	<ul>
		<li>Questions about completion of this form may be directed to the Bureau of Public Health Pharmacy at (850)841-8530.</li>
	</ul>
	<ul>
		<li>
			<b>Patient: Please complete this form.</b>
		</li>
	</ul>
</div>

<div class="container border border-dark">
	<div class="row">
		<div class="col edit-body">PATIENT INFORMATION</div>
	</div>

	<form 
		[formGroup]='formGroup' 
		(submit)='savePatient()'
	>
		<input 
			type='hidden' 
			formControlName='applicationStatus' 
		/>
		<div class="container clearfix">
			<div class='row'>
				<pcg-control-group 
					class="col-sm-4" 
					label="First Name"
				>
					<input 
						type="text" 
						pcgControlRef 
						class="form-control" 
						formControlName="firstName" 
					/>
				</pcg-control-group>

				<pcg-control-group 
					class="col-sm-4" 
					label="Last Name"
				>
					<input 
						type="text" 
						pcgControlRef 
						class="form-control" 
						formControlName="lastName" 
					/>
				</pcg-control-group>

				<pcg-control-group 
					class="col-sm-4" 
					label="Email Address"
				>
					<input 
						type="text" 
						pcgControlRef 
						class="form-control" 
						formControlName="emailAddress" 
					/>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group 
					class="col-sm-4" 
					label="Address"
				>
					<pcg-smarty 
						pcgControlRef 
						formControlName="smartyAddress" 
						[showAllStates]="false"
						[canManualEntry]="true" 
						[getFullZip]="true" 
						(change)='updateCounty()'
					/>
				</pcg-control-group>

				<pcg-control-group 
					class="col-sm-4" 
					label="County"
				>
					<pcg-select 
						pcgControlRef 
						[multiple]='false' 
						dataSource='api/PublicSelect/Counties'
						formControlName="countyId" 
						placeholder='Select a County' 
						[sendFormData]='false' 
					/>
				</pcg-control-group>
				<pcg-control-group 
					class="col-sm-4" 
					label="Telephone Number (home or work)" 
					[validations]="phoneValidation"
				>
					<input 
						pcgPhoneExt 
						type="text" 
						pcgControlRef 
						class="form-control" 
						formControlName="homePhone" 
					/>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group 
					class="col-sm-8" 
					label="Preferred Pickup Location"
				>
					<pcg-select 
						pcgControlRef 
						[multiple]='false' 
						dataSource='api/PublicSelect/RxConnectsRepositories'
						formControlName="repositoryId" 
						placeholder='Select a Preferred pickup location'
						[sendFormData]='false'
					></pcg-select>
				</pcg-control-group>

				<pcg-control-group 
					class="col-sm-4" 
					label="Telephone Number (cell)" 
					[validations]="phoneValidation"
				>
					<input 
						pcgPhoneExt 
						type="text" 
						pcgControlRef 
						class="form-control" 
						formControlName="cellPhone" 
					/>
				</pcg-control-group>
			</div>

			<div class="row">
				<pcg-control-group 
					class='col-md-12' 
					label='Please indicate if you are: (check boxes that apply)'
				>
					<div class="form-check">
						<pcg-radio-button-list 
							pcgControlRef 
							formControlName='eligibilityStatusId' 
							id="eligibilityStatusId"
							[items]='[
								{ text: EligibilityStatusEnum.toDisplay(EligibilityStatusEnum.Indigent), value: EligibilityStatusEnum.Indigent },
								{ text: EligibilityStatusEnum.toDisplay(EligibilityStatusEnum.UnderinsuredBenefitsExhausted), value: EligibilityStatusEnum.UnderinsuredBenefitsExhausted },
								{ text: EligibilityStatusEnum.toDisplay(EligibilityStatusEnum.UninsuredNoCoverage), value: EligibilityStatusEnum.UninsuredNoCoverage }
							]'
						></pcg-radio-button-list>
					</div>
				</pcg-control-group>
			</div>

			<div class="row">
				<pcg-control-group 
					class="col-md-12" 
					label='Attestation'
				>
					<div class="form-check">
						<input 
							class="form-check-input" 
							pcgControlRef 
							id="isAttested" 
							formControlName='isAttested' 
							type="checkbox" 
							required 
						/>
						<label 
							class='form-check-label' 
							style="font-weight:bold;" 
							for='isAttested'
						>
							By signing below, I affirm that I meet the eligibility requirements of this section and will inform the repository if my eligibility changes. 
							I also acknowledge the following: The prescription drug or supply I am receiving was donated to the program. Donors and participants in the program are immune from civil or criminal liability or disciplinary action. Eligible patients are not required to pay for the prescription drug or supply.
						</label>
					</div>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group 
					class="col-md-6" 
					label=" Patient Electronic Signature"
				>
					<input 
						type="text" 
						pcgControlRef 
						class="form-control" 
						formControlName="signature" 
					/>
				</pcg-control-group>

				<pcg-control-group 
					class="col-md-6" 
					label="Date Signed"
				>
					<input 
						type="text" 
						pcgControlRef 
						class="form-control" 
						readonly="readonly"
						formControlName="dateSigned" 
					/>
				</pcg-control-group>
			</div>

			<div 
				class="row alert alert-success" 
				role="alert" 
				*ngIf='this.disableSave'
			>
				<b>Thank you for submitting your application to the Rx Connects Program.</b>
			</div>
		</div>

		<pcg-fixed-buttons *ngIf='!this.disableSave'>
			<a 
				class='btn btn-cancel btn-lg' 
				routerLink=''
			>Cancel</a>
			<button 
				type='submit' 
				class="btn btn-save btn-lg" 
				[disabled]="emptyCaptcha"
			>Submit</button>
		</pcg-fixed-buttons>

		<pcg-control-group 
			class='form-inline' 
			hidden
		>
			<input 
				id="control-pot" 
				type="checkbox" 
				pcgControlRef 
				formControlName='honeyPot' 
			/>
		</pcg-control-group>

		<ngx-invisible-recaptcha 
			#captchaElem 
			[siteKey]="siteKey" 
			(reset)="handleReset()" 
			(ready)="handleReady()"
			(load)="handleLoad()" 
			(success)="handleSuccess($event)" 
			[useGlobalDomain]="false" 
			theme="light"
			type="normal" 
			badge="bottomright" 
			formControlName="recaptcha" 
			*ngIf='!this.disableSave'
		>
		</ngx-invisible-recaptcha>
	</form>
</div>
<meta-pixel />
