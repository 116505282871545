import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TableModule } from "app/shared/table/table.module";

@NgModule({
    declarations: []
    , imports: [
        CommonModule
        , TableModule
    ]
    , exports: []
})
export class EditOrganizationsModule {}