<pcg-prevent-browser-autofill/>

<div id="actionElements" style='width: 100%;'>
	<div class='input-group'>
		<input 
			id='scanRx' 
			#rxNumber 
			pcgNumeric inputmode="numeric"
			type='text' 
			[placeholder]="inputPlaceholder" 
			(keydown)="keyDown($event)" 
			class="form-control" 
			maxLength="100" 
			[attr.ngbAutoFocus]="this.autofocus" 
			autocomplete='off' 
		/>
		<div class="input-group-append">
			<button (click)="scanBarcode()" type="button" class="btn btn-save">Scan</button>
		</div>
	</div>
</div>