import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { CiShortageOverageVm } from 'app/shared/generated/CIRepack/Models/CiRepackOrder/CiShortageOverageVm';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';

@Component({
    selector: 'pcg-ci-repack-order-short-over-printable',
    templateUrl: './ci-repack-order-short-over-printable.component.html',
    styleUrls: ['./ci-repack-order-short-over-printable.component.scss'],
    standalone: false
})
export class CiRepackOrderShortOverPrintableComponent implements OnInit {
    
    subscriptions = new Subscription();
    orderNumber: string;
    shortOverList: CiShortageOverageVm[] = [];
    dataIsLoaded: boolean = false;
    pageIsLoaded: boolean = false;
    code: string;
    emptyShortOverList: boolean = false;

    constructor(public title: Title, private route: ActivatedRoute, private httpClient: HttpClient
        , public btnFilterService: ButtonFilterService) { 
            title.setTitle("CI Order Shortage / Overage")
            document.querySelector('pcg-header')?.classList?.add('d-none');
            document.getElementById('footer')?.classList?.add('d-none');
    }

    ngOnInit() {
        if (this.route.snapshot.queryParamMap.get('code')) { this.code = this.route.snapshot.queryParamMap.get('code'); }
        this.getShortOverList();
    }

    ngAfterViewInit() {
		// After page load, call this function to verify all elements are loaded and print the page
		this.pageIsLoaded = true;
        setTimeout(() => {
            this.printPage();
        }, 300);
	}

    getShortOverList(){
        this.subscriptions.add(this.httpClient.get(`api/PublicCiShortOver/GetCiShortageOverageListPrintable?code=${this.code}`)
			.subscribe((list: CiShortageOverageVm[]) => {
                if(list.length > 0)
                {
                    this.shortOverList = list
                    this.orderNumber = this.shortOverList[0].ciOrderNumber;
                    this.dataIsLoaded = true;
                } else { this.emptyShortOverList = true; }
            }));
    }

    printPage() {
		// If all page elements are loaded and data has been received from the server, print the page
		if (this.dataIsLoaded) {
            window.print();
			window.close();
		}
	}

}
