import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControlName, UntypedFormGroup } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'pcg-show-hide-password',
    templateUrl: './show-hide-password.component.html',
    styleUrls: ['./show-hide-password.component.scss'],
    standalone: false
})
export class ShowHidePasswordComponent implements OnInit{

    @Input() fcName: FormControlName;
    @Input() parentForm: UntypedFormGroup;
    @Input() validations: { [index: string]: string } = {};
    @Input() label: string;
    @Input() inputCssClass: string;
    @Input() password: string;
    @Output() enterKeyPressed : EventEmitter<void> = new EventEmitter<void>();

    isNotSmallScreen : boolean;
    showPassword = false;
    faIconName = { faEye, faEyeSlash };
    isFormControl: boolean

    constructor() { }
    
    @HostListener('document:keyup.enter', ['$event'])
    handleKeyboardEvent(): void {
      this.enterKeyPressed.emit();
    }

    ngOnInit() { 
      this.isNotSmallScreen = this.notSmallScreen(); 
      this.isFormControl = this.fcName != null && this.parentForm != null;
    }
    toggleShowHidePassword() { this.showPassword = !this.showPassword; }
    notSmallScreen() : boolean { return !this.inputCssClass.includes('mx-sm-1');  }
}
