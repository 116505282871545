import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../nav.route.interface';
import { NavDividerCharacter, NavigationService } from '../navigation.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';

/**
 * This component is intended to be used in the top header bar
 * to create a nested navigation. Dropdowns are opened by
 * click.
 */
@Component({
    selector: 'pcg-top-click-nav',
    templateUrl: './top-click-nav.component.html',
    styleUrls: ['./top-click-nav.component.scss'],
    standalone: false
})
export class TopClickNavComponent implements OnInit, OnChanges {

	@Input() navRoutes: NavRoute[] = [];
	@Input() menuItemId = 'root';
	@Input() isRoot = true;

	// Current user information
	user: UserJsVm;
	// Whether or not the curren nav menu is open
	isOpen: boolean;
	// Currently OPEN nav menu
	currNavMenuItem: string;
	// Currently SELECTED nav menu
	currSelectedNavMenuItem: string;
	// The currently OPEN secondary navigation menu
	currOpenSecondaryNavMenu: string;
	// Force select item in the nav
	currForceSelect: string;
	// Classes in the current ul of the nav menu
	ulClasses: string[];
	// Nav routes used in loop
	loopNavRoutes: NavRoute[] = [];
	// For user nav
	userNavOpen = false;
	isMobile = false;

	constructor(
		public navService: NavigationService
		, public elRef: ElementRef
		, public sec: SecurityService
		, private router: Router
		, private httpClient: HttpClient
		, private ms: SystemMessageService
	) { }

	ngOnInit() {
		if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
			this.isMobile = true;
		}
		// Get the current user information
		this.sec.user$.subscribe(user => { this.user = user; });
		// Triggred on update of currently OPEN menu item
		this.navService.currOpenPrimaryNavMenu$.subscribe(c => {
			this.currNavMenuItem = c;
			this.isOpen = this.isSubNav(c, this.menuItemId) || c === this.menuItemId;
			this.userNavOpen = c === ['root', 'user'].join(NavDividerCharacter);
			// The first ul should have classes indicating it is the root.
			// Nested ul elements, those at higher depths, are dropdown menus
			// and need classes indicating that.
			this.ulClasses = this.isRoot 
				? ['nav', 'navbar-nav', 'top-nav-bg', this.isOpen ? 'nav-open' : ''] 
				: ['dropdown-menu', 'top-nav-bg', this.isOpen ? 'nav-open' : ''];
		});
		// Triggered on update of currently SELECTED menu item
		this.navService.currSelectedMainNavMenu$.subscribe(c => { this.currSelectedNavMenuItem = c ? c?.id : 'root'; });
		this.navService.currOpenSecondaryNavMenu$.subscribe(c => { this.currOpenSecondaryNavMenu = c ? c?.id : 'root'; });
		this.navService.currForceSelect$.subscribe(c => {
			this.currForceSelect = c;
			this.loopNavRoutes = this.getNavRoutes();
		});
	}

	isSubNav(currNavMenuItem, menuItemId) {
		// Get divider counts
		const divCountCurrNav = currNavMenuItem.split(NavDividerCharacter).length;
		const divCountMenuItem = menuItemId.split(NavDividerCharacter).length;

		// Sub navs and current nav should be open
		return divCountMenuItem < divCountCurrNav && currNavMenuItem.startsWith(this.menuItemId);
	}

	ngOnChanges(changes: SimpleChanges) {
		const chng = changes['navRoutes'];
		const cur = chng.currentValue;
		const prev = chng.previousValue;
		if (cur !== prev) { this.loopNavRoutes = this.getNavRoutes(); }
	}

	getHtmlId(routeId: string) { return routeId.replace(/^[^a-z0-9]+|[^\w:.-]+/gi, ''); }

	// Show or hide submenu based on dropdown click
	clickDropdown(childMenuId: string) {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		// If the submenu is open, close it
		if (this.currNavMenuItem.startsWith(childMenuId)) { this.navService.setCurrOpenNavMenu(this.menuItemId); } 
		else { this.navService.setCurrOpenNavMenu(childMenuId); } // Otherwise, open the submenu		
		return false;
	}

	openDropdown(childMenuId: string) {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		this.navService.setCurrOpenNavMenu(childMenuId); 
		return false;
	}

	closeDropdown(childMenuId: string) {
		if (childMenuId === 'user') { childMenuId = ['root', 'user'].join(NavDividerCharacter); }
		this.navService.setCurrOpenNavMenu(this.menuItemId); 		
		return false;
	}

	clickLink(route: NavRoute) { 
		this.navService.setCurrOpenNavMenu('');
		if (route != null) {
			if (route.openNewTab) { window.open(route.path); }
			else { this.router.navigate([route.path], {queryParams: route.queryParams}); }
		}
	}

	// Logout removes all of our security variables
	// and redirects user to root
	logout() {
		this.sec.setSecurity(null, null);
		this.deleteAllCookies();
		this.router.navigate(['/']);
		sessionStorage.removeItem('notificationCount');
		sessionStorage.removeItem('notificationModalOpened');
		sessionStorage.removeItem('step1Count');
		sessionStorage.removeItem('step2Count');
		sessionStorage.removeItem('step3Count');
		localStorage.removeItem('ciWorkstationModalOpened');
		localStorage.removeItem('requestedProduct');
		var loggedInUsingSso = localStorage.getItem("loggedInUsingSso") == "true";
		localStorage.setItem("loggedInUsingSso", "false");
		if (loggedInUsingSso) {
            this.httpClient.get(`api/Account/SingleSignOut?userId=${this.user.id}&entraIdentifierUrl=${localStorage.getItem("entraIdentifierUrl")}&entraLoginUrl=${localStorage.getItem("entraLoginUrl")}`).subscribe((sm: SystemMessage) => {
                localStorage.setItem("entraIdentifierUrl", "");
                localStorage.setItem("entraLoginUrl", "");
                if (sm.isSuccessful) { window.location.href = sm.value; }
                else { this.ms.setSystemMessage(sm.message, sm.messageClass); }
            });
        }
	}

	// This is used above to delete all cookies on logout
	deleteAllCookies() {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}

	getNavRoutes() {
		const routeIndex = this.navService.getCurrRouteIndex(this.navRoutes);
		return (
			this.navRoutes
				?.map((o, i) => ({ ...o, index: i }))
				.filter(route => 
					(route?.children == null || route?.children?.length > 0) 
					&& 
					(!route?.shouldDisplay || route?.shouldDisplay(routeIndex))
				) ?? []
		);
	}
}
