import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UiSwitchComponent } from 'ngx-ui-switch';
import { Observable } from 'rxjs';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { FacilityCardinalAccountVm } from 'app/shared/generated/Administration/Models/Facilities/FacilityCardinalAccountVm';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-shared-facility-cardinal-accounts',
    templateUrl: './facility-cardinal-accounts.component.html',
    styleUrls: ['./facility-cardinal-accounts.component.scss'],
    standalone: false
})
export class FacilityCardinalAccountsComponent implements OnInit {

    @ViewChild(TableComponent, { static: true }) table: TableComponent<FacilityCardinalAccountVm>;
    @ViewChild('isCiModule810', { static: false }) isCiModule810: UiSwitchComponent; 
    @ViewChild('isBulk810', { static: false }) isBulk810: UiSwitchComponent; 
    @ViewChild('isIssuance810', { static: false }) isIssuance810: UiSwitchComponent; 

    @Input() businessArea: BusinessAreaEnum;

    columns = FacilityCardinalAccountVm.ColumnDefs;
    formGroup = FacilityCardinalAccountVm.Form;
    model = FacilityCardinalAccountVm.GetModel(this.formGroup);
    model$: Observable<FacilityCardinalAccountVm>;

    isAdd = false;
    canEdit = false;
    tab = 'Cardinal Accounts';
    facilityId: number;
    filters = { siteId: null };

    constructor(
        public title: Title
        , private sec: SecurityService
        , private route: ActivatedRoute
        , private httpClient: HttpClient
    ) {
        title.setTitle('Edit Facility');
        this.canEdit = this.sec.hasModuleAccess(
            [BusinessAreaEnum.CI, BusinessAreaEnum.MetabolicFormula]
            , [PermissionRole.UserAdmin, ...SecurityService.setMinRole(PermissionRole.Manager)]
        );
     }

    ngOnInit() {
        this.facilityId = this.route.snapshot.params['id'];
        this.filters.siteId = this.facilityId;
        if (this.route.snapshot.queryParamMap.get('tab')) { this.tab = this.route.snapshot.queryParamMap.get('tab'); }
    }

    editAccount(cardinalAccountId: number) {
        this.isAdd = true;
        
        if (cardinalAccountId !== 0) {
            this.httpClient.get(`api/Administration/Facility/GetCardinalAccount?id=${cardinalAccountId}&facilityId=${this.facilityId}`)
                .subscribe((model: FacilityCardinalAccountVm) => {
                    this.formGroup.setValue(model);
                }
            );
        }        
        this.formGroup.get('facilityId').patchValue(this.facilityId);        
    }

    cancel() {
        this.resetFormGroup();
        this.isAdd = false;
    }

    saveSuccess(msg: SystemMessage) {
        this.isAdd = false;
        this.resetFormGroup();
        this.table?.ajaxReload();
    }

    resetFormGroup() {
        this.formGroup.get('cardinalAccountId').patchValue(0);
        this.formGroup.get('accountName').patchValue("");
        this.formGroup.get('accountNumber').patchValue("");
        this.formGroup.get('isCiModule810').patchValue(false);
        this.formGroup.get('isBulk810').patchValue(false);
        this.formGroup.get('isIssuance810').patchValue(false);
    }
}
