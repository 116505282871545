import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { WebAuthnService } from 'app/core/security/multi-factor-auth/web-authn.service';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { ShowHidePasswordComponent } from 'app/shared/form-elements/components/show-hide-password/show-hide-password.component';
import { FidoService } from 'app/shared/services/fido.service';

@Component({
    selector: 'pcg-register-auth-device-modal',
    templateUrl: './register-auth-device-modal.component.html',
    styleUrls: ['./register-auth-device-modal.component.scss'],
    standalone: false
})
export class RegisterAuthDeviceModalComponent implements OnInit {

	@ViewChild('VerifyPassword', { static: false }) VerifyPassword: ShowHidePasswordComponent;

	id: number;
	password = '';
	displayName = '';
	displayNameVal = '';
	isFingerprint: boolean = false;	

	constructor(
		public activeModal: NgbActiveModal
		, private webAuthn: WebAuthnService
		, private ms: SystemMessageService
	) { }

	ngOnInit() { }

	validateForm() { return this.VerifyPassword?.password !== '' && this.displayNameVal !== ''; }

	registerCredential() {
		if (this.validateForm()) {
			this.displayName = this.displayNameVal;
			const pwd = this.VerifyPassword?.password;

			// Make sure their browser is FIDO compatible
			if (!this.webAuthn.isFidoCompatible()) { this.activeModal.dismiss(); }

			// Get the options from the server to use to make a new credential
			this.webAuthn.makeCredentials(this.id, pwd)
				.subscribe(async publicKeyCredentialCreationOptions => {
					if (publicKeyCredentialCreationOptions.status === 'error') {
						this.ms.setSystemMessage(publicKeyCredentialCreationOptions.errorMessage, 'error');
						this.activeModal.dismiss();
						return;
					}

					try {
						publicKeyCredentialCreationOptions = FidoService.fixPublicKeyCredentialCreationOptions(publicKeyCredentialCreationOptions);
						const credential = await navigator.credentials.create({ publicKey: publicKeyCredentialCreationOptions });
						// Send the credentials to the server to be saved in the database
						this.webAuthn.saveCredentials(
							credential
							, this.displayName
							, this.id
							, pwd
							, this.isFingerprint
						).subscribe((o: any) => {
							if (o.status === 'ok') { this.ms.setSystemMessage('The Security Device has been added successfully.'); } 
							else { this.ms.setSystemMessage('Error: The Security Device could not be added.', 'error'); }
							this.activeModal.dismiss();
						});
					} catch (e) {
						console.log("error");
						console.log(e);
						this.ms.setSystemMessage('Exception: The Security Device could not be added.', 'error');
						this.activeModal.dismiss();
					}
				}
			);
		}
	}
}
