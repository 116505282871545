<div *ngIf="!isAdd">
    <div id="informativeContent"> 
        <div id="actionElements">
            <!-- On button click, route user to page with 
                '0' ID, suggesting it is a new inventory. -->
            <button *ngIf="canEdit" (click)="editAccount(0)" class="btn btn-save btn-sm inline-add-btn">Create Cardinal Account</button>
        </div>
    </div>

    <pcg-table #table [ajaxData]='filters' [multipleSearch]='false' [pcgSort]='[[ "accountName", "asc" ]]' 
        dataSource='api/Administration/Facility/GetCardinalAccounts' [columnDefs]='columns'>

        <ng-container matColumnDef="accountName">
            <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('accountName').displayName }}</th>
            <td mat-cell *matCellDef='let row'>
                <pcg-edit-link (click)="editAccount(row.cardinalAccountId)" class="editLink">{{ row.accountName }}</pcg-edit-link>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="accountNumber">
            <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('accountNumber').displayName }}</th>
            <td mat-cell *matCellDef='let row'> {{ row.accountNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="isCiModule810String">
            <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('isCiModule810String').displayName }}</th>
            <td mat-cell *matCellDef='let row'> {{ row.isCiModule810String }}</td>
        </ng-container>

        <ng-container matColumnDef="isIssuance810String">
            <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('isIssuance810String').displayName }}</th>
            <td mat-cell *matCellDef='let row'> {{ row.isIssuance810String }}</td>
        </ng-container>

        <ng-container matColumnDef="isBulk810String">
            <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('isBulk810String').displayName }}</th>
            <td mat-cell *matCellDef='let row'> {{ row.isBulk810String }}</td>
        </ng-container>
    
        <ng-container matColumnDef="canDelete">
            <th class='text-center' mat-header-cell *matHeaderCellDef>
                <span ngbTooltip='Only an Account with no associated data can be deleted.' container='body'>Delete</span>
            </th>
            <td class='text-center' mat-cell *matCellDef='let row'>
                <pcg-delete-button *ngIf='canEdit' [table]='table' [confirmMessage]="'Are you sure you want to delete this Account Number?'"
                    url='api/Administration/Facility/DeleteCardinalAccount' [id]='row.cardinalAccountId'></pcg-delete-button>
            </td>
        </ng-container>
    </pcg-table>
</div>

<div *ngIf="isAdd">
    <form [formGroup]="formGroup" class='p-4' 
        pcgSubmitUrl="api/Administration/Facility/SaveCardinalAccount" 
        (messageSuccess)="saveSuccess($event.value)">

        <input type='hidden' formControlName='cardinalAccountId' />
        <input type='hidden' formControlName='facilityId' />

        <div class="row">
            <pcg-control-group class="col-md-4" label="Account Name">
                <input pcgControlRef formControlName="accountName" class="form-control" type="text" />
            </pcg-control-group>
            
            <pcg-control-group class="col-md-4" label="Account Number">
                <input pcgControlRef formControlName="accountNumber" class="form-control" type="text" />
            </pcg-control-group>
        </div>

        <div class="row">
            <pcg-control-group class="col-md-4 form-check-label" label="Use in CI">
                <ui-switch pcgControlRef #isCiModule810 color='#007bff' defaultBgColor='#EEE' class='ml-2 mt-2' size='small' formControlName='isCiModule810'></ui-switch>
            </pcg-control-group>

            <pcg-control-group class="col-md-4 form-check-label" label="Use in Bulk">
                <ui-switch pcgControlRef #isBulk810 color='#007bff' defaultBgColor='#EEE' class='ml-2 mt-2' size='small' formControlName='isBulk810'></ui-switch>
            </pcg-control-group>

            <pcg-control-group class="col-md-4 form-check-label" label="Use in Issuance">
                <ui-switch pcgControlRef #isIssuance810 color='#007bff' defaultBgColor='#EEE' class='ml-2 mt-2' size='small' formControlName='isIssuance810'></ui-switch>
            </pcg-control-group>
        </div>

        <pcg-fixed-buttons>
            <a class='btn btn-cancel btn-lg' (click)="cancel()">Cancel</a>
            <button type="submit" class='btn btn-save btn-lg'>{{ model.cardinalAccountId === 0 ?
                "Create"
                : "Save" }}</button>
        </pcg-fixed-buttons>
    </form>
</div>