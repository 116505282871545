import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

/**
 * This component is intended to be used to create
 * collapsible sections on forms.
 */
@Component({
    selector: 'pcg-collapsible-divider',
    templateUrl: './collapsible-divider.component.html',
    styleUrls: ['./collapsible-divider.component.scss'],
    standalone: false
})
export class CollapsibleDividerComponent implements OnInit {

	@Input() visible: boolean; // Whether or not section collapsed by default
	@Input() header: string | TemplateRef<any>; // Text that shows in the section header
	headerIsString = false;

	faMinus = faMinus;
	faPlus = faPlus;
	
	ngOnInit() {
		if (this.visible !== false) {
			this.visible = true;
		}
		if (typeof this.header === 'string') {
			this.headerIsString = true;
		}
	}
}
