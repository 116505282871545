import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { faBell, faCircle, faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { InvoiceNotificationVm } from 'app/shared/generated/Inventory/Models/Invoices/InvoiceNotificationVm';
import { ReconciliationNotificationVm } from 'app/shared/generated/Inventory/Models/Reconciliation/ReconciliationNotificationVm';

@Component({
    selector: 'pcg-notification-nav',
    templateUrl: './notification-nav.component.html',
    styleUrls: ['./notification-nav.component.scss'],
    standalone: false
})
export class NotificationNavComponent implements OnInit {

	@Input() icqCount: number = 0;
	@Input() ciInvoiceCount: number = 0;

	faBell = faBell;
	faCircle = faCircle;
	faLink = faExternalLinkAlt;

	reconNotifications: ReconciliationNotificationVm[] = [];
	ciInvoiceNotifications: InvoiceNotificationVm[] = [];
	notificationsCount: number = 0;

  	constructor(
		public modal: NgbActiveModal
		, private httpClient: HttpClient
	) { }

  	ngOnInit() {
	  	this.getReconciliationNotifications();
		this.getCiInvoiceNotifications();
  	}

	getReconciliationNotifications() {
		this.httpClient.get(`api/Inventory/Reconciliation/GetReconciliationNotifications`)
			.subscribe((list: ReconciliationNotificationVm[]) => {
				this.reconNotifications = list;
				this.notificationsCount += list.length;
			}
		);
  	}	

	getHref(inventoryId, reconEventId) {
		if (reconEventId > 0) { return '/pharmacy/inventories/reconciliation/product-list/' + reconEventId + '/' + inventoryId; } 
		else { return '/pharmacy/inventories/reconciliation/list/' + inventoryId; }
	}

	getCiInvoiceNotifications() { this.notificationsCount += this.ciInvoiceCount; }
	getInvoiceHref() { return 'ba/cardinal-invoices?isCi=true'; }
	getIcqHref() { return '/icq/review-queue/list'; }
}
