import { Component, OnInit } from "@angular/core";

import { SystemMessage } from "app/core/system-message/system-message-service";
import { UserRoleInfoComponent } from "../user-role-info/user-role-info.component";

@Component({
    selector: "pcg-role-info",
    templateUrl: "../user-role-info/user-role-info.component.html",
    styleUrls: ["../../user-role-info-modal.component.scss"],
    standalone: false
})
export class RoleInfoComponent extends UserRoleInfoComponent implements OnInit {

	ngOnInit() { this.getModalData(); }

	getModalData() {
		this.loading.update(() => true);
		this.http.get(`api/Administration/PermissionsInfo/GetPermissionsForModal?module=${this.module}`)
			.subscribe((msg: SystemMessage) => {
				this.secAccess = this.roleInfoService
					.addSecData(JSON.parse(msg.model))
					.map((o) => this.roleInfoService.addSec(o));
				this.roles = JSON.parse(msg.value);
				this.loading.update(() => false);
			}
		);
	}
}
