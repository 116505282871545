import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { HomeDeliveryPublicVm } from 'app/shared/generated/Inventory/Models/HomeDeliveryPublicVm';
import { RxToFillVm } from 'app/shared/generated/Inventory/Models/RxToFillVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'app-home-delivery-submit-modal',
    templateUrl: './home-delivery-submit-modal.component.html',
    styleUrls: ['./home-delivery-submit-modal.component.scss'],
    standalone: false
})
export class HomeDeliverySubmitModalComponent implements OnInit {
	// Get table reference from the page
	@ViewChild(TableComponent, { static: true }) table: TableComponent<RxToFillVm>;
	
	@Input() modal: any;
	@Input() model: HomeDeliveryPublicVm;
	@Input() parentFormGroup: UntypedFormGroup;
	@Input() submitSuccessful: boolean;
	@Output() submitSuccessfulChange = new EventEmitter<boolean>();

	columns = RxToFillVm.ColumnDefs;
	subscriptions = new Subscription();

	tableData: any;

	constructor(public formatHelper: FormatHelperService, private http: HttpClient, private ms: SystemMessageService) { }

	ngOnInit() {
		this.tableData = this.model?.rxRefills;
	}

	submitRxs() {
		this.subscriptions.add(this.http.post('api/PublicHomeDelivery/SubmitHomeDeliveryRefill', this.model).subscribe((o: SystemMessage) => {
			this.ms.setSystemMessage(o.message, o.messageClass);
			this.submitSuccessfulChange.emit(o.isSuccessful);
			this.modal.dismiss();
		}));
	}

	closeModal() {
		this.submitSuccessfulChange.emit(true);
		this.modal.dismiss();
	}
}
