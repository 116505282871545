import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class FacilityCardinalAccountVm {
	cardinalAccountId: number;
	facilityId: number;
	accountName?: string;
	accountNumber?: string;
	isIssuance810: boolean;
	isIssuance810String?: string;
	isBulk810: boolean;
	isBulk810String?: string;
	isCiModule810: boolean;
	isCiModule810String?: string;
	friendlyId?: string;
	canDelete: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'cardinalAccountId': new UntypedFormControl(0, [...(validators['cardinalAccountId'] ?? []), ...[Validators.required]]),
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'accountName': new UntypedFormControl(null, [...(validators['accountName'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.minLength(0)]]),
			'accountNumber': new UntypedFormControl(null, [...(validators['accountNumber'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.minLength(0)]]),
			'isIssuance810': new UntypedFormControl(false, [...(validators['isIssuance810'] ?? []), ...[]]),
			'isIssuance810String': new UntypedFormControl(null, [...(validators['isIssuance810String'] ?? []), ...[]]),
			'isBulk810': new UntypedFormControl(false, [...(validators['isBulk810'] ?? []), ...[]]),
			'isBulk810String': new UntypedFormControl(null, [...(validators['isBulk810String'] ?? []), ...[]]),
			'isCiModule810': new UntypedFormControl(false, [...(validators['isCiModule810'] ?? []), ...[]]),
			'isCiModule810String': new UntypedFormControl(null, [...(validators['isCiModule810String'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['accountName', {"orderId":0,"sortColumn":"accountName","searchColumn":"accountName","includeInExcel":true,"exportColumns":null,"displayName":"Account Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['accountNumber', {"orderId":1,"sortColumn":"accountNumber","searchColumn":"accountNumber","includeInExcel":true,"exportColumns":null,"displayName":"Account Number","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['isCiModule810String', {"orderId":2,"sortColumn":"isCiModule810","searchColumn":"isCiModule810String","includeInExcel":true,"exportColumns":null,"displayName":"Show in CI","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['isIssuance810String', {"orderId":3,"sortColumn":"isIssuance810","searchColumn":"isIssuance810String","includeInExcel":true,"exportColumns":null,"displayName":"Show in Issuance","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['isBulk810String', {"orderId":4,"sortColumn":"isBulk810","searchColumn":"isBulk810String","includeInExcel":true,"exportColumns":null,"displayName":"Show in Bulk","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['canDelete', {"orderId":5,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":true,"exportColumns":null,"displayName":"Delete","isVisible":false,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): FacilityCardinalAccountVmFromFg {
		return new FacilityCardinalAccountVmFromFg(fg);
	}
}

export class FacilityCardinalAccountVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get cardinalAccountId(): number {
		return this.fg.get('cardinalAccountId').value;
	}
	set cardinalAccountId(val) {
		this.fg.get('cardinalAccountId').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get accountName(): string {
		return this.fg.get('accountName').value;
	}
	set accountName(val) {
		this.fg.get('accountName').setValue(val);
	}

	get accountNumber(): string {
		return this.fg.get('accountNumber').value;
	}
	set accountNumber(val) {
		this.fg.get('accountNumber').setValue(val);
	}

	get isIssuance810(): boolean {
		return this.fg.get('isIssuance810').value;
	}
	set isIssuance810(val) {
		this.fg.get('isIssuance810').setValue(val);
	}

	get isIssuance810String(): string {
		return this.fg.get('isIssuance810String').value;
	}
	set isIssuance810String(val) {
		this.fg.get('isIssuance810String').setValue(val);
	}

	get isBulk810(): boolean {
		return this.fg.get('isBulk810').value;
	}
	set isBulk810(val) {
		this.fg.get('isBulk810').setValue(val);
	}

	get isBulk810String(): string {
		return this.fg.get('isBulk810String').value;
	}
	set isBulk810String(val) {
		this.fg.get('isBulk810String').setValue(val);
	}

	get isCiModule810(): boolean {
		return this.fg.get('isCiModule810').value;
	}
	set isCiModule810(val) {
		this.fg.get('isCiModule810').setValue(val);
	}

	get isCiModule810String(): string {
		return this.fg.get('isCiModule810String').value;
	}
	set isCiModule810String(val) {
		this.fg.get('isCiModule810String').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get canDelete(): boolean {
		return this.fg.get('canDelete').value;
	}
	set canDelete(val) {
		this.fg.get('canDelete').setValue(val);
	}
}
