import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class MetabolicFormulaPatientInfoVm {
	patientInfoId: number;
	patientFirstName?: string;
	patientLastName?: string;
	gender?: any;
	genderStr?: string;
	dob?: string;
	parentGuardianFirstName?: string;
	parentGuardianLastName?: string;
	patientAddress?: string;
	patientAddress2?: string;
	patientCity?: string;
	patientState?: string;
	patientStateId: number;
	patientZip?: string;
	smartyAddress?: any;
	smartyAddressString?: string;
	hasSmarty: boolean;
	patientPhone?: string;
	patientExt?: string;
	friendlyId?: string;
	canEdit?: boolean;
	orderId: number;
	stepTitle?: string;
	stepDescription?: string;
	isActive: boolean;
	isComplete: boolean;
	orderIndex: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'patientInfoId': new UntypedFormControl(0, [...(validators['patientInfoId'] ?? []), ...[Validators.required]]),
			'patientFirstName': new UntypedFormControl(null, [...(validators['patientFirstName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'patientLastName': new UntypedFormControl(null, [...(validators['patientLastName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'gender': new UntypedFormControl(null, [...(validators['gender'] ?? []), ...[Validators.required]]),
			'genderStr': new UntypedFormControl(null, [...(validators['genderStr'] ?? []), ...[]]),
			'dob': new UntypedFormControl(null, [...(validators['dob'] ?? []), ...[Validators.required]]),
			'parentGuardianFirstName': new UntypedFormControl(null, [...(validators['parentGuardianFirstName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'parentGuardianLastName': new UntypedFormControl(null, [...(validators['parentGuardianLastName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'patientAddress': new UntypedFormControl(null, [...(validators['patientAddress'] ?? []), ...[]]),
			'patientAddress2': new UntypedFormControl(null, [...(validators['patientAddress2'] ?? []), ...[]]),
			'patientCity': new UntypedFormControl(null, [...(validators['patientCity'] ?? []), ...[]]),
			'patientState': new UntypedFormControl(null, [...(validators['patientState'] ?? []), ...[]]),
			'patientStateId': new UntypedFormControl(0, [...(validators['patientStateId'] ?? []), ...[Validators.required]]),
			'patientZip': new UntypedFormControl(null, [...(validators['patientZip'] ?? []), ...[]]),
			'smartyAddress': new UntypedFormControl(null, [...(validators['smartyAddress'] ?? []), ...[Validators.required]]),
			'smartyAddressString': new UntypedFormControl(null, [...(validators['smartyAddressString'] ?? []), ...[]]),
			'hasSmarty': new UntypedFormControl(false, [...(validators['hasSmarty'] ?? []), ...[]]),
			'patientPhone': new UntypedFormControl(null, [...(validators['patientPhone'] ?? []), ...[Validators.required, Validators.maxLength(14)]]),
			'patientExt': new UntypedFormControl(null, [...(validators['patientExt'] ?? []), ...[Validators.maxLength(5)]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'canEdit': new UntypedFormControl(null, [...(validators['canEdit'] ?? []), ...[]]),
			'orderId': new UntypedFormControl(0, [...(validators['orderId'] ?? []), ...[Validators.required]]),
			'stepTitle': new UntypedFormControl(null, [...(validators['stepTitle'] ?? []), ...[]]),
			'stepDescription': new UntypedFormControl(null, [...(validators['stepDescription'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isComplete': new UntypedFormControl(false, [...(validators['isComplete'] ?? []), ...[]]),
			'orderIndex': new UntypedFormControl(0, [...(validators['orderIndex'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): MetabolicFormulaPatientInfoVmFromFg {
		return new MetabolicFormulaPatientInfoVmFromFg(fg);
	}
}

export class MetabolicFormulaPatientInfoVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get patientInfoId(): number {
		return this.fg.get('patientInfoId').value;
	}
	set patientInfoId(val) {
		this.fg.get('patientInfoId').setValue(val);
	}

	get patientFirstName(): string {
		return this.fg.get('patientFirstName').value;
	}
	set patientFirstName(val) {
		this.fg.get('patientFirstName').setValue(val);
	}

	get patientLastName(): string {
		return this.fg.get('patientLastName').value;
	}
	set patientLastName(val) {
		this.fg.get('patientLastName').setValue(val);
	}

	get gender(): any {
		return this.fg.get('gender').value;
	}
	set gender(val) {
		this.fg.get('gender').setValue(val);
	}

	get genderStr(): string {
		return this.fg.get('genderStr').value;
	}
	set genderStr(val) {
		this.fg.get('genderStr').setValue(val);
	}

	get dob(): string {
		return this.fg.get('dob').value;
	}
	set dob(val) {
		this.fg.get('dob').setValue(val);
	}

	get parentGuardianFirstName(): string {
		return this.fg.get('parentGuardianFirstName').value;
	}
	set parentGuardianFirstName(val) {
		this.fg.get('parentGuardianFirstName').setValue(val);
	}

	get parentGuardianLastName(): string {
		return this.fg.get('parentGuardianLastName').value;
	}
	set parentGuardianLastName(val) {
		this.fg.get('parentGuardianLastName').setValue(val);
	}

	get patientAddress(): string {
		return this.fg.get('patientAddress').value;
	}
	set patientAddress(val) {
		this.fg.get('patientAddress').setValue(val);
	}

	get patientAddress2(): string {
		return this.fg.get('patientAddress2').value;
	}
	set patientAddress2(val) {
		this.fg.get('patientAddress2').setValue(val);
	}

	get patientCity(): string {
		return this.fg.get('patientCity').value;
	}
	set patientCity(val) {
		this.fg.get('patientCity').setValue(val);
	}

	get patientState(): string {
		return this.fg.get('patientState').value;
	}
	set patientState(val) {
		this.fg.get('patientState').setValue(val);
	}

	get patientStateId(): number {
		return this.fg.get('patientStateId').value;
	}
	set patientStateId(val) {
		this.fg.get('patientStateId').setValue(val);
	}

	get patientZip(): string {
		return this.fg.get('patientZip').value;
	}
	set patientZip(val) {
		this.fg.get('patientZip').setValue(val);
	}

	get smartyAddress(): any {
		return this.fg.get('smartyAddress').value;
	}
	set smartyAddress(val) {
		this.fg.get('smartyAddress').setValue(val);
	}

	get smartyAddressString(): string {
		return this.fg.get('smartyAddressString').value;
	}
	set smartyAddressString(val) {
		this.fg.get('smartyAddressString').setValue(val);
	}

	get hasSmarty(): boolean {
		return this.fg.get('hasSmarty').value;
	}
	set hasSmarty(val) {
		this.fg.get('hasSmarty').setValue(val);
	}

	get patientPhone(): string {
		return this.fg.get('patientPhone').value;
	}
	set patientPhone(val) {
		this.fg.get('patientPhone').setValue(val);
	}

	get patientExt(): string {
		return this.fg.get('patientExt').value;
	}
	set patientExt(val) {
		this.fg.get('patientExt').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get canEdit(): boolean {
		return this.fg.get('canEdit').value;
	}
	set canEdit(val) {
		this.fg.get('canEdit').setValue(val);
	}

	get orderId(): number {
		return this.fg.get('orderId').value;
	}
	set orderId(val) {
		this.fg.get('orderId').setValue(val);
	}

	get stepTitle(): string {
		return this.fg.get('stepTitle').value;
	}
	set stepTitle(val) {
		this.fg.get('stepTitle').setValue(val);
	}

	get stepDescription(): string {
		return this.fg.get('stepDescription').value;
	}
	set stepDescription(val) {
		this.fg.get('stepDescription').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isComplete(): boolean {
		return this.fg.get('isComplete').value;
	}
	set isComplete(val) {
		this.fg.get('isComplete').setValue(val);
	}

	get orderIndex(): number {
		return this.fg.get('orderIndex').value;
	}
	set orderIndex(val) {
		this.fg.get('orderIndex').setValue(val);
	}
}
