import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class OrganizationListVm {
	organizationId: number;
	organizationName?: string;
	address?: string;
	city?: string;
	state?: string;
	zip?: string;
	county?: string;
	countyId?: number;
	isActive?: string;
	canDelete?: boolean;
	isParent?: boolean;
	orgCount: number;
	facilityCount: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'organizationId': new UntypedFormControl(0, [...(validators['organizationId'] ?? []), ...[Validators.required]]),
			'organizationName': new UntypedFormControl(null, [...(validators['organizationName'] ?? []), ...[]]),
			'address': new UntypedFormControl(null, [...(validators['address'] ?? []), ...[]]),
			'city': new UntypedFormControl(null, [...(validators['city'] ?? []), ...[]]),
			'state': new UntypedFormControl(null, [...(validators['state'] ?? []), ...[]]),
			'zip': new UntypedFormControl(null, [...(validators['zip'] ?? []), ...[]]),
			'county': new UntypedFormControl(null, [...(validators['county'] ?? []), ...[]]),
			'countyId': new UntypedFormControl(null, [...(validators['countyId'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(null, [...(validators['isActive'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(null, [...(validators['canDelete'] ?? []), ...[]]),
			'isParent': new UntypedFormControl(null, [...(validators['isParent'] ?? []), ...[]]),
			'orgCount': new UntypedFormControl(0, [...(validators['orgCount'] ?? []), ...[Validators.required]]),
			'facilityCount': new UntypedFormControl(0, [...(validators['facilityCount'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['organizationName', {"orderId":0,"sortColumn":"organizationName","searchColumn":"organizationName","includeInExcel":true,"exportColumns":null,"displayName":"Organization","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['address', {"orderId":1,"sortColumn":"address","searchColumn":"address","includeInExcel":true,"exportColumns":null,"displayName":"Address","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['city', {"orderId":2,"sortColumn":"city","searchColumn":"city","includeInExcel":true,"exportColumns":null,"displayName":"City","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['county', {"orderId":3,"sortColumn":"county","searchColumn":"county","includeInExcel":true,"exportColumns":null,"displayName":"County","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['isActive', {"orderId":4,"sortColumn":"isActive","searchColumn":"isActive","includeInExcel":true,"exportColumns":null,"displayName":"Is Active?","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['facilityCount', {"orderId":5,"sortColumn":"facilityCount","searchColumn":"facilityCount","includeInExcel":true,"exportColumns":null,"displayName":"Facilities","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['canDelete', {"orderId":6,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":true,"exportColumns":null,"displayName":"Delete","isVisible":false,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): OrganizationListVmFromFg {
		return new OrganizationListVmFromFg(fg);
	}
}

export class OrganizationListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get organizationName(): string {
		return this.fg.get('organizationName').value;
	}
	set organizationName(val) {
		this.fg.get('organizationName').setValue(val);
	}

	get address(): string {
		return this.fg.get('address').value;
	}
	set address(val) {
		this.fg.get('address').setValue(val);
	}

	get city(): string {
		return this.fg.get('city').value;
	}
	set city(val) {
		this.fg.get('city').setValue(val);
	}

	get state(): string {
		return this.fg.get('state').value;
	}
	set state(val) {
		this.fg.get('state').setValue(val);
	}

	get zip(): string {
		return this.fg.get('zip').value;
	}
	set zip(val) {
		this.fg.get('zip').setValue(val);
	}

	get county(): string {
		return this.fg.get('county').value;
	}
	set county(val) {
		this.fg.get('county').setValue(val);
	}

	get countyId(): number {
		return this.fg.get('countyId').value;
	}
	set countyId(val) {
		this.fg.get('countyId').setValue(val);
	}

	get isActive(): string {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get canDelete(): boolean {
		return this.fg.get('canDelete').value;
	}
	set canDelete(val) {
		this.fg.get('canDelete').setValue(val);
	}

	get isParent(): boolean {
		return this.fg.get('isParent').value;
	}
	set isParent(val) {
		this.fg.get('isParent').setValue(val);
	}

	get orgCount(): number {
		return this.fg.get('orgCount').value;
	}
	set orgCount(val) {
		this.fg.get('orgCount').setValue(val);
	}

	get facilityCount(): number {
		return this.fg.get('facilityCount').value;
	}
	set facilityCount(val) {
		this.fg.get('facilityCount').setValue(val);
	}
}
