import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DynamicFormFieldVm } from 'app/shared/generated/Models/DynamicFormFieldVm';

@Component({
    selector: 'pcg-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.css'],
    standalone: false
})
export class DynamicFormComponent implements OnInit {

	@Input()
	set fields(val: DynamicFormFieldVm[]) {
		this._fields = val;
		this.setGroupedFields();
	}
	get fields(): DynamicFormFieldVm[] { return this._fields; }

	@Input() fgName: string;
	@Input() fg: UntypedFormGroup;
	@Input() columnsPerRow = 3;

	private _fields: DynamicFormFieldVm[];
	groupedFields: DynamicFormFieldVm[][];

	constructor() { }

	ngOnInit() { }

	setGroupedFields() {
		let groupIndex = -1;
		this.groupedFields = [];
		for (let i = 0; i < this.fields.length; ++i) {
			if ((i % this.columnsPerRow) === 0) {
				this.groupedFields.push([]);
				++groupIndex;
			}
			this.groupedFields[groupIndex].push(this.fields[i]);
		}
	}
}
