import { Component, ElementRef, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faFingerprint, faUsbDrive } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { VerificationVm } from 'app/shared/generated/Administration/Models/VerificationVm';
import { FingerprintService } from 'app/core/security/multi-factor-auth/fingerprint.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { SecurityService } from 'app/core/security/security.service';
import { MultiFactorAuthModalComponent } from 'app/core/pages/login/multi-factor-auth-modal/multi-factor-auth-modal.component';
import { FidoService } from 'app/shared/services/fido.service';
import { WebAuthnService } from 'app/core/security/multi-factor-auth/web-authn.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';

@Component({
    selector: 'pcg-pin',
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PinComponent),
            multi: true
        }],
    templateUrl: './pin.component.html',
    styleUrls: ['./pin.component.scss'],
    standalone: false
})
export class PinComponent implements OnInit, ControlValueAccessor {

    @Input() labelForId = null;
    @Input() labelText: string = null;
    @Input('value') _value = null;
    @Input() isDisabled = false;
    @Input() inputClass: string = "form-control text-right pin-width";
    @Input() autofocus: boolean;
    @Input() hideSubmit: boolean;
    @Input() submitOnChange: boolean = false;
    @Input() maxLength: number = 4;
    @Input() verificationVm: VerificationVm;
    @Input() userJs: UserJsVm = null;

    @Output() submitPin = new EventEmitter<string>();

    // Firefox does not support -webkit-text-security, but has a much less annoying approach to handling password fields.
    // The following is used to set the input type to reduce annoying password security warnings in chrome.  
    isFirefox: boolean = navigator.userAgent.includes("Firefox");

    user: UserJsVm;

    hasVerificationSettings = false;
    isFido = false;
	isSecuGen = false;
	isPin = false;

    faFingerprint = faFingerprint;
    faUsbDrive = faUsbDrive;
    
    onChange: any = () => { };
    onTouched: any = () => { };

    get value() { return this._value; }

    set value(val) {
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }

    constructor(
        private element: ElementRef
        , private fingerprint: FingerprintService
        , private sec: SecurityService
        , private modalService: NgbModal
        , private webAuthn: WebAuthnService
        , private ms: SystemMessageService
    ) { }

    ngOnInit() { 
        this.labelForId ??= this.element?.nativeElement?.attributes?.formcontrolname?.value; 
        if (this.verificationVm != null) { 
            if (this.userJs == null) { this.user = this.sec.getUser(); }
            else { this.user = this.userJs; }

            this.hasVerificationSettings = true; 
            this.hideSubmit = true;
            this.submitOnChange = true;
            for (var i = 0; i < this.verificationVm.verificationTypesAllowed.length; i++) {
                if (this.verificationVm.verificationTypesAllowed[i] === 0) { this.isFido = true; }
                if (this.verificationVm.verificationTypesAllowed[i] === 1) { this.isSecuGen = true; }
                if (this.verificationVm.verificationTypesAllowed[i] === 2) { this.isPin = true; }
            }
        } else { this.hasVerificationSettings = false; }
    }

    onSubmitPin(submit: boolean = true) { 
        if (submit 
            && !FormatHelperService.GetIsNullyOrWhitespace(this.value)
        ) { this.submitPin.emit(this.value); } 
    }
    writeValue = (obj: any) => this.value = obj;
    registerOnChange = (fn: any) => this.onChange = fn;
    registerOnTouched = (fn: any) => this.onTouched = fn;
    setDisabledState?= (isDisabled: boolean) => this.isDisabled = isDisabled;

    // #region SecuGen
    scanSecuGen() { 
        if (this.value == null) { this.doFingerprintIdentification(); }
    }

    async doFingerprintIdentification() {
        const response = await this.fingerprint.verifySaveFingerprint(this.user.id, 'api/CIRepack/CiJob/VerifyFingerPrint');
        // Fire the save event
		if (response !== null) {
			if (response?.isSuccessful) { 
                this.value = response.value;
                this.submitPin.emit(response.value); 
            } else { return false; }
		} 
        return false;
	}
    // #endregion

    // #region Usb Fido
    usbDevice() {
        if (this.value == null) {
            this.modalService.open(MultiFactorAuthModalComponent, { animation: false });
		
            // Make sure their browser is FIDO compatible
            if (!this.webAuthn.isFidoCompatible()) {
                this.modalService.dismissAll();
                return;
            }
    
            // Get assertion options from the server for user
            this.webAuthn.getAssertionOptionsNoUser().subscribe(async makeAssertionOptions => {
                try {
                    makeAssertionOptions = FidoService.fixAssertionOptions(makeAssertionOptions);
                    
                    let credential;
                    try { credential = await navigator.credentials.get({ publicKey: makeAssertionOptions }); }
                    catch (err) { console.log(err); }					
                    this.sec.setLocalStorage('lastAccess', new Date());
                    this.webAuthn.verifyAssertionNoUser(
                        credential
                        , ""
                        , ""
                    ).subscribe((sm: SystemMessage) => {
                        this.modalService.dismissAll();
                        if (sm.isSuccessful) { 
                            this.value = sm.value;
                            this.submitPin.emit(sm.value); 
                        } else { this.ms.setSystemMessage(sm.message, "error"); }
                    });						
                } catch (e) { 
                    this.modalService.dismissAll();
                    this.webAuthn.verifyAssertionNoUser(
                        null
                        , ""
                        , ""
                    ).subscribe((sm: SystemMessage) => { });
                }
            });
        }
	}
    // #endregion
}
