<ng-container *ngIf='!showEditOrganizationFacility; else editOrganizationFacility;'>
	<pcg-table-filter id='organizationFacilityListFilters'>
		<div (change)="reloadTable();">
			<div class="additional-info mt-1">
				<div class='mt-2 filter-width'>
					<label class='for'>Is Active?</label>
					<mat-button-toggle-group 
						[(ngModel)]='filters.isActive' 
						[value]='filters.isActive' 
						(change)="table.ajaxReload();"
					>
						<mat-button-toggle 
							class="flex-fill" 
							[value]="true"
						>Yes</mat-button-toggle>
						<mat-button-toggle 
							class="flex-fill mx-2" 
							value="false"
						>No</mat-button-toggle>
						<mat-button-toggle 
							class="flex-fill" 
							value="null"
						>All</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
		</div>
	</pcg-table-filter>

	<div id="informativeContent">
		<div id="actionElements">
			<button 
				class="btn btn-save btn-sm inline-add-btn" 
				(click)="showEditOrganizationFacility = !showEditOrganizationFacility"
			>Add New</button>
		</div>
	</div>

	<pcg-table
		#table
		[ajaxData]='filters'
		[multipleSearch]='false'
		[pcgSort]='[[ "facilityName", "asc" ]]'
		[filterMap]="filterMap"
		dataSource='api/Administration/Organization/GetOrganizationFacilityList'
		[columnDefs]='columns'
		filterId="organizationFacilityListFilters"
		[canShowHideColumns]="true"
		[canExactMatchSearch]="false"
		[canResetFilters]="true"
		[emptyFilters]="initFilters"
		[isRowClickable]="true"
		(clickRow)="openFacility($event)"
	>
		<ng-container matColumnDef="facilityId">
			<th 
				mat-header-cell 
				*matHeaderCellDef
				class="text-center facilityRedirect"
			></th>
			<td 
				mat-cell *matCellDef='let row'
				class="text-center facilityRedirect"
			>
				@if (row.facilityId != 0) {
					<div
						class="btn btn-outline-secondary toggle text-center"
						[routerLink]="'/admin/organizations/facilities/edit/' + row.facilityId"
						ngbTooltip="Navigate to Edit Facility"
					><fa-icon [icon]="faIcon.faExternalLink"></fa-icon></div>
				}
			</td>
		</ng-container>

		<ng-container matColumnDef="facilityName">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('facilityName').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row'
			>{{ row.facilityName }}</td>
		</ng-container>

		<ng-container matColumnDef="facilityIdentifier">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('facilityIdentifier').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row'
			>{{ row.facilityIdentifier }}</td>
		</ng-container>

		<ng-container matColumnDef="city">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('city').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row'
			>{{ row.city }}</td>
		</ng-container>

		<ng-container matColumnDef="county">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('county').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row'
			>{{ row.county }}</td>
		</ng-container>

		<ng-container matColumnDef="isActive">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('isActive').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row'
			>{{ row.isActive }}</td>
		</ng-container>

		<ng-container matColumnDef="canDelete">
			<th 
				class='text-center' 
				mat-header-cell 
				*matHeaderCellDef
			>Delete</th>
			<td 
				class='text-center' 
				mat-cell 
				*matCellDef='let row'
			>
				@if (canDeleteFacility()) {
					<pcg-delete-button 
						[table]='table' 
						[confirmMessage]="'Are you sure you want to delete this Facility?'"
						url='api/Administration/Organization/DeleteFacility' 
						[id]='row.facilityId'
					></pcg-delete-button>
				}
			</td>
		</ng-container>
	</pcg-table>
</ng-container>

<ng-template #editOrganizationFacility>
    <pcg-shared-edit-organization-facility
		[organizationId]="id"
		[facilityId]="selectedFacilityId"
		(onSaveSuccess)="orgFacilityInventories?.getInventoryList();">
	</pcg-shared-edit-organization-facility>
	<hr />

	@if (selectedFacilityId != 0) {
		<pcg-org-facility-inventories
			#orgFacilityInventories
			[organizationId]="id"
			[facilityId]="selectedFacilityId">
		</pcg-org-facility-inventories>
	}

	<div class='bottom-buttons'>
		<a 
			class='btn btn-cancel btn-lg' 
			(click)="hideEditOrganizationFacility()" 
			ngbTooltip="Back to Facility List"
		>Back</a>
	</div>
</ng-template>