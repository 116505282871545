import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';
import { ProductInfoVm, ProductInfoVmFromFg } from '../Inventory/Models/ProductInfoVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class PatientDataVm {
	patientID?: string;
	firstName?: string;
	middleInitial?: string;
	lastName?: string;
	patientDOB?: string;
	address?: string;
	address2?: string;
	city?: string;
	state?: string;
	zip?: string;
	homePhone?: string;
	facility?: string;
	facilityCode?: string;
	refillsRemaining?: string;
	qs1Status?: string;
	medicalRecordNumber?: string;
	quantityPrescribed: number;
	ndc?: string;
	expirationDate?: string;
	lastFillDate?: string;
	lastFillDateColor?: string;
	within30DaysDate?: string;
	within30DaysCreateddBy?: string;
	nextFillDate?: string;
	rxNumber?: string;
	errorMessage?: string;
	productName?: string;
	pfsFormID?: number;
	pfsProductId?: number;
	pfsProgramId?: number;
	pfsUnitOfMeasureId?: number;
	pfsCountUom?: number;
	pfsUrl?: string;
	pfsRequestType?: string;
	shipmentId?: number;
	inventorySiteId?: number;
	inventoryProductId?: number;
	isFacility?: boolean;
	isEos?: boolean;
	isOverrideLastFillDate?: boolean;
	isOverridePatientTransfer?: boolean;
	isSoftStop?: boolean;
	softStopDays?: number;
	isHardStop?: boolean;
	hardStopDays?: number;
	inventories?: ProductInfoVm[];
	drugClass?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'patientID': new UntypedFormControl(null, [...(validators['patientID'] ?? []), ...[]]),
			'firstName': new UntypedFormControl(null, [...(validators['firstName'] ?? []), ...[]]),
			'middleInitial': new UntypedFormControl(null, [...(validators['middleInitial'] ?? []), ...[]]),
			'lastName': new UntypedFormControl(null, [...(validators['lastName'] ?? []), ...[]]),
			'patientDOB': new UntypedFormControl(null, [...(validators['patientDOB'] ?? []), ...[]]),
			'address': new UntypedFormControl(null, [...(validators['address'] ?? []), ...[]]),
			'address2': new UntypedFormControl(null, [...(validators['address2'] ?? []), ...[]]),
			'city': new UntypedFormControl(null, [...(validators['city'] ?? []), ...[]]),
			'state': new UntypedFormControl(null, [...(validators['state'] ?? []), ...[]]),
			'zip': new UntypedFormControl(null, [...(validators['zip'] ?? []), ...[]]),
			'homePhone': new UntypedFormControl(null, [...(validators['homePhone'] ?? []), ...[]]),
			'facility': new UntypedFormControl(null, [...(validators['facility'] ?? []), ...[]]),
			'facilityCode': new UntypedFormControl(null, [...(validators['facilityCode'] ?? []), ...[]]),
			'refillsRemaining': new UntypedFormControl(null, [...(validators['refillsRemaining'] ?? []), ...[]]),
			'qs1Status': new UntypedFormControl(null, [...(validators['qs1Status'] ?? []), ...[]]),
			'medicalRecordNumber': new UntypedFormControl(null, [...(validators['medicalRecordNumber'] ?? []), ...[]]),
			'quantityPrescribed': new UntypedFormControl(0, [...(validators['quantityPrescribed'] ?? []), ...[Validators.required]]),
			'ndc': new UntypedFormControl(null, [...(validators['ndc'] ?? []), ...[]]),
			'expirationDate': new UntypedFormControl(null, [...(validators['expirationDate'] ?? []), ...[]]),
			'lastFillDate': new UntypedFormControl(null, [...(validators['lastFillDate'] ?? []), ...[]]),
			'lastFillDateColor': new UntypedFormControl(null, [...(validators['lastFillDateColor'] ?? []), ...[]]),
			'within30DaysDate': new UntypedFormControl(null, [...(validators['within30DaysDate'] ?? []), ...[]]),
			'within30DaysCreateddBy': new UntypedFormControl(null, [...(validators['within30DaysCreateddBy'] ?? []), ...[]]),
			'nextFillDate': new UntypedFormControl(null, [...(validators['nextFillDate'] ?? []), ...[]]),
			'rxNumber': new UntypedFormControl(null, [...(validators['rxNumber'] ?? []), ...[]]),
			'errorMessage': new UntypedFormControl(null, [...(validators['errorMessage'] ?? []), ...[]]),
			'productName': new UntypedFormControl(null, [...(validators['productName'] ?? []), ...[]]),
			'pfsFormID': new UntypedFormControl(null, [...(validators['pfsFormID'] ?? []), ...[]]),
			'pfsProductId': new UntypedFormControl(null, [...(validators['pfsProductId'] ?? []), ...[]]),
			'pfsProgramId': new UntypedFormControl(null, [...(validators['pfsProgramId'] ?? []), ...[]]),
			'pfsUnitOfMeasureId': new UntypedFormControl(null, [...(validators['pfsUnitOfMeasureId'] ?? []), ...[]]),
			'pfsCountUom': new UntypedFormControl(null, [...(validators['pfsCountUom'] ?? []), ...[]]),
			'pfsUrl': new UntypedFormControl(null, [...(validators['pfsUrl'] ?? []), ...[]]),
			'pfsRequestType': new UntypedFormControl(null, [...(validators['pfsRequestType'] ?? []), ...[]]),
			'shipmentId': new UntypedFormControl(null, [...(validators['shipmentId'] ?? []), ...[]]),
			'inventorySiteId': new UntypedFormControl(null, [...(validators['inventorySiteId'] ?? []), ...[]]),
			'inventoryProductId': new UntypedFormControl(null, [...(validators['inventoryProductId'] ?? []), ...[]]),
			'isFacility': new UntypedFormControl(null, [...(validators['isFacility'] ?? []), ...[]]),
			'isEos': new UntypedFormControl(null, [...(validators['isEos'] ?? []), ...[]]),
			'isOverrideLastFillDate': new UntypedFormControl(null, [...(validators['isOverrideLastFillDate'] ?? []), ...[]]),
			'isOverridePatientTransfer': new UntypedFormControl(null, [...(validators['isOverridePatientTransfer'] ?? []), ...[]]),
			'isSoftStop': new UntypedFormControl(null, [...(validators['isSoftStop'] ?? []), ...[]]),
			'softStopDays': new UntypedFormControl(null, [...(validators['softStopDays'] ?? []), ...[]]),
			'isHardStop': new UntypedFormControl(null, [...(validators['isHardStop'] ?? []), ...[]]),
			'hardStopDays': new UntypedFormControl(null, [...(validators['hardStopDays'] ?? []), ...[]]),
			'inventories': new UntypedFormArray([]),
			'drugClass': new UntypedFormControl(null, [...(validators['drugClass'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): PatientDataVmFromFg {
		return new PatientDataVmFromFg(fg);
	}
}

export class PatientDataVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get patientID(): string {
		return this.fg.get('patientID').value;
	}
	set patientID(val) {
		this.fg.get('patientID').setValue(val);
	}

	get firstName(): string {
		return this.fg.get('firstName').value;
	}
	set firstName(val) {
		this.fg.get('firstName').setValue(val);
	}

	get middleInitial(): string {
		return this.fg.get('middleInitial').value;
	}
	set middleInitial(val) {
		this.fg.get('middleInitial').setValue(val);
	}

	get lastName(): string {
		return this.fg.get('lastName').value;
	}
	set lastName(val) {
		this.fg.get('lastName').setValue(val);
	}

	get patientDOB(): string {
		return this.fg.get('patientDOB').value;
	}
	set patientDOB(val) {
		this.fg.get('patientDOB').setValue(val);
	}

	get address(): string {
		return this.fg.get('address').value;
	}
	set address(val) {
		this.fg.get('address').setValue(val);
	}

	get address2(): string {
		return this.fg.get('address2').value;
	}
	set address2(val) {
		this.fg.get('address2').setValue(val);
	}

	get city(): string {
		return this.fg.get('city').value;
	}
	set city(val) {
		this.fg.get('city').setValue(val);
	}

	get state(): string {
		return this.fg.get('state').value;
	}
	set state(val) {
		this.fg.get('state').setValue(val);
	}

	get zip(): string {
		return this.fg.get('zip').value;
	}
	set zip(val) {
		this.fg.get('zip').setValue(val);
	}

	get homePhone(): string {
		return this.fg.get('homePhone').value;
	}
	set homePhone(val) {
		this.fg.get('homePhone').setValue(val);
	}

	get facility(): string {
		return this.fg.get('facility').value;
	}
	set facility(val) {
		this.fg.get('facility').setValue(val);
	}

	get facilityCode(): string {
		return this.fg.get('facilityCode').value;
	}
	set facilityCode(val) {
		this.fg.get('facilityCode').setValue(val);
	}

	get refillsRemaining(): string {
		return this.fg.get('refillsRemaining').value;
	}
	set refillsRemaining(val) {
		this.fg.get('refillsRemaining').setValue(val);
	}

	get qs1Status(): string {
		return this.fg.get('qs1Status').value;
	}
	set qs1Status(val) {
		this.fg.get('qs1Status').setValue(val);
	}

	get medicalRecordNumber(): string {
		return this.fg.get('medicalRecordNumber').value;
	}
	set medicalRecordNumber(val) {
		this.fg.get('medicalRecordNumber').setValue(val);
	}

	get quantityPrescribed(): number {
		return this.fg.get('quantityPrescribed').value;
	}
	set quantityPrescribed(val) {
		this.fg.get('quantityPrescribed').setValue(val);
	}

	get ndc(): string {
		return this.fg.get('ndc').value;
	}
	set ndc(val) {
		this.fg.get('ndc').setValue(val);
	}

	get expirationDate(): string {
		return this.fg.get('expirationDate').value;
	}
	set expirationDate(val) {
		this.fg.get('expirationDate').setValue(val);
	}

	get lastFillDate(): string {
		return this.fg.get('lastFillDate').value;
	}
	set lastFillDate(val) {
		this.fg.get('lastFillDate').setValue(val);
	}

	get lastFillDateColor(): string {
		return this.fg.get('lastFillDateColor').value;
	}
	set lastFillDateColor(val) {
		this.fg.get('lastFillDateColor').setValue(val);
	}

	get within30DaysDate(): string {
		return this.fg.get('within30DaysDate').value;
	}
	set within30DaysDate(val) {
		this.fg.get('within30DaysDate').setValue(val);
	}

	get within30DaysCreateddBy(): string {
		return this.fg.get('within30DaysCreateddBy').value;
	}
	set within30DaysCreateddBy(val) {
		this.fg.get('within30DaysCreateddBy').setValue(val);
	}

	get nextFillDate(): string {
		return this.fg.get('nextFillDate').value;
	}
	set nextFillDate(val) {
		this.fg.get('nextFillDate').setValue(val);
	}

	get rxNumber(): string {
		return this.fg.get('rxNumber').value;
	}
	set rxNumber(val) {
		this.fg.get('rxNumber').setValue(val);
	}

	get errorMessage(): string {
		return this.fg.get('errorMessage').value;
	}
	set errorMessage(val) {
		this.fg.get('errorMessage').setValue(val);
	}

	get productName(): string {
		return this.fg.get('productName').value;
	}
	set productName(val) {
		this.fg.get('productName').setValue(val);
	}

	get pfsFormID(): number {
		return this.fg.get('pfsFormID').value;
	}
	set pfsFormID(val) {
		this.fg.get('pfsFormID').setValue(val);
	}

	get pfsProductId(): number {
		return this.fg.get('pfsProductId').value;
	}
	set pfsProductId(val) {
		this.fg.get('pfsProductId').setValue(val);
	}

	get pfsProgramId(): number {
		return this.fg.get('pfsProgramId').value;
	}
	set pfsProgramId(val) {
		this.fg.get('pfsProgramId').setValue(val);
	}

	get pfsUnitOfMeasureId(): number {
		return this.fg.get('pfsUnitOfMeasureId').value;
	}
	set pfsUnitOfMeasureId(val) {
		this.fg.get('pfsUnitOfMeasureId').setValue(val);
	}

	get pfsCountUom(): number {
		return this.fg.get('pfsCountUom').value;
	}
	set pfsCountUom(val) {
		this.fg.get('pfsCountUom').setValue(val);
	}

	get pfsUrl(): string {
		return this.fg.get('pfsUrl').value;
	}
	set pfsUrl(val) {
		this.fg.get('pfsUrl').setValue(val);
	}

	get pfsRequestType(): string {
		return this.fg.get('pfsRequestType').value;
	}
	set pfsRequestType(val) {
		this.fg.get('pfsRequestType').setValue(val);
	}

	get shipmentId(): number {
		return this.fg.get('shipmentId').value;
	}
	set shipmentId(val) {
		this.fg.get('shipmentId').setValue(val);
	}

	get inventorySiteId(): number {
		return this.fg.get('inventorySiteId').value;
	}
	set inventorySiteId(val) {
		this.fg.get('inventorySiteId').setValue(val);
	}

	get inventoryProductId(): number {
		return this.fg.get('inventoryProductId').value;
	}
	set inventoryProductId(val) {
		this.fg.get('inventoryProductId').setValue(val);
	}

	get isFacility(): boolean {
		return this.fg.get('isFacility').value;
	}
	set isFacility(val) {
		this.fg.get('isFacility').setValue(val);
	}

	get isEos(): boolean {
		return this.fg.get('isEos').value;
	}
	set isEos(val) {
		this.fg.get('isEos').setValue(val);
	}

	get isOverrideLastFillDate(): boolean {
		return this.fg.get('isOverrideLastFillDate').value;
	}
	set isOverrideLastFillDate(val) {
		this.fg.get('isOverrideLastFillDate').setValue(val);
	}

	get isOverridePatientTransfer(): boolean {
		return this.fg.get('isOverridePatientTransfer').value;
	}
	set isOverridePatientTransfer(val) {
		this.fg.get('isOverridePatientTransfer').setValue(val);
	}

	get isSoftStop(): boolean {
		return this.fg.get('isSoftStop').value;
	}
	set isSoftStop(val) {
		this.fg.get('isSoftStop').setValue(val);
	}

	get softStopDays(): number {
		return this.fg.get('softStopDays').value;
	}
	set softStopDays(val) {
		this.fg.get('softStopDays').setValue(val);
	}

	get isHardStop(): boolean {
		return this.fg.get('isHardStop').value;
	}
	set isHardStop(val) {
		this.fg.get('isHardStop').setValue(val);
	}

	get hardStopDays(): number {
		return this.fg.get('hardStopDays').value;
	}
	set hardStopDays(val) {
		this.fg.get('hardStopDays').setValue(val);
	}

	get drugClass(): string {
		return this.fg.get('drugClass').value;
	}
	set drugClass(val) {
		this.fg.get('drugClass').setValue(val);
	}
}
