import { Injectable, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class GlobalVariablesService {    
    
    pharmInvName = signal<string>("");
    invName = signal<string>("");
    isNavFixed = signal<boolean>(false);
}
