import { Component } from '@angular/core';

// This component has hidden decoy fields to prevent browser autofill
// Remember to add the autocomplete='off' attribute to the
// input fields you want to prevent from being autocompleted.
@Component({
  selector: 'pcg-prevent-browser-autofill',
  template: `
    <input type="text" style="display:none" name="fakeusernameremembered">
    <input type="password" style="display:none" name="fakepasswordremembered">
  `,
  standalone: false
})
export class PreventBrowserAutofillComponent {}
