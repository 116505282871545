import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';
import { ShipmentCommentListVm, ShipmentCommentListVmFromFg } from '../../../Inventory/Models/Shipping/ShipmentCommentListVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ShipmentCommentVm {
	shipmentCommentId: number;
	shipmentId?: number;
	pfsFormID?: number;
	comment?: string;
	commentorInfo?: string;
	commentDateTime: Date;
	strCommentDateTime?: string;
	strTimeAgo?: string;
	countPfsFormIds: number;
	pfsUrl?: string;
	currentStep: number;
	commentList?: ShipmentCommentListVm[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'shipmentCommentId': new UntypedFormControl(0, [...(validators['shipmentCommentId'] ?? []), ...[Validators.required]]),
			'shipmentId': new UntypedFormControl(null, [...(validators['shipmentId'] ?? []), ...[]]),
			'pfsFormID': new UntypedFormControl(null, [...(validators['pfsFormID'] ?? []), ...[]]),
			'comment': new UntypedFormControl(null, [...(validators['comment'] ?? []), ...[Validators.required]]),
			'commentorInfo': new UntypedFormControl(null, [...(validators['commentorInfo'] ?? []), ...[]]),
			'commentDateTime': new UntypedFormControl('', [...(validators['commentDateTime'] ?? []), ...[Validators.required]]),
			'strCommentDateTime': new UntypedFormControl(null, [...(validators['strCommentDateTime'] ?? []), ...[]]),
			'strTimeAgo': new UntypedFormControl(null, [...(validators['strTimeAgo'] ?? []), ...[]]),
			'countPfsFormIds': new UntypedFormControl(0, [...(validators['countPfsFormIds'] ?? []), ...[Validators.required]]),
			'pfsUrl': new UntypedFormControl(null, [...(validators['pfsUrl'] ?? []), ...[]]),
			'currentStep': new UntypedFormControl(0, [...(validators['currentStep'] ?? []), ...[Validators.required]]),
			'commentList': new UntypedFormArray([])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['shipmentCommentId', {"orderId":0,"sortColumn":"shipmentCommentId","searchColumn":"shipmentCommentId","includeInExcel":true,"exportColumns":null,"displayName":"Comment ID","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['shipmentId', {"orderId":1,"sortColumn":"shipmentId","searchColumn":"shipmentId","includeInExcel":true,"exportColumns":null,"displayName":"Fulfillment ID","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['pfsFormID', {"orderId":2,"sortColumn":"pfsFormID","searchColumn":"pfsFormID","includeInExcel":true,"exportColumns":null,"displayName":"PFS Form ID","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['comment', {"orderId":3,"sortColumn":"comment","searchColumn":"comment","includeInExcel":true,"exportColumns":null,"displayName":"Comment","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['commentorInfo', {"orderId":4,"sortColumn":"commentorInfo","searchColumn":"commentorInfo","includeInExcel":true,"exportColumns":null,"displayName":"Commenter Info","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['commentDateTime', {"orderId":5,"sortColumn":"commentDateTime","searchColumn":"commentDateTime","includeInExcel":true,"exportColumns":null,"displayName":"Comment Date","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): ShipmentCommentVmFromFg {
		return new ShipmentCommentVmFromFg(fg);
	}
}

export class ShipmentCommentVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get shipmentCommentId(): number {
		return this.fg.get('shipmentCommentId').value;
	}
	set shipmentCommentId(val) {
		this.fg.get('shipmentCommentId').setValue(val);
	}

	get shipmentId(): number {
		return this.fg.get('shipmentId').value;
	}
	set shipmentId(val) {
		this.fg.get('shipmentId').setValue(val);
	}

	get pfsFormID(): number {
		return this.fg.get('pfsFormID').value;
	}
	set pfsFormID(val) {
		this.fg.get('pfsFormID').setValue(val);
	}

	get comment(): string {
		return this.fg.get('comment').value;
	}
	set comment(val) {
		this.fg.get('comment').setValue(val);
	}

	get commentorInfo(): string {
		return this.fg.get('commentorInfo').value;
	}
	set commentorInfo(val) {
		this.fg.get('commentorInfo').setValue(val);
	}

	get commentDateTime(): Date {
		return this.fg.get('commentDateTime').value;
	}
	set commentDateTime(val) {
		this.fg.get('commentDateTime').setValue(val);
	}

	get strCommentDateTime(): string {
		return this.fg.get('strCommentDateTime').value;
	}
	set strCommentDateTime(val) {
		this.fg.get('strCommentDateTime').setValue(val);
	}

	get strTimeAgo(): string {
		return this.fg.get('strTimeAgo').value;
	}
	set strTimeAgo(val) {
		this.fg.get('strTimeAgo').setValue(val);
	}

	get countPfsFormIds(): number {
		return this.fg.get('countPfsFormIds').value;
	}
	set countPfsFormIds(val) {
		this.fg.get('countPfsFormIds').setValue(val);
	}

	get pfsUrl(): string {
		return this.fg.get('pfsUrl').value;
	}
	set pfsUrl(val) {
		this.fg.get('pfsUrl').setValue(val);
	}

	get currentStep(): number {
		return this.fg.get('currentStep').value;
	}
	set currentStep(val) {
		this.fg.get('currentStep').setValue(val);
	}
}
