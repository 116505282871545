import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class MetabolicFormulaPatientCareVm {
	patientCareId: number;
	diagnosis?: string;
	diagnosisId?: number;
	comments?: string;
	physicianFirstName?: string;
	physicianLastName?: string;
	licenseNumber?: string;
	friendlyId?: string;
	stepName?: string;
	canEdit?: boolean;
	orderId: number;
	stepTitle?: string;
	stepDescription?: string;
	isActive: boolean;
	isComplete: boolean;
	orderIndex: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'patientCareId': new UntypedFormControl(0, [...(validators['patientCareId'] ?? []), ...[Validators.required]]),
			'diagnosis': new UntypedFormControl(null, [...(validators['diagnosis'] ?? []), ...[Validators.maxLength(500), Validators.required]]),
			'diagnosisId': new UntypedFormControl(null, [...(validators['diagnosisId'] ?? []), ...[]]),
			'comments': new UntypedFormControl(null, [...(validators['comments'] ?? []), ...[Validators.maxLength(500)]]),
			'physicianFirstName': new UntypedFormControl(null, [...(validators['physicianFirstName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'physicianLastName': new UntypedFormControl(null, [...(validators['physicianLastName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'licenseNumber': new UntypedFormControl(null, [...(validators['licenseNumber'] ?? []), ...[Validators.required, Validators.maxLength(25)]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'stepName': new UntypedFormControl(null, [...(validators['stepName'] ?? []), ...[]]),
			'canEdit': new UntypedFormControl(null, [...(validators['canEdit'] ?? []), ...[]]),
			'orderId': new UntypedFormControl(0, [...(validators['orderId'] ?? []), ...[Validators.required]]),
			'stepTitle': new UntypedFormControl(null, [...(validators['stepTitle'] ?? []), ...[]]),
			'stepDescription': new UntypedFormControl(null, [...(validators['stepDescription'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isComplete': new UntypedFormControl(false, [...(validators['isComplete'] ?? []), ...[]]),
			'orderIndex': new UntypedFormControl(0, [...(validators['orderIndex'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): MetabolicFormulaPatientCareVmFromFg {
		return new MetabolicFormulaPatientCareVmFromFg(fg);
	}
}

export class MetabolicFormulaPatientCareVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get patientCareId(): number {
		return this.fg.get('patientCareId').value;
	}
	set patientCareId(val) {
		this.fg.get('patientCareId').setValue(val);
	}

	get diagnosis(): string {
		return this.fg.get('diagnosis').value;
	}
	set diagnosis(val) {
		this.fg.get('diagnosis').setValue(val);
	}

	get diagnosisId(): number {
		return this.fg.get('diagnosisId').value;
	}
	set diagnosisId(val) {
		this.fg.get('diagnosisId').setValue(val);
	}

	get comments(): string {
		return this.fg.get('comments').value;
	}
	set comments(val) {
		this.fg.get('comments').setValue(val);
	}

	get physicianFirstName(): string {
		return this.fg.get('physicianFirstName').value;
	}
	set physicianFirstName(val) {
		this.fg.get('physicianFirstName').setValue(val);
	}

	get physicianLastName(): string {
		return this.fg.get('physicianLastName').value;
	}
	set physicianLastName(val) {
		this.fg.get('physicianLastName').setValue(val);
	}

	get licenseNumber(): string {
		return this.fg.get('licenseNumber').value;
	}
	set licenseNumber(val) {
		this.fg.get('licenseNumber').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get stepName(): string {
		return this.fg.get('stepName').value;
	}
	set stepName(val) {
		this.fg.get('stepName').setValue(val);
	}

	get canEdit(): boolean {
		return this.fg.get('canEdit').value;
	}
	set canEdit(val) {
		this.fg.get('canEdit').setValue(val);
	}

	get orderId(): number {
		return this.fg.get('orderId').value;
	}
	set orderId(val) {
		this.fg.get('orderId').setValue(val);
	}

	get stepTitle(): string {
		return this.fg.get('stepTitle').value;
	}
	set stepTitle(val) {
		this.fg.get('stepTitle').setValue(val);
	}

	get stepDescription(): string {
		return this.fg.get('stepDescription').value;
	}
	set stepDescription(val) {
		this.fg.get('stepDescription').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isComplete(): boolean {
		return this.fg.get('isComplete').value;
	}
	set isComplete(val) {
		this.fg.get('isComplete').setValue(val);
	}

	get orderIndex(): number {
		return this.fg.get('orderIndex').value;
	}
	set orderIndex(val) {
		this.fg.get('orderIndex').setValue(val);
	}
}
