import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';
import { CiOrderJobVm, CiOrderJobVmFromFg } from '../../../CIRepack/Models/CiRepackOrderJobs/CiOrderJobVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CiOrderInfoVm {
	ciOrderId: number;
	ciFacility?: any;
	facilityId: number;
	pfsFacilityId?: number;
	ciOrderStatus?: any;
	ciOrderStatusString?: string;
	createOrderStep?: any;
	createOrderStepString?: string;
	orderNumber?: string;
	orderDateStr?: string;
	orderDate: Date;
	createdByUser?: string;
	createdByUserEmail?: string;
	createdUserId: number;
	facility?: string;
	facilityAddress?: string;
	facilityCity?: string;
	facilityState?: string;
	facilityZip?: string;
	facilityIdentifier?: string;
	permit50?: string;
	licenseNumber?: string;
	friendlyId?: string;
	commentCount: number;
	shipSmartyAddress?: string;
	allJobsCompleted: boolean;
	availableStatuses?: any;
	receivedStatuses?: any;
	permitNumber?: string;
	pfsCiOrderId?: number;
	productsCount: number;
	bottlesCount: number;
	productsReceivedCount: number;
	bottlesReceivedCount: number;
	products?: any;
	ciOrderJobs?: CiOrderJobVm[];
	invoiceSecurityStamp?: string;
	canGenerateCiInvoices: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'ciOrderId': new UntypedFormControl(0, [...(validators['ciOrderId'] ?? []), ...[Validators.required]]),
			'ciFacility': new UntypedFormControl(null, [...(validators['ciFacility'] ?? []), ...[]]),
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'pfsFacilityId': new UntypedFormControl(null, [...(validators['pfsFacilityId'] ?? []), ...[]]),
			'ciOrderStatus': new UntypedFormControl('', [...(validators['ciOrderStatus'] ?? []), ...[Validators.required]]),
			'ciOrderStatusString': new UntypedFormControl(null, [...(validators['ciOrderStatusString'] ?? []), ...[]]),
			'createOrderStep': new UntypedFormControl('', [...(validators['createOrderStep'] ?? []), ...[Validators.required]]),
			'createOrderStepString': new UntypedFormControl(null, [...(validators['createOrderStepString'] ?? []), ...[]]),
			'orderNumber': new UntypedFormControl(null, [...(validators['orderNumber'] ?? []), ...[]]),
			'orderDateStr': new UntypedFormControl(null, [...(validators['orderDateStr'] ?? []), ...[]]),
			'orderDate': new UntypedFormControl('', [...(validators['orderDate'] ?? []), ...[Validators.required]]),
			'createdByUser': new UntypedFormControl(null, [...(validators['createdByUser'] ?? []), ...[]]),
			'createdByUserEmail': new UntypedFormControl(null, [...(validators['createdByUserEmail'] ?? []), ...[]]),
			'createdUserId': new UntypedFormControl(0, [...(validators['createdUserId'] ?? []), ...[Validators.required]]),
			'facility': new UntypedFormControl(null, [...(validators['facility'] ?? []), ...[]]),
			'facilityAddress': new UntypedFormControl(null, [...(validators['facilityAddress'] ?? []), ...[]]),
			'facilityCity': new UntypedFormControl(null, [...(validators['facilityCity'] ?? []), ...[]]),
			'facilityState': new UntypedFormControl(null, [...(validators['facilityState'] ?? []), ...[]]),
			'facilityZip': new UntypedFormControl(null, [...(validators['facilityZip'] ?? []), ...[]]),
			'facilityIdentifier': new UntypedFormControl(null, [...(validators['facilityIdentifier'] ?? []), ...[]]),
			'permit50': new UntypedFormControl(null, [...(validators['permit50'] ?? []), ...[]]),
			'licenseNumber': new UntypedFormControl(null, [...(validators['licenseNumber'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'commentCount': new UntypedFormControl(0, [...(validators['commentCount'] ?? []), ...[Validators.required]]),
			'shipSmartyAddress': new UntypedFormControl(null, [...(validators['shipSmartyAddress'] ?? []), ...[]]),
			'allJobsCompleted': new UntypedFormControl(false, [...(validators['allJobsCompleted'] ?? []), ...[]]),
			'availableStatuses': new UntypedFormControl([], [...(validators['availableStatuses'] ?? []), ...[]]),
			'receivedStatuses': new UntypedFormControl([], [...(validators['receivedStatuses'] ?? []), ...[]]),
			'permitNumber': new UntypedFormControl(null, [...(validators['permitNumber'] ?? []), ...[]]),
			'pfsCiOrderId': new UntypedFormControl(null, [...(validators['pfsCiOrderId'] ?? []), ...[]]),
			'productsCount': new UntypedFormControl(0, [...(validators['productsCount'] ?? []), ...[Validators.required]]),
			'bottlesCount': new UntypedFormControl(0, [...(validators['bottlesCount'] ?? []), ...[Validators.required]]),
			'productsReceivedCount': new UntypedFormControl(0, [...(validators['productsReceivedCount'] ?? []), ...[Validators.required]]),
			'bottlesReceivedCount': new UntypedFormControl(0, [...(validators['bottlesReceivedCount'] ?? []), ...[Validators.required]]),
			'products': new UntypedFormControl([], [...(validators['products'] ?? []), ...[]]),
			'ciOrderJobs': new UntypedFormArray([]),
			'invoiceSecurityStamp': new UntypedFormControl(null, [...(validators['invoiceSecurityStamp'] ?? []), ...[]]),
			'canGenerateCiInvoices': new UntypedFormControl(false, [...(validators['canGenerateCiInvoices'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): CiOrderInfoVmFromFg {
		return new CiOrderInfoVmFromFg(fg);
	}
}

export class CiOrderInfoVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get ciOrderId(): number {
		return this.fg.get('ciOrderId').value;
	}
	set ciOrderId(val) {
		this.fg.get('ciOrderId').setValue(val);
	}

	get ciFacility(): any {
		return this.fg.get('ciFacility').value;
	}
	set ciFacility(val) {
		this.fg.get('ciFacility').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get pfsFacilityId(): number {
		return this.fg.get('pfsFacilityId').value;
	}
	set pfsFacilityId(val) {
		this.fg.get('pfsFacilityId').setValue(val);
	}

	get ciOrderStatus(): any {
		return this.fg.get('ciOrderStatus').value;
	}
	set ciOrderStatus(val) {
		this.fg.get('ciOrderStatus').setValue(val);
	}

	get ciOrderStatusString(): string {
		return this.fg.get('ciOrderStatusString').value;
	}
	set ciOrderStatusString(val) {
		this.fg.get('ciOrderStatusString').setValue(val);
	}

	get createOrderStep(): any {
		return this.fg.get('createOrderStep').value;
	}
	set createOrderStep(val) {
		this.fg.get('createOrderStep').setValue(val);
	}

	get createOrderStepString(): string {
		return this.fg.get('createOrderStepString').value;
	}
	set createOrderStepString(val) {
		this.fg.get('createOrderStepString').setValue(val);
	}

	get orderNumber(): string {
		return this.fg.get('orderNumber').value;
	}
	set orderNumber(val) {
		this.fg.get('orderNumber').setValue(val);
	}

	get orderDateStr(): string {
		return this.fg.get('orderDateStr').value;
	}
	set orderDateStr(val) {
		this.fg.get('orderDateStr').setValue(val);
	}

	get orderDate(): Date {
		return this.fg.get('orderDate').value;
	}
	set orderDate(val) {
		this.fg.get('orderDate').setValue(val);
	}

	get createdByUser(): string {
		return this.fg.get('createdByUser').value;
	}
	set createdByUser(val) {
		this.fg.get('createdByUser').setValue(val);
	}

	get createdByUserEmail(): string {
		return this.fg.get('createdByUserEmail').value;
	}
	set createdByUserEmail(val) {
		this.fg.get('createdByUserEmail').setValue(val);
	}

	get createdUserId(): number {
		return this.fg.get('createdUserId').value;
	}
	set createdUserId(val) {
		this.fg.get('createdUserId').setValue(val);
	}

	get facility(): string {
		return this.fg.get('facility').value;
	}
	set facility(val) {
		this.fg.get('facility').setValue(val);
	}

	get facilityAddress(): string {
		return this.fg.get('facilityAddress').value;
	}
	set facilityAddress(val) {
		this.fg.get('facilityAddress').setValue(val);
	}

	get facilityCity(): string {
		return this.fg.get('facilityCity').value;
	}
	set facilityCity(val) {
		this.fg.get('facilityCity').setValue(val);
	}

	get facilityState(): string {
		return this.fg.get('facilityState').value;
	}
	set facilityState(val) {
		this.fg.get('facilityState').setValue(val);
	}

	get facilityZip(): string {
		return this.fg.get('facilityZip').value;
	}
	set facilityZip(val) {
		this.fg.get('facilityZip').setValue(val);
	}

	get facilityIdentifier(): string {
		return this.fg.get('facilityIdentifier').value;
	}
	set facilityIdentifier(val) {
		this.fg.get('facilityIdentifier').setValue(val);
	}

	get permit50(): string {
		return this.fg.get('permit50').value;
	}
	set permit50(val) {
		this.fg.get('permit50').setValue(val);
	}

	get licenseNumber(): string {
		return this.fg.get('licenseNumber').value;
	}
	set licenseNumber(val) {
		this.fg.get('licenseNumber').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get commentCount(): number {
		return this.fg.get('commentCount').value;
	}
	set commentCount(val) {
		this.fg.get('commentCount').setValue(val);
	}

	get shipSmartyAddress(): string {
		return this.fg.get('shipSmartyAddress').value;
	}
	set shipSmartyAddress(val) {
		this.fg.get('shipSmartyAddress').setValue(val);
	}

	get allJobsCompleted(): boolean {
		return this.fg.get('allJobsCompleted').value;
	}
	set allJobsCompleted(val) {
		this.fg.get('allJobsCompleted').setValue(val);
	}

	get permitNumber(): string {
		return this.fg.get('permitNumber').value;
	}
	set permitNumber(val) {
		this.fg.get('permitNumber').setValue(val);
	}

	get pfsCiOrderId(): number {
		return this.fg.get('pfsCiOrderId').value;
	}
	set pfsCiOrderId(val) {
		this.fg.get('pfsCiOrderId').setValue(val);
	}

	get productsCount(): number {
		return this.fg.get('productsCount').value;
	}
	set productsCount(val) {
		this.fg.get('productsCount').setValue(val);
	}

	get bottlesCount(): number {
		return this.fg.get('bottlesCount').value;
	}
	set bottlesCount(val) {
		this.fg.get('bottlesCount').setValue(val);
	}

	get productsReceivedCount(): number {
		return this.fg.get('productsReceivedCount').value;
	}
	set productsReceivedCount(val) {
		this.fg.get('productsReceivedCount').setValue(val);
	}

	get bottlesReceivedCount(): number {
		return this.fg.get('bottlesReceivedCount').value;
	}
	set bottlesReceivedCount(val) {
		this.fg.get('bottlesReceivedCount').setValue(val);
	}

	get invoiceSecurityStamp(): string {
		return this.fg.get('invoiceSecurityStamp').value;
	}
	set invoiceSecurityStamp(val) {
		this.fg.get('invoiceSecurityStamp').setValue(val);
	}

	get canGenerateCiInvoices(): boolean {
		return this.fg.get('canGenerateCiInvoices').value;
	}
	set canGenerateCiInvoices(val) {
		this.fg.get('canGenerateCiInvoices').setValue(val);
	}
}
