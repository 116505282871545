@if (!hasOrgs) {
	<div class="alert alert-danger text-center">
		In order to see this list you must be associated to at least one Organization.
		Please contact an administrator for assistance.
	</div>
}

<ng-container>
	<div id="informativeContent">
		<div id="actionElements">
			<button 
				class="btn btn-save btn-sm inline-add-btn" 
				(click)="navigateToNewOrganization()"
			>Add New</button>
		</div>
		<div id="actionElements">
			<mat-button-toggle-group 
				(change)="getGroupedByCountyList();" 
				[value]="true"
			>
				<mat-button-toggle 
					class="flex-fill" 
					[value]="true"
				>Organization View</mat-button-toggle>
				<mat-button-toggle 
					class="flex-fill" 
					[value]="false"
				>County View</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</div>
	
	@if (groupByCounty) {
		<div class="table-responsive">
			<table class="data-grid">
				<thead>
					<tr>
						<th>
							<div 
								class="expand-button" 
								(click)="collapsibleTableService.toggleAllRows(orgList.length)"
							>{{ collapsibleTableService.expandedRows.size > 0 ? '－' : '＋' }}</div>
						</th>
						<th 
							class="text-right" 
							style="width: 10%;"
						>Organizations</th>
						<th>County</th>
						<th>Address</th>
						<th 
							class="text-right" 
							style="width: 10%;"
						>Facilities</th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let row of filterParents(); let i = index">
						<tr class="county-row">
							<td class="text-nowrap">
								<div 
									(click)="collapsibleTableService.toggleRow(i)" 
									class="expand-button"
								>{{ collapsibleTableService.expandedOrCollapsed(i) === 'expanded' ? '－' : '＋' }}</div>
							</td>
							<td 
								class="text-right" 
								style="width: 10%;"
							>{{ row.orgCount }}</td>
							<td>{{ row.county }}</td>
							<td></td>
							<td 
								class="text-right" 
								style="width: 10%;"
							>{{ row.facilityCount }}</td>
						</tr>
						<ng-container *ngFor="let rowchild of filterChildren(row);">
							<tr class="child-row">
								<td 
									class="detail-expand" 
									[@detailExpand]="collapsibleTableService.expandedOrCollapsed(i)"
								></td>
								<td 
									class="detail-expand" 
									[@detailExpand]="collapsibleTableService.expandedOrCollapsed(i)"
								></td>
								<td 
									class="detail-expand" 
									[@detailExpand]="collapsibleTableService.expandedOrCollapsed(i)"
								>
									<pcg-edit-link 
										[hasAccess]='canEditOrganization()' 
										[url]="editOrganizationLink() + rowchild.organizationId"
									>{{ rowchild.organizationName }}</pcg-edit-link>
								</td>
								<td 
									class="detail-expand" 
									[@detailExpand]="collapsibleTableService.expandedOrCollapsed(i)"
								>{{ rowchild.address }}, {{ rowchild.city }}, {{ rowchild.state }} {{ rowchild.zip }}</td>
								<td 
									class="detail-expand text-right" 
									[@detailExpand]="collapsibleTableService.expandedOrCollapsed(i)"
								>{{ rowchild.facilityCount }}</td>
							</tr>
						</ng-container>
					</ng-container>
				</tbody>
			</table>
		</div>
	} @else {
		<div>
			<pcg-table-filter id='organizationListFilters'>
				<div (change)="reloadTable();">
					<div class="additional-info mt-1">
						<div class='mt-2 filter-width'>
							<label class='for'>Is Active?</label>
							<mat-button-toggle-group 
								[(ngModel)]='filters.isActive' 
								[value]='filters.isActive' 
								(change)="table.ajaxReload();"
							>
								<mat-button-toggle 
									class="flex-fill" 
									[value]="true"
								>Yes</mat-button-toggle>
								<mat-button-toggle 
									class="flex-fill mx-2" 
									value="false"
								>No</mat-button-toggle>
								<mat-button-toggle 
									class="flex-fill" 
									value="null"
								>All</mat-button-toggle>
							</mat-button-toggle-group>
						</div>

						<div class='mt-2 filter-width'>
							<label class='for'>Medical Director</label>
							<pcg-select 
								[multiple]='false' 
								[(ngModel)]='filters.medicalDirectorId' 
								autocomplete='off'
								dataSource='api/Select/MedicalDirectors' 
								placeholder='Select...'
								class="select"
							></pcg-select>
						</div>

						<div class='mt-2 filter-width'>
							<label class='for'>Facility</label>
							<pcg-select 
								[multiple]='false' 
								[(ngModel)]='filters.facilityId' 
								autocomplete='off'
								dataSource='api/Select/UserFacilities' 
								placeholder='Select...'
								class="select"
							></pcg-select>
						</div>
					</div>
				</div>
			</pcg-table-filter>

			<pcg-table 
				#table 
				[ajaxData]='filters' 
				[multipleSearch]='false' 
				[pcgSort]='[[ "organizationName", "asc" ]]' 
				[filterMap]="filterMap"
				dataSource='api/Administration/Organization/GetOrganizationAjaxList' 
				[columnDefs]='columns' 
				filterId="organizationListFilters"
				[canShowHideColumns]="true" 
				[canExactMatchSearch]="false" 
				[canResetFilters]="true"
				[emptyFilters]="initFilters"
				(tableReceive)="tableCallback($event)"
			>

				<ng-container matColumnDef="organizationName">
					<th 
						mat-header-cell 
						pcg-sort-header 
						*matHeaderCellDef
					>{{ columns.get('organizationName').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef='let row'
					>
						<pcg-edit-link 
							[hasAccess]='canEditOrganization()' 
							[url]="editOrganizationLink() + row.organizationId"
						>{{ row.organizationName }}</pcg-edit-link>
					</td>
				</ng-container>

				<ng-container matColumnDef="address">
					<th 
						mat-header-cell 
						pcg-sort-header 
						*matHeaderCellDef
					>{{ columns.get('address').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef='let row'
					>{{ row.address }}, {{ row.city }}, {{ row.state }} {{ row.zip }}</td>
				</ng-container>

				<ng-container matColumnDef="city">
					<th 
						mat-header-cell 
						pcg-sort-header 
						*matHeaderCellDef
					>{{ columns.get('city').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef='let row'
					>{{ row.city }}</td>
				</ng-container>

				<ng-container matColumnDef="county">
					<th 
						mat-header-cell 
						pcg-sort-header 
						*matHeaderCellDef
					>{{ columns.get('county').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef='let row'
					>{{ row.county }}</td>
				</ng-container>

				<ng-container matColumnDef="isActive">
					<th 
						mat-header-cell 
						pcg-sort-header 
						*matHeaderCellDef
					>{{ columns.get('isActive').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef='let row'
					>{{ row.isActive }}</td>
				</ng-container>

				<ng-container matColumnDef="facilityCount">
					<th 
						mat-header-cell 
						pcg-sort-header 
						*matHeaderCellDef
						class="text-right"
					>{{ columns.get('facilityCount').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef='let row'
						class="text-right"
					>{{ row.facilityCount }}</td>
				</ng-container>

				<ng-container matColumnDef="canDelete">
					<th 
						class='text-center' 
						mat-header-cell 
						*matHeaderCellDef
						ngbTooltip="Only Organizations with no child Facilities can be deleted."
					>Delete</th>
					<td 
						class='text-center' 
						mat-cell 
						*matCellDef='let row'
					>
						@if (
							canDelete 
							&& row.canDelete
						) {
							<pcg-delete-button 
								[table]='table' 
								[confirmMessage]="'Are you sure you want to delete this Organization?'"
								url='api/Administration/Organization/DeleteOrganization' 
								[id]='row.organizationId'
							></pcg-delete-button>
						}
					</td>
				</ng-container>

			</pcg-table>
		</div>
	}	
</ng-container>