<ng-container *ngIf='!isCreate; else createUserForm;'>
	<div id="informativeContent">
		<div *ngIf='canCreateUser' id="actionElements">
			<a (click)="createUser()" class='btn btn-save btn-sm inline-add-btn'>Create New User</a>
		</div>
		<div *ngIf='canAddUser' id="actionElements">
			<a (click)="addUserBtn()" class='btn btn-save btn-sm inline-add-btn'>Add Existing User</a>
		</div>
	</div>

	<ng-template #fullNameTooltip>
	<div class="text-left">
		<fa-icon 
			[icon]="faIconName.faUserLock" 
			style="color: red; cursor: none"
		></fa-icon>&nbsp;&nbsp;
		<span>
			Locked Users: These users have entered an incorrect password 5 consecutive times. 
			Click the Unlock button on their profile to unlock their account and send them a reset password email.
		</span> 
		<br />
		<fa-icon 
			[icon]="faIconName.faEnvelope" 
			style="color: red; cursor: none"
		></fa-icon>&nbsp;&nbsp;
		<span>Email needs manual validation:  Our automated validation tool flagged this email.  Click the Validate Email button on their profile.</span> 
		<br />
		<fa-icon 
			[icon]="faIconName.faTennisBall" 
			style="color: red; cursor: none"
		></fa-icon>&nbsp;&nbsp;
		<span>
			Undeliverable Email: Usually indicates a permanent error, but can also be caused by a strict email security filter.
			You may need to reach out to your IT to whitelist our domain. Click the Unbounce Email button on their profile.
		</span> 
		<br />
		<!-- TODO: Implement this property after adding user deactivation quartz jobs -->
		<!-- <fa-icon 
			[icon]="faIconName.faHourglassClock" 
			style="color: red; cursor: none"
		></fa-icon>&nbsp;&nbsp;
		<span>
			This user has been deactivated due to 180 days of not logging in or verifying a medication use, not completing their setup within 90 days, 
			or no welcome email has been sent in 180 days since this user was created.
			Click the Reactivate button on their profile.
		</span>
		<br /> -->
		<fa-icon 
			[icon]="faIconName.faEnvelopesBulk" 
			style="color: red; cursor: none"
		></fa-icon>&nbsp;&nbsp;
		<span>
			Undeliverable Email due to Spam Report: The user has reported one of our emails as spam.
			Click the Undo Spam button on their profile.
		</span> 
	</div>
</ng-template>

	<pcg-table-filter id='userListFilters'>
		<div (change)="reloadTable();">
			<div class="additional-info mt-1">
				<div class='mt-2'>
					<label class='for'>Show System Admins?</label>
					<mat-button-toggle-group 
						[(ngModel)]='filters.showSysAdmin' 
						[value]="filters.showSysAdmin" 
						(change)="reloadTable()"
					>
						<mat-button-toggle class="flex-fill" [value]="true">Yes</mat-button-toggle>
						<mat-button-toggle class="flex-fill mx-2" [value]="false">No</mat-button-toggle>
					</mat-button-toggle-group>
				</div>
			</div>
		</div>
	</pcg-table-filter>

	<pcg-table 
		[pcgSort]="[['name', 'asc']]" 
		dataSource="api/Administration/Facility/GetFacilityUsers"
		[columnDefs]="columns" 
		[ajaxData]="filters"
		[canResetFilters]="true"
		filterId="userListFilters"
		[emptyFilters]="initFilters"
		[filterMap]="filterMap"
		[hasTooltips]="true"
		[showTooltipsBtn]="true"
		[tooltipContent]="tooltipModalString"
	>

		<ng-container matColumnDef="name">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>
			<span 
				container='body' 
				ngbTooltip="fullNameTooltip" 
				[ngbTooltip]="fullNameTooltip" 
				ng-click="fullNameTooltip" 
				placement="right" 
			>{{ columns.get('name').displayName }}</span>
			</th>
			<td mat-cell *matCellDef="let row">
				<a *ngIf='canEditUser' [href]="getEditUserLink(row.id)">{{ row.name }}</a>
				<span *ngIf='!canEditUser'>{{ row.name }}</span>
				<fa-icon 
					*ngIf="row.isLockedOut === true" 
					[icon]="faIconName.faUserLock" 
					class="unlockIcon"
					ngbTooltip="Locked out"
				></fa-icon>
				<fa-icon 
					*ngIf="row.emailConfirmed === false" 
					[icon]="faIconName.faEnvelope" 
					class="unlockIcon"
					ngbTooltip="Invalid email"
				></fa-icon>
				<fa-icon 
					*ngIf="row.isEmailBounced === true" 
					[icon]="faIconName.faTennisBall" 
					class="unlockIcon"
					ngbTooltip="Bounced email"
				></fa-icon>
				<!-- TODO: Implement this property after adding user deactivation quartz jobs -->
				<!-- <fa-icon 
					*ngIf="row.deactivatedDueToInactivity === true" 
					[icon]="faIconName.faHourglassClock" 
					class="unlockIcon"
					ngbTooltip="Deactivated due to inactivity"
				></fa-icon> -->
				<fa-icon 
					*ngIf="row.isEmailSpamReported === true" 
					[icon]="faIconName.faEnvelopesBulk" 
					class="unlockIcon"
					ngbTooltip="User reported us as spam"
				></fa-icon>
			</td>
		</ng-container>

		<ng-container matColumnDef="email">
			<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('email').displayName }}</th>
			<td mat-cell *matCellDef="let row">{{ row.email }}</td>
		</ng-container>

		<ng-container matColumnDef="phoneNumber">
			<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('phoneNumber').displayName }}</th>
			<td mat-cell *matCellDef="let row">{{ row.phoneNumber }}</td>
		</ng-container>

	</pcg-table>
</ng-container>

<ng-template #createUserForm>
	<form 
		pcgSubmitUrl="api/Administration/Users/SaveUser" 
		(messageReceive)="saveUser($event)" 
		[formGroup]="formGroup"
	>
		<input type="hidden" formControlName="userId" />
		<input type="hidden" formControlName="isActive" />
		<input type="hidden" formControlName="inactivityTimeout" />
		<input type="hidden" formControlName="isInternalBphpUser" />
		<input type="hidden" formControlName="updateOrgs" />
		<input type="hidden" formControlName="updateFacilities" />

		<div class="row">
			<h2 class="col-md-9">Create User</h2>
		</div>
				<pcg-page-info>New users will not show up in the facility associated user list until they possess module access to at least one of the business areas assigned to this facilities. Upon creation, please set this in the user's profile.</pcg-page-info>
		<hr />

		<div class='row'>
			<pcg-control-group class="col-md-4" label="First Name">
				<input pcgControlRef class='form-control' type='text' formControlName="firstName" />
			</pcg-control-group>

			<pcg-control-group class="col-md-4" label="Last Name">
				<input pcgControlRef class='form-control' type='text' formControlName="lastName" />
			</pcg-control-group>

			<pcg-control-group class="col-md-4" label="Username">
				<input pcgControlRef class='form-control' type='text' formControlName="userName" />
			</pcg-control-group>
		</div>

		<div class='row'>
			<pcg-control-group class="col-md-4" label="Email">
				<input pcgControlRef class='form-control email' type='text' formControlName="email" />
			</pcg-control-group>

			<pcg-control-group class="col-md-4" label="Phone">
				<input pcgPhoneExt pcgControlRef class='form-control' type='text' formControlName="phoneNumber" />
			</pcg-control-group>
		</div>

		<div *ngIf="
				!canEditFacilities 
				|| userId === 0
			" class="container">
			<input type="hidden" formControlName="facilityIds" pcgControlRef />
		</div>

		<div class='bottom-buttons'>
			<br />
			<a class='btn btn-cancel btn-lg' (click)="cancelAdd()">Cancel</a>
			<button class='btn btn-save btn-lg'>Create</button>
		</div>
	</form>
</ng-template>

<ng-template #modalExistingUsersModal let-modal>
	<div id="modalExistingUsers" class="modal-content">
		<div class='modal-header'>
			<h5 class='modal-title' id='modal-basic-title'>Add an Existing User</h5>
			<button type='button' class='close' aria-label='Close' (click)='modal.dismiss()'><span
					aria-hidden='true'>&times;</span></button>
		</div>

		<div class='modal-body'>
			<pcg-table #existingUserTable id="existingUserTable" [multipleSearch]='false' [canShowHideColumns]="false"
				[canExactMatchSearch]="false" [canResetFilters]="false" [pcgSort]='[[ "firstName", "asc" ]]'
				[pageSize]='10' [pageLengths]='[5,10]' dataSource='api/Administration/Facility/GetUsersToAdd'
				[columnDefs]='columns' [ajaxData]="filters">

				<ng-container matColumnDef="name">
					<th style="top:0" mat-header-cell *matHeaderCellDef>{{ columns.get('name').displayName }}</th>
					<td mat-cell *matCellDef="let row">
						<a [href]="getEditUserLink(row.id)">{{ row.name }}</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th style="top:0" mat-header-cell *matHeaderCellDef>{{ columns.get('email').displayName }}</th>
					<td mat-cell *matCellDef="let row">{{ row.email }}</td>
				</ng-container>

				<ng-container matColumnDef="phoneNumber">
					<th style="top:0" class='text-center' mat-header-cell *matHeaderCellDef>Add</th>
					<td class='text-center' mat-cell *matCellDef='let row'>
						<img (click)='addExistingUser(row.id, existingUserTable)' style="width: 25px; cursor: pointer;"
							src="assets/images/actions/Add.svg" />
					</td>
				</ng-container>

			</pcg-table>

		</div>
	</div>
</ng-template>