import { faBoxesPacking } from '@fortawesome/pro-solid-svg-icons';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getCiRepackNav: () => NavRoute = () => {
	const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.

	if (user !== null) {
		var navItems: NavRoute[] = [
			{
				name: 'Dashboard'
				, exact: true
				, path: 'ci-repack/dashboard'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'ci-repack/dashboard'
				, secondaryNav: ['CI Repack', 'Dashboard']
			}
			, {
				name: "KPI's"
				, exact: true
				, path: 'ci-repack/kpis'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.Technician)
					}
				]
				, activeNavLink: 'ci-repack/kpis'
				, secondaryNav: ['CI Repack', 'KPI\'s']
			}
			, {
				name: 'Global Orders'
				, exact: true
				, path: 'ci-repack/orders/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'ci-repack/orders'
				, secondaryNav: ['CI Repack', 'Global Orders']
			}
			, {
				name: 'Global Jobs'
				, exact: true
				, path: 'ci-repack/jobs/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'ci-repack/jobs'
				, secondaryNav: ['CI Repack', 'Global Jobs']
			}
			, {
				name: 'Repack Invoices'
				, exact: true
				, path: 'ci-repack/invoices'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.Manager)
					}
				]
				, activeNavLink: 'ci-repack/invoices'
				, secondaryNav: ['CI Repack', 'Repack Invoices']
			}
			, {
				name: 'Bottle Log'
				, exact: true
				, path: 'ci-repack/bottle-log'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: sec.setMinRole(p.Technician)
					}
				]
				, activeNavLink: 'ci-repack/bottle-log'
				, secondaryNav: ['CI Repack', 'Bottle Log']
			}
			, {
				name: 'Facility List'
				, exact: true
				, path: 'ci-repack/facilities/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.CI ]
						, permissionRoles: [
							p.UserAdmin
							, ...sec.setMinRole(p.Manager)
						]
					}
				]
				, activeNavLink: 'ci-repack/facilities'
				, secondaryNav: ['CI Repack', 'Facility List']
			}
		];
		
		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.CI)) {
			navItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ErrorReport ]
						, permissionRoles: [ p.User ]
					}
				]
				, exact: true
				, path: `error-report/error-reports/ci-repack/list/${BusinessAreaEnum.CI}`
				, activeNavLink: 'error-report/error-reports/ci-repack'
				, secondaryNav: ['CI Repack', 'Error Reporting']
			});
		}

		if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.CI)) {
			navItems.push({
				name: 'Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Reports ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, exact: true
				, path: `reporting/list/${BusinessAreaEnum.CI}`
				, activeNavLink: 'reporting/list'
				, secondaryNav: ['CI Repack', 'Reporting']
			});
		}

		navItems.push({
			name: 'Admin'
			, href: 'ci-repack/admin/settings'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.CI ]
					, permissionRoles: sec.setMinRole(p.User)
				}
			]
			, children: [
				{
					name: 'CI Settings'
					, exact: true
					, path: 'ci-repack/admin/settings'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'ci-repack/admin/settings'
					, secondaryNav: ['CI Repack', 'Admin', 'CI Settings']
				}
				, {
					name: 'Invoicing Costs'
					, exact: true
					, path: 'ci-repack/admin/invoice-costs'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.Pharmacist)
						}
					]
					, activeNavLink: 'ci-repack/admin/invoice-costs'
					, secondaryNav: ['CI Repack', 'Admin', 'Invoicing Costs']
				}
				, {
					name: 'Plates'
					, exact: true
					, path: 'ci-repack/admin/plates/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: sec.setMinRole(p.Technician)
						}
					]
					, activeNavLink: 'ci-repack/admin/plates'
					, secondaryNav: ['CI Repack', 'Admin', 'Plates']
				}
				, {
					name: 'Workstations'
					, exact: true
					, path: 'ci-repack/admin/workstations/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'ci-repack/admin/workstations'
					, secondaryNav: ['CI Repack', 'Admin', 'Workstations']
				}
				, {
					name: 'Label Printers'
					, exact: true
					, path: 'ci-repack/admin/label-printers/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'ci-repack/admin/label-printers'
					, secondaryNav: ['CI Repack', 'Admin', 'Label Printers']
				}
				, {
					name: 'Office Printers'
					, exact: true
					, path: 'ci-repack/admin/office-printers/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'ci-repack/admin/office-printers'
					, secondaryNav: ['CI Repack', 'Admin', 'Office Printers']
				}
				, {
					name: 'Zebra Printers'
					, exact: true
					, path: 'ci-repack/admin/zebra-printers/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'ci-repack/admin/zebra-printers'
					, secondaryNav: ['CI Repack', 'Admin', 'Zebra Printers']
				}
				, {
					name: 'Boxes'
					, exact: true
					, path: 'ci-repack/admin/boxes/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.Manager ]
						}
					]
					, activeNavLink: 'ci-repack/admin/boxes'
					, secondaryNav: ['CI Repack', 'Admin', 'Boxes']
				}
				, {
					name: 'Corrected Card/Strip Reasons'
					, exact: true
					, path: 'ci-repack/admin/rework-reasons/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.Manager ]
						}
					]
					, activeNavLink: 'ci-repack/admin/rework-reasons'
					, secondaryNav: ['CI Repack', 'Admin', 'Corrected Card/Strip Reasons']
				}
				, {
					name: 'Verification Settings'
					, exact: true
					, path: 'ci-repack/admin/verification-settings'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.CI ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'ci-repack/admin/verification-settings'
					, secondaryNav: ['CI Repack', 'Admin', 'Verification Settings']
				}
			]
		});

		return {
			name: 'CI Repack'
			, canActivate: [ AuthGuard ]
			, href: 'ci-repack/dashboard'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.CI ]
					, permissionRoles: sec.setMinRole(p.User)
				}
			]
			, children: navItems
			, icon: faBoxesPacking
			, area: 'ci-repack'
			, module: BusinessAreaEnum.CI
		};
	}

	return { name: "" };
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
