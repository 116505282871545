<nav 
	role="none"
	id="pcgMainNavigation" 
	class='navbar'
>
	<!--#region Mobile header -->
	@if (
		isMobile 
		&& isLoggedOn
	) {
		<div 
			id='primaryNav' 
			class='d-flex align-items-center w-100'
		>
			<button
				aria-label="Open menu"
				[attr.aria-expanded]="this.currMainNavMenu !== ''"
				aria-controls="pcgTopClickNavEl"
				#mainHamburger
				(click)="toggleNav()"
				id="mainHamburger"
				class="btn"
			>
				<fa-icon 
					[icon]="faIconName.faBars" 
					class="hamburger-icon"
				></fa-icon>
			</button>
			<div 
				role="banner" 
				class="navbar-header" 
				[routerLink]="['/']"
			>
				HOMER
				<div 
					*ngIf="mode !== 'Production'" 
					class="beta-text"
				></div>
			</div>
		</div>
	}
	<!--#endregion-->

	<!--#region Secondary nav -->
	@if (
		navService.currNav() 
		&& navService.currNav().id !== 'root'
	) {
		<div 
			id='secondaryNav' 
			class="left-shift"
		>
			<div class="name-wrapper">
				<span class="current-section">{{ navService.dynamicNavTitle() }}</span> <!--breadcrumbs-->
			</div>
			
			<pcg-top-secondary-nav 
				role="navigation" 
				aria-label="Secondary" 
				[forceSelect]='currForceSelect' 
				[rootNavId]='"root" + navDividerCharacter + navService.currNav()?.id?.split(navDividerCharacter)[1]' 
				[routeNavId]="navService.currNav()?.id" 
				[navRoutes]='navService.currNav()?.children'
				[tabPanel]="tabPanel"
				(clickedNav)="setSubNavPosition()"
			></pcg-top-secondary-nav>			
		</div>
	}	
	<!--#endregion-->
	
	@if (hasCiAccess) {
		<div id='bannerDiv'>
			<pcg-workstation-banner></pcg-workstation-banner>
		</div>
	}

	@if (loading) {
		<mat-progress-bar 
			color="primary" 
			mode="determinate"
		></mat-progress-bar>
	}
</nav>