import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faBook, faFolder, faFolderOpen, faFile, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { GlobalService } from 'app/shared/services/global.service';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { KnowledgeBaseDocumentVm } from 'app/shared/generated/HelpDesk/Models/KnowledgeBaseDocumentVm';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SelectItem } from 'app/shared/models/select-item';

@Component({
    selector: 'pcg-knowledge-base',
    templateUrl: './knowledge-base.component.html',
    styleUrls: ['./knowledge-base.component.scss'],
    standalone: false
})
export class KnowledgeBaseComponent {

    formGroup = KnowledgeBaseDocumentVm.Form;

    knowledgeBaseModules: SelectItem[] = [];
    permissionItems: SelectItem[];

    // Font awesome
    faIconName = { faBook, faFolder, faFolderOpen, faFile, faXmark };

    docOpened = false;
    isMobile = false;
    isSupportAdmin = false;
    videoUrl: string; 
    hasSearchResults = true;

    // Module access variables
    hasAdminAccess = false;
    hasCiRepackAccess = false;
    hasHerosAccess = false;
	hasMetabolicFormulaAccess = false;
    hasNdcsAccess = false;
    hasPharmacyAccess = false;
    hasRxConnectsAccess = false;
    hasSupportAccess = false;   

    // File navigation
    showAdminSubMenu = false;
    showCiRepackSubMenu = false;
    showHerosSubMenu = false;
    showMetabolicSubMenu = false;
    showNdcSubMenu = false;
    showPfsSubMenu = false;
    showPharmacySubMenu = false;
    showRxConnectsSubMenu = false;
    showSupportSubMenu = false;
    showOtherSubMenu = false;

    // Document lists by module
    docList: Array<KnowledgeBaseDocumentVm>;
    adminDocList: Array<KnowledgeBaseDocumentVm>;
    ciRepackDocList: Array<KnowledgeBaseDocumentVm>;
    herosDocList: Array<KnowledgeBaseDocumentVm>;
    metabolicFormulaDocList: Array<KnowledgeBaseDocumentVm>;
    ndcsDocList: Array<KnowledgeBaseDocumentVm>;
    pfsDocList: Array<KnowledgeBaseDocumentVm>;
    pharmacyDocList: Array<KnowledgeBaseDocumentVm>;
    rxConnectsDocList: Array<KnowledgeBaseDocumentVm>;
    supportDocList: Array<KnowledgeBaseDocumentVm>;
    otherDocList: Array<KnowledgeBaseDocumentVm>;

    isCreateDoc = false;
    isEditDoc = false;
    currentDocument: KnowledgeBaseDocumentVm;
    canEdit = false;

    editorString: any;
    textEditor: any;

    isError = false;
    errorStr = "";
    
    constructor(
        title: Title
        , private httpClient: HttpClient
        , public ms: SystemMessageService
        , private sec: SecurityService
        , private gs: GlobalService
    ) { title.setTitle('Knowledge Base'); }

    // Keeps track if nav is docked or not
	isFixed = false;

    @HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth);  }

    ngOnInit() {
        this.isMobile = GlobalService.setIsMobile(window.innerWidth);
        this.getDocuments();
        this.setModuleAccesses();
        this.getModules();

        this.gs.isNavFixed.subscribe(
			r => { this.isFixed = r; }
		);
		this.isFixed = localStorage.getItem('isNavFixed') === 'true';
    }

    setModuleAccesses() {
        this.isSupportAdmin = this.sec?.hasModuleAccess(
            [BusinessAreaEnum.HelpDesk]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
        );

        // Checks for Admin Module Access Roles
        this.hasAdminAccess = this.sec?.hasModuleAccess(
            [BusinessAreaEnum.Admin]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
        );
        this.hasCiRepackAccess = this.sec?.hasModuleAccess(
			[BusinessAreaEnum.CI]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
		);
        this.hasHerosAccess = this.sec?.hasModuleAccess(
			[BusinessAreaEnum.HEROS]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
		);
		this.hasMetabolicFormulaAccess = this.sec?.hasModuleAccess(
			[BusinessAreaEnum.MetabolicFormula]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
		);
		this.hasNdcsAccess = this.sec?.hasModuleAccess(
			[BusinessAreaEnum.NDC]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
		);
        this.hasPharmacyAccess = this.sec?.hasModuleAccess(
			[BusinessAreaEnum.Inventory]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin))
		
        this.hasRxConnectsAccess = this.sec?.hasModuleAccess(
			[BusinessAreaEnum.RxConnects]
			, SecurityService.setMinRole(PermissionRole.RxConnectsAdmin)
		);
		this.hasSupportAccess = this.sec?.hasModuleAccess(
			[BusinessAreaEnum.HelpDesk]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
		);
    }

    setCanEdit() {
        this.canEdit = false;
        switch (this.currentDocument?.module) {
            case 1: 
                if (this.hasHerosAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 3: 
                if (this.hasNdcsAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 4:
                if (this.hasPharmacyAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 5: 
                if (this.hasMetabolicFormulaAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 6:
                if (this.hasCiRepackAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 10:
                if (this.hasAdminAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 11: 
                if (this.hasRxConnectsAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 12: 
                if (this.hasSupportAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 13: 
                if (this.hasAdminAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            case 14: 
                if (this.hasAdminAccess || this.isSupportAdmin) { this.canEdit = true; }
                break;
            default: 
                this.canEdit = false;
                break;
        }
    }

    // #region Document actions
    createDocument() {
        this.formGroup.reset();
        this.isCreateDoc = true;
        this.docOpened = true;
        this.formGroup.controls.documentId.setValue(0);
        this.editorString = "";
        this.isError = false;
        this.errorStr = "";
    }

    editDocument() {
        this.isEditDoc = true;
        this.formGroup.setValue(this.currentDocument);
        this.editorString = this.formGroup.controls.content.value;
        if (this.formGroup.controls.module.value != null) { this.getPermissions(); }
        this.isError = false;
        this.errorStr = "";
    }

    cancelDocumentEdit() { this.isEditDoc = false; }

    deleteDocument() {
        if (confirm("Are you sure you want to delete this record?")) {
            this.httpClient.get(`api/HelpDesk/KnowledgeBase/DeleteKnowledgeBaseDocument?id=${this.currentDocument.documentId}`)
                .subscribe((sm: SystemMessage) => {
                    if (sm?.isSuccessful) { 
                        this.docList = sm?.value;
                        this.upsertSortedDocuments();
                        this.closeDocument();
                        this.isError = false;
                        this.errorStr = "";
                    }
                    this.ms.setSystemMessage(sm.message, sm.messageClass);
                });
        }
    }

    openDocument(doc: KnowledgeBaseDocumentVm) {
        this.docOpened = true;
        this.isCreateDoc = false;
        this.isEditDoc = false;
        this.currentDocument = doc;
        this.isError = false;
        this.errorStr = "";
        if (this.currentDocument.videoId != null && this.currentDocument.videoId != "") {
            this.videoUrl = `https://player.vimeo.com/video/${this.currentDocument.videoId}`
        } else {
            this.videoUrl = "";
        }
        this.setCanEdit();
    }

    closeDocument() {
        this.isCreateDoc = false;
        this.isEditDoc = false;
        this.docOpened = false;
        this.formGroup = KnowledgeBaseDocumentVm.Form;
        this.currentDocument = new KnowledgeBaseDocumentVm();
        this.canEdit = false;
        this.videoUrl = "";
        this.isError = false;
        this.errorStr = "";
    }

    onSave() {
        this.isError = false;
        this.errorStr = "";

        if (this.formGroup.controls.module.value === null) {
            this.isError = true;
            this.errorStr += "Module is required. ";
        } 
        if (
            this.formGroup.controls.title.value === null 
            || this.formGroup.controls.title.value === ""
        ) {
            this.isError = true;
            this.errorStr += "Title is required. ";
        } 
        if (this.formGroup.controls.permissionRole.value === null) {
            this.isError = true;
            this.errorStr += "Permission is required. ";
        } 

        if (this.isError === true) { return; }

        this.textEditor = document.querySelector("trix-editor");
        this.formGroup.controls.content.setValue(this.textEditor.value);

        this.httpClient.post("api/HelpDesk/KnowledgeBase/SaveKnowledgeBaseDocument", this.formGroup.getRawValue())
            .subscribe((sm: SystemMessage) => {
                if (sm?.isSuccessful) { 
                    // Clear the formGroup
                    this.formGroup = KnowledgeBaseDocumentVm.Form;

                    // Open created document
                    this.openDocument(sm?.value);

                    // Update document lists
                    this.docList.push(sm?.value);
                    this.upsertSortedDocuments();
                }
                this.ms.setSystemMessage(sm.message, sm.messageClass);
            });
    }
    // #endregion

    getDocuments() {
        this.docList = new Array<KnowledgeBaseDocumentVm>();

        // Get List of Documents
        this.httpClient.get("api/HelpDesk/KnowledgeBase/GetKnowledgeBaseDocumentList")
            .subscribe((docList: Array<KnowledgeBaseDocumentVm>) => {
                this.docList = docList;
                this.upsertSortedDocuments();
            });
    }

    upsertSortedDocuments(filteredList: KnowledgeBaseDocumentVm[] = [], isFiltering = false) {
        // Clear old lists
        this.adminDocList = new Array<KnowledgeBaseDocumentVm>();
        this.ciRepackDocList = new Array<KnowledgeBaseDocumentVm>();
        this.herosDocList = new Array<KnowledgeBaseDocumentVm>();
        this.metabolicFormulaDocList = new Array<KnowledgeBaseDocumentVm>();
        this.ndcsDocList = new Array<KnowledgeBaseDocumentVm>();
        this.pfsDocList = new Array<KnowledgeBaseDocumentVm>();
        this.pharmacyDocList = new Array<KnowledgeBaseDocumentVm>();
        this.rxConnectsDocList = new Array<KnowledgeBaseDocumentVm>();
        this.supportDocList = new Array<KnowledgeBaseDocumentVm>();
        this.otherDocList = new Array<KnowledgeBaseDocumentVm>();

        // Ensure that docList does not contain duplicate documentIds
        this.docList =  [...new Map(this.docList.map(doc => [doc.documentId, doc])).values()];
        var listToPullFrom = isFiltering ? filteredList : this.docList;
        
        // Sort documents my module into corresponding lists
        listToPullFrom.forEach((doc) => {
            switch (doc?.module) {
                case 1: 
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.HEROS ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                        || this.isSupportAdmin
                    ) { this.herosDocList?.push(doc); }
                    break;
                case 3: 
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.NDC ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                        || this.isSupportAdmin
                    ) { this.ndcsDocList?.push(doc); }
                    break;
                case 4:
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.Inventory ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                        || this.isSupportAdmin
                    ) { this.pharmacyDocList?.push(doc); }
                    break;
                case 5: 
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.MetabolicFormula ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                        || this.isSupportAdmin
                    ) { this.metabolicFormulaDocList?.push(doc); }
                    break;
                case 6:
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.CI ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                        || this.isSupportAdmin
                    ) { this.ciRepackDocList?.push(doc); }
                    break;
                case 10:
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.Admin ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                        || this.isSupportAdmin
                    ) { this.adminDocList?.push(doc); }
                    break;
                case 11: 
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.RxConnects ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                        || this.isSupportAdmin
                    ) { this.rxConnectsDocList?.push(doc); }
                    break;
                case 12: 
                    if (
                        this.sec?.hasModuleAccess(
                            [ BusinessAreaEnum.HelpDesk ]
                            , SecurityService.setMinRole(doc.permissionRole)
                        )
                    ) { this.supportDocList?.push(doc); }
                    break;
                case 13: 
                    this.pfsDocList?.push(doc);
                    break;
                case 14: 
                    this.otherDocList?.push(doc);
                    break;
                default: 
                    break;
            }
        });
        this.sortDocList(this.herosDocList);
        this.sortDocList(this.ndcsDocList);
        this.sortDocList(this.pharmacyDocList);
        this.sortDocList(this.metabolicFormulaDocList);
        this.sortDocList(this.ciRepackDocList);
        this.sortDocList(this.adminDocList);
        this.sortDocList(this.rxConnectsDocList);
        this.sortDocList(this.supportDocList);
        this.sortDocList(this.pfsDocList);
        this.sortDocList(this.otherDocList);
    }

    setImageInFormGroup(event) { 
        this.ms.setSystemMessage("Save images individually to see format. May need to refresh page after saving to view image.", "warning");
        this.formGroup?.controls.uploadFiles?.setValue(event); 
    }

    getModules() {
        this.httpClient.get(`api/Select/KnowledgeBaseModules`).subscribe((items: SelectItem[]) => {
            this.knowledgeBaseModules = items;

            // Filter by module access
            if (!this.hasAdminAccess && !this.isSupportAdmin) {
                this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 10);

                // Also use admin module access to filter PFS and Other
                this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 13);
                this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 14);
            }
            if (!this.hasCiRepackAccess && !this.isSupportAdmin) { this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 6); }
            if (!this.hasHerosAccess && !this.isSupportAdmin) { this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 1); }
            if (!this.hasMetabolicFormulaAccess && !this.isSupportAdmin) { this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 5); }
            if (!this.hasNdcsAccess && !this.isSupportAdmin) { this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 3); }
            if (!this.hasRxConnectsAccess && !this.isSupportAdmin) { this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 11); }
            if (!this.hasSupportAccess && !this.isSupportAdmin) { this.knowledgeBaseModules = this.knowledgeBaseModules.filter(o => o.value != 12); }
        })
    }

    onModuleChange() {
        this.formGroup?.controls?.permissionRole?.setValue(null);
        this.permissionItems =  new Array<SelectItem>();
        if (this.formGroup?.controls?.module?.value != null) { this.getPermissions(); }
    }

    getPermissions() {
        this.httpClient.get(`api/Select/PermissionRoles?module=${this.formGroup?.controls?.module?.value}`)
            .subscribe((items: SelectItem[]) => { 
                if (
                    this.formGroup?.controls?.module?.value !== 13 
                    && this.formGroup?.controls?.module?.value !== 14
                ) { this.permissionItems = items.filter(o => o.value !== 0); } // filter out 'no access'
                else { this.permissionItems = items; }
            });
    }

    drop(event: CdkDragDrop<KnowledgeBaseDocumentVm[]>, module: BusinessAreaEnum) {
        switch (module) {
            case 1: 
                if (this.hasHerosAccess || this.isSupportAdmin) {
                    moveItemInArray(this.herosDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.herosDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 3: 
                if (this.hasNdcsAccess || this.isSupportAdmin) {
                    moveItemInArray(this.ndcsDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.ndcsDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;  
            case 4:
                if (this.hasPharmacyAccess || this.isSupportAdmin) {
                    moveItemInArray(this.pharmacyDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.pharmacyDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 5: 
                if (this.hasMetabolicFormulaAccess || this.isSupportAdmin) {
                    moveItemInArray(this.metabolicFormulaDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.metabolicFormulaDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 6:
                if (this.hasCiRepackAccess || this.isSupportAdmin) {
                    moveItemInArray(this.ciRepackDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.ciRepackDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 10:
                if (this.hasAdminAccess || this.isSupportAdmin) {
                    moveItemInArray(this.adminDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.adminDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 11: 
                if (this.hasRxConnectsAccess || this.isSupportAdmin) {
                    moveItemInArray(this.rxConnectsDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.rxConnectsDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 12: 
                if (this.hasSupportAccess || this.isSupportAdmin) {
                    moveItemInArray(this.supportDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.supportDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 13: 
                if (this.hasAdminAccess || this.isSupportAdmin) {
                    moveItemInArray(this.pfsDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.pfsDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            case 14: 
                if (this.hasAdminAccess || this.isSupportAdmin) {
                    moveItemInArray(this.otherDocList, event.previousIndex, event.currentIndex);
                    this.httpClient.post("api/HelpDesk/KnowledgeBase/SortKnowledgeBaseDocuments", this.otherDocList)
                        .subscribe((msg: SystemMessage) => {});
                }
                break;
            default: 
                this.ms.setSystemMessage("Module is not set", "warning");
                break;
        }
    }

    // #region Searches
    searchDocuments(event) {
        var filteredList = this.docList.filter(o => 
            o?.title?.toLowerCase().includes(event.target.value.toLowerCase()
        ) || o?.description?.toLowerCase().includes(event.target.value.toLowerCase()));
        this.upsertSortedDocuments(filteredList, true);

        if (filteredList.length == 0) {
            this.hasSearchResults = false;
        } else {
            this.hasSearchResults = true;
        }
        this.showAdminSubMenu = true;
        this.showCiRepackSubMenu = true;
        this.showHerosSubMenu = true;
        this.showMetabolicSubMenu = true;
        this.showNdcSubMenu = true;
        this.showPfsSubMenu = true;
        this.showPharmacySubMenu = true;
        this.showRxConnectsSubMenu = true;
        this.showSupportSubMenu = true;
        this.showOtherSubMenu = true;

        if (event.target.value === "") {
            this.showAdminSubMenu = false;
            this.showCiRepackSubMenu = false;
            this.showHerosSubMenu = false;
            this.showMetabolicSubMenu = false;
            this.showNdcSubMenu = false;
            this.showPfsSubMenu = false;
            this.showPharmacySubMenu = false;
            this.showRxConnectsSubMenu = false;
            this.showSupportSubMenu = false;
            this.showOtherSubMenu = false;
            this.hasSearchResults = true;
        }
    }

    clearSearch() {
        this.upsertSortedDocuments([], false);
        this.showAdminSubMenu = false;
        this.showCiRepackSubMenu = false;
        this.showHerosSubMenu = false;
        this.showMetabolicSubMenu = false;
        this.showNdcSubMenu = false;
        this.showPfsSubMenu = false;
        this.showPharmacySubMenu = false;
        this.showRxConnectsSubMenu = false;
        this.showSupportSubMenu = false;
        this.showOtherSubMenu = false;
        this.hasSearchResults = true;
    }
    // #endregion

    sortDocList(docList: any) { docList.sort((a, b) => { return a.sortOrder - b.sortOrder; }); }

    // #region Toggle file nav submenus
    toggleAdminSubMenu() { this.showAdminSubMenu = !this.showAdminSubMenu; }
    toggleCiRepackSubMenu() { this.showCiRepackSubMenu = !this.showCiRepackSubMenu; }
    toggleHerosSubMenu() { this.showHerosSubMenu = !this.showHerosSubMenu; }
    toggleMetabolicSubMenu() { this.showMetabolicSubMenu = !this.showMetabolicSubMenu; }
    toggleNdcSubMenu() { this.showNdcSubMenu = !this.showNdcSubMenu; }
    togglePfsSubMenu() { this.showPfsSubMenu = !this.showPfsSubMenu; }
    togglePharmacySubMenu() { this.showPharmacySubMenu = !this.showPharmacySubMenu; }
    toggleRxConnectsSubMenu() { this.showRxConnectsSubMenu = !this.showRxConnectsSubMenu; }
    toggleSupportSubMenu() { this.showSupportSubMenu = !this.showSupportSubMenu; }
    toggleOtherSubMenu() { this.showOtherSubMenu = !this.showOtherSubMenu; }
    // #endregion
}