import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewChildren, inject, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UiSwitchComponent } from 'ngx-ui-switch';
import { Observable } from 'rxjs';
import { faExternalLink, faDiagramNested } from '@fortawesome/pro-solid-svg-icons';

import { ActivationEnum } from 'app/core/enums/generated/ActivationEnum';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { EditOrganizationFacilityVm } from 'app/shared/generated/Administration/Models/Organizations/EditOrganizationFacilityVm';
import { ActivationService } from 'app/shared/services/activation.service';
import { ReconciliationVm } from 'app/shared/generated/SysInv/Models/Reconciliation/ReconciliationVm';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityTypeVm } from 'app/shared/generated/Administration/Models/Facilities/FacilityTypeVm';

@Component({
    selector: 'pcg-shared-edit-organization-facility',
    templateUrl: './edit-organization-facility.component.html',
    styleUrls: ['./edit-organization-facility.component.scss'],
    standalone: false
})
export class EditOrganizationFacilityComponent implements OnInit {

	@Input() businessArea: BusinessAreaEnum;
	@Input() facilityId = 0;
	@Input() organizationId = 0;
    @Output() onSaveSuccess = new EventEmitter();

	// @ViewChild('businessAreaSettings', { static: false }) businessAreaSettings: CiBusinessAreaComponent;
	@ViewChild('isActive', { static: false }) isActive: UiSwitchComponent;
	@ViewChild('isSameAddress', { static: false }) isSameAddress: UiSwitchComponent;
	@ViewChild('isShipAddressValidated', { static: false }) isShipAddressValidated: UiSwitchComponent;
	@ViewChild('infographicModal', { static: true }) infographicModal: TemplateRef<any>;
	@ViewChild('facilityTypeSelect', { static: true }) facilityTypeSelect: PcgSelectComponent;

	modalService = inject(NgbModal);

	formGroup = EditOrganizationFacilityVm.Form;
	model = EditOrganizationFacilityVm.GetModel(this.formGroup);
	model$: Observable<EditOrganizationFacilityVm>;
	api = "api/Select/SmartyCounties";

	canEdit = false;
	canDeactivate = false;
	canViewReports = false;
	canManualEntry = true;
	canEditFacilityType = false;
	shipAddressDisabled = false;
    showHiddenFields = false;

	facilityName: string;
	oldShipSmartyAddress: string;
	oldShipFacilityName: string;

    faIconName = { faExternalLink, faDiagramNested };

	// Used for "Pending Reconciliations" Error Message
	pendingRecons: ReconciliationVm[];
	showReconErrorMessage = false;
	firstLoad = false;

	facilityTypeName = "";
	facilityTypeTooltip = signal<string>("Determines which programs can be associated to the inventories under this facility.");

	constructor(
		private httpClient: HttpClient
		, public router: Router
		, title: Title
		, private sec: SecurityService
		, private activationService: ActivationService
		, public ms: SystemMessageService
	) { title.setTitle('Edit Facility'); }

	ngOnInit() {
		this.canEdit = this.sec.hasModuleAccess(
			[BusinessAreaEnum.CI, BusinessAreaEnum.MetabolicFormula, BusinessAreaEnum.Admin]
			, [PermissionRole.UserAdmin, ...SecurityService.setMinRole(PermissionRole.User)]
		);
		this.canViewReports = this.sec.hasModuleAccess(
			[BusinessAreaEnum.CI, BusinessAreaEnum.Admin]
			, [PermissionRole.UserAdmin, ...SecurityService.setMinRole(PermissionRole.Manager)]
		);
		this.canEditFacilityType = this.sec.hasModuleAccess(
			[BusinessAreaEnum.Admin]
			, [...SecurityService.setMinRole(PermissionRole.Pharmacist)]
		);
		this.editFacility();

		if (
			this.businessArea === null 
			|| this.businessArea === undefined 
			|| this.businessArea === BusinessAreaEnum.Admin
		) {
			if (this.sec.hasModuleAccess([BusinessAreaEnum.Admin], [...SecurityService.setMinRole(PermissionRole.SystemAdmin)])) {
				this.canEdit = true;
				this.canDeactivate = true;
			}
		}
	}

	editFacility() {
		this.httpClient.get(`api/Administration/Organization/GetOrganizationFacility/?id=${this.facilityId}&organizationId=${this.organizationId}`)
			.subscribe((vm: EditOrganizationFacilityVm) => {
				if (vm.countyId === 0) { vm.countyId = null; }// preventing a blank selection on load
				this.formGroup.setValue(vm);
				this.facilityName = vm.facilityName;
				this.oldShipFacilityName = this.model.shipFacilityName;
				this.oldShipSmartyAddress = this.model.shipSmartyAddress;
				this.toggleShippingFormActivation(!this.model.isSameAddress);
				if(!this.canEditFacilityType) { 
					this.formGroup.get('facilityTypeId')?.disable();
					this.facilityTypeTooltip.update(tooltip => tooltip += " Can only be changed by a Pharmacist or System Admin." ); 
				}
			});
	}

	activateDeactivate() {
		this.activationService.activateDeactivate(
			this.facilityId
			, ActivationEnum.Facility
			, this.formGroup.value.isActive ? false : true
		);
	}

	updateShippingInfo() {
		this.formGroup.controls.countyName.patchValue(this.formGroup.controls.smartyAddress.value.countyName);
		if (this.isSameAddress.checked) {
			this.formGroup.get('shipFacilityName').patchValue(this.formGroup.value.facilityName);
			this.formGroup.get('shipSmartyAddress').patchValue(this.formGroup.value.smartyAddress);
			this.toggleShippingFormActivation(false);
		} else {
			this.formGroup.get('shipFacilityName').patchValue(this.oldShipFacilityName);
			this.formGroup.get('shipSmartyAddress').patchValue(this.oldShipSmartyAddress);
			this.toggleShippingFormActivation(true);
		}
	}

	toggleShippingFormActivation(isActive: Boolean) {
		if (isActive) {
			this.enableShipFacilityName();
			this.shipAddressDisabled = false;
		} else {
			this.disableShipFacilityName();
			this.shipAddressDisabled = true;
		}
	}

	validateCountyId() {
		if (this.formGroup?.value?.countyId === 0) {
			this.formGroup?.controls?.countyId?.patchValue(null);
			this.model = EditOrganizationFacilityVm.GetModel(this.formGroup);
		}
		this.formGroup.updateValueAndValidity();
	}

	submitForms() {
		if (!this.formGroup.valid) {
			validateForm(this.formGroup);
			return;
		}
		let disabled = this.hasDisabledShipFacilityName();
		// As a result of moving this to a .ts POST method, disabled values
		// won't go through within the request body.
		if (this.hasDisabledShipFacilityName) { this.enableShipFacilityName(); }
		this.httpClient.post(`api/Administration/Organization/SaveOrganizationFacility`, this.formGroup.value)
			.subscribe((sm: SystemMessage) => {
				// Re-disable property after POST request.
				if (disabled) { this.disableShipFacilityName(); }
				if (sm.isSuccessful) { this.onMessageSuccess(sm); }
				else { this.onMessageError(sm); }
			}
		);
	}

	reroute(sm: SystemMessage) {
		if (
			this.businessArea === BusinessAreaEnum.Admin 
			|| this.businessArea === null 
			|| this.businessArea == undefined 
			|| Number.isNaN(this.businessArea)
		) {
			this.router.navigate([`/admin/organizations/facilities/edit/${sm.value}`]);
		} else if (this.businessArea === BusinessAreaEnum.CI) {
			this.router.navigate([`/ci-repack/facilities/edit/${sm.value}`]);
		} else if (this.businessArea === BusinessAreaEnum.MetabolicFormula) {
			this.router.navigate([`/metabolic-formula/facilities/edit/${sm.value}`]);
		}
	}

	onMessageSuccess(sm: SystemMessage) {
		this.setSysMessage(sm);
		this.formGroup.setValue(sm.model);
		this.onSaveSuccess.emit();
	}

	onMessageError(sm: SystemMessage) { 
		this.formGroup.patchValue(sm.model); 
		if(!sm.showSystemMessage) { this.showCustomReconMessage(sm); } 
		else { this.setSysMessage(sm);  }
	}

	showCustomReconMessage(sm: SystemMessage) {
		this.pendingRecons = sm.model;
		// Hide default system message and show custom message instead
		this.ms.settings.messageOpen = false; 
		this.firstLoad = true;
		this.showReconErrorMessage = true;
		setTimeout(() => { this.showReconErrorMessage = false; }, 8000);
	}	

	getCenteredStyles() {
		const mm = document?.body?.clientWidth;
		const mw = 650;
		return {
			minWidth: (mm < mw ? mm : mw) + 'px',
			left: (mm < mw ? 0 : (mm - mw) / 2) + 'px',
		};
	}

	enableShipFacilityName() {
		this.formGroup.get('shipFacilityName').enable();
		this.formGroup.get('shipSmartyAddress').enable();
	}

	disableShipFacilityName() {
		this.formGroup.get('shipFacilityName').disable();
		this.formGroup.get('shipSmartyAddress').disable();
	}

	medDirectorChange(select: PcgSelectComponent) {
		if (select.value) {
			this.model.medicalDirectorLicense = select.additionalData?.licenseNumber;
			this.model.medicalDirectorExpDate = select.additionalData?.expirationDate;
		} else {
			this.model.medicalDirectorLicense = "";
			this.model.medicalDirectorExpDate = "";
		}
	}

	closeSystemMessage() { this.showReconErrorMessage = false; }
	setSysMessage = (sm: SystemMessage) => this.ms.setSystemMessage(sm.message, sm.messageClass);
	hasDisabledShipFacilityName = () => this.formGroup.get('shipFacilityName').disabled;
    navToFacilityDetail() { this.router.navigateByUrl(`${this.editFacilityLink()}${this.facilityId}`); }

    editFacilityLink(): string {
        switch(this.businessArea) {
            case BusinessAreaEnum.CI: return '/ci-repack/facilities/edit/';
            case BusinessAreaEnum.MetabolicFormula: return '/metabolic-formula/facilities/edit/';
            default: return '/admin/organizations/facilities/edit/';
        }
    }

	openChartModal(){
		// Set modal header using select drop down
		this.facilityTypeName = this.facilityTypeSelect?.text;
		this.modalService.open(this.infographicModal, { size: 'sm', animation: false });
	}
}
