import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TableComponent } from 'app/shared/table/table.component';
import { ReleaseNotesVm } from 'app/shared/generated/Models/ReleaseNotesVm';

@Component({
    selector: 'pcg-web-release-notes',
    templateUrl: './web-release-notes.component.html',
    styleUrls: ['./web-release-notes.component.scss'],
    standalone: false
})
export class WebReleaseNotesComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<ReleaseNotesVm>;
	columns = ReleaseNotesVm.ColumnDefs;

	constructor(public title: Title) { }

	ngOnInit() { 
		this.title.setTitle('Web Release Notes'); 
	}
}
