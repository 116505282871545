import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class FacilityInventoryVm {
	id: number;
	facilityId: number;
	organizationId: number;
	name?: string;
	type?: any;
	active: boolean;
	canDelete?: boolean;
	friendlyId?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'organizationId': new UntypedFormControl(0, [...(validators['organizationId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.required]]),
			'type': new UntypedFormControl('', [...(validators['type'] ?? []), ...[Validators.required]]),
			'active': new UntypedFormControl(false, [...(validators['active'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(null, [...(validators['canDelete'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['name', {"orderId":0,"sortColumn":"name","searchColumn":"name","includeInExcel":true,"exportColumn":null,"displayName":"Inventory","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false}],
			['type', {"orderId":1,"sortColumn":"type","searchColumn":"type","includeInExcel":true,"exportColumn":null,"displayName":"Type","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false}],
			['active', {"orderId":2,"sortColumn":"active","searchColumn":"active","includeInExcel":true,"exportColumn":null,"displayName":"Is Active?","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false}],
			['canDelete', {"orderId":3,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":true,"exportColumn":null,"displayName":"Delete","isVisible":false,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): FacilityInventoryVmFromFg {
		return new FacilityInventoryVmFromFg(fg);
	}
}

export class FacilityInventoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get name(): string {
		return this.fg.get('name').value;
	}
	set name(val) {
		this.fg.get('name').setValue(val);
	}

	get type(): any {
		return this.fg.get('type').value;
	}
	set type(val) {
		this.fg.get('type').setValue(val);
	}

	get active(): boolean {
		return this.fg.get('active').value;
	}
	set active(val) {
		this.fg.get('active').setValue(val);
	}

	get canDelete(): boolean {
		return this.fg.get('canDelete').value;
	}
	set canDelete(val) {
		this.fg.get('canDelete').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}
}
