import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { EditOrganizationVm } from "app/shared/generated/Administration/Models/Organizations/EditOrganizationVm";
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { LoginTypeEnum } from 'app/core/enums/generated/LoginTypeEnum';
import { SSOTypeEnum } from 'app/core/enums/generated/SSOTypeEnum';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { GlobalService } from 'app/shared/services/global.service';

@Component({
    selector: 'pcg-shared-organization-logins',
    templateUrl: './organization-logins.component.html',
    styleUrls: ['./organization-logins.component.scss'],
    standalone: false
})
export class OrganizationLoginsComponent implements OnInit {

    @Input() vm: EditOrganizationVm;

    formGroup = EditOrganizationVm.Form;
    model = EditOrganizationVm.GetModel(this.formGroup);

    showSsoType = false;
	canEdit = false;
    isMobile = false;
	infoOpened = false;

    faIconName = { faXmark };

    constructor(
        private sec: SecurityService
		, public router: Router
		, private httpClient: HttpClient
		, private ms: SystemMessageService
    ) { }

    @HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

    ngOnInit() { 
        this.isMobile = GlobalService.setIsMobile(window.innerWidth);
        const bae = BusinessAreaEnum;
        const pr = PermissionRole;

        this.canEdit = this.sec.hasModuleAccess(
			[
                bae.CI
                , bae.MetabolicFormula
                , bae.Admin
                , bae.HEROS
            ],
			[
                pr.SystemAdmin
                , ...SecurityService.setMinRole(pr.SystemAdmin)
            ]
		);
        const newvm = this.vm;
        this.model = EditOrganizationVm.GetModel(this.formGroup);
        this.formGroup.patchValue(newvm);
        if (
            this.vm.loginTypeEnum === LoginTypeEnum.SSO 
            || this.vm.loginTypeEnum === LoginTypeEnum.Both
        ) { this.showSsoType = true; }
    }

    changeLoginType() {
		if (
			this.formGroup.controls.loginTypeEnum.value === LoginTypeEnum.SSO
			|| this.formGroup.controls.loginTypeEnum.value === LoginTypeEnum.Both
		) { 
			this.showSsoType = true; 
			this.formGroup.controls.ssoTypeEnum.patchValue(SSOTypeEnum.MicrosoftEntraAD);
		} else { 
			this.formGroup.controls.ssoTypeEnum.patchValue(null);
			this.showSsoType = false; 
		}
	}

    onSave() {
        this.httpClient.post("api/Administration/Organization/SaveOrganization?fromLogin=true", this.formGroup.getRawValue())
            .subscribe(async (sm: SystemMessage) => {
                if (sm.isSuccessful) { 
                    this.ms.setSystemMessage(sm.message, sm.messageClass);
        		    this.formGroup.patchValue(sm.model);
                } else { 
                    this.ms.setSystemMessage(sm.message, sm.messageClass);
		            this.formGroup.patchValue(sm.model); 
                }
            }
		);
	}

    openInfo() { this.infoOpened = true; }
	closeInfo() { this.infoOpened = false; }
}
