import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { CiWorkstationComponent } from 'app/shared/ci-workstation/ci-workstation.component';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { pcgSettings } from 'app/shared/generated/pcg-settings';
import { NavDividerCharacter, NavigationService } from 'app/shared/navigation/navigation.service';
import { TopClickNavComponent } from 'app/shared/navigation/top-click-nav/top-click-nav.component';
import { GlobalService } from 'app/shared/services/global.service';

@UntilDestroy()
@Component({
    selector: 'pcg-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class TopHeaderComponent implements OnInit {

	@ViewChild(TopClickNavComponent, { static: true }) nav: TopClickNavComponent;
	@ViewChild('userNav') userNav: ElementRef;
	@ViewChild('mainHamburger', { static: true }) mainHamburger: ElementRef;

	@Input() tabPanel: any;
	@Output() hamburgerClick = new EventEmitter<boolean>();

	user: UserJsVm;
	mode = pcgSettings.mode;	
	navDividerCharacter = NavDividerCharacter;	
	hrefLocation: string = "";

	userNavOpen = false;
	adminAccess = false;	
	isMobile = false;
	isLoggedOn = false;
	isNavFixed = false;
	isCiUser = false;
	hasCiWorkstation = false;
	hasCiAccess = false;
	loading = false;

	navigationOffset = 0;

	faIconName = { faBars };
	navService = inject(NavigationService);

	constructor(
		private sec: SecurityService
		, private router: Router
		, private modalService: NgbModal
		, private location: Location
		, private gvs: GlobalVariablesService
		, public globalService: GlobalService
	) {
		// Subscribe to route changes and update the navs when they happen
		this.router.events
			.pipe(untilDestroyed(this))
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => { 
				this.hasCiAccess = this.location?.path()?.includes('ci-repack') 
					&& this.sec?.hasModuleAccess( 
						[ BusinessAreaEnum.CI ]
						, SecurityService.setMinRole(PermissionRole.Technician)
					);
					
				if (this.hasCiAccess && !this.location.path().includes('/job-sheet/')) { 
					this.getCiWorkstation(); 
				}
			}
		);
	 }

	// Close the main navigation when they click outside of it
	// and are not clicking the mobile hamburger button
	@HostListener('document:click', ['$event'])
	clickDoc(event: { target: any; }) {
		if (
			!this.mainHamburger?.nativeElement.contains(event.target)
			&& !this.nav?.elRef?.nativeElement.contains(event.target)
			&& !this.userNav?.nativeElement.contains(event.target)
		) { 
			this.navService.setCurrOpenNavMenu(''); 
			this.userNavOpen = false;
		}
	}

	// Fix the nav offset on resize
	@HostListener('window:resize')
	onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

	ngOnInit() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.sec.user$.pipe(untilDestroyed(this)).subscribe(user => { this.user = user; });
		setTimeout(() => {
			let secondaryNav = document.getElementById("secondaryNav");
			if (secondaryNav != null) { secondaryNav.scrollLeft = Number(localStorage?.getItem("subNavPosition")); }
		}, 250);

		localStorage.getItem("isNavFixed") === "true" 
			? this.isNavFixed = true 
			: this.isNavFixed = false;
	}

	ngDoCheck() { this.isLoggedOn = this.sec.isLoggedOn(); }	//Needed to check if user is logged in (to toggle header)
	setSubNavPosition() { localStorage.setItem("subNavPosition", document.getElementById("secondaryNav").scrollLeft.toString()); }
	toggleNav() { this.hamburgerClick.emit(true); } // Toggle whether or not the main navigation is open
	openCiWorkstationModal() { this.modalService.open(CiWorkstationComponent, { animation: false }); }	

	getCiWorkstation() {
		// Check if is a CI user
		this.isCiUser = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.CI ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		if (this.isCiUser === true) {
			const opened = localStorage.getItem('ciWorkstationModalOpened');
			const sessionToken = localStorage.getItem('jwt');
			this.hasCiWorkstation = false;
			if (
				opened !== sessionToken 
				&& this.user.isInternalBphpUser
				&& !this.user.email.includes("@paulconsulting.com")
			) { this.openCiWorkstationModal(); }
			localStorage.setItem('ciWorkstationModalOpened', sessionToken);
		}
	}

	setDynamicStyle(
		name: string
		, styles: string
	) {
		let dynamicStyle = document.getElementById(name);
		if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		dynamicStyle = document.createElement('style');
		dynamicStyle.id = name;
		dynamicStyle.innerHTML = styles;
		document.head.appendChild(dynamicStyle);
	}
}
