import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { RxConnectsSecurityEnum } from 'app/core/enums/generated/RxConnectsSecurityEnum';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { RxConnectsUserSecurityVm } from 'app/shared/generated/RxConnects/Models/RxConnectsUserSecurityVm';
import { SelectItem } from 'app/shared/models/select-item';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-user-rxconnects-settings',
    templateUrl: './user-rxconnects-settings.component.html',
    styleUrls: ['./user-rxconnects-settings.component.scss'],
    standalone: false
})
export class UserRxConnectsSettingsComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<RxConnectsUserSecurityVm>;
	//columns = RxConnectsUserRepositoriesVm.ColumnDefs;
	canEditUser = false;
	fullName: string = null;
	filters = {
		userId: null
	};

	formGroup = RxConnectsUserSecurityVm.Form;
	model$: Observable<RxConnectsUserSecurityVm>;
	user: UserJsVm;
	userId: number;
	selectedUserId: number;
	repositoryItems: SelectItem[] = [];
	donorItems: SelectItem[] = [];


	selectedSecurityType: RxConnectsSecurityEnum;
	public RxConnectsSecurityEnum = RxConnectsSecurityEnum;
	
	@ViewChild(TableComponent, { static: true }) credentialTable: TableComponent<RxConnectsUserSecurityVm>;

	constructor(
		title: Title
		, private route: ActivatedRoute
		, private sec: SecurityService
		, public sm: SystemMessageService
		, private httpClient: HttpClient
	) { title.setTitle('User RxConnects Security'); }

	ngOnInit() {
		this.route.params.subscribe(params =>
			this.selectedUserId = params['id']);

		this.loadSecurity();
	}

	loadSecurity() {
		this.user = this.sec.getUser();
		this.formGroup.get('userId').patchValue(this.userId);

		this.httpClient.get(`api/RxConnects/Repository/GetUserRxConnectsSettings/${this.selectedUserId}`)
			.subscribe((vm: RxConnectsUserSecurityVm) => {

				this.selectedSecurityType = vm.securityEnum;
				this.formGroup.get('securityEnum').patchValue(vm.securityEnum);
				this.formGroup.get('repositoryId').patchValue(vm.repositoryId);
				this.formGroup.get('donorId').patchValue(vm.donorId);
			}
		);
	}
	

	saveSecurityForm() {
		this.formGroup.get('userId').patchValue(this.selectedUserId);


		if (this.formGroup.valid && this.IsValid()) {
			this.sm.getHttpObservable(this,
				`api/RxConnects/Repository/SaveRxConnectsSecurity`,
				this.formGroup, null
			).subscribe((sm: SystemMessage) => {
				if (sm.isSuccessful) {
					this.formGroup.setValue(sm.model);
					this.sm.setSystemMessage(sm.message);
				} else {
					this.formGroup.patchValue(sm.model);
					this.sm.setSystemMessage(sm.message, 'error');
				}
			});
		} else { validateForm(this.formGroup); }
	}

	removeSecurity() {
		this.formGroup.get('userId').patchValue(this.selectedUserId);

		if (this.IsValid()) {
			this.sm.getHttpObservable(this,
				`api/RxConnects/Repository/DeleteRxConnectsSecurity`,
				this.formGroup, null
			).subscribe((sm: SystemMessage) => {
				if (sm.isSuccessful) {
					this.sm.setSystemMessage(sm.message);
					this.loadSecurity();
				} else {
					this.sm.setSystemMessage(sm.message, 'error');
				}
			});
		}
	}


	IsValid() {
		var result: boolean = true;
		if (this.formGroup.get('securityEnum').value === RxConnectsSecurityEnum.Repository) {
			if (!(this.formGroup.get('repositoryId').value > 0)) {
				result = false;
				this.sm.setSystemMessage("Repository is required!", 'error');
			}
		}
		if (this.formGroup.get('securityEnum').value === RxConnectsSecurityEnum.Donor) {
			if (!(this.formGroup.get('donorId').value > 0)) {
				result = false;
				this.sm.setSystemMessage("Donor is required!", 'error');
			}
		}

		return result;
	}
}
