// tslint:disable: quotemark
export enum SSOTypeEnum {
	MicrosoftEntraAD = 1
}

export namespace SSOTypeEnum {
	export function toDescription(val: SSOTypeEnum) {
		switch (val) {
			case 1:
				return "Microsoft Entra AD";
		}
		return null;
	}
	export function toDisplay(val: SSOTypeEnum) {
		switch (val) {
			case 1:
				return "Microsoft Entra AD";
		}
		return null;
	}
}
