import { HttpClient } from '@angular/common/http';
import { EventEmitter, OnDestroy } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { RegisterPinForm } from '../register-pin-modal/register-pin.form';

@Component({
    selector: 'pcg-register-pin-modal',
    templateUrl: './register-pin-modal.component.html',
    styleUrls: ['./register-pin-modal.component.scss'],
    standalone: false
})
export class RegisterPinModalComponent implements OnInit, OnDestroy {

    @Output() emitPin: EventEmitter<string> = new EventEmitter();
	@Output() dismiss: EventEmitter<void> = new EventEmitter();

    pinForm: UntypedFormGroup = null;
	subscriptions: Subscription = new Subscription();
	id: number;

    constructor(
		private httpClient: HttpClient
		, private ms: SystemMessageService
		, private sec: SecurityService
		, private route: ActivatedRoute
	) { 
        const pinForm = new RegisterPinForm(httpClient);
		this.pinForm = pinForm.formGroup;
    }

    ngOnInit() {
        this.route.paramMap.subscribe(map => { this.id = parseInt(map.get('id'), 10); });
		document.getElementById('pin').focus();
    }

    checkCredential() {
		if (this.pinForm.valid) {
			this.pinForm.addControl('userId', new UntypedFormControl('', Validators.required));
			this.pinForm.patchValue({ userId: this.id });
			this.subscriptions.add(
				this.ms
					.getHttpObservable(this, 'api/Administration/Users/SavePIN', this.pinForm)
					.subscribe((msg: SystemMessage) => {
						if (msg.isSuccessful) {
							this.ms.setSystemMessage(msg.message);
							this.emitPin.emit(this.pinForm.controls['pin'].value as string);
							this.dismiss.emit();
						} else { this.ms.setSystemMessage(msg.message, 'error'); }
					})
			);
		} else { validateForm(this.pinForm); }
	}

    ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
