import { faPrescription } from '@fortawesome/pro-solid-svg-icons';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getRxConnectsNav: () => NavRoute = () => {
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.

	const dashboard: NavRoute = {
		name: 'Dashboard'
		, exact: true
		, path: 'rxconnects/dashboard'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.RxConnects ]
				, permissionRoles: sec.setMinRole(p.User)
			}
		]
		, activeNavLink: 'rxconnects/dashboard'
		, secondaryNav: ['RxConnects', 'Dashboard']
	};

	const patients: NavRoute = {
		name: 'Patients'
		, exact: true
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.RxConnects ]
				, permissionRoles: sec.setMinRole(p.User)
			}
		]
		, children: [
			{
				name: 'Applications'
				, exact: true
				, path: 'rxconnects/patients/applications/list'
				, activeNavLink: 'rxconnects/patients/applications'
				, secondaryNav: ['RxConnects', 'Patients', 'Applications']
			}
		]
	};

	const repository: NavRoute = {
		name: 'Repository'
		, exact: true
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.RxConnects ]
				, permissionRoles: sec.setMinRole(p.RxConnectsPharmacist)
			}
		]	
		, children: [
			{
				name: 'Repositories'
				, exact: true
				, path: 'rxconnects/repository/repositories/list'
				, activeNavLink: 'rxconnects/repository/repositories'
				, secondaryNav: ['RxConnects', 'Repository', 'Repositories']
			}
			, {
				name: 'Available Donations'
				, exact: true
				, path: 'rxconnects/repository/available-donations'
				, activeNavLink: 'rxconnects/repository/available-donations'
				, secondaryNav: ['RxConnects', 'Repository', 'Available Donations']
			}
			
			, {
				name: 'Inventory'
				, exact: true
				, path: 'rxconnects/repository/inventory/list'
				, activeNavLink: 'rxconnects/repository/inventory'
				, secondaryNav: ['RxConnects', 'Repository', 'Inventory']
			}
			, {
				name: 'Transfers'
				, exact: true
				, path: 'rxconnects/repository/transfers/list'
				, activeNavLink: 'rxconnects/repository/transfers'
				, secondaryNav: ['RxConnects', 'Repository', 'Transfers']
			}
			, {
				name: 'Drug Destructions'
				, exact: true
				, path: 'rxconnects/repository/destructions'
				, activeNavLink: 'rxconnects/repository/destructions'
				, secondaryNav: ['RxConnects', 'Repository', 'Drug Destructions']
			}
			, {
				name: 'Withdrawal'
				, exact: true
				, path: 'rxconnects/repository/withdrawal'
				, activeNavLink: 'rxconnects/repository/withdrawal'
				, secondaryNav: ['RxConnects', 'Repository', 'Withdrawal']
			}
		]	
	};

	const donors: NavRoute = {
		name: 'Donors'
		, exact: true
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.RxConnects ]
				, permissionRoles: sec.setMinRole(p.RxConnectsPharmacist)
			}
		]
		, children: [
			{
				name: 'Donors'
				, exact: true
				, path: 'rxconnects/donors/donors/list'
				, activeNavLink: 'rxconnects/donors/donors'
				, secondaryNav: ['RxConnects', 'Donors', 'Donors']
			}
			, {
				name: 'Donations'
				, exact: true
				, path: 'rxconnects/donors/donations/list'
				, activeNavLink: 'rxconnects/donors/donations'
				, secondaryNav: ['RxConnects', 'Donors', 'Donations']
			}
		]
	};

	const reports: NavRoute = {
		name: 'Reports'
		, exact: true
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.RxConnects ]
				, permissionRoles: sec.setMinRole(p.RxConnectsPharmacist)
			}
		]
		, children: [
			{
				name: 'Drug Destruction'
				, exact: true
				, path: 'rxconnects/reports/destruction'
				, activeNavLink: 'rxconnects/reports/destruction'
				, secondaryNav: ['RxConnects', 'Reports', 'Drug Destruction']
			}
		]
	};

	const children: NavRoute[] = [
		dashboard
		, patients
		, repository
		, donors
		, reports
	];

	const rxConnectsNavItems: NavRoute = {
		name: 'RxConnects'
		, children: children
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.RxConnects ]
				, permissionRoles: [ ...sec.setMinRole(p.User) ]
			}
		]
		, href: 'rxconnects/dashboard'
		, icon: faPrescription
		, area: 'rxconnects'
		, module: BusinessAreaEnum.RxConnects
	};

	return rxConnectsNavItems;
};