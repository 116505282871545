import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';

import { PrimeNgModule } from '../primeNg.module';
import { ActiveFilterDisplayComponent } from './active-filter-display/active-filter-display.component';
import { DeleteButtonComponent } from './row-components/delete-button/delete-button.component';
import { EditLinkComponent } from './row-components/edit-link/edit-link.component';
import { EllipsisComponent } from './row-components/ellipsis/ellipsis.component';
import { SortModule } from './sort/sort.module';
import { TableTopTooltipComponent } from './table-top-tooltip/table-top-tooltip.component';
import { ButtonFilterComponent } from './table-top/button-filter/button-filter.component';
import { ButtonHideShowComponent } from './table-top/button-hide-show/button-hide-show.component';
import { ButtonResetFilterComponent } from './table-top/button-reset-filter/button-reset-filter.component';
import { TableFilterComponent } from './table-top/table-filter/table-filter.component';
import { TableTopLegendComponent } from './table-top/table-top-legend/table-top-legend.component';
import { TableTopComponent } from './table-top/table-top.component';
import { TableComponent } from './table.component';

@NgModule({
	declarations: [ 
		TableComponent
		, TableTopComponent
		, ButtonHideShowComponent
		, ButtonFilterComponent
		, ButtonResetFilterComponent
		, TableTopLegendComponent
		// Components to be used by developers outside of table module
		, EditLinkComponent
		, DeleteButtonComponent
		, EllipsisComponent
		, TableFilterComponent
		, ActiveFilterDisplayComponent
		, TableTopTooltipComponent
	 ],
	imports: [
		CommonModule
		, FormsModule
		, RouterModule
		, MatTableModule
		, NgbPaginationModule
		, SortModule
		, NgbTooltipModule
		, UiSwitchModule
		, FontAwesomeModule
		, PrimeNgModule
	],
	exports: [ 
		TableComponent
		, TableTopComponent
		, MatTableModule
		, SortModule
		, TableFilterComponent
		// Components to be used by developers outside of table module
		, EditLinkComponent
		, DeleteButtonComponent
		, EllipsisComponent
		, ActiveFilterDisplayComponent	
		, TableTopTooltipComponent
	]
})
export class TableModule { }

