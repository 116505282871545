import { HttpClient } from '@angular/common/http';
import { Component, computed, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { Observable, tap } from 'rxjs';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { FacilityBusinessAreaVm } from 'app/shared/generated/Administration/Models/Facilities/BusinessAreas/FacilityBusinessAreaVm';
import { SelectItem } from 'app/shared/models/select-item';
import { CiBusinessAreaComponent } from './ci-business-area/ci-business-area.component';
import { InventoryBusinessAreaComponent } from './inventory-business-area/inventory-business-area.component';
import { SecurityService } from 'app/core/security/security.service';

@Component({
    selector: 'pcg-facilty-business-areas',
    templateUrl: './facilty-business-areas.component.html',
    styleUrls: ['./facilty-business-areas.component.scss'],
    standalone: false
})
export class FaciltyBusinessAreasComponent implements OnInit {

    @Input() businessArea: BusinessAreaEnum;
    @Input() id: number;
    @Input() canEdit: boolean;

    @ViewChild('ciBusinessArea') ciBusinessArea: CiBusinessAreaComponent;
    @ViewChild('inventoryBusinessArea') inventoryBusinessArea: InventoryBusinessAreaComponent;

  formGroup = FacilityBusinessAreaVm.Form;
  model: FacilityBusinessAreaVm = FacilityBusinessAreaVm.GetModel(this.formGroup);
  model$: Observable<FacilityBusinessAreaVm>;
  userId = computed(() => this.sec.getUser().id);

    facilityBusinessAreas = [
        BusinessAreaEnum.CI,
        BusinessAreaEnum.Inventory,
        BusinessAreaEnum.MetabolicFormula,
    ];

    items: SelectItem[] = [
        { text: "Has Access", value: true },
        { text: "No Access", value: false, additionalData: { color: 'red' } }
    ];

    businessAreaEnum = BusinessAreaEnum;

    faCaretDown = faCaretDown;
    faCaretUp = faCaretUp;

    constructor(
        private route: ActivatedRoute
        , private httpClient: HttpClient
        , public router: Router
        , public ms: SystemMessageService
        , private sec: SecurityService
    ) { }

  ngOnInit() {
    this.GetFacilityBusinessSettings();
    if (!this.businessArea) { this.route.queryParams.subscribe((params) => { this.businessArea = Number(params.module); }); }
  }

    GetFacilityBusinessSettings() {
      this.route.paramMap.subscribe(map => {
          this.model$ = this.httpClient.get(`api/Administration/Facility/GetFacilityBusinessSettings/${this.id}`)
                .pipe(tap((vm: FacilityBusinessAreaVm) => {
                    this.model = vm;
                    this.formGroup.patchValue(this.model);
                })
            );
        });
    }

    getDisplayName = (ba: BusinessAreaEnum) => this.businessAreaEnum.toDescription(ba);
    hasBusinessArea = (ba: BusinessAreaEnum) => !this.model?.businessAreas.filter((o: string) => o === this.getDisplayName(ba)).length;
    hasValidCiForms = () => this.ciBusinessArea?.formGroup?.valid;
    hideBusinessArea = (ba: BusinessAreaEnum): boolean => !(ba == this.businessArea || this.businessArea == BusinessAreaEnum.Admin);

    submitForms() {
        this.formGroup.patchValue(this.model);
        if (
            !this.model.hasCiAccess 
            && !this.model.hasInventoryAccess 
            && !this.model.hasMetabolicAccess
        ) { return this.ms.setSystemMessage('At least one Business Area must be selected.', 'error'); }
        // Business areas will be undefined if user 
        // is creating a new facility.
        if (this.model.hasCiAccess && !this.hasValidCiForms()) {
            return this.ms.setSystemMessage('You must fill out the facility account information before submitting.', 'error');
        }

        this.httpClient.post(`api/Administration/Facility/SaveFacilityBusinessSettings`, this.formGroup.value)
            .subscribe((sm: SystemMessage) => {
                if (sm.isSuccessful) { this.onMessageSuccess(sm); }
                else { this.onMessageError(sm); }
            }
        );
    }

    onMessageSuccess(sm: SystemMessage) {
        this.setSysMessage(sm);
        this.formGroup.setValue(sm.model);
    }

    onMessageError(sm: SystemMessage) { this.setSysMessage(sm); this.formGroup.patchValue(sm.model); }

    reroute(sm: SystemMessage) {
        if (
            this.businessArea === BusinessAreaEnum.Admin 
            || this.businessArea === null 
            || this.businessArea == undefined 
            || Number.isNaN(this.businessArea)
        ) {
            this.router.navigate([`/admin/organizations/facilities/edit/${sm.value}`]);
        } else if (this.businessArea === BusinessAreaEnum.CI) {
            this.router.navigate([`/ci-repack/facilities/edit/${sm.value}`]);
        } else if (this.businessArea === BusinessAreaEnum.MetabolicFormula) {
            this.router.navigate([`/metabolic-formula/facilities/edit/${sm.value}`]);
        }
    }

    setSysMessage = (sm: SystemMessage) => this.ms.setSystemMessage(sm.message, sm.messageClass);
}
