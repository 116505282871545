import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { EditFacilityCiDetailVm } from 'app/shared/generated/Administration/Models/Facilities/BusinessAreas/EditFacilityCiDetailVm';
import { UiSwitchComponent } from 'ngx-ui-switch';

@Component({
    selector: 'pcg-ci-business-area',
    templateUrl: './ci-business-area.component.html',
    styleUrls: ['./ci-business-area.component.scss'],
    standalone: false
})
export class CiBusinessAreaComponent implements OnInit {
  @Input() model: EditFacilityCiDetailVm = new EditFacilityCiDetailVm();
  @Output() modelChange = new EventEmitter<EditFacilityCiDetailVm>();

  @ViewChild('canGenerateCiInvoices', { static: false }) canGenerateCiInvoices: UiSwitchComponent;

  formGroup = EditFacilityCiDetailVm.Form;
  id: number;

  canEditPrimaryContacts: boolean;
  canEdit = false;

  constructor(private sec: SecurityService, private route: ActivatedRoute, private httpClient: HttpClient) { }

  ngOnInit() {
    this.formGroup.setValue(this.model);
    this.id = this.model.facilityId;
    this.canEditPrimaryContacts = this.sec.hasModuleAccess([BusinessAreaEnum.Admin, BusinessAreaEnum.CI],
      [...SecurityService.setMinRole(PermissionRole.UserAdmin)]);
    this.canEdit = this.sec.hasModuleAccess(
      [BusinessAreaEnum.CI, BusinessAreaEnum.MetabolicFormula, BusinessAreaEnum.Admin]
      , [PermissionRole.UserAdmin, ...SecurityService.setMinRole(PermissionRole.Manager)]
    );
  }

  canGenerateCiInvoiceChange() {
    this.formGroup.controls['canGenerateCiInvoices'].patchValue(this.canGenerateCiInvoices.checked);
    this.modelChange.emit(this.formGroup.value);
  }
}
