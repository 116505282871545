import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditOrganizationVm {
	organizationId: number;
	organizationName?: string;
	countyId?: number;
	countyName?: string;
	smartyAddress?: any;
	smartyAddressString?: string;
	hasSmarty: boolean;
	stateAbr?: string;
	phoneNumber?: string;
	isActive: boolean;
	isSameAddress: boolean;
	shipOrganizationName?: string;
	shipSmartyAddress?: any;
	shipSmartyAddressString?: string;
	hasShipSmarty: boolean;
	shipStateAbr?: string;
	isShipAddressValidated: boolean;
	friendlyId?: string;
	gln?: string;
	medicalDirectorId?: number;
	medicalDirectorName?: string;
	medicalDirectorLicense?: string;
	medicalDirectorExpDate?: string;
	isSingleFacility: boolean;
	facilityIdentifier?: string;
	dauCode?: string;
	facilityIds?: number[];
	loginType?: string;
	loginTypeEnum?: any;
	ssoType?: string;
	ssoTypeEnum?: any;
	entraIdentifierUrl?: string;
	entraLoginUrl?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'organizationId': new UntypedFormControl(0, [...(validators['organizationId'] ?? []), ...[Validators.required]]),
			'organizationName': new UntypedFormControl(null, [...(validators['organizationName'] ?? []), ...[Validators.required, Validators.maxLength(100)]]),
			'countyId': new UntypedFormControl(null, [...(validators['countyId'] ?? []), ...[]]),
			'countyName': new UntypedFormControl(null, [...(validators['countyName'] ?? []), ...[]]),
			'smartyAddress': new UntypedFormControl(null, [...(validators['smartyAddress'] ?? []), ...[Validators.required]]),
			'smartyAddressString': new UntypedFormControl(null, [...(validators['smartyAddressString'] ?? []), ...[]]),
			'hasSmarty': new UntypedFormControl(false, [...(validators['hasSmarty'] ?? []), ...[]]),
			'stateAbr': new UntypedFormControl(null, [...(validators['stateAbr'] ?? []), ...[]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.required, Validators.minLength(10)]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isSameAddress': new UntypedFormControl(false, [...(validators['isSameAddress'] ?? []), ...[]]),
			'shipOrganizationName': new UntypedFormControl(null, [...(validators['shipOrganizationName'] ?? []), ...[Validators.required, Validators.maxLength(100)]]),
			'shipSmartyAddress': new UntypedFormControl(null, [...(validators['shipSmartyAddress'] ?? []), ...[Validators.required]]),
			'shipSmartyAddressString': new UntypedFormControl(null, [...(validators['shipSmartyAddressString'] ?? []), ...[]]),
			'hasShipSmarty': new UntypedFormControl(false, [...(validators['hasShipSmarty'] ?? []), ...[]]),
			'shipStateAbr': new UntypedFormControl(null, [...(validators['shipStateAbr'] ?? []), ...[]]),
			'isShipAddressValidated': new UntypedFormControl(false, [...(validators['isShipAddressValidated'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'gln': new UntypedFormControl(null, [...(validators['gln'] ?? []), ...[Validators.pattern("^[A-Za-z0-9 ]*"), Validators.maxLength(13)]]),
			'medicalDirectorId': new UntypedFormControl(null, [...(validators['medicalDirectorId'] ?? []), ...[]]),
			'medicalDirectorName': new UntypedFormControl(null, [...(validators['medicalDirectorName'] ?? []), ...[]]),
			'medicalDirectorLicense': new UntypedFormControl(null, [...(validators['medicalDirectorLicense'] ?? []), ...[]]),
			'medicalDirectorExpDate': new UntypedFormControl(null, [...(validators['medicalDirectorExpDate'] ?? []), ...[]]),
			'isSingleFacility': new UntypedFormControl(false, [...(validators['isSingleFacility'] ?? []), ...[]]),
			'facilityIdentifier': new UntypedFormControl(null, [...(validators['facilityIdentifier'] ?? []), ...[Validators.maxLength(20)]]),
			'dauCode': new UntypedFormControl(null, [...(validators['dauCode'] ?? []), ...[Validators.maxLength(6)]]),
			'facilityIds': new UntypedFormControl([], [...(validators['facilityIds'] ?? []), ...[]]),
			'loginType': new UntypedFormControl(null, [...(validators['loginType'] ?? []), ...[]]),
			'loginTypeEnum': new UntypedFormControl('', [...(validators['loginTypeEnum'] ?? []), ...[Validators.required]]),
			'ssoType': new UntypedFormControl(null, [...(validators['ssoType'] ?? []), ...[]]),
			'ssoTypeEnum': new UntypedFormControl(null, [...(validators['ssoTypeEnum'] ?? []), ...[]]),
			'entraIdentifierUrl': new UntypedFormControl(null, [...(validators['entraIdentifierUrl'] ?? []), ...[Validators.maxLength(500)]]),
			'entraLoginUrl': new UntypedFormControl(null, [...(validators['entraLoginUrl'] ?? []), ...[Validators.maxLength(500)]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditOrganizationVmFromFg {
		return new EditOrganizationVmFromFg(fg);
	}
}

export class EditOrganizationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get organizationName(): string {
		return this.fg.get('organizationName').value;
	}
	set organizationName(val) {
		this.fg.get('organizationName').setValue(val);
	}

	get countyId(): number {
		return this.fg.get('countyId').value;
	}
	set countyId(val) {
		this.fg.get('countyId').setValue(val);
	}

	get countyName(): string {
		return this.fg.get('countyName').value;
	}
	set countyName(val) {
		this.fg.get('countyName').setValue(val);
	}

	get smartyAddress(): any {
		return this.fg.get('smartyAddress').value;
	}
	set smartyAddress(val) {
		this.fg.get('smartyAddress').setValue(val);
	}

	get smartyAddressString(): string {
		return this.fg.get('smartyAddressString').value;
	}
	set smartyAddressString(val) {
		this.fg.get('smartyAddressString').setValue(val);
	}

	get hasSmarty(): boolean {
		return this.fg.get('hasSmarty').value;
	}
	set hasSmarty(val) {
		this.fg.get('hasSmarty').setValue(val);
	}

	get stateAbr(): string {
		return this.fg.get('stateAbr').value;
	}
	set stateAbr(val) {
		this.fg.get('stateAbr').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isSameAddress(): boolean {
		return this.fg.get('isSameAddress').value;
	}
	set isSameAddress(val) {
		this.fg.get('isSameAddress').setValue(val);
	}

	get shipOrganizationName(): string {
		return this.fg.get('shipOrganizationName').value;
	}
	set shipOrganizationName(val) {
		this.fg.get('shipOrganizationName').setValue(val);
	}

	get shipSmartyAddress(): any {
		return this.fg.get('shipSmartyAddress').value;
	}
	set shipSmartyAddress(val) {
		this.fg.get('shipSmartyAddress').setValue(val);
	}

	get shipSmartyAddressString(): string {
		return this.fg.get('shipSmartyAddressString').value;
	}
	set shipSmartyAddressString(val) {
		this.fg.get('shipSmartyAddressString').setValue(val);
	}

	get hasShipSmarty(): boolean {
		return this.fg.get('hasShipSmarty').value;
	}
	set hasShipSmarty(val) {
		this.fg.get('hasShipSmarty').setValue(val);
	}

	get shipStateAbr(): string {
		return this.fg.get('shipStateAbr').value;
	}
	set shipStateAbr(val) {
		this.fg.get('shipStateAbr').setValue(val);
	}

	get isShipAddressValidated(): boolean {
		return this.fg.get('isShipAddressValidated').value;
	}
	set isShipAddressValidated(val) {
		this.fg.get('isShipAddressValidated').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get gln(): string {
		return this.fg.get('gln').value;
	}
	set gln(val) {
		this.fg.get('gln').setValue(val);
	}

	get medicalDirectorId(): number {
		return this.fg.get('medicalDirectorId').value;
	}
	set medicalDirectorId(val) {
		this.fg.get('medicalDirectorId').setValue(val);
	}

	get medicalDirectorName(): string {
		return this.fg.get('medicalDirectorName').value;
	}
	set medicalDirectorName(val) {
		this.fg.get('medicalDirectorName').setValue(val);
	}

	get medicalDirectorLicense(): string {
		return this.fg.get('medicalDirectorLicense').value;
	}
	set medicalDirectorLicense(val) {
		this.fg.get('medicalDirectorLicense').setValue(val);
	}

	get medicalDirectorExpDate(): string {
		return this.fg.get('medicalDirectorExpDate').value;
	}
	set medicalDirectorExpDate(val) {
		this.fg.get('medicalDirectorExpDate').setValue(val);
	}

	get isSingleFacility(): boolean {
		return this.fg.get('isSingleFacility').value;
	}
	set isSingleFacility(val) {
		this.fg.get('isSingleFacility').setValue(val);
	}

	get facilityIdentifier(): string {
		return this.fg.get('facilityIdentifier').value;
	}
	set facilityIdentifier(val) {
		this.fg.get('facilityIdentifier').setValue(val);
	}

	get dauCode(): string {
		return this.fg.get('dauCode').value;
	}
	set dauCode(val) {
		this.fg.get('dauCode').setValue(val);
	}

	get loginType(): string {
		return this.fg.get('loginType').value;
	}
	set loginType(val) {
		this.fg.get('loginType').setValue(val);
	}

	get loginTypeEnum(): any {
		return this.fg.get('loginTypeEnum').value;
	}
	set loginTypeEnum(val) {
		this.fg.get('loginTypeEnum').setValue(val);
	}

	get ssoType(): string {
		return this.fg.get('ssoType').value;
	}
	set ssoType(val) {
		this.fg.get('ssoType').setValue(val);
	}

	get ssoTypeEnum(): any {
		return this.fg.get('ssoTypeEnum').value;
	}
	set ssoTypeEnum(val) {
		this.fg.get('ssoTypeEnum').setValue(val);
	}

	get entraIdentifierUrl(): string {
		return this.fg.get('entraIdentifierUrl').value;
	}
	set entraIdentifierUrl(val) {
		this.fg.get('entraIdentifierUrl').setValue(val);
	}

	get entraLoginUrl(): string {
		return this.fg.get('entraLoginUrl').value;
	}
	set entraLoginUrl(val) {
		this.fg.get('entraLoginUrl').setValue(val);
	}
}
