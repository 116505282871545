import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditFacilityInventoryDetailVm {
	facilityId: number;
	formTypeIds?: number[];
	formTypes?: string[];
	friendlyId?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'formTypeIds': new UntypedFormControl([], [...(validators['formTypeIds'] ?? []), ...[]]),
			'formTypes': new UntypedFormControl([], [...(validators['formTypes'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditFacilityInventoryDetailVmFromFg {
		return new EditFacilityInventoryDetailVmFromFg(fg);
	}
}

export class EditFacilityInventoryDetailVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}
}
