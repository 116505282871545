import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

import { RxDataStorageService } from 'app/fulfillment/services/rx-data-storage.service';
import { PatientDataVm } from 'app/shared/generated/Models/PatientDataVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { InventoryService } from '../services/inventory.service';

@UntilDestroy()
@Component({
    selector: 'pcg-scan-rx',
    templateUrl: './scan-rx.component.html',
    standalone: false
})
export class ScanRxComponent implements OnInit {

	constructor(private httpClient: HttpClient, private ms: SystemMessageService, private inventoryService: InventoryService
		, public router: Router, private rxDataStorageService: RxDataStorageService, private formatHelper: FormatHelperService) {
	}

	// These variables are used for the scanning sounds
	beepSuccess = new Audio();
	beepError = new Audio();
	isControlDown = false;

	@Input() autofocus = true;
	@Input() inputPlaceholder = 'Scan Rx';
	@Input() id?: number = null;
	@Input() inventoryId: number;
	@Input() navigateToShipment = false;
	@Input() hitApi = true;

	@Output() scanSuccess = new EventEmitter<PatientDataVm>();
	@Output() scanSuccessFromList = new EventEmitter<number[]>();
	@Output() scanSuccessInventoryList = new EventEmitter<PatientDataVm>();
	@Output() scanError = new EventEmitter<PatientDataVm>();
	@Output() scan = new EventEmitter<PatientDataVm>();

	@ViewChild('rxNumber', { static: true }) rxNumber: ElementRef;

	subscriptions = new Subscription();
	formGroup = PatientDataVm.Form;
	model: any;
	patient: any;

	/** Handle control up and down */
	@HostListener('document:keydown', ['$event'])
	controlDown(event: KeyboardEvent) { if (event.key === 'Control') { this.isControlDown = true; } }

	@HostListener('document:keyup', ['$event'])
	controlUp(event: KeyboardEvent) { if (event.key === 'Control') { this.isControlDown = false; } }

	ngOnInit() {
		const basePath = document.getElementsByTagName('base')[0].href;
		this.beepSuccess.src = basePath + 'assets/sounds/GreenScan.mp3';
		this.beepSuccess.load();
		this.beepError.src = basePath + 'assets/sounds/RedScan.mp3';
		this.beepError.load();
	}

	/**
	 * Hitting F8 and Ctrl + ] will add a double arrow, which can be used as a delimiter.
	 * New Zebra Scanners will add a delimeter with the Alt key.
	 * Hitting Enter will process the barcode.
	 * @param event The key down event
	 */
	keyDown(event: { key: string; preventDefault: () => void; }) {
		if (event.key === 'F8' || (this.isControlDown && event.key === ']') || event.key === 'Alt') {
			this.rxNumber.nativeElement.value += '↔';
			console.log("scan-rx: keydown", this.rxNumber.nativeElement.value);
		} else if (event.key === 'Enter') {
			event.preventDefault();
			this.scanBarcode();
		}
	}

	/** This function gets fired when enter is pressed in the textbox or the scan button is pressed */
	scanBarcode() {
		if (!FormatHelperService.GetIsNullyOrWhitespace(this.rxNumber?.nativeElement?.value)) {
			const barcodeStr = this.rxNumber.nativeElement.value;
			this.rxNumber.nativeElement.value = '';
			if (this.hitApi === true) {
				// Emit the scan events and get the product info, if desired
				if (this.navigateToShipment) {
					this.subscriptions.add(
						this.httpClient.get(`api/Inventory/Shipping/GetShipmentByRxNumber/?rxNumber=${barcodeStr}&inventoryId=${this.inventoryId}`)
							.subscribe((ids: number[]) => {
								// if scanning inside an inventory area and returning a single id go directly to fulfillment
								if (this.inventoryId != null && ids?.length === 1) { 
									// navigate to the shipment
									this.router.navigateByUrl(`pharmacy/inventories/fulfillment/edit/${ids[0]}/${this.inventoryId}`);
									// returning anything greater than 0 filter the list
								} else if (ids?.length > 0 ) {
									this.scanSuccessFromList.emit(ids);
									this.scanSuccess.emit(barcodeStr);
								} else {
									this.beepError.play();
									//Decide the validation message based off of whether or not we are in centralized fulfillment
									let validationMessage = this.formatHelper.GetIsNully(this.inventoryId) 
										? 'The Rx Number scanned has not been used on a fulfillment.' 
										: 'The Rx Number scanned has not been used on a fulfillment in this inventory.';
									this.ms.setSystemMessage(validationMessage, 'warning');
								}		
					}));
				} else if (barcodeStr?.length > 0) {
					console.log("scan-rx: scanBarcode() - should show patient info");
					//If there is no shipment
					if(this.formatHelper.GetIsNully(this.id)) {
						// Passing in true, since it was scanned
						this.inventoryService.getPatientInfoNoShipment(this, barcodeStr, this.rxDataStorageService.IsFacility, true).subscribe(model => {
							this.processPatientInfo(model, barcodeStr, false);
						});
					} else {
						//Otherwise, there is a shipment
						// Passing in true, since it was scanned
						this.inventoryService.getPatientInfo(this, barcodeStr, this.id, true).subscribe(model => { this.processPatientInfo(model, barcodeStr, true); });
					}
				} else { this.emitScan(barcodeStr); }
			} else {
				this.scanSuccessFromList.emit(barcodeStr);
				this.scanSuccess.emit(barcodeStr);
			}
		}
	}

	processPatientInfo(model: PatientDataVm, barcodeStr: string, hasShipment: boolean) {
		console.log("scan-rx: processPatientInfo()", model, barcodeStr, hasShipment);		
		if (!this.formatHelper.GetIsNully(model.errorMessage)) { this.ms.setSystemMessage(model.errorMessage, 'error'); } 
		else if (this.formatHelper.GetIsNully(model.lastName)) { this.ms.setSystemMessage('Invalid Rx Number was entered.', 'error'); }
		this.formGroup.patchValue(model);
		this.model = model;
		if (hasShipment) { this.emitScan(barcodeStr); } 
		else {
			this.rxDataStorageService.PatientData = model;
			this.emitScan(barcodeStr, model);
		}
	}

	/**
	 * This function emits the scan events that can be used by other components
	 * @param barcode The string (RxNumber) that was parsed from the barcode
	 */
	emitScan(barcode: string, patientListDataModel: PatientDataVm = null) {
		console.log("scan-rx: emitScan()", barcode, patientListDataModel);
		if(patientListDataModel !== null){
			this.beepSuccess?.play();
			this.scanSuccessInventoryList?.emit(patientListDataModel);
		} else if (barcode !== null && barcode !== '') {
			this.beepSuccess?.play();
			this.scanSuccess?.emit(this.model);
		} else {
			this.beepError?.play();
			this.scanError?.emit(this.model);
		}
		this.scan?.emit(this.model);
	}
}
