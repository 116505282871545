import { faFileLines } from '@fortawesome/pro-solid-svg-icons';

import { NavRoute } from "app/shared/navigation/nav.route.interface";
import { BusinessAreaEnum } from "../enums/generated/BusinessAreaEnum";
import { PermissionRole } from "../enums/generated/PermissionRole";
import { SecurityService } from "../security/security.service";

export const getReportingNav: () => NavRoute = () => {
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.
	return {
		name: 'Reporting'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Reports ]
				, permissionRoles: [ ...sec.setMinRole(p.User) ]
			}
		]
		, icon: faFileLines
		, area: 'reporting'
		, children: [
			{
				name: 'Reports'
				, exact: true
				, path: 'reporting/list'
				, activeNavLink: 'reporting'
				, secondaryNav: ['Reporting', 'Reports']
			}
			, {
				name: 'Admin'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Reports ]
						, permissionRoles: [ ...sec.setMinRole(p.SystemAdmin) ]
					}
				]
				, children: [
					{
						name: 'Facility Users'
						, exact: true
						, path: 'reporting/admin/facility-users/list'
						, activeNavLink: 'reporting/admin/facility-users'
						, secondaryNav: ['Reporting', 'Admin', 'Facility Users']
					}
				]
			}
		]
	};
};
