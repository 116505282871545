import { Component } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'pcg-page-info',
    template: `
		<div class='d-flex flex-row mb-4 info-container'>
			<fa-icon [icon]='faInfoCircle' class='description-icon'></fa-icon>
			<span class='d-flex flex-column'>
				<!-- Content passed through will be placed here. This is intended to be used for page information. -->
				<ng-content></ng-content>
      </span>
		</div>
	`,
    standalone: false
})
export class PageInfoComponent {
	faInfoCircle = faInfoCircle;

	constructor() {}
}
