import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { UserAssociatedOrganizationsComponent } from './user-associated-organizations/user-associated-organizations.component';
import { UserNonAssociatedOrganizationsComponent } from './user-non-associated-organizations/user-non-associated-organizations.component';

@UntilDestroy()
@Component({
    selector: 'pcg-user-organizations',
    templateUrl: './user-organizations.component.html',
    styleUrls: ['./user-organizations.component.scss'],
    standalone: false
})
export class UserOrganizationsComponent implements OnInit {
  
    @Input() userId : number;

    @ViewChild("available") availableOrgs : UserNonAssociatedOrganizationsComponent;
    @ViewChild("associated") associatedOrgs : UserAssociatedOrganizationsComponent;

    faIcon = { faInfoCircle }
    canManageOrgs : boolean;

    constructor(private sec : SecurityService) {}

    ngOnInit(): void {
        this.canManageOrgs = this.sec.hasModuleAccess([ BusinessAreaEnum.Admin ]
          , [ ...SecurityService.setMinRole(PermissionRole.Manager) ])
    }

    sync() {
        this.availableOrgs.table.ajaxReload();
        this.associatedOrgs.table.ajaxReload();
    }
}
