import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class SysInvInventoryVm {
	sysInvInventoryId: number;
	facilityId: number;
	organizationId: number;
	name?: string;
	type?: any;
	programIds?: number[];
	programs?: string[];
	active: boolean;
	canDelete?: boolean;
	friendlyId?: string;
	createdByUserStr?: string;
	createdByUserId?: number;
	createdDate?: Date;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'sysInvInventoryId': new UntypedFormControl(0, [...(validators['sysInvInventoryId'] ?? []), ...[Validators.required]]),
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'organizationId': new UntypedFormControl(0, [...(validators['organizationId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.required]]),
			'type': new UntypedFormControl('', [...(validators['type'] ?? []), ...[Validators.required]]),
			'programIds': new UntypedFormControl([], [...(validators['programIds'] ?? []), ...[Validators.required]]),
			'programs': new UntypedFormControl([], [...(validators['programs'] ?? []), ...[]]),
			'active': new UntypedFormControl(false, [...(validators['active'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(null, [...(validators['canDelete'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'createdByUserStr': new UntypedFormControl(null, [...(validators['createdByUserStr'] ?? []), ...[]]),
			'createdByUserId': new UntypedFormControl(null, [...(validators['createdByUserId'] ?? []), ...[]]),
			'createdDate': new UntypedFormControl(null, [...(validators['createdDate'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['name', {"orderId":0,"sortColumn":"name","searchColumn":"name","includeInExcel":true,"exportColumns":null,"displayName":"Inventory","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['type', {"orderId":1,"sortColumn":"type","searchColumn":"type","includeInExcel":true,"exportColumns":null,"displayName":"Type","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['active', {"orderId":2,"sortColumn":"active","searchColumn":"active","includeInExcel":true,"exportColumns":null,"displayName":"Is Active?","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['canDelete', {"orderId":3,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":true,"exportColumns":null,"displayName":"Delete","isVisible":false,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): SysInvInventoryVmFromFg {
		return new SysInvInventoryVmFromFg(fg);
	}
}

export class SysInvInventoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get sysInvInventoryId(): number {
		return this.fg.get('sysInvInventoryId').value;
	}
	set sysInvInventoryId(val) {
		this.fg.get('sysInvInventoryId').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get name(): string {
		return this.fg.get('name').value;
	}
	set name(val) {
		this.fg.get('name').setValue(val);
	}

	get type(): any {
		return this.fg.get('type').value;
	}
	set type(val) {
		this.fg.get('type').setValue(val);
	}

	get active(): boolean {
		return this.fg.get('active').value;
	}
	set active(val) {
		this.fg.get('active').setValue(val);
	}

	get canDelete(): boolean {
		return this.fg.get('canDelete').value;
	}
	set canDelete(val) {
		this.fg.get('canDelete').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get createdByUserStr(): string {
		return this.fg.get('createdByUserStr').value;
	}
	set createdByUserStr(val) {
		this.fg.get('createdByUserStr').setValue(val);
	}

	get createdByUserId(): number {
		return this.fg.get('createdByUserId').value;
	}
	set createdByUserId(val) {
		this.fg.get('createdByUserId').setValue(val);
	}

	get createdDate(): Date {
		return this.fg.get('createdDate').value;
	}
	set createdDate(val) {
		this.fg.get('createdDate').setValue(val);
	}
}
