import { Component, ElementRef, HostListener, inject, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User as ddUser } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { faUser, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { BusinessAreaEnum } from './core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from './core/enums/generated/PermissionRole';
import { InactivityTimeoutService } from './core/inactivity-timeout/inactivity-timeout.service';
import { SecurityService } from './core/security/security.service';
import { UserJsVm } from './shared/generated/Administration/Models/Users/UserJsVm';
import { pcgSettings } from './shared/generated/pcg-settings';
import { NavigationService } from './shared/navigation/navigation.service';
import { GlobalService } from './shared/services/global.service';

/**
 * This is the application's root component.
 */
@Component({
	selector: 'pcg-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: false
})
export class AppComponent implements OnInit, OnDestroy {

	@ViewChild('tabPanel') tabPanel: any;

	fileLogoUrl = "../assets/images/logos/logo_small.png";
	
	errorCache: any = {};
	// The following are error codes thrown as a result of
	// system security and should not be logged to datadog.
	// If you make changes here, also update the ErrorLoggingMiddleware.cs
	ignoredErrorCodes: number[] = [
		401   // Login redirect
		, 403 // Access denied
		, 466 // Notification.Confirm() Msg
		, 467 // 2fa not enabled
	];
	
	// Keeps track if nav was fixed or not before closing for print
	navState: boolean = false;
	navService = inject(NavigationService);

	@HostListener('window:resize')
	onResize() { 
		this.navService.isMobile.set(GlobalService.setIsMobile(window.innerWidth)); 
		if (this.navService.isMobile()) { 
			localStorage.setItem("isNavFixed", "false"); 
			this.navService.toggleNavFixed(false); 
			this.navService.toggleNavOpen(false);
		}
	}

	// Ensure nav is not fixed open before printing
	@HostListener('window:beforeprint')
	closeNav = () => {
		this.navState = this.navService.isNavFixed();
		this.navService.toggleNavFixed(false);
	}

	@HostListener('window:afterprint')
	resetNav = () => this.navService.toggleNavFixed(this.navState);

	// Nav
	isLoggedOn = signal<boolean>(false);
	mode = pcgSettings.mode; //for responsive BETA text

	// These should be managed in the navigation routes, use only for special cases
	faIconName = { faUser, faArrowRight };

	// My Profile
	user: UserJsVm;

	// Enums
    busArea = BusinessAreaEnum;
	role = PermissionRole;

	// Inactivity timeout needs to be injected here to have a constant timeout going in the background
	constructor(
		private router: Router
		, elRef: ElementRef
		, private inactivityService: InactivityTimeoutService
		, private sec: SecurityService
		, private modalConfig: NgbModalConfig
		, public gs: GlobalService
	) {
		this.configureDatadog();
		this.configureModal(); // Default modal settings, such as disabling the animation
		sec.user$.subscribe(user => {
			// For 'My Profile' nav functionality
			this.user = user; 
			const isLoggedIn: boolean = sec.isLoggedOn();

			this.isLoggedOn.set(isLoggedIn);
			if (isLoggedIn) { elRef.nativeElement.classList.add('is-logged-in'); } 
			else { elRef.nativeElement.classList.remove('is-logged-in'); }
		});
		window['$router'] = router;
	}

	ngOnInit() { 
		this.navService.isMobile.set(GlobalService.setIsMobile(window.innerWidth)); 
		// Remember if nav is fixed
		if (this.navService.isMobile()) { this.navService.toggleNavFixed(false); } 
		else { this.navService.toggleNavFixed(localStorage.getItem("isNavFixed") === true.toString()); }
	}

	/** Set default ng-bootstrap modal options */
	configureModal() {
		// Disable ng bootstrap modal animations by default
		this.modalConfig.animation = false;
		this.modalConfig.ariaLabelledBy = 'modal-basic-title';
	}
	
	configureDatadog() {
		if (pcgSettings.mode.toLowerCase() !== 'development') {
			datadogLogs.setGlobalContext({ env: pcgSettings.mode });
			datadogLogs.setGlobalContextProperty('referrer', document.referrer);
			datadogLogs.logger.setLevel('warn');
	
			this.initDataDog();
			datadogLogs.setUser(this.getDatadogUser(this.sec.getUser()));
			
			this.sec.user$.subscribe(newUser => { 
				this.initDataDog();
				datadogLogs.setUser(this.getDatadogUser(newUser));
			});
		}
	}

	initDataDog() {
		datadogLogs.clearUser();
		const initConfig = datadogLogs.getInitConfiguration();
		if (initConfig == null) { // stop datadog init if it is already initialized
			datadogLogs.init({
				beforeSend: (log) => {
					// discard ignored error codes
					if (this.ignoredErrorCodes.includes(log?.http?.status_code)) { return false; }
				}
				, clientToken: "pub77451973018f724e8a9f58aa8d77985d"
				, trackingConsent: 'granted'
				, site: "us5.datadoghq.com"
				, service: "homer"
				, version: "3c21de4113"
				, env: pcgSettings.mode
				, forwardErrorsToLogs: true
				, sessionSampleRate: 100			
			});
			console.log("datadogLogs initialized!");
		}	
	}

	getDatadogUser(userJs: UserJsVm): ddUser {
		let ddUser : ddUser = { 
			id: userJs?.id.toString()
			, email: userJs?.email
			, name: `${userJs?.firstName} ${userJs?.lastName}` 
		}
		return ddUser;
	}

	takeToMyProfile() { this.router.navigateByUrl('/admin/users/user/edit/' + this.user?.id); }
	ngOnDestroy(): void { this.inactivityService.stopTracking(); }
}
