<div
    id="ribbon"
    class="d-flex justify-content-between"
>
    <span 
        (click)="openCiWorkstationModal()" 
        class="current-workstation align-with-nav"
    >
        Current Workstation: <b [ngClass]="noWorkstation ? 'text-danger' : ''">{{ workStationName }}</b>
    </span>

    <div [ngClass]="isFixed ? 'fixed-nav-margin' : 'nav-margin'" class="pr-1">        
        @if (dashboardActionsClicked) {
            <pcg-ci-dashboard-actions 
                [dashboardActionsClicked]="dashboardActionsClicked"
                (reload)="dashboardActionsClicked = false"
                #ciDashboardComponent
                id="ciDashboardContent"
                pcgClickOutside 
                (clickOutside)="ciDashboardComponent.clickedOutside()"
                [elementId]="'ciDashboardContent'"   
                class="dashboard-actions-overlay"
            ></pcg-ci-dashboard-actions>
        } @else {
        <span 
        class="scan-button"
        (click)="dashboardActionsClicked = true">
            <fa-icon 
                [icon]="faIcons.faScannerGun"                 
                class="scanner-gun"/>
        </span>
        }
    </div>    
</div>
