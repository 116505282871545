import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { InventoryTypeEnum } from 'app/core/enums/generated/InventoryTypeEnum';
import { FacilityInventoryVm } from 'app/shared/generated/Administration/Models/Facilities/Inventories/FacilityInventoryVm';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-facility-inventories',
    templateUrl: './facility-inventories.component.html',
    styleUrls: ['./facility-inventories.component.scss'],
    standalone: false
})
export class FacilityInventoriesComponent implements OnInit {
  @Input() facilityId : number;
  @ViewChild(TableComponent, { static: true })	table: TableComponent<FacilityInventoryVm>;
  
  filters = {
    facilityId: null,
    isActive: true,
  };

  filterMap = {
    isActive: "Is Active"
	};

  /** Filter params to be set when filter is reset. */
	initFilters = Object.assign({}, this.filters);

  columns = FacilityInventoryVm.ColumnDefs

  constructor(public btnFilterService: ButtonFilterService) {
    
  }

  ngOnInit() : void {
    this.filters.facilityId = this.facilityId;
    this.btnFilterService.resetFilterData.subscribe(() => {
			Object.assign(this.filters, this.initFilters);
      this.filters.facilityId = this.facilityId;
			this.table.ajaxReload();
		});
  }

  /**
   * Converts enum to its display value.
   * @param val 
   * @returns The display name of the enumerable.
   */
  parseEnum = (val : number) => InventoryTypeEnum.toDisplay(val)
}