import { HttpClient } from "@angular/common/http";
import { Component, inject, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { faBuilding } from "@fortawesome/pro-solid-svg-icons";
import { PcgSelectComponent } from "app/shared/form-elements/components/pcg-select-component/select.component";
import { UiSwitchComponent } from "ngx-ui-switch";
import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivationEnum } from "app/core/enums/generated/ActivationEnum";
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessage } from "app/core/system-message/system-message-service";
import { NotificationService } from 'app/notification.service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { MedicalDirectorVm } from 'app/shared/generated/Administration/Models/Facilities/MedicalDirectorVm';
import { EditOrganizationVm } from "app/shared/generated/Administration/Models/Organizations/EditOrganizationVm";
import { GenericFilesVm } from 'app/shared/generated/Models/GenericFilesVm';
import { FormatHelperService } from "app/shared/helpers/format-helper.service";
import { ActivationService } from "app/shared/services/activation.service";

@Component({
    selector: "pcg-shared-edit-organization",
    templateUrl: "./edit-organization.component.html",
    styleUrls: ["./edit-organization.component.scss"],
    standalone: false
})
export class EditOrganizationComponent implements OnInit {
	
	@ViewChild("isActive", { static: false }) isActive: UiSwitchComponent;
	@ViewChild("isSameAddress", { static: false }) isSameAddress: UiSwitchComponent;
	@ViewChild("isShipAddressValidated", { static: false }) isShipAddressValidated: UiSwitchComponent;
	@ViewChild("addNewMD", { static: true }) addNewMD: TemplateRef<any>;
	@ViewChild("select", { static: false }) select: PcgSelectComponent;

	@Input() businessArea: BusinessAreaEnum;
	
	model$: Observable<EditOrganizationVm>;
	formGroup = EditOrganizationVm.Form;
	model = EditOrganizationVm.GetModel(this.formGroup);
	viewModel = new EditOrganizationVm();	

	canEdit = false;
	canDeactivate = false;
	canViewReports = false;
	canManualEntry = true;
	shipAddressDisabled = false;
	canAccess = false;

	id: number;
	facilityIds: number[] = [];

	organizationName: string;
	oldShipSmartyAddress: string;
	oldShipOrganizationName: string;
	api = "api/Select/SmartyCounties";

	faIconName = { faBuilding };

	newMD = new MedicalDirectorVm();	
    mdFormGroup = MedicalDirectorVm.Form;
    subscriptions = new Subscription();
    medicalDirectorFiles: GenericFilesVm[] = [];

    deleteClicked = false;
    hasFile = false;
    clearFileClick = false;

	modalService = inject(NgbModal);
	
	constructor(
		public router: Router
		, public notification: NotificationService
		, private route: ActivatedRoute
		, private httpClient: HttpClient
		, private sec: SecurityService
		, private activationService: ActivationService
		, title: Title
	) { title.setTitle("Edit Organization"); }

	ngOnInit() {
		const bae = BusinessAreaEnum;
		const pr = PermissionRole;

		this.canEdit = this.sec.hasModuleAccess(
			[bae.CI, bae.MetabolicFormula, bae.Admin],
			[pr.UserAdmin, ...SecurityService.setMinRole(pr.Manager)]
		);

		this.canViewReports = this.sec.hasModuleAccess(
			[bae.CI, bae.Admin], 
			[pr.UserAdmin, ...SecurityService.setMinRole(pr.Manager)]
		);

		this.editOrganization();

		if (
			FormatHelperService.GetIsNully(this.businessArea) 
			|| this.businessArea === bae.Admin
		) {
			if (this.sec.hasModuleAccess([bae.Admin], [...SecurityService.setMinRole(pr.SystemAdmin)])) {
				this.canEdit = true;
				this.canDeactivate = true;
			}
		} 

		this.setCanAccess();
	}

	setCanAccess() {
		this.canAccess = this.sec.hasModuleAccess(
			[BusinessAreaEnum.Admin],
			[...SecurityService.setMinRole(PermissionRole.User)]
		);
	}

	editOrganization() {
		this.route.paramMap.subscribe((map) => {
			this.id = parseInt(map.get("id"), 10);
			this.model$ = this.httpClient.get(`api/Administration/Organization/GetOrganization/${this.id}`)
				.pipe(tap((vm: EditOrganizationVm) => {
					if (vm.countyId === 0) { vm.countyId = null; } 
					// preventing a blank selection on load
					this.formGroup.setValue(vm);
					this.model = EditOrganizationVm.GetModel(this.formGroup);
					this.viewModel = vm;
					this.organizationName = vm.organizationName;
					this.oldShipOrganizationName = this.model.shipOrganizationName;
					this.oldShipSmartyAddress = this.model.shipSmartyAddress;
					this.toggleShippingFormActivation(!this.model.isSameAddress);
					this.facilityIds = vm.facilityIds;
				})
			);
		});
	}

	activateDeactivate = () => this.activationService.activateDeactivate(
		this.id
		, ActivationEnum.Organization
		, this.formGroup.value.isActive ? false : true
	);

	updateShippingInfo() {
		this.formGroup.controls.countyName.patchValue(this.formGroup.controls.smartyAddress.value.countyName);
		if (this.isSameAddress.checked) {
			this.formGroup.get("shipOrganizationName").patchValue(this.formGroup.value.organizationName);
			this.formGroup.get("shipSmartyAddress").patchValue(this.formGroup.value.smartyAddress);
			this.toggleShippingFormActivation(false);
		} else {
			this.formGroup.get("shipOrganizationName").patchValue(this.oldShipOrganizationName);
			this.formGroup.get("shipSmartyAddress").patchValue(this.oldShipSmartyAddress);
			this.toggleShippingFormActivation(true);
		}
	}

	toggleShippingFormActivation(isActive: Boolean) {
		if (isActive) {
			this.enableShipOrganizationName();
			this.shipAddressDisabled = false;
		} else {
			this.disableShipOrganizationName();
			this.shipAddressDisabled = true;
		}
	}

	validateCountyId() {
		if (this.formGroup?.value?.countyId === 0) {
			this.formGroup?.controls?.countyId?.patchValue(null);
			this.model = EditOrganizationVm.GetModel(this.formGroup);
		}
		this.formGroup.updateValueAndValidity();
	}

	submitForms() {
		if (!this.formGroup.valid) {
			validateForm(this.formGroup);
			return;
		}
		
		let disabled = this.hasDisabledShipOrganizationName();
		// As a result of moving this to a .ts POST method, disabled values
		// won't go through within the request body.
		if (this.hasDisabledShipOrganizationName) { this.enableShipOrganizationName(); }

		this.httpClient.post(
			`api/Administration/Organization/SaveOrganization`,
			this.formGroup.value
		).subscribe((sm: SystemMessage) => {
			// Re-disable property after POST request.
			if (disabled) { this.disableShipOrganizationName(); }
			if (sm.isSuccessful) { this.onMessageSuccess(sm); } 
			else { this.onMessageError(sm); }
		});
	}

	reroute(sm: SystemMessage) {
		if (
			this.businessArea === BusinessAreaEnum.Admin 
			|| FormatHelperService.GetIsNully(this.businessArea) 
			|| Number.isNaN(this.businessArea)
		) {
			this.router.navigate([`/admin/organizations/orgs/edit/${sm.value}`]);
		} else if (this.businessArea === BusinessAreaEnum.CI) {
			this.router.navigate([`/ci-repack/facilities/edit/${sm.value}`]);
		} else if (this.businessArea === BusinessAreaEnum.MetabolicFormula) {
			this.router.navigate([`/metabolic-formula/facilities/edit/${sm.value}`]);
		}
	}

	enableShipOrganizationName() {
		this.formGroup.get("shipOrganizationName").enable();
		this.formGroup.get("shipSmartyAddress").enable();
	}

	disableShipOrganizationName() {
		this.formGroup.get("shipOrganizationName").disable();
		this.formGroup.get("shipSmartyAddress").disable();
	}

	medDirectorChange(select: PcgSelectComponent) {
		if (select.value) {
			this.model.medicalDirectorLicense = select.additionalData?.licenseNumber;
			this.model.medicalDirectorExpDate = select.additionalData?.expirationDate;
		} else {
			this.model.medicalDirectorLicense = "";
			this.model.medicalDirectorExpDate = "";
		}
	}

	onSingleFacilityChange() {
		if (this.model.isSingleFacility) {
			this.formGroup.controls.facilityIdentifier.addValidators([Validators.required]);
		} else {
			this.formGroup.controls.facilityIdentifier.removeValidators([Validators.required]);
		}
		this.formGroup.controls.facilityIdentifier.updateValueAndValidity();
	}

	setSysMessage = (sm: SystemMessage) => this.notification.showMessage(sm.message, sm.messageClass);	
	hasDisabledShipOrganizationName = () => this.formGroup.get("shipOrganizationName").disabled;	
	goBack = () => window.history.back();

	onMessageSuccess(sm: SystemMessage) {
		this.setSysMessage(sm);
		this.formGroup.setValue(sm.model);
		this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => { this.reroute(sm); });
	}

	onMessageError(sm: SystemMessage) {
		this.setSysMessage(sm);
		this.formGroup.patchValue(sm.model);
	}

	addMedicalDirector(name) {
		this.newMD.fullName = FormatHelperService.ToTitleCase(name).trim();
		this.newMD.medicalDirectorId = 0;
        this.mdFormGroup.patchValue(this.newMD); 
		this.mdFormGroup.updateValueAndValidity();
        this.medicalDirectorFiles = this.newMD?.medicalDirectorFiles;
        if (!FormatHelperService.GetIsNullyOrWhitespace(this.mdFormGroup.controls.licenseFileName.value)) { this.hasFile = true; }
        else { this.hasFile = false; }
		this.modalService.open(this.addNewMD, { animation: false });
		return false;
	}

	saveNewMedicalDirector() {
        if (validateForm(this.mdFormGroup)) { 
            if (this.canEdit === true) {
              this.subscriptions.add(this.notification.getHttpObservable(`api/Administration/MedicalDirector/Save${this.id == 0 ? "" : "/" +  this.id }`, this.mdFormGroup)
                  .subscribe(msg => {
                      if (msg.isSuccessful) { 
						  this.formGroup.controls.medicalDirectorId.patchValue(msg.value);
						  this.select.getData();
						  if (this.id == 0) this.submitForms(); // need to look into saving this in the first backend hit.
                          this.modalService.dismissAll();
                      }
                  }));
          }
        } 
        return false;       
    }
}
