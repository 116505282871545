<br />

<div class='clearfix'>
	<div class='float-left'>
		<h1>HEROS Application</h1>
	</div>
</div>

<hr />

<div 
	id='informativeContent' 
	*ngIf='showInformativeContent'
>
	<p>
		HEROS: Helping Emergency Responders Obtain Support. See 
		<a 
			target='_blank' 
			rel="noreferrer noopener"
			href='http://www.floridahealth.gov/licensing-and-regulation/ems-system/heros.html'
		>link</a> 
		for more details on the HEROS program
	</p>
	<span *ngIf='canSubmit && !isSubmitted'>
		Fill out agency and application information to complete your submission.
		Please contact 
		<a href='mailTo:HEROSProgram@flhealth.gov'>HEROSProgram&#64;flhealth.gov</a>
		if you need assistance with completing a submission.
		<br>Previous Applicants, please 
		<a href='https://fdepcs.com/login'>login</a> 
		to complete the application.
	</span>
	<!-- Do not allow submissions outside of open rounds -->
	<span *ngIf='!canSubmit'>We are not accepting applications at this time.</span>
</div>

<div 
	id='enterAgency' 
	*ngIf='showAgencyInput && canSubmit'
>
	<br />
	<img 
		class='system-logo' 
		src='assets/images/logos/logo.png' 
	/>
	<br />
	<div class='agency-container clearfix'>
		<div class='row'>
			<div class="col-sm-12">
				<label class='for required-field'>Enter your Agency's name</label>
				<input 
					type="text" 
					#agency 
					class="form-control" 
					(keydown.enter)="checkAgency(agency.value)"
				/>
			</div>
		</div>
		<button 
			*ngIf="!emptyCaptcha" 
			type="submit" 
			(click)='checkAgency(agency.value)'
			class="btn btn-secondary btn-lg"
		>Next</button>

		<form [formGroup]='agencyFormGroup'>
			<div>
				<ngx-invisible-recaptcha 
					#agencyCaptchaElem 
					[siteKey]="siteKey" 
					(reset)="handleAgencyReset()"
					(ready)="handleAgencyReady()" 
					(load)="handleAgencyLoad()" 
					(success)="handleAgencySuccess($event)"
					[useGlobalDomain]="false" 
					theme="light" 
					type="image" 
					badge="bottomleft"
					formControlName="agencyRecaptcha">
				</ngx-invisible-recaptcha>
			</div>
		</form>
	</div>
</div>

<div 
	class='clearfix padded' 
	*ngIf='showAgencyList'
>
	<br>
	<pcg-table 
		[ajaxData]='filters' 
		[multipleSearch]='false' 
		[pcgSort]='[[ "agencyName", "asc" ]]'
		dataSource='api/PublicHEROS/GetAgenciesByInput' 
		[columnDefs]='columns'
	>
		<ng-container matColumnDef="agencyName">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('agencyName').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row'
			>{{ row.agencyName }}</td>
		</ng-container>
		<ng-container matColumnDef="agencyAbbreviation">
			<th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('agencyAbbreviation').displayName }}</th>
			<td 
				mat-cell 
				*matCellDef='let row'
			>{{ row.agencyAbbreviation }}</td>
		</ng-container>
	</pcg-table>
	<pcg-fixed-buttons>
		<div class='text-center'>
			<h4>Please Select One of the Following</h4>
			<button 
				class='btn select-one btn-dark'
				(click)='showAgencyList = false; showInformativeContent = false; showAgencyInSystem=true'
			>My Agency is on this list</button>
			<button 
				class='btn select-one btn-dark'
				(click)="newAgencyForm()"
			>My Agency is not on this list</button>
		</div>
		<a 
			class='btn btn-cancel btn-lg mt-1' 
			(click)="backBtn()"
		> Back</a>
	</pcg-fixed-buttons>
</div>

<div *ngIf='showAgencyInSystem'>
	<div class='padded'>
		<p>
			Your agency is already in our system. Please contact 
			<a href='mailTo:HEROSProgram@flhealth.gov'>HEROSProgram&#64;flhealth.gov</a> 
			for further instructions.
		</p>
	</div>
	<pcg-fixed-buttons>
		<a 
			class='btn btn-cancel btn-lg' 
			(click)="backBtn()"
		> Back</a>
	</pcg-fixed-buttons>
</div>

<div 
	id='application' 
	*ngIf='showForm'
>
	<form 
		*ngIf='!isSubmitted && canSubmit' 
		(submit)='savePublicApplicationClick(website.value)'
		[formGroup]='formGroup'
	>
		<div formGroupName='agency'>
			<pcg-collapsible-divider header='Agency Information'>
				<pcg-control-group label='Agency Name'>
					<input 
						pcgControlRef 
						class='form-control' 
						type='text' 
						formControlName='agencyName'
						maxlength="100" 
					/>
				</pcg-control-group>

				<div class='row'>
					<pcg-control-group 
						class="col-md-2" 
						label="Federal Employer ID Number"
					>
						<input 
							pcgControlRef 
							pcgFein 
							class='form-control' 
							type='text' 
							formControlName="fein" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-2" 
						label="Agency Abbreviation"
						labelTooltip="You can enter up to 5 letters for an abbreviation for your agency. The abbreviation, along with the FEIN, will be used to uniquely identify your agency."
					>
						<input 
							pcgControlRef 
							pcgAbbreviation 
							class='form-control' 
							type='text'
							formControlName="agencyAbbreviation" 
							oninput="this.value = this.value.toUpperCase()"
							maxlength="5" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="MMCAP Membership ID"
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName="mmcapAccountNumber"
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="Organizational Tax Status"
					>
						<pcg-select 
							[sendFormData]='false' 
							pcgControlRef [multiple]='false'
							dataSource='api/PublicHEROS/HEROSOrgTaxStatuses' 
							formControlName='taxStatus'
							placeholder='Select an Organizational Tax Status'
						>
						</pcg-select>
					</pcg-control-group>
				</div>

				<div class='row'>
					<pcg-control-group 
						class="col-md-4" 
						label="EMS Agency State Licensure ID"
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							pcgNumeric 
							inputmode="numeric"
							formControlName="emsStateId" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="Anticipated MMCAP Membership Date"
					>
						<input 
							type="date" 
							pcgControlRef 
							formControlName="mmcapAnticipatedDate" 
							class="form-control"
							[ngModel]="formGroup?.controls?.mmcapAnticipatedDate?.value | date:'yyyy-MM-dd'" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="Other Organizational Tax Status"
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName="otherTaxStatus"
							maxlength="100" 
						/>
					</pcg-control-group>
				</div>

				<div class='row'>
					<pcg-control-group 
						class="col-md-4" 
						label="County"
					>
						<pcg-select 
							[sendFormData]='false' 
							pcgControlRef 
							[multiple]='false'
							dataSource='api/PublicHEROS/Counties' 
							formControlName='county'
							placeholder='Select a County'
						></pcg-select>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="Is Agency MMCAP Member?"
					>
						<pcg-radio-button-list 
							pcgControlRef 
							formControlName="isMmcap" 
							items='yesNo'
						></pcg-radio-button-list>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="Organizational Type"
					>
						<pcg-select 
							[sendFormData]='false' 
							pcgControlRef 
							[multiple]='false'
							dataSource='api/PublicHEROS/HEROSOrgTypes' 
							formControlName='orgType'
							placeholder='Select an Organizational Type'
						></pcg-select>
					</pcg-control-group>
				</div>

				<div class='row'>
					<pcg-control-group 
						class="col-md-4" 
						label="County of Operation"
					>
						<pcg-select 
							[sendFormData]='false' 
							pcgControlRef 
							[multiple]='false'
							dataSource='api/PublicHEROS/Counties' 
							formControlName='operationCounty'
							placeholder='Select a County of Operation'
						></pcg-select>
					</pcg-control-group>
					<div class='col-md-4'></div>
					<pcg-control-group 
						class="col-md-4" 
						label="Other Organization Type"
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName="otherOrgType"
							maxlength="100" 
						/>
					</pcg-control-group>
				</div>
			</pcg-collapsible-divider>

			<pcg-collapsible-divider header="Agency Address">
				<div class='row'>
					<pcg-control-group 
						class="col-md-8" 
						label="Address"
					>
						<pcg-smarty 
							pcgControlRef 
							formControlName="agencySmartyAddress" 
							id="agencySmartyAddress"
							[showAllStates]="false" 
							[canManualEntry]="false"
							(change)='updateShippingInfo(useAgencyInfoForShipInfo, false)'
							[countyApi]="countyApi"
						></pcg-smarty>
					</pcg-control-group>
				</div>
			</pcg-collapsible-divider>

			<pcg-collapsible-divider header="Shipping Information">
				<pcg-control-group label="Same as Agency Address?">
					<ui-switch 
						#isSameAddress 
						pcgControlRef 
						(click)='updateShippingInfo(isSameAddress.checked, true)'
						id='useAgencyInfoForShipInfo' 
						class='ml-2 mt-2' 
						formControlName='useAgencyInfoForShipInfo'
					></ui-switch>
				</pcg-control-group>
				<br />

				<div class='row'>
					<pcg-control-group 
						class="col-md-8" 
						label="Shipping Address"
					>
						<pcg-smarty 
							pcgControlRef 
							formControlName="shipSmartyAddress" 
							[showAllStates]="false"
							[canManualEntry]="false" 
							id="shipSmartyAddress"
						></pcg-smarty>
					</pcg-control-group>
				</div>
			</pcg-collapsible-divider>

			<pcg-collapsible-divider header="Agency Head">
				<div class='row'>
					<pcg-control-group 
						class="col-md-4" 
						label="Name"
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName="agencyHeadName"
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="Title"
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName="agencyHeadTitle"
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class="col-md-4" 
						label="Email Address"
					>
						<input 
							pcgControlRef 
							class='form-control email' 
							type='text' 
							formControlName="agencyHeadEmail"
							maxlength="100" 
						/>
					</pcg-control-group>
				</div>

				<div class='row'>
					<pcg-control-group 
						class="col-md-4" 
						label="Phone Number"
					>
						<input 
							pcgControlRef 
							pcgPhoneExt 
							id='agencyHeadPhoneNumber' 
							class='form-control' 
							type='text'
							formControlName='agencyHeadPhoneNumber' 
						/>
					</pcg-control-group>
				</div>
			</pcg-collapsible-divider>

			<pcg-collapsible-divider header='Agency Contacts'>
				<h5>Primary</h5>
				<span class='text-danger'>
					A copy of this application will be sent to this contact upon submission.
					Additionally, a user account will be created from this information after the agency is initially
					approved.
				</span>
				<br />
				<div class='row'>
					<pcg-control-group 
						class='col-md-4' 
						label='Name'
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName='primaryName'
							maxlength="100"
						/>
					</pcg-control-group>

					<pcg-control-group 
						class='col-md-4' 
						label='Title'
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName='primaryTitle'
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class='col-md-4' 
						label='Email'
					>
						<input 
							pcgControlRef 
							class='form-control email' 
							type='text' 
							formControlName='primaryEmail'
							maxlength="100" 
						/>
					</pcg-control-group>
				</div>

				<div class='row'>
					<pcg-control-group 
						class='col-md-4' 
						label='Phone'
					>
						<input 
							pcgControlRef 
							pcgPhoneExt 
							id='phone' 
							class='form-control' 
							type='text'
							formControlName='primaryPhone' 
						/>
					</pcg-control-group>
				</div>
				<br />

				<h5>Secondary</h5>
				<div class='row'>
					<pcg-control-group 
						class='col-md-4' 
						label='Name'
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName='secondaryName'
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class='col-md-4' 
						label='Title'
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName='secondaryTitle'
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class='col-md-4' 
						label='Email'
					>
						<input 
							pcgControlRef 
							class='form-control email' 
							type='text' 
							formControlName='secondaryEmail'
							maxlength="100" 
						/>
					</pcg-control-group>
				</div>

				<div class='row'>
					<pcg-control-group 
						class='col-md-4' 
						label='Phone'
					>
						<input 
							pcgControlRef 
							pcgPhoneExt 
							id='phone' 
							class='form-control' 
							type='text'
							formControlName='secondaryPhone' 
						/>
					</pcg-control-group>
				</div>

				<br />

				<h5>Tertiary</h5>

				<div class='row'>
					<pcg-control-group 
						class='col-md-4' 
						label='Name'
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName='tertiaryName'
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class='col-md-4' 
						label='Title'
					>
						<input 
							pcgControlRef 
							class='form-control' 
							type='text' 
							formControlName='tertiaryTitle'
							maxlength="100" 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class='col-md-4' 
						label='Email'
					>
						<input 
							pcgControlRef 
							class='form-control email' 
							type='text' 
							formControlName='tertiaryEmail'
							maxlength="100" 
						/>
					</pcg-control-group>
				</div>

				<div class='row'>
					<pcg-control-group 
						class='col-md-4' 
						label='Phone'
					>
						<input 
							pcgControlRef 
							pcgPhoneExt 
							id='phone' 
							class='form-control' 
							type='text'
							formControlName='tertiaryPhone' 
						/>
					</pcg-control-group>
				</div>
			</pcg-collapsible-divider>
		</div>

		<div formGroupName='application'>
			<input 
				type='hidden' 
				formControlName='roundId' 
			/>
			<input 
				type='hidden' 
				formControlName='applicationStatus' 
			/>
			<input 
				type='hidden' 
				formControlName='isPublicSubmit' 
			/>
			<input 
				type='hidden' 
				formControlName='oathAcceptedNew' 
			/>
			<input 
				type='hidden' 
				formControlName='oathDateTimeNew' 
			/>

			<pcg-collapsible-divider header='Application Details'>
				<div class='row'>
					<pcg-control-group 
						class='col-md-4' 
						label="Overdoses Responded to Previous Year"
						labelTooltip="If exact numbers are unknown, please estimate an approximate amount."
					>
						<input 
							pcgControlRef 
							class='form-control large-control' 
							type='text' 
							pattern="\d*"
							maxlength="100" 
							formControlName='overdosesPreviousYear' 
						/>
					</pcg-control-group>

					<pcg-control-group 
						class='col-md-4' 
						label="Percentage Trained to Administer Naloxone"
					>
						<input 
							pcgControlRef 
							pcgPercentage 
							[decimalPrecision]="0" 
							class='form-control' 
							type='text'
							maxlength="4" 
							formControlName='percentageTrainedNaloxone' 
						/>
					</pcg-control-group>
				</div>

				<div class='row'>
					<div class='col-md-2'>
						<h6>Order Option</h6>
					</div>
					<div class='col-md-4'>
						<label class='required-field for'>Doses Requested</label>
					</div>
				</div>

				<ng-container formArrayName='orderOptions'>
					<div 
						*ngFor='let order of formGroup.get("application.orderOptions").controls; let i = index'
						[formGroupName]='i'
					>
						<div class='row'>
							<input 
								type='hidden' 
								formControlName='applicantOrderId' 
							/>
							<input 
								type='hidden' 
								formControlName='orderId' 
							/>
							<div class='col-md-2'>
								<label class='control-label for'>{{ order.value.optionName }}</label>
								<span>Strength: {{ order.value.dosage }}</span>
								<br>
							</div>
							<div class='col-md-4'>
								<pcg-control-group>
									<input 
										pcgControlRef 
										class='form-control large-control' 
										type='number' 
										min="0"
										max="999999" 
										formControlName='dosesRequested' 
									/>
								</pcg-control-group>
							</div>
						</div>
						<br />
					</div>
				</ng-container>

				<br />

				<pcg-control-group label="Narrative (Please provide an explanation of the agency's need in 2500 characters or less)">
					<textarea 
						pcgControlRef 
						class='form-control' 
						formControlName="narrative"
						maxlength="2500"
					></textarea>
				</pcg-control-group>

				<hr />

				<p>
					<b>
						OATH: "Under penalties of perjury, by submission to the Florida Department of Health, I declare
						that I have read the foregoing application and that the facts
						stated in it are true." I understand that all I attest to in this application is subject to
						audit by the Department
					</b>.
				</p>
				<button 
					type="button" 
					*ngIf='showButtonOath' 
					(click)='acceptOath()' 
					class='btn btn-save btn-md'
				>Accept Oath</button>
				<p *ngIf='oathAccepted'>
					<i>Oath accepted at {{ oathTimeStamp }}</i>
				</p>
			</pcg-collapsible-divider>
		</div>

		<input 
			#website 
			id="website" 
			name="website" 
			type="text" 
			value="" 
		/>

		<pcg-fixed-buttons>
			<a 
				class='btn btn-cancel btn-lg' 
				(click)="backBtn()"
			> Back</a>
			<button 
				[disabled]="emptyCaptcha" 
				class='btn btn-save btn-lg'
			>Submit</button>
			<ngx-invisible-recaptcha 
				#captchaElem 
				[siteKey]="siteKey" 
				(reset)="handleReset()" 
				(ready)="handleReady()"
				(load)="handleLoad()" 
				(success)="handleSuccess($event)" 
				[useGlobalDomain]="false" 
				theme="light"
				type="image" 
				badge="bottomright" 
				formControlName="recaptcha"
			>
			</ngx-invisible-recaptcha>
		</pcg-fixed-buttons>
	</form>
</div>

<!-- Display thank you message once application is submitted -->
<div 
	*ngIf='isSubmitted && canSubmit' 
	id='informativeContent'
>
	<p>Thank you! Your HEROS application has been submitted.</p>
	<p>
		Next Step: Please wait until the Primary Contact receives an email from the system to create a user account. In
		the meantime, please download and fill out the form below. After a user account has been created, login and
		upload the completed form to your agency.
	</p>
	<ul>
		<li>
			<a 
				href='https://infuse-mn.gov/membership-application/index.jsp' 
				target='_blank'
			>MMCAP Application</a>
		</li>
	</ul>
</div>
