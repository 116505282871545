import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';
import { GenericOrderCommentListVm, GenericOrderCommentListVmFromFg } from '../../Models/GenericOrder/GenericOrderCommentListVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class GenericOrderCommentVm {
	commentId: number;
	orderId: number;
	comment?: string;
	commentorInfo?: string;
	commentDateTime: Date;
	strCommentDateTime?: string;
	strTimeAgo?: string;
	commentList?: GenericOrderCommentListVm[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'commentId': new UntypedFormControl(0, [...(validators['commentId'] ?? []), ...[Validators.required]]),
			'orderId': new UntypedFormControl(0, [...(validators['orderId'] ?? []), ...[Validators.required]]),
			'comment': new UntypedFormControl(null, [...(validators['comment'] ?? []), ...[Validators.required]]),
			'commentorInfo': new UntypedFormControl(null, [...(validators['commentorInfo'] ?? []), ...[]]),
			'commentDateTime': new UntypedFormControl('', [...(validators['commentDateTime'] ?? []), ...[Validators.required]]),
			'strCommentDateTime': new UntypedFormControl(null, [...(validators['strCommentDateTime'] ?? []), ...[]]),
			'strTimeAgo': new UntypedFormControl(null, [...(validators['strTimeAgo'] ?? []), ...[]]),
			'commentList': new UntypedFormArray([])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['commentId', {"orderId":0,"sortColumn":"commentId","searchColumn":"commentId","includeInExcel":true,"exportColumns":null,"displayName":"Comment Id","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['orderId', {"orderId":1,"sortColumn":"orderId","searchColumn":"orderId","includeInExcel":true,"exportColumns":null,"displayName":"Order Id","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['comment', {"orderId":2,"sortColumn":"comment","searchColumn":"comment","includeInExcel":true,"exportColumns":null,"displayName":"Comment","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['commentorInfo', {"orderId":3,"sortColumn":"commentorInfo","searchColumn":"commentorInfo","includeInExcel":true,"exportColumns":null,"displayName":"Commenter Info","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['commentDateTime', {"orderId":4,"sortColumn":"commentDateTime","searchColumn":"commentDateTime","includeInExcel":true,"exportColumns":null,"displayName":"Comment Date","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): GenericOrderCommentVmFromFg {
		return new GenericOrderCommentVmFromFg(fg);
	}
}

export class GenericOrderCommentVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get commentId(): number {
		return this.fg.get('commentId').value;
	}
	set commentId(val) {
		this.fg.get('commentId').setValue(val);
	}

	get orderId(): number {
		return this.fg.get('orderId').value;
	}
	set orderId(val) {
		this.fg.get('orderId').setValue(val);
	}

	get comment(): string {
		return this.fg.get('comment').value;
	}
	set comment(val) {
		this.fg.get('comment').setValue(val);
	}

	get commentorInfo(): string {
		return this.fg.get('commentorInfo').value;
	}
	set commentorInfo(val) {
		this.fg.get('commentorInfo').setValue(val);
	}

	get commentDateTime(): Date {
		return this.fg.get('commentDateTime').value;
	}
	set commentDateTime(val) {
		this.fg.get('commentDateTime').setValue(val);
	}

	get strCommentDateTime(): string {
		return this.fg.get('strCommentDateTime').value;
	}
	set strCommentDateTime(val) {
		this.fg.get('strCommentDateTime').setValue(val);
	}

	get strTimeAgo(): string {
		return this.fg.get('strTimeAgo').value;
	}
	set strTimeAgo(val) {
		this.fg.get('strTimeAgo').setValue(val);
	}
}
