import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { PfsHelpTicketVm } from 'app/shared/generated/Models/Public/PfsHelpTicketVm';
import { pcgSettings } from 'app/shared/generated/pcg-settings';
import { SelectItem } from 'app/shared/models/select-item';

@Component({
    selector: 'pcg-public-help-ticket',
    templateUrl: './help-ticket.component.html',
    styleUrls: ['./help-ticket.component.scss'],
    standalone: false
})
export class PublicHelpTicketComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('facilitySelect', { static: false }) facilitySelect: PcgSelectComponent;
    @ViewChild('categorySelect', { static: false }) categorySelect: PcgSelectComponent;
    @ViewChild('prioritySelect', { static: false }) prioritySelect: PcgSelectComponent;
    @ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;

    formGroup = PfsHelpTicketVm.Form;
    model = PfsHelpTicketVm.GetModel(this.formGroup);
    categories: SelectItem[] = [];

    public captchaIsLoaded = false;
	public captchaSuccess = false;
    public captchaIsReady = false;	
    emptyCaptcha: boolean = true;
    isSuccess = false;
    isRegister = false;
	
	public recaptcha: any = null;
    public execute: any;     
    honeypotControl: any;
    
    public captchaResponse?: string;
    returnUrl = "";
    facilityName = "";
    categoryName = "Login Issues";
    priorityName = "High";
    headerStr = "Create Support Desk Ticket";
    siteKey: string = environment.siteKey; // should pick up environment specific siteKey
    infoText = "The Pharmaceutical Forms System (PFS) Support provides a single source of assistance and resolution " 
        + "for any PFS-related problems or request. This includes providing useful answers regarding access, permissions, and using PFS. " 
        + "In addition, the PFS Support is used to report suspected bugs experienced while using PFS and the User Portal. " 
        + "To reach the PFS Support please submit your Support Desk Ticket request via this form or send email to " 
        + "pfssupport@doh.state.fl.us. The Support System uses the PFS Support Desk Ticketing System and will assign your email or " 
        + "phone call to a Support Desk Ticket. You will receive auto-notification back that lets you know your email has been received, " 
        + "and provides you with a Support Desk Ticket number for reference.";
    facilityDataSource = "api/PublicPfsRegister/PfsFacilities";
    categoryDataSource = "api/PublicPfsHelpTicket/PfsHelpCategories";
    priorityDataSource = "api/PublicPfsHelpTicket/PfsHelpPriorities";
    
    constructor(
        private ms: SystemMessageService
        , private cdr: ChangeDetectorRef
        , private route: ActivatedRoute
        , private httpClient: HttpClient
    ) { }

    ngOnInit() {
        const queryParams = this.route?.snapshot?.queryParams;
        if (queryParams && queryParams["id"] === "register") { 
            this.isRegister = true; 
            this.headerStr = "Register for an account in HOMER";
            this.infoText = "Fill out your information and select your requested facility from the dropdown list below. " 
                + "This will submit an account request to the BPHP support staff.";
            this.facilityDataSource = "api/PublicHelpTicket/Facilities";
            this.categoryDataSource = "api/PublicHelpTicket/HelpDeskCategories";
            this.priorityDataSource = "api/PublicHelpTicket/HelpDeskPriorities";
            this.categoryName = "Account Registration";
            this.formGroup.controls.categoryId.disable();
            this.setEnv(false); 
        } else {
            this.model.categoryId = 4;
            this.setEnv(true); 
        }

        this.httpClient.get(this.categoryDataSource).subscribe((items: SelectItem[]) => { 
            this.categories = items;
            if (this.isRegister === true) { this.model.categoryId = items.find(o => o.text === "Account Registration")?.value; }
        });
        this.model.categoryName = this.categoryName;
        this.model.priorityName = this.priorityName;
        this.model.priorityId = 1;        
        
        this.setUpHoneyPot();            
        this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
    }

    ngAfterViewInit(): void {
        this.captchaIsLoaded = true;
       	this.cdr.detectChanges();
	}

    setUpHoneyPot() {
		this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
	}

    requireFalse() : ValidatorFn {
		return (control : AbstractControl) : ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true}; }
			return null;
		}
	}

    handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

    handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
        this.execute = setInterval(() => {
			this.captchaElem?.execute();
            this.captchaElem?.getResponse() !== '' 
                ? this.emptyCaptcha = false 
                : this.emptyCaptcha = true;
		}, 2000);
    }

    handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
    }

    handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
    }

    setEnv (isPfs = true) {
		if (pcgSettings.mode === 'Development') { 
            this.returnUrl = isPfs ? "https://localhost:44384/" : "https://localhost:44327"; 
        } else if (pcgSettings.mode === 'Demo') { 
            this.returnUrl = isPfs ? "https://demo.fdohcentralpharmacy.com/" : "https://demo.fdepcs.com/"; 
        } else { 
            this.returnUrl = isPfs ?  "https://fdohcentralpharmacy.com/" : "https://fdepcs.com/"; 
        }
	}

    sanitize(email: string) {
        var strTempString = email.trim();
        strTempString = strTempString.replace("'", "");
        strTempString = strTempString.replace("--", "");
        strTempString = strTempString.replace("/", "");
        strTempString = strTempString.replace("&", "");
        strTempString = strTempString.replace("?", "");
        strTempString = strTempString.replace(":", "");
        strTempString = strTempString.replace(",", "");
        strTempString = strTempString.replace(";", "");
        strTempString = strTempString.replace("NULL", "");
        strTempString = strTempString.replace("xp_", "");
        strTempString.replace(/[\n\r]+/g, '');
        strTempString.replace('\\', '');
        this.formGroup.controls['email'].setValue(strTempString);
    }

    categoryChange() {
        this.categoryName = this.categorySelect.text;
        this.model.categoryName = this.categoryName;
        this.formGroup.controls['categoryName'].setValue(this.categoryName);        
    }

    priorityChange() {
        this.priorityName = this.prioritySelect.text;
        this.model.priorityName = this.priorityName;
        this.formGroup.controls['priorityName'].setValue(this.priorityName);
    }

    facilityChange() {
        this.facilityName = this.facilitySelect.text;
        this.model.facilityName = this.facilityName;
        this.formGroup.controls['facilityName'].setValue(this.facilityName);
    }

    onSubmit() {
        if (this.formGroup.valid) {            
            this.ms.getHttpObservable(
                this
                , 'api/PublicPfsHelpTicket/SubmitPfsHelpTicket'
                , this.formGroup
            ).subscribe(msg => {
                    if (msg.isSuccessful) {
                        this.isSuccess = true;
                        this.formGroup.reset();
                    } else { this.isSuccess = false; }
                }
            );
        } else { 
            validateForm(this.formGroup); 
            this.isSuccess = false;
        }
    }

    ngOnDestroy() { clearInterval(this.execute); }
}
