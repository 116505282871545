import { HttpClient } from "@angular/common/http";
import { Component, HostListener, inject, Input, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { faAngleDown, faArrowLeft, faArrowRight, faArrowRightArrowLeft, faBell, faBoxesPacking, faBoxOpen, faPrescription, faUser, faUserMd } from "@fortawesome/pro-solid-svg-icons";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { getMainNavRoutes } from "app/core/main-nav-routes";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessage, SystemMessageService } from "app/core/system-message/system-message-service";
import { FaIconName } from "app/shared/fa-num-icon/fa-icon-name/fa-icon-name";
import { FaIconPosition, FaPositionUnits } from "app/shared/fa-num-icon/fa-icon-position/fa-icon-position";
import { FaNumColor } from "app/shared/fa-num-icon/fa-num-color/fa-num-color.enum";
import { UserJsVm } from "app/shared/generated/Administration/Models/Users/UserJsVm";
import { HeaderVm } from "app/shared/generated/Models/HeaderVm";
import { pcgSettings } from "app/shared/generated/pcg-settings";
import { FormatHelperService } from "app/shared/helpers/format-helper.service";
import { GlobalService } from "app/shared/services/global.service";
import { AccountRequestModalStateService } from "app/shared/state/account-request-modal-state.service";
import { NavRoute } from "../nav.route.interface";
import { NavigationService } from "../navigation.service";
import { NotificationNavComponent } from "../notification-nav/notification-nav.component";
import { OrganizationAccountUpdateNavComponent } from "../organization-account-update-nav/organization-account-update-nav.component";
import { GlobalVariablesService } from "app/services/global-variables.service";

// Navigation sidebar component template that provides:
// - Toggle button for docking/undocking nav (desktop only)
// - Logo/brand header with environment indicator
// - Main navigation menu with:
//   - Dynamic route-based navigation items
//   - Nested navigation support with expandable sections
//   - User profile section with edit profile and logout
// - Status indicators and quick access buttons for:
//   - Fulfillment queue counts (Product Prep, Shipping Prep, QC Check)
//   - Account request notifications
//   - Reconciliation/Invoice notifications

// Key features:
// - Responsive mobile/desktop layouts
// - Role-based navigation visibility
// - Active route highlighting
// - Expandable/collapsible nested navigation
// - Notification badges and tooltips
@UntilDestroy()
@Component({
    selector: "pcg-mat-side-nav",
    templateUrl: "./mat-side-nav.component.html",
    styleUrl: "./mat-side-nav.component.scss",
    standalone: false
})
export class MatSideNavComponent implements OnInit {

	@Input() user: UserJsVm;

	navService = inject(NavigationService);

	navContentOffscreen: boolean = false;
	// Setting the scroll height used to keep the nested table scrollable with headers shown.
	observer = new IntersectionObserver((entries) => { this.navContentOffscreen = !entries[0]?.isIntersecting; });
	// Used to display a loading spinner on the navigation link when clicked, notifying the user that the page is loading.
	navLinkLoading = signal<[string, number]>([null, null]);

	notificationModal: NgbModalRef;

	faIcons = {
		faArrowLeft
		, faArrowRight
		, faAngleDown
		, faUser
		, faBoxOpen
		, faUserMd
		, faArrowRightArrowLeft
		, faBell
		, faBoxesPacking
		, faPrescription
	};

	step1Position: FaIconPosition = new FaIconPosition(
		null
		, 13
		, null
		, null
		, FaPositionUnits.percent
	);
	faNumColor: typeof FaNumColor = FaNumColor;
	faIconName: FaIconName = new FaIconName();
	iconColorName = "inherit";

	fileLogoUrl = "../assets/images/logos/logo_small.png";
	mode = pcgSettings.mode;

	notificationCount = 0;
	reconCount = 0;
	ciInvoicesCount = 0;
	icqCount = 0;
	step1Count = 0;
	step2Count = 0;
	step3Count = 0;
	navigationOffset = 0;

	fulfillmentNavAccess = false;
	invoiceNavAccess = false;
	accountRequestAccess = false;
	reconNavAccess = false;
	icqAccess = false;
	isCiUser = false;
	hasCiWorkstation = false;

	// Enums
	busArea = BusinessAreaEnum;
	role = PermissionRole;

	private resizeTimeout;

	@HostListener("window:resize")
	onResize() {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout(() => { this.fixNavOffset(); }, 200);
	}
	constructor(
		public router: Router
		, private sec: SecurityService
		, private httpClient: HttpClient
		, private modalService: NgbModal
		, public arms: AccountRequestModalStateService
		, public gs: GlobalService
		, public ms: SystemMessageService
		, public gvs: GlobalVariablesService
	) { }

	ngOnInit() {
		this.navService.navRoutes.set(this.sec.getSecureNavItems(getMainNavRoutes()));
		this.navService.isMobile.set(GlobalService.setIsMobile(window.innerWidth));
		this.navService.navRoutes$
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.fixNavOffset();
				this.navLinkLoading.set([null, null]);
				this.getHeaderCounts();
			}
		);

		// Checking if there is any nave content off-screen.
		const navAnchor = document.getElementById("navAnchor");
		this.observer.observe(navAnchor);
	}

	fixNavOffset() {
		const mainNav = document.getElementById("pcgMainNavigation");
		setTimeout(() => {
			if (getComputedStyle(mainNav, null).position === "fixed") { this.navigationOffset = mainNav.getBoundingClientRect().bottom; } 
			else { this.navigationOffset = 0; }

			const styles = `pcg-side-nav { top: ${this.navigationOffset - 1}px }` 
				+ `.nav-padding-placeholder { padding-top: ${this.navigationOffset}px; }` 
				+ `.conversations-sidenav { top: ${this.navigationOffset}px }` 
				+ `.knowledge-base-doc-popout { top: ${this.navigationOffset}px }` 
				+ `.popout { top: ${this.navigationOffset}px }`;

			const tableStyles = `.pcg-table-fixed-header thead tr th, .pcg-table-fixed-header thead tr { top: ${this.navigationOffset - 1}px }`;

			this.setDynamicStyle("navPaddingOffset", styles);
			this.setDynamicStyle("navTableOffset", tableStyles);
		}, 100);
	}

	setDynamicStyle(name: string, styles: string) {
		let dynamicStyle = document.getElementById(name);
		if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		dynamicStyle = document.createElement("style");
		dynamicStyle.id = name;
		dynamicStyle.innerHTML = styles;
		document.head.appendChild(dynamicStyle);
	}

	getHeaderCounts() {
		this.setAccessFlags();
		const step1: number = +sessionStorage.getItem("step1Count");
		const step2: number = +sessionStorage.getItem("step2Count");
		const step3: number = +sessionStorage.getItem("step3Count");
		const opened = sessionStorage.getItem("notificationModalOpened");

		if (
			this.fulfillmentNavAccess 
			|| this.reconNavAccess 
			|| this.invoiceNavAccess 
			|| this.icqAccess
		) { this.fetchHeaderCountsFromServer(opened, step1, step2, step3); } 
		else { this.setStepCounts(step1, step2, step3); }
	}

	setAccessFlags() {
		this.fulfillmentNavAccess = this.reconNavAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Technician)
		);
		this.invoiceNavAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Technician)
		);
		this.accountRequestAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Manager)
		);
		this.icqAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.ICQ ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
	}

	fetchHeaderCountsFromServer(
		opened: string
		, step1: number
		, step2: number
		, step3: number
	) {
		this.httpClient.get(`api/Header/GetHeaderCounts
			?reconAccess=${this.reconNavAccess}
			&rxAccess=${this.fulfillmentNavAccess}
			&invoiceAccess=${this.invoiceNavAccess}
			&icqAccess=${this.icqAccess}`
		).subscribe((vm: HeaderVm) => {
			this.updateSessionStorage(vm);
			this.updateCounts(vm);
			this.checkAndOpenNotificationModal(opened);
		});

		if (!this.fulfillmentNavAccess) { this.setStepCounts(step1, step2, step3); }
	}

	updateSessionStorage(vm: HeaderVm) {
		sessionStorage.setItem("step1Count", vm.fulfillmentStep1ProductPrepCount.toString());
		sessionStorage.setItem("step2Count", vm.fulfillmentStep2ShippingCount.toString());
		sessionStorage.setItem("step3Count", vm.fulfillmentStep3QCCount.toString());
	}

	updateCounts(vm: HeaderVm) {
		this.notificationCount = 0;
		this.step1Count = vm.fulfillmentStep1ProductPrepCount;
		this.step2Count = vm.fulfillmentStep2ShippingCount;
		this.step3Count = vm.fulfillmentStep3QCCount;

		this.notificationCount = vm.reconciliationCount 
			+ [
				vm.ciInvoicesCount
				, vm.icqNewSurveyCount
				, vm.icqOverdueSurveyCount
				, vm.icqPostSiteVisitCount
				, vm.icqScheduleSiteVisitCount
				, vm.icqSurveysToCompleteCount
			].filter(count => count > 0).length;

		this.reconCount = vm.reconciliationCount;
		this.ciInvoicesCount = vm.ciInvoicesCount;
		this.icqCount = vm.icqNewSurveyCount;
		this.icqCount += vm.icqOverdueSurveyCount;
		this.icqCount += vm.icqPostSiteVisitCount;
		this.icqCount += vm.icqScheduleSiteVisitCount;
		this.icqCount += vm.icqSurveysToCompleteCount;
	}

	checkAndOpenNotificationModal(opened: string) {
		if (this.notificationCount > 0) {
			if (
				opened !== "true" 
				&& this.user?.receiveReconciliationNotifications 
				&& (
					this.isCiUser !== true 
					|| this.hasCiWorkstation === true
				)
			) { setTimeout(() => { this.openNotificationModal(); }, 100); }
		}
		sessionStorage.setItem("notificationModalOpened", "true");
	}

	setStepCounts(
		step1: number
		, step2: number
		, step3: number
	) {
		this.step1Count = step1;
		this.step2Count = step2;
		this.step3Count = step3;
	}

	// Stores modal reference in state of the service.
	openOrgAccountUpdateModal = () => this.arms.setModalRef(this.modalService.open(OrganizationAccountUpdateNavComponent, { animation: false }));

	openNotificationModal() {
		this.notificationModal = this.modalService.open(NotificationNavComponent, { animation: false });
		this.notificationModal.componentInstance.modalRef = this.notificationModal;
		this.notificationModal.componentInstance.icqCount = this.icqCount;
		this.notificationModal.componentInstance.ciInvoiceCount = this.ciInvoicesCount;
	}

	openFulfillmentList(step: number) { window.location.href = "/pharmacy/fulfillment/list?status=" + step; }

	logout() {
		this.gvs.isNavFixed.set(false);
		this.navService.collapseAllSubNavs();
		this.sec.setSecurity(null, null);
		localStorage.removeItem("subNavPosition");
		this.deleteAllCookies();
		this.router.navigate(["/"]);
		sessionStorage.removeItem("notificationModalOpened");
		sessionStorage.removeItem("step1Count");
		sessionStorage.removeItem("step2Count");
		sessionStorage.removeItem("step3Count");
		sessionStorage.removeItem("updateRequestCount");
		sessionStorage.removeItem("ciWorkstationModalOpened");
		localStorage.removeItem("herosAgencyFilters");
		localStorage.removeItem("herosApplicantFilters");

        var loggedInUsingSso = localStorage.getItem("loggedInUsingSso") == "true";
        localStorage.setItem("loggedInUsingSso", "false");
        if (loggedInUsingSso) {
            this.httpClient.get(`api/Account/SingleSignOut?userId=${this.user.id}&entraIdentifierUrl=${localStorage.getItem("entraIdentifierUrl")}&entraLoginUrl=${localStorage.getItem("entraLoginUrl")}`)
                .subscribe((msg: SystemMessage) => {
                    localStorage.setItem("entraIdentifierUrl", "");
                    localStorage.setItem("entraLoginUrl", "");
                    if (msg.isSuccessful) { window.location.href = msg.value; }
                    else { this.ms.setSystemMessage(msg.message, msg.messageClass); }
                }
            )
        }
	}

	deleteAllCookies() {
		document.cookie.split(";").forEach((cookie) => {
			const name = cookie.split("=")[0].trim();
			document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
		});
	}

	toggleUserProfileSubNav() {
		const isOpen = this.navService.userProfileExpanded();
		this.navService.collapseAllSubNavs();
		if (!isOpen) { this.navService.userProfileExpanded.set(true); }
	}

	/**
	 * Handles the click event on a navigation link.
	 * Sets the `navLinkLoading` state to the provided `name` and `level` values,
	 * and then resets the state to `[null, null]` after 1 second.
	 * This is likely used to indicate that a navigation link is currently loading.
	 *
	 * @param nav - The navigation link that was clicked.
	 * @param level - The level of the navigation link that was clicked.
	*/
	navLinkClicked(
		nav: NavRoute
		, level: number
	) {
		if (this.navService.isMobile()) { this.navService.isNavOpen.set(false); }
		this.navLinkLoading.set([nav.name, level]);
		let path = nav.href?.length 
			? nav.href 
			: nav.path;				
		if (nav.openNewTab) { this.openInNewTab(path); } 
		if (this.navService.isMobile()) { this.navService.toggleNavOpen(false, true); }	
		setTimeout(() => { this.navLinkLoading.set([null, null]); }, 1000);
	}

	openInNewTab(namedRoute: string) {
		let newRelativeUrl = this.router.createUrlTree([namedRoute]);
		let baseUrl = window.location.href.replace(this.router.url, "");
		window.open(baseUrl + newRelativeUrl, "_blank");
	}

	redirect(path: string) {
		if (!FormatHelperService.GetIsNullyOrWhitespace(path)) {
			this.router.navigateByUrl(path)
				.catch(err => {
					console.log('Error: ' + err);
					window.location.href = path; 
				}
			);
		} else { window.location.reload(); }
	}

	/**
	 * Checks if a navigation link is currently loading.
	 *
	 * @param name - The name of the navigation link.
	 * @param level - The level of the navigation link.
	 * @returns `true` if the specified navigation link is currently loading, `false` otherwise.
	*/
	navLinkIsLoading(
		name
		, level
	) {
		const [currentName, currentLevel] = this.navLinkLoading();
		return currentName === name && currentLevel === level;
	}

	scrollToBottom() {
		const navAnchor = document.getElementById("navAnchor");
		if (navAnchor) { navAnchor.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); }
	}
}
