import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit} from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'pcg-programs-accounts-infographic',
    templateUrl: './programs-accounts-infographic.component.html',
    styleUrls: ['./programs-accounts-infographic.component.scss'],
    standalone: false
})
export class ProgramsAccountsInfographicComponent implements OnInit {
    @Input() facilityTypeId = null;
    data: TreeNode[][];

    constructor(private httpClient: HttpClient) { }

    ngOnInit() {
        this.httpClient.get(`api/Administration/Organization/GetInfographicData/${this.facilityTypeId}`)
            .subscribe((vm: any) => {
                this.data = this.buildObject(vm);
            });
    }

    buildObject(vm: any): TreeNode[][] {
        return vm.map((p: any) => {
            return [{
                label: p.program,
                expanded: true,
                styleClass: 'program-node',
                children: p.children.map((c: any, i: number) => {
                    // Extracts the pattern ({programAbreviation} 340B ID) from orgAccount
                    const orgAccount = c.orgAccount.replace(/.*(\(\w+\) 340B ID)/, '$1');
                    return {
                        label: orgAccount,
                        expanded: true,
                        styleClass: i % 2 === 0 ? 'org-account-node' : 'org-account-node-alt',
                        children: [
                            {
                                label: c.wholesalerAccount,
                                styleClass: i % 2 === 0 ? 'wholesaler-account-node' : 'wholesaler-account-node-alt',
                                children: []
                            }
                        ]
                    };
                })
            }];
        });
    }

}
