import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { AgencyListByAgencyVm } from 'app/shared/generated/HEROS/Models/AgencyListByAgencyVm';
import { EditAgencyVm } from 'app/shared/generated/HEROS/Models/EditAgencyVm';
import { EditPublicApplicationVm } from 'app/shared/generated/HEROS/Models/EditPublicApplicationVm';
import { OrderOptionVm } from 'app/shared/generated/HEROS/Models/OrderOptionVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { TableComponent } from 'app/shared/table/table.component';
import { environment } from 'environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { ConfirmationModalComponent } from 'app/shared/form-elements/components/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'pcg-heros-application',
    templateUrl: './heros-application.component.html',
    styleUrls: ['./heros-application.component.scss'],
    standalone: false
})
export class HerosApplicationComponent implements OnInit {

	siteKey: string = environment.siteKey; // should pick up environment specific siteKey
	// FEIN reCaptcha properties
	@ViewChild('agencyCaptchaElem') agencyCaptcha: InvisibleReCaptchaComponent;
	agencyFormGroup = new UntypedFormGroup({});
	public agencyCaptchaIsLoaded = false;
	public agencyCaptchaSuccess = false;
	public agencyCaptchaResponse?: string;
	public agencyCaptchaIsReady = false;
	public agencyExecute: any;
	emptyCaptcha: boolean = true;

	@ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;
	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public execute: any;
	public recaptcha: any = null;

	formGroup = EditPublicApplicationVm.Form;
	oldShippingInfo: EditAgencyVm;
	canSubmit = true;
	showButtonOath = true;
	oathAccepted = false;
	oathTimeStamp: string;
	isSubmitted = false;
	subscriptions = new Subscription();
	agency: string;
	//  Handles showing certain parts of the public application process	
	showForm = false;
	showAgencyList = false;
	showAgencyInput = true;
	showAgencyInSystem = false;
	showInformativeContent = true;
	canManualEntry = true;
	// Agency List by FEIN Table
	@ViewChild(TableComponent) table: TableComponent<AgencyListByAgencyVm>;
	columns = AgencyListByAgencyVm.ColumnDefs;
	filters = {
		agency: null
	};
	countyApi = "api/Select/SmartyCounties";

	constructor(private httpClient: HttpClient, private ms: SystemMessageService, private cdr: ChangeDetectorRef, public modalService: NgbModal) { }

	ngOnInit() {
		this.getPublicApplication();
		this.agencyFormGroup.addControl('agencyRecaptcha', new UntypedFormControl('', Validators.required));
		this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
	}

	// Checks if the Agency is in the system already
	checkAgency(agency: string) {
		// Check if agency is in system
		this.subscriptions.add(this.httpClient.get(`api/PublicHEROS/CheckForAgencies/?agency=${encodeURIComponent(agency)}`)
			.subscribe((agencyInSystem: boolean) => {
				if (agencyInSystem) {
					// if Agency is in system naviage to list of all agencies with their abbreviation
					this.filters.agency = agency; // set ajaxdata/filters
					this.showAgencyInput = false; // hide Agency input
					this.showAgencyList = true; // show agency list if there already is agencies in the system with the same fein
					this.agency = agency;
				} else {
					// If Agency is new then go to form
					this.showAgencyInput = false; // hide Agency input
					this.showForm = true; // show the form
					this.formGroup.get('agency.agencyName').setValue(agency); // prepopulate Agency
					this.ms.setSystemMessage("You have entered a new Agency.");

					// Destroys agencyExecute interval and resets emptyCaptcha for next page
					clearInterval(this.agencyExecute);
					this.agencyExecute = null;
					this.emptyCaptcha = true;
				}
			}));
	}

	backBtn(){
		this.showForm = false;
		if(this.agency != null && !this.showAgencyList){
			this.filters.agency = this.agency;
			this.showAgencyList = true;
			this.showAgencyInput = false;
			if (this.showAgencyInSystem){
				this.showAgencyInSystem = false;
				this.showInformativeContent = true;
			}
		}
		else {
			this.showAgencyInput = true; 
			this.showAgencyList = false;
			this.agency = null;
		}
	}

	newAgencyForm() {
		this.showAgencyList = false;
		this.showForm = true;
		this.formGroup.get('agency.agencyName').setValue(this.filters.agency);
		this.formGroup.get('agency.fein').removeValidators(Validators.required);
	}

	// Get the public application model from the server
	getPublicApplication() {
		this.subscriptions.add(this.httpClient.get('api/PublicHEROS/GetPublicApplication')
		.subscribe((model: EditPublicApplicationVm) => {
			if (model.application.roundId !== 0) {
				setTimeout(() => { this.setModel(model); }, 100);
			} else {
				this.canSubmit = false;
			}
		}));
	}

	setModel(model: EditPublicApplicationVm) {
		this.addOrderOptionFormGroups(model.application.orderOptions.length);
		this.formGroup.controls['application'].patchValue({
			roundId: model.application.roundId,
			isPublicSubmit: true,
			applicationStatus: model.application.applicationStatus,
			orderOptions: model.application.orderOptions
		});
	}

	addOrderOptionFormGroups(count: number) {
		for (let i = 0; i < count; ++i) {
			(this.formGroup.get('application.orderOptions') as UntypedFormArray).push(OrderOptionVm.Form);
		}
	}

	// Disable and fill shipping information with agency information when Same as Agency Address is checked; enable and clear when unchecked
	updateShippingInfo(saveShippingInfo = false, fromCheckbox = false) {
		// Back up the old shipping info when Same as Agency Address is checked
		if (saveShippingInfo) {
			this.oldShippingInfo = this.formGroup.value.agency.shippingInformation;
		}

		// Set address to match agency address
		if (this.formGroup.value.agency.useAgencyInfoForShipInfo) {
			this.formGroup.get('agency.shipSmartyAddress').disable();
			this.formGroup.get('agency.shipSmartyAddress').setValue(this.formGroup.value.agency.agencySmartyAddress);
		}
		// Set the shipping address back to what it was if unchecked
		// Or reset it if there is no previous value
		else if (fromCheckbox) {
			this.formGroup.get('agency.shipSmartyAddress').enable();
			if (this.oldShippingInfo) {
				this.formGroup.get('agency.shipSmartyAddress').setValue(this.oldShippingInfo);
			} else {
				this.formGroup.get('agency.shipSmartyAddress').reset();
			}
			this.formGroup.get('agency.shipSmartyAddress').markAsUntouched();
		}
	}

	acceptOath() {
		const today = new Date();
		this.formGroup.controls['application'].patchValue({ oathAcceptedNew: true, oathDateTimeNew: today });
		this.oathTimeStamp = today.toLocaleDateString() + ' by public submitter';
		this.showButtonOath = false;
		this.oathAccepted = true;
	}

	// The main save event for the page
	// The below code is the "HoneyPot" used to deter hackerz
	// <input id="website" name="website" type="text" value="" /
	savePublicApplication(honeypot: string) {
		this.validateAddress();
		if (honeypot === "") {
			if (this.formGroup.valid) {
				if (!this.oathAccepted) {
					this.ms.setSystemMessage('Please acknowledge the Oath before submitting.', 'error');
					return;
				}
				this.formGroup.enable();
				this.setFein();
				this.subscriptions.add(this.ms.getHttpObservable(this, 'api/PublicHEROS/SavePublicApplication', this.formGroup).subscribe(msg => {
					if (msg.isSuccessful) {
						this.isSubmitted = true;
						clearInterval(this.execute);
						this.execute = null;
					}
				}));
			} else {
				validateForm(this.formGroup);
			}
		}
	}

	savePublicApplicationClick(honeypot: string){
		if (this.formGroup.valid){
			if (this.formGroup?.get('agency.secondaryName')?.value === null || this.formGroup?.get('agency.secondaryName')?.value === ''
			|| this.formGroup?.get('agency.secondaryEmail')?.value === null || this.formGroup?.get('agency.secondaryEmail')?.value === ''
			|| !this.formGroup?.get('agency.secondaryEmail')?.valid || this.formGroup?.get('agency.secondaryPhone')?.value === null
			|| this.formGroup?.get('agency.secondaryPhone')?.value === '') {
                const modal = this.modalService.open(ConfirmationModalComponent);
                let warningMessage = 'Attention: There is no secondary contact assigned to your agency. It is highly advised you include secondary contact information.';
                // Pass modal reference into component to give us the ability to close it
                modal.componentInstance.modalRef = modal;
                modal.componentInstance.confirmationMessage =
                    `<p class="text-center">${warningMessage}</p>`;
                // If user confirms, save agency
                modal.result.then((emittedValue) => {
                    if (emittedValue) { this.savePublicApplication(honeypot); }
                    else { return null; }
                }).catch(err => { // When user clicks out of confirmation box.
                    return null;
                });
			} else {
				this.savePublicApplication(honeypot);
			}
		} else {
			validateForm(this.formGroup);
		}		
	}

	validateAddress() {
		let agency = this.formGroup.get('agency.agencySmartyAddress')?.value?.formattedAddress
		let shipping = this.formGroup.get('agency.shipSmartyAddress')?.value?.formattedAddress
		
		if (FormatHelperService.GetIsNullyOrWhitespace(agency) && FormatHelperService.GetIsNullyOrWhitespace(shipping)) {
			this.ms.setSystemMessage('No valid addresses were found. Please enter an agency and shipping address to submit.', 'error')
		}
		else if (FormatHelperService.GetIsNullyOrWhitespace(agency)) {
			this.ms.setSystemMessage('No valid agency address found. Please enter an agency address to submit.', 'error')
		}
		else if (FormatHelperService.GetIsNullyOrWhitespace(shipping)) {
			this.ms.setSystemMessage('No valid shipping address found. Please enter a shipping address to submit.', 'error')
		}
	}

	setFein() {
		const emptyFein = "----------"
		let isEmpty = this.formGroup?.get('agency.fein')?.value == null;

		if (isEmpty) { this.formGroup?.get('agency.fein')?.setValue(emptyFein) } 
	}

	// Agency Captcha Callbacks
	handleAgencyReset() {
		this.agencyCaptchaSuccess = false;
		this.agencyCaptchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleAgencyReady(): void {
		this.agencyCaptchaIsReady = true;
		this.cdr.detectChanges();
		this.agencyExecute = setInterval(() => {
			this.agencyCaptcha?.execute();
			this.agencyCaptcha?.getCurrentResponse() !== '' ? this.emptyCaptcha = false : this.emptyCaptcha = true;
		}, 2000);
	}

	handleAgencyLoad(): void {
		this.agencyCaptchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleAgencySuccess(captchaResponse: string): void {
		this.agencyCaptchaSuccess = true;
		this.agencyCaptchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

	// captcha Callbacks
	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
		this.execute = setInterval(() => {
			this.captchaElem?.execute();
			this.captchaElem?.getCurrentResponse() !== '' ? this.emptyCaptcha = false : this.emptyCaptcha = true;
		}, 2000);
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}
}
