import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnInit,
    Output
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
    BarcodeReader,
    CodeDetection,
    Configuration,
    SdkError,
    StrichSDK,
    SymbologyName
} from "@pixelverse/strichjs-sdk";

import { environment } from '../../../environments/environment';
import { InventoryService } from '../../inventory/services/inventory.service';
import { ProductBarcode } from '../../inventory/services/product-barcode';
import { GlobalService } from '../services/global.service';

@UntilDestroy()
@Component({
    selector: 'pcg-barcode-scanner-strich',
    templateUrl: './barcode-scanner-strich.component.html',
    styleUrls: ['./barcode-scanner-strich.component.scss'],
    standalone: false
})
export class BarcodeScannerStrichComponent implements OnInit, AfterViewInit {

    @Input() symbology: SymbologyName[];
    @Input() scanningBtnText = "Start Scanning";
    @Input() showResults = true;
    @Input() overrideSound = false;
    @Input() closeScanAfterScan = true;
    @Input() showHeader = true;
    @Input() height = "18rem"
    @Input() showCloseButton = true;
    @Input() isClosed = false;

    @Output() scanResult = new EventEmitter<ProductBarcode>();
    @Output() barcodeReaderOutput = new EventEmitter<BarcodeReader>();
	@Output() closeReader = new EventEmitter<Boolean>(this.isClosed);

    errorMessage?: string;

    busy = false;
    initialized = false;
    isScan = false;
    isMobile = false;
    isLoaded = false;

    codeDetection?: CodeDetection;
    barcodeReader?: BarcodeReader;
    configuration: Configuration;

    constructor(private ngZone: NgZone, private inventoryService: InventoryService) { }

    @HostListener('window:resize')
    onResize() { this.isMobile = GlobalService.setIsMobile(window.innerWidth); }

    ngOnInit() {
        this.isMobile = GlobalService.setIsMobile(window.innerWidth);
        this.setConfig();
        this.busy = true;
        if (!this.isLoaded) {
            StrichSDK.initialize(environment.strichLicenseKey)
                .then(() => { console.log('STRICH SDK initialized'); this.isLoaded = true; })
                .catch(err => { this.errorMessage = `Failed to initialize Strich SDK (Have you provided a license key?) ${err}`; })
                .finally(() => { this.busy = false; });
        }
    }

    ngAfterViewInit() { setTimeout(() => { this.startScanning(); }, 1000); }

    setConfig() {
        const basePath = document.getElementsByTagName('base')[0].href;
        this.configuration = {
            selector: '#scanner' // the CSS selector identifying the host element
            //@ts-ignore
            , debug: true
            , engine: {
                symbologies: this.symbology // restrict to the required symbologies
                , numScanlines: 10
                , minScanlinesNeeded: 3
                , invertedCodes: true
                , duplicateInterval: 1000
            }
            , frameSource: { resolution: 'full-hd' } // full-hd is recommended for more challenging codes 
            , overlay: {
                customLogoSrc: basePath + "assets/images/logos/logo_small.png"
                , showCameraSelector: true
                , showDetections: true
                , showFlashlight: true
                , showTargetingLine: true
            }
            , locator: {
                regionOfInterest: { // restrict active area to a horizontal bar in the center
                    left: 0.05
                    , right: 0.05
                    , top: 0.35
                    , bottom: 0.35
                }
            }
            , feedback: { // get audio and vibration feedback for scans               
                audio: this.overrideSound === true ? false : true
                , vibration: true
            }
        };
    }

    removeCodeDetection(): void { this.codeDetection = undefined; }

    startScanning(): void {
        this.isScan = true;
        setTimeout(() => {
            this.initializeBarcodeReader();
        }, 50);
    }

    private initializeBarcodeReader() {
        if (!this.barcodeReader) {
            // BarcodeReader initialization
            try {
                const barcodeReader = new BarcodeReader(this.configuration);
                barcodeReader.initialize().then(result => {
                    this.initialized = true;
                    this.barcodeReader = result;
                    this.barcodeReaderOutput.emit(this.barcodeReader);

                    // register detection hook, run it in the Angular zone so change detection works
                    this.barcodeReader.detected = (detections) => {
                        this.ngZone.run(() => {
                            this.codeDetection = detections[0];
                            this.processScanResult(this.codeDetection.data);
                        });
                    };

                    // start reading codes
                    this.barcodeReader.start()
                        .then(() => { })
                        .catch(err => { this.errorMessage = `BarcodeReader failed to start: ${err}`; });
                })
                    .catch(err => { this.errorMessage = `BarcodeReader failed to initialize: ${err}`; })
                    .finally(() => { this.busy = false; });
            } catch (err) {
                if (err instanceof SdkError) { this.errorMessage = err.message; }
                else { this.errorMessage = 'An unknown error occurred'; }
            }
        }
    }

    closeScanning() {
        this.barcodeReader?.stop();
        this.barcodeReader?.destroy();
        this.barcodeReader = null;
        this.removeCodeDetection();
        this.isScan = false;
        this.isLoaded = false;
        this.isClosed = true;
        this.closeReader.emit(this.isClosed);
    }

    processScanResult(barcodeStr: string) {
        if (barcodeStr?.includes("\u001d17")) { 
            let barcode: ProductBarcode = 
                this.inventoryService.scanGs1Barcode(barcodeStr);
            this.scanResult.emit(barcode);
        }
        if (this.closeScanAfterScan) {
            this.closeScanning();
            this.initialized = false;
        } else {
            this.barcodeReaderOutput.emit(this.barcodeReader);
        }        
    }
}
