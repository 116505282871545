import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { ActivationEnum } from 'app/core/enums/generated/ActivationEnum';
import { CustomFieldTypeEnum } from 'app/core/enums/generated/CustomFieldTypeEnum';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { DynamicFormFieldVm } from 'app/shared/generated/Models/DynamicFormFieldVm';
import { ActivationService } from 'app/shared/services/activation.service';
import { validateForm } from '../form-validateForm.function';

@Component({
    selector: 'pcg-edit-custom-field',
    templateUrl: './edit-custom-field.component.html',
    styleUrls: ['./edit-custom-field.component.scss'],
    standalone: false
})
export class EditCustomFieldComponent implements OnInit {

	@Input() getFieldUrl: string;
	@Input() saveFieldUrl: string;
	@Input() backUrl: string;
	@Input() loadUrl: string;
	@Input() backParams: any;
	@Input() backPageTitle: string = 'list of User Custom Fields';
	@Input() linkId: number;
	@Input() id = 0;
	@Input() canEdit: boolean = true;

	formGroup = DynamicFormFieldVm.Form;
	model = DynamicFormFieldVm.GetModel(this.formGroup);
	CustomFieldTypeEnum = CustomFieldTypeEnum;

	constructor(
		private httpClient: HttpClient
		, private ms: SystemMessageService
		, private activationService: ActivationService
		, private router: Router
	) { }

	ngOnInit() { this.getField(); }

	// Get a custom field
	getField() {
		if (this.id !== 0) {
			this.httpClient.get(`${this.getFieldUrl}/${this.id}`)
				.subscribe((model: DynamicFormFieldVm) => {
					if (model.hasBeenUsed) {
						this.formGroup.disable();
						this.formGroup.get('isActive').enable();
						this.formGroup.get('tooltip').enable();
						this.formGroup.get('name').enable();
					}
					if (model.customFieldType === CustomFieldTypeEnum.DropDown) { this.setDropdownFields(model); } 
					else { this.formGroup.patchValue(model); }
				}
			);
		} else {
			this.model.linkId = this.linkId;
			this.model.isActive = true;
		}
	}

	// Save a custom field
	saveField() {
		if (
			this.model.customFieldType === CustomFieldTypeEnum.DropDown
			&& (
				this.model.selectOption1 === null 
				|| this.model.selectOption1 === ''
			)
		) {
			this.ms.setSystemMessage('Option 1 must be entered for Custom Fields with a Control Type of Dropdown', 'error');
			return;
		} else if (this.model.customFieldType === CustomFieldTypeEnum.DropDown) { this.getDropdownFields(); }

		if (this.formGroup.valid) {
			if (this.router.url.includes("users")) { this.loadUrl = "/admin/users/custom-fields/edit/"; } 
			else { this.loadUrl = "/admin/inventory/custom-fields/edit/"; }
			this.ms.getHttpObservable(this, `${this.saveFieldUrl}`, this.formGroup)
				.subscribe(msg => {
					if (msg.isSuccessful) { 
						if (this.loadUrl) {
							this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
								this.router.navigate([`${this.loadUrl + msg.value}`]);
							});
						}
						this.formGroup.patchValue(msg.model); 
					}
				}
			);
		} else { validateForm(this.formGroup); }
	}

	setDropdownFields(model: DynamicFormFieldVm) {
		const selectItems = JSON.parse(model.selectItemJson);

		for (let i = 0; i < 10; ++i) {
			if (selectItems[i] !== null) {
				const count = (i + 1).toString();
				model['selectOption' + count] = selectItems[i];
			}
		}

		this.formGroup.patchValue(model);
	}

	getDropdownFields() {
		const selectItems: string[] = [];
		selectItems.push(this.model.selectOption1);

		for (let i = 2; i < 11; ++i) {
			const field = 'selectOption' + i.toString();
			if (
				this.model[field] !== null 
				&& this.model[field] !== '' 
				&& this.model[field] !== undefined
			) { selectItems.push(this.model[field]); }
		}

		this.model.selectItems = selectItems;
	}

	activateDeactivate() {
		var activationEnum: ActivationEnum;
		if (this.router.url.includes("users")) { activationEnum = ActivationEnum.UserCustomField; } 
		else { activationEnum = ActivationEnum.InventoryCustomField; }

		this.activationService.activateDeactivate(
			this.formGroup.value.customFieldId
			, activationEnum
			, this.formGroup.value.isActive ? false : true
		);
		this.formGroup.controls['isActive'].patchValue(!this.formGroup.value.isActive);

		if (this.router.url.includes("users")) { this.loadUrl = "/admin/users/custom-fields/edit/"; } 
		else { this.loadUrl = "/admin/inventory/custom-fields/edit/"; }

		if (this.loadUrl) {
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
				this.router.navigate([`${this.loadUrl + this.formGroup.value.customFieldId}`]);
			});
		}
	}
}
