import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';
import { GenericOrderProductVm, GenericOrderProductVmFromFg } from '../../Models/GenericOrder/GenericOrderProductVm';
import { MetabolicFormulaPatientInfoVm, MetabolicFormulaPatientInfoVmFromFg } from '../../MetabolicFormula/Models/MetabolicFormulaPatientInfoVm';
import { MetabolicFormulaPatientCareVm, MetabolicFormulaPatientCareVmFromFg } from '../../MetabolicFormula/Models/MetabolicFormulaPatientCareVm';
import { MetabolicFormulaInsuranceInfoVm, MetabolicFormulaInsuranceInfoVmFromFg } from '../../MetabolicFormula/Models/MetabolicFormulaInsuranceInfoVm';
import { GenericOrderStepVm, GenericOrderStepVmFromFg } from '../../Models/GenericOrder/GenericOrderStepVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class GenericOrderDetailsVm {
	dateCreated?: Date;
	dateCreatedStr?: string;
	createdUserId?: number;
	createdUserName?: string;
	createdUserEmail?: string;
	facilityId?: number;
	senderFacilityName?: string;
	senderFacilityAddress?: string;
	senderFacilityCity?: string;
	senderFacilityCounty?: string;
	senderFacilityState?: string;
	senderFacilityZip?: string;
	senderPhone?: string;
	senderPermitNumber?: string;
	facilityName?: string;
	facilityIdentifier?: string;
	facilityAddress?: string;
	facilityCity?: string;
	facilityCounty?: string;
	facilityState?: string;
	facilityZip?: string;
	facilityPhone?: string;
	phoneNumber?: string;
	dateOrdered?: Date;
	dateOrderedStr?: string;
	orderedUserId?: number;
	orderedUserName?: string;
	orderedUserEmail?: string;
	dateReviewed?: Date;
	dateReviewedStr?: string;
	reviewedUserId?: number;
	reviewedUserName?: string;
	reviewedUserEmail?: string;
	friendlyId?: string;
	stepName?: string;
	orderProductsVm?: GenericOrderProductVm[];
	missing2FA: boolean;
	canEdit?: boolean;
	trackingLink?: string;
	trackingNumber?: string;
	confirmationNumber?: string;
	shipmentServiceType?: any;
	shipmentServiceTypeStr?: string;
	patientInfoVm?: MetabolicFormulaPatientInfoVm;
	patientCareVm?: MetabolicFormulaPatientCareVm;
	insuranceInfoVm?: MetabolicFormulaInsuranceInfoVm;
	uniqueOrderIndex: number;
	orderId: number;
	order?: any;
	orderTitle?: string;
	orderStatus?: any;
	orderTabs?: any;
	availableStatuses?: any;
	orderSteps?: GenericOrderStepVm[];
	commentCount: number;
	uploadCount: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'dateCreated': new UntypedFormControl(null, [...(validators['dateCreated'] ?? []), ...[]]),
			'dateCreatedStr': new UntypedFormControl(null, [...(validators['dateCreatedStr'] ?? []), ...[]]),
			'createdUserId': new UntypedFormControl(null, [...(validators['createdUserId'] ?? []), ...[]]),
			'createdUserName': new UntypedFormControl(null, [...(validators['createdUserName'] ?? []), ...[]]),
			'createdUserEmail': new UntypedFormControl(null, [...(validators['createdUserEmail'] ?? []), ...[]]),
			'facilityId': new UntypedFormControl(null, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'senderFacilityName': new UntypedFormControl(null, [...(validators['senderFacilityName'] ?? []), ...[]]),
			'senderFacilityAddress': new UntypedFormControl(null, [...(validators['senderFacilityAddress'] ?? []), ...[]]),
			'senderFacilityCity': new UntypedFormControl(null, [...(validators['senderFacilityCity'] ?? []), ...[]]),
			'senderFacilityCounty': new UntypedFormControl(null, [...(validators['senderFacilityCounty'] ?? []), ...[]]),
			'senderFacilityState': new UntypedFormControl(null, [...(validators['senderFacilityState'] ?? []), ...[]]),
			'senderFacilityZip': new UntypedFormControl(null, [...(validators['senderFacilityZip'] ?? []), ...[]]),
			'senderPhone': new UntypedFormControl(null, [...(validators['senderPhone'] ?? []), ...[]]),
			'senderPermitNumber': new UntypedFormControl(null, [...(validators['senderPermitNumber'] ?? []), ...[]]),
			'facilityName': new UntypedFormControl(null, [...(validators['facilityName'] ?? []), ...[]]),
			'facilityIdentifier': new UntypedFormControl(null, [...(validators['facilityIdentifier'] ?? []), ...[]]),
			'facilityAddress': new UntypedFormControl(null, [...(validators['facilityAddress'] ?? []), ...[]]),
			'facilityCity': new UntypedFormControl(null, [...(validators['facilityCity'] ?? []), ...[]]),
			'facilityCounty': new UntypedFormControl(null, [...(validators['facilityCounty'] ?? []), ...[]]),
			'facilityState': new UntypedFormControl(null, [...(validators['facilityState'] ?? []), ...[]]),
			'facilityZip': new UntypedFormControl(null, [...(validators['facilityZip'] ?? []), ...[]]),
			'facilityPhone': new UntypedFormControl(null, [...(validators['facilityPhone'] ?? []), ...[]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.maxLength(10)]]),
			'dateOrdered': new UntypedFormControl(null, [...(validators['dateOrdered'] ?? []), ...[]]),
			'dateOrderedStr': new UntypedFormControl(null, [...(validators['dateOrderedStr'] ?? []), ...[]]),
			'orderedUserId': new UntypedFormControl(null, [...(validators['orderedUserId'] ?? []), ...[]]),
			'orderedUserName': new UntypedFormControl(null, [...(validators['orderedUserName'] ?? []), ...[]]),
			'orderedUserEmail': new UntypedFormControl(null, [...(validators['orderedUserEmail'] ?? []), ...[]]),
			'dateReviewed': new UntypedFormControl(null, [...(validators['dateReviewed'] ?? []), ...[]]),
			'dateReviewedStr': new UntypedFormControl(null, [...(validators['dateReviewedStr'] ?? []), ...[]]),
			'reviewedUserId': new UntypedFormControl(null, [...(validators['reviewedUserId'] ?? []), ...[]]),
			'reviewedUserName': new UntypedFormControl(null, [...(validators['reviewedUserName'] ?? []), ...[]]),
			'reviewedUserEmail': new UntypedFormControl(null, [...(validators['reviewedUserEmail'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'stepName': new UntypedFormControl(null, [...(validators['stepName'] ?? []), ...[]]),
			'orderProductsVm': new UntypedFormArray([]),
			'missing2FA': new UntypedFormControl(false, [...(validators['missing2FA'] ?? []), ...[]]),
			'canEdit': new UntypedFormControl(null, [...(validators['canEdit'] ?? []), ...[]]),
			'trackingLink': new UntypedFormControl(null, [...(validators['trackingLink'] ?? []), ...[]]),
			'trackingNumber': new UntypedFormControl(null, [...(validators['trackingNumber'] ?? []), ...[]]),
			'confirmationNumber': new UntypedFormControl(null, [...(validators['confirmationNumber'] ?? []), ...[]]),
			'shipmentServiceType': new UntypedFormControl(null, [...(validators['shipmentServiceType'] ?? []), ...[]]),
			'shipmentServiceTypeStr': new UntypedFormControl(null, [...(validators['shipmentServiceTypeStr'] ?? []), ...[]]),
			'patientInfoVm': MetabolicFormulaPatientInfoVm.Form,
			'patientCareVm': MetabolicFormulaPatientCareVm.Form,
			'insuranceInfoVm': MetabolicFormulaInsuranceInfoVm.Form,
			'uniqueOrderIndex': new UntypedFormControl(0, [...(validators['uniqueOrderIndex'] ?? []), ...[Validators.required]]),
			'orderId': new UntypedFormControl(0, [...(validators['orderId'] ?? []), ...[Validators.required]]),
			'order': new UntypedFormControl('', [...(validators['order'] ?? []), ...[Validators.required]]),
			'orderTitle': new UntypedFormControl(null, [...(validators['orderTitle'] ?? []), ...[]]),
			'orderStatus': new UntypedFormControl('', [...(validators['orderStatus'] ?? []), ...[Validators.required]]),
			'orderTabs': new UntypedFormControl([], [...(validators['orderTabs'] ?? []), ...[]]),
			'availableStatuses': new UntypedFormControl([], [...(validators['availableStatuses'] ?? []), ...[]]),
			'orderSteps': new UntypedFormArray([]),
			'commentCount': new UntypedFormControl(0, [...(validators['commentCount'] ?? []), ...[Validators.required]]),
			'uploadCount': new UntypedFormControl(0, [...(validators['uploadCount'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): GenericOrderDetailsVmFromFg {
		return new GenericOrderDetailsVmFromFg(fg);
	}
}

export class GenericOrderDetailsVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get dateCreated(): Date {
		return this.fg.get('dateCreated').value;
	}
	set dateCreated(val) {
		this.fg.get('dateCreated').setValue(val);
	}

	get dateCreatedStr(): string {
		return this.fg.get('dateCreatedStr').value;
	}
	set dateCreatedStr(val) {
		this.fg.get('dateCreatedStr').setValue(val);
	}

	get createdUserId(): number {
		return this.fg.get('createdUserId').value;
	}
	set createdUserId(val) {
		this.fg.get('createdUserId').setValue(val);
	}

	get createdUserName(): string {
		return this.fg.get('createdUserName').value;
	}
	set createdUserName(val) {
		this.fg.get('createdUserName').setValue(val);
	}

	get createdUserEmail(): string {
		return this.fg.get('createdUserEmail').value;
	}
	set createdUserEmail(val) {
		this.fg.get('createdUserEmail').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get senderFacilityName(): string {
		return this.fg.get('senderFacilityName').value;
	}
	set senderFacilityName(val) {
		this.fg.get('senderFacilityName').setValue(val);
	}

	get senderFacilityAddress(): string {
		return this.fg.get('senderFacilityAddress').value;
	}
	set senderFacilityAddress(val) {
		this.fg.get('senderFacilityAddress').setValue(val);
	}

	get senderFacilityCity(): string {
		return this.fg.get('senderFacilityCity').value;
	}
	set senderFacilityCity(val) {
		this.fg.get('senderFacilityCity').setValue(val);
	}

	get senderFacilityCounty(): string {
		return this.fg.get('senderFacilityCounty').value;
	}
	set senderFacilityCounty(val) {
		this.fg.get('senderFacilityCounty').setValue(val);
	}

	get senderFacilityState(): string {
		return this.fg.get('senderFacilityState').value;
	}
	set senderFacilityState(val) {
		this.fg.get('senderFacilityState').setValue(val);
	}

	get senderFacilityZip(): string {
		return this.fg.get('senderFacilityZip').value;
	}
	set senderFacilityZip(val) {
		this.fg.get('senderFacilityZip').setValue(val);
	}

	get senderPhone(): string {
		return this.fg.get('senderPhone').value;
	}
	set senderPhone(val) {
		this.fg.get('senderPhone').setValue(val);
	}

	get senderPermitNumber(): string {
		return this.fg.get('senderPermitNumber').value;
	}
	set senderPermitNumber(val) {
		this.fg.get('senderPermitNumber').setValue(val);
	}

	get facilityName(): string {
		return this.fg.get('facilityName').value;
	}
	set facilityName(val) {
		this.fg.get('facilityName').setValue(val);
	}

	get facilityIdentifier(): string {
		return this.fg.get('facilityIdentifier').value;
	}
	set facilityIdentifier(val) {
		this.fg.get('facilityIdentifier').setValue(val);
	}

	get facilityAddress(): string {
		return this.fg.get('facilityAddress').value;
	}
	set facilityAddress(val) {
		this.fg.get('facilityAddress').setValue(val);
	}

	get facilityCity(): string {
		return this.fg.get('facilityCity').value;
	}
	set facilityCity(val) {
		this.fg.get('facilityCity').setValue(val);
	}

	get facilityCounty(): string {
		return this.fg.get('facilityCounty').value;
	}
	set facilityCounty(val) {
		this.fg.get('facilityCounty').setValue(val);
	}

	get facilityState(): string {
		return this.fg.get('facilityState').value;
	}
	set facilityState(val) {
		this.fg.get('facilityState').setValue(val);
	}

	get facilityZip(): string {
		return this.fg.get('facilityZip').value;
	}
	set facilityZip(val) {
		this.fg.get('facilityZip').setValue(val);
	}

	get facilityPhone(): string {
		return this.fg.get('facilityPhone').value;
	}
	set facilityPhone(val) {
		this.fg.get('facilityPhone').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get dateOrdered(): Date {
		return this.fg.get('dateOrdered').value;
	}
	set dateOrdered(val) {
		this.fg.get('dateOrdered').setValue(val);
	}

	get dateOrderedStr(): string {
		return this.fg.get('dateOrderedStr').value;
	}
	set dateOrderedStr(val) {
		this.fg.get('dateOrderedStr').setValue(val);
	}

	get orderedUserId(): number {
		return this.fg.get('orderedUserId').value;
	}
	set orderedUserId(val) {
		this.fg.get('orderedUserId').setValue(val);
	}

	get orderedUserName(): string {
		return this.fg.get('orderedUserName').value;
	}
	set orderedUserName(val) {
		this.fg.get('orderedUserName').setValue(val);
	}

	get orderedUserEmail(): string {
		return this.fg.get('orderedUserEmail').value;
	}
	set orderedUserEmail(val) {
		this.fg.get('orderedUserEmail').setValue(val);
	}

	get dateReviewed(): Date {
		return this.fg.get('dateReviewed').value;
	}
	set dateReviewed(val) {
		this.fg.get('dateReviewed').setValue(val);
	}

	get dateReviewedStr(): string {
		return this.fg.get('dateReviewedStr').value;
	}
	set dateReviewedStr(val) {
		this.fg.get('dateReviewedStr').setValue(val);
	}

	get reviewedUserId(): number {
		return this.fg.get('reviewedUserId').value;
	}
	set reviewedUserId(val) {
		this.fg.get('reviewedUserId').setValue(val);
	}

	get reviewedUserName(): string {
		return this.fg.get('reviewedUserName').value;
	}
	set reviewedUserName(val) {
		this.fg.get('reviewedUserName').setValue(val);
	}

	get reviewedUserEmail(): string {
		return this.fg.get('reviewedUserEmail').value;
	}
	set reviewedUserEmail(val) {
		this.fg.get('reviewedUserEmail').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get stepName(): string {
		return this.fg.get('stepName').value;
	}
	set stepName(val) {
		this.fg.get('stepName').setValue(val);
	}

	get missing2FA(): boolean {
		return this.fg.get('missing2FA').value;
	}
	set missing2FA(val) {
		this.fg.get('missing2FA').setValue(val);
	}

	get canEdit(): boolean {
		return this.fg.get('canEdit').value;
	}
	set canEdit(val) {
		this.fg.get('canEdit').setValue(val);
	}

	get trackingLink(): string {
		return this.fg.get('trackingLink').value;
	}
	set trackingLink(val) {
		this.fg.get('trackingLink').setValue(val);
	}

	get trackingNumber(): string {
		return this.fg.get('trackingNumber').value;
	}
	set trackingNumber(val) {
		this.fg.get('trackingNumber').setValue(val);
	}

	get confirmationNumber(): string {
		return this.fg.get('confirmationNumber').value;
	}
	set confirmationNumber(val) {
		this.fg.get('confirmationNumber').setValue(val);
	}

	get shipmentServiceType(): any {
		return this.fg.get('shipmentServiceType').value;
	}
	set shipmentServiceType(val) {
		this.fg.get('shipmentServiceType').setValue(val);
	}

	get shipmentServiceTypeStr(): string {
		return this.fg.get('shipmentServiceTypeStr').value;
	}
	set shipmentServiceTypeStr(val) {
		this.fg.get('shipmentServiceTypeStr').setValue(val);
	}

	get patientInfoVm(): MetabolicFormulaPatientInfoVmFromFg {
		return new MetabolicFormulaPatientInfoVmFromFg(this.fg.get('patientInfoVm') as UntypedFormGroup);
	}
	set patientInfoVm(val) {
		(this.fg.get('patientInfoVm') as UntypedFormGroup).setValue(val);
	}

	get patientCareVm(): MetabolicFormulaPatientCareVmFromFg {
		return new MetabolicFormulaPatientCareVmFromFg(this.fg.get('patientCareVm') as UntypedFormGroup);
	}
	set patientCareVm(val) {
		(this.fg.get('patientCareVm') as UntypedFormGroup).setValue(val);
	}

	get insuranceInfoVm(): MetabolicFormulaInsuranceInfoVmFromFg {
		return new MetabolicFormulaInsuranceInfoVmFromFg(this.fg.get('insuranceInfoVm') as UntypedFormGroup);
	}
	set insuranceInfoVm(val) {
		(this.fg.get('insuranceInfoVm') as UntypedFormGroup).setValue(val);
	}

	get uniqueOrderIndex(): number {
		return this.fg.get('uniqueOrderIndex').value;
	}
	set uniqueOrderIndex(val) {
		this.fg.get('uniqueOrderIndex').setValue(val);
	}

	get orderId(): number {
		return this.fg.get('orderId').value;
	}
	set orderId(val) {
		this.fg.get('orderId').setValue(val);
	}

	get order(): any {
		return this.fg.get('order').value;
	}
	set order(val) {
		this.fg.get('order').setValue(val);
	}

	get orderTitle(): string {
		return this.fg.get('orderTitle').value;
	}
	set orderTitle(val) {
		this.fg.get('orderTitle').setValue(val);
	}

	get orderStatus(): any {
		return this.fg.get('orderStatus').value;
	}
	set orderStatus(val) {
		this.fg.get('orderStatus').setValue(val);
	}

	get commentCount(): number {
		return this.fg.get('commentCount').value;
	}
	set commentCount(val) {
		this.fg.get('commentCount').setValue(val);
	}

	get uploadCount(): number {
		return this.fg.get('uploadCount').value;
	}
	set uploadCount(val) {
		this.fg.get('uploadCount').setValue(val);
	}
}
