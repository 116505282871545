<ng-container *ngIf='!isCreate; else createUserForm;'>
	<pcg-page-info>
		Users in this list are granted access to all facilities within the organization, provided that they possess module access to at least 
		one of the business areas assigned to those facilities.
	</pcg-page-info>
	
	<div id="informativeContent">
		@if (canCreateUser) {
			<div id="actionElements">
				<a 
					(click)="createUser()" 
					class='btn btn-save btn-sm inline-add-btn'
				>Create New User</a>
			</div>
		}
		@if (canAddUser) {
			<div id="actionElements">
				<a 
					(click)="addUserBtn()" 
					class='btn btn-save btn-sm inline-add-btn'
				>Add Existing User</a>
			</div>
		}
	</div>

    <pcg-table 
		dataSource="api/Administration/Users/GetOrganizationUsers" 
		[pcgSort]="[['name', 'asc']]"
        [columnDefs]="columns" 
		[ajaxData]="params"
	>

        <ng-container matColumnDef="name">
            <th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('name').displayName }}</th>
            <td 
				mat-cell 
				*matCellDef="let row"
			>
				@if (canEditUser) { <a [href]="getEditUserLink(row.id)">{{ row.name }}</a> }
				@else { <span>{{ row.name }}</span> }
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('email').displayName }}</th>
            <td 
				mat-cell 
				*matCellDef="let row"
			>{{ row.email }}</td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
            <th 
				mat-header-cell 
				pcg-sort-header 
				*matHeaderCellDef
			>{{ columns.get('phoneNumber').displayName }}</th>
            <td 
				mat-cell 
				*matCellDef="let row"
			>{{ row.phoneNumber }}</td>
        </ng-container>

    </pcg-table>
</ng-container>

<ng-template #createUserForm>
	<form 
		pcgSubmitUrl="api/Administration/Users/SaveUser" 
		(messageReceive)="saveUser($event)" 
		[formGroup]="formGroup"
	>
		<input type="hidden" formControlName="userId" />
		<input type="hidden" formControlName="isActive" />
		<input type="hidden" formControlName="inactivityTimeout" />
		<input type="hidden" formControlName="isInternalBphpUser" />
		<input type="hidden" formControlName="updateOrgs" />
		<input type="hidden" formControlName="updateFacilities" />
		<input type="hidden" formControlName="facilityIds" />

		<div class="row">
			<h2 class="col-md-9">Create User</h2>
		</div>
		<hr />

		<div class='row'>
			<pcg-control-group 
				class="col-md-4" 
				label="First Name"
			>
				<input 
					pcgControlRef 
					class='form-control' 
					type='text' 
					formControlName="firstName" 
				/>
			</pcg-control-group>

			<pcg-control-group 
				class="col-md-4" 
				label="Last Name"
			>
				<input 
					pcgControlRef 
					class='form-control' 
					type='text' 
					formControlName="lastName" 
				/>
			</pcg-control-group>

			<pcg-control-group 
				class="col-md-4" 
				label="Username"
			>
				<input 
					pcgControlRef 
					class='form-control' 
					type='text' 
					formControlName="userName" 
				/>
			</pcg-control-group>
		</div>

		<div class='row'>
			<pcg-control-group 
				class="col-md-4" 
				label="Email"
			>
				<input 
					pcgControlRef 
					class='form-control email' 
					type='text' 
					formControlName="email" 
				/>
			</pcg-control-group>

			<pcg-control-group 
				class="col-md-4" 
				label="Phone"
			>
				<input 
					pcgPhoneExt 
					pcgControlRef 
					class='form-control' 
					type='text' 
					formControlName="phoneNumber" 
				/>
			</pcg-control-group>
		</div>

		@if (
			canEditOrganizations 
			&& userId > 0
		) {
			<div class="container">
				<br /><br />
				<h2>Organizations</h2>
				<hr />

				<div class='row'>
					<div 
						class="col-md-12" 
						label="Organizations"
					>
						<pcg-dual-list-box 
							leftHeader='Available Organizations' 
							rightHeader='Selected Organizations'
							[source]='allOrganizations' 
							[destination]='associatedOrganizations'
							(click)="handleOrganizations()"
						></pcg-dual-list-box>
					</div>
				</div>
			</div>
		}
		@if (
			!canEditOrganizations
			|| userId === 0
		) {
			<div class="container">
				<input 
					type="hidden" 
					formControlName="organizationIds" 
					pcgControlRef 
				/>
			</div>
		}

		<div class='bottom-buttons'>
			<br />
			<a 
				class='btn btn-cancel btn-lg' 
				(click)="cancelAdd()"
			>Cancel</a>
			<button class='btn btn-save btn-lg'>Create</button>
		</div>
	</form>
</ng-template>

<ng-template 
	#modalExistingUsersModal 
	let-modal
>
	<div 
		id="modalExistingUsers" 
		class="modal-content"
	>
		<div class='modal-header'>
			<h5 
				class='modal-title' 
				id='modal-basic-title'
			>Add an Existing User</h5>
			<button 
				type='button' 
				class='close' 
				aria-label='Close' 
				(click)='modal.dismiss()'
			><span aria-hidden='true'>&times;</span></button>
		</div>

		<div class='modal-body'>
			<pcg-table 
				#existingUserTable 
				id="existingUserTable" 
				[multipleSearch]='false' 
				[canShowHideColumns]="false"
				[canExactMatchSearch]="false" 
				[canResetFilters]="false" 
				[pcgSort]='[[ "firstName", "asc" ]]'
				[pageSize]='10' 
				[pageLengths]='[5,10]' 
				dataSource='api/Administration/UserOrganization/GetUsersToAdd'
				[columnDefs]='columns' 
				[ajaxData]="filters"
			>

				<ng-container matColumnDef="name">
					<th 
						style="top:0" 
						mat-header-cell 
						*matHeaderCellDef
					>{{ columns.get('name').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef="let row"
					><a [href]="getEditUserLink(row.id)">{{ row.name }}</a></td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th 
						style="top:0" 
						mat-header-cell 
						*matHeaderCellDef
					>{{ columns.get('email').displayName }}</th>
					<td 
						mat-cell 
						*matCellDef="let row"
					>{{ row.email }}</td>
				</ng-container>

				<ng-container matColumnDef="phoneNumber">
					<th 
						style="top:0" 
						class='text-center' 
						mat-header-cell 
						*matHeaderCellDef
					>Add</th>
					<td 
						class='text-center' 
						mat-cell 
						*matCellDef='let row'
					>
						<img 
							(click)='addExistingUser(row.id, existingUserTable)' 
							style="width: 25px; cursor: pointer;"
							src="assets/images/actions/Add.svg" 
						/>
					</td>
				</ng-container>

			</pcg-table>
		</div>
	</div>
</ng-template>