<div *ngIf="formGroup.controls.id.value > 0" class="ticket-feedback">
    <!-- Page Header -->
    <div class="ticket-feedback-hdr">
        <div class="title">
            <img src="assets\images\logos\logo.png" class="logo">
            <h1>User Feedback Survey</h1>
        </div>
        <p *ngIf="formGroup.controls.rating.value <= 0">
            Thank you for using the HOMER Support Desk Ticketing system. Your Support Desk Ticket has been completed and closed. 
            <br>
            Please help us improve our customer support by providing feedback on your experience.
        </p>
    </div>
    
    <!-- Ticket Information -->
    <div *ngIf="formGroup.controls.rating.value <= 0" class="ticket-info-ctnr">
        <div class="ticket-info-body">
            <div class="ticket-info">
                <h5>ID:</h5>
                <p>{{ formGroup.controls.helpDeskTicketId.value }}</p>
            </div>
            <div class="ticket-info">
                <h5>Subject:</h5>
                <p>{{ formGroup.controls.helpDeskTicketSubject.value }}</p>
            </div>
            <div class="ticket-info">
                <h5>Facility:</h5>
                <p>{{ formGroup.controls.helpDeskTicketFacility.value }}</p>
            </div>
        </div>
    </div>
    
    <!-- Rating Input -->
    <form *ngIf="formGroup.controls.rating.value <= 0" [formGroup]="formGroup">
        <div class="rating-ctnr">
            <div class="rating-hdr">
                <h5 class="required-field">Rate Your Experience</h5>
            </div>
            <div class="rating-body">
                <div class="star-rating">
                    <span><span>{{ this.starRating }}</span> / 5</span>                                
                    &nbsp;&nbsp;
                    <fa-icon [icon]="faIconName.faStar" class="star-icon fa-lg" (click)="addStars(1)"></fa-icon>
                    <fa-icon [icon]="faIconName.faStar" class="star-icon fa-lg" (click)="addStars(2)"></fa-icon>
                    <fa-icon [icon]="faIconName.faStar" class="star-icon fa-lg" (click)="addStars(3)"></fa-icon>
                    <fa-icon [icon]="faIconName.faStar" class="star-icon fa-lg" (click)="addStars(4)"></fa-icon>	
                    <fa-icon [icon]="faIconName.faStar" class="star-icon fa-lg" (click)="addStars(5)"></fa-icon>
                </div>
                <pcg-control-group class="col-md-12">
                    <textarea 
                        class="form-control" 
                        formControlName="comment" 
                        placeholder="Optional comments..."
                        pcgControlRef 
                        maxlength="1000"
                    ></textarea>
                    <span>Characters left: {{1000 - formGroup.controls.comment.value?.length}}</span>
                </pcg-control-group>
            </div>
        </div>
        <br>
        <div *ngIf="isError" class="alert alert-danger text-center error">Rating is required to submit this feedback.</div>
        <button class="btn btn-save btn-lg" (click)="saveForm()">Submit</button>

        <ngx-invisible-recaptcha 
            #captchaElem 
            [siteKey]="siteKey" 
            (reset)="handleReset()" 
            (ready)="handleReady()"
			(load)="handleLoad()" 
            (success)="handleSuccess($event)" 
            [useGlobalDomain]="false" 
            theme="light" 
            type="image"
			badge="bottomright" 
            formControlName="recaptcha"
        ></ngx-invisible-recaptcha>
    </form>
    <div 
        *ngIf="formGroup.controls.rating.value > 0" 
        class="alert alert-success text-center"
    >Thank you for your response! Your feedback has been submitted.</div>
</div>