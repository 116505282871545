import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { faUserLock, faEnvelope, faTennisBall, faHourglassClock, faEnvelopesBulk } from '@fortawesome/pro-solid-svg-icons';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { DynamicFormService } from 'app/shared/form-elements/components/dynamic-form/dynamic-form.service';
import { FacilityUserListVm } from 'app/shared/generated/Administration/Models/Facilities/FacilityUserListVm';
import { EditUserVm } from 'app/shared/generated/Administration/Models/Users/EditUserVm';
import { TableComponent } from 'app/shared/table/table.component';
import { FacilityBusinessAreaVm } from 'app/shared/generated/Administration/Models/Facilities/BusinessAreas/FacilityBusinessAreaVm';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';

@Component({
    selector: 'pcg-shared-facility-user-list',
    templateUrl: './facility-user-list.component.html',
    styleUrls: ['./facility-user-list.component.scss'],
    standalone: false
})
export class FacilityUserListComponent implements OnInit {

    @ViewChild(TableComponent, { static: false }) table: TableComponent<FacilityUserListVm>;

    @Input() businessArea: BusinessAreaEnum;
    @Input() facilityName: string;
    @ViewChild('modalExistingUsersModal', { static: true }) modalContent: TemplateRef<any>;

    subscriptions = new Subscription();
    formGroup = EditUserVm.Form;
	model = EditUserVm.GetModel(this.formGroup);

    canCreateUser = false;
	canDeleteUser = false;
	canEditUser = false;
    canAddUser = false;
    canEditFacilities = false; 
    isCreate = false;
    userId = 0;
    facilityId: number;
    newUser = "";
    
	columns = FacilityUserListVm.ColumnDefs;
	filters = {
		facilityId: 0,
        businessArea: null,
        showSysAdmin: false
	};
    initFilters = Object.assign({}, this.filters);
	filterMap = { "showSysAdmin" : "Show System Admins?"}

    faIconName = { 
		faUserLock
		, faEnvelope
		, faTennisBall
		, faHourglassClock 
		, faEnvelopesBulk
	};

    tooltipModalString: string = `
		<p>
			<img src="assets/icons/user-lock-solid.svg" width="18px" />
			&nbsp; Locked Users: These users have entered an incorrect password 5 consecutive times. 
			Click the Unlock button on their profile to unlock their account and send them a reset password email.<br />
			<img src="assets/icons/invalid-email.svg" width="18px" />
			&nbsp; Email needs manual validation:  Our automated validation tool flagged this email.  
			Click the Validate Email button on their profile.<br />
			<img src="assets/icons/bounced-email.svg" width="18px" />
			&nbsp; Undeliverable Email: Usually indicates a permanent error, 
			but can also be caused by a strict email security filter.You may need to reach out to your IT to whitelist our domain. 
			Click the Unbounce Email button on their profile.<br />
			<img src="assets/icons/spam-email.svg" width="18px" />
			&nbsp; Undeliverable Email due to Spam Report: 
			The user has reported one of our emails as spam. Click the Undo Spam button on their profile.
		</p>
	`;
    //TODO: Implement this property after adding user deactivation quartz jobs
	// <img src="assets/icons/deactivated-inactivity.svg" width="18px" />
	// &nbsp; This user has been deactivated due to 180 days of not logging in
	// or verifying a medication use, not completing their setup within 90 days, or no welcome email has been sent in 180 days since this user was created. 
	// Click the Reactivate button on their profile.<br/>

    constructor(
        private activatedRoute: ActivatedRoute
        , public modalService: NgbModal
        , private security: SecurityService
        , private httpClient: HttpClient
        , private dynForm: DynamicFormService
        , private ms: SystemMessageService
        , public title: Title
        , public btnFilterService: ButtonFilterService
    ) {
        title.setTitle('Edit Facility');
		this.canCreateUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.Pharmacist ]
        ) 
        || this.security.hasModuleAccess(
            [ 
                BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [
                PermissionRole.UserAdmin
                , ...SecurityService.setMinRole(PermissionRole.Manager)
            ]
        );
		this.canDeleteUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.Pharmacist ]
        ) 
        || this.security.hasModuleAccess(
            [
                BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [
                PermissionRole.UserAdmin
                , ...SecurityService.setMinRole(PermissionRole.Manager)
            ]
        );
		this.canEditUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.Pharmacist ]
        ) 
        || this.security.hasModuleAccess(
            [ 
                BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [
                PermissionRole.UserAdmin
                , ...SecurityService.setMinRole(PermissionRole.Manager)
            ]
        );
        this.canAddUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.SystemAdmin ]
        );
     }

    ngOnInit() {
        this.filters.facilityId = this.activatedRoute.snapshot.params['id'];
        this.newUser = "/admin/users/user/edit/0?facilityId=" + this.filters.facilityId;
        if (
            this.businessArea !== null 
            && this.businessArea !== undefined
        ) {
            this.filters.businessArea = this.businessArea; 
            this.newUser += "&businessArea=" + this.businessArea;
        }     

        this.getBusinessArea();
        this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters(); });
    }

    reloadTable() { this.table.ajaxReload(); }

    resetFilters() {
        this.filters.showSysAdmin = false;
		this.reloadTable();
	}

    getBusinessArea(){
        this.httpClient.get(`api/Administration/Facility/GetFacilityBusinessSettings/${this.filters.facilityId}`)
        .subscribe((ba: FacilityBusinessAreaVm) => {
            if(ba.hasCiAccess && this.security.hasModuleAccess(
                [ BusinessAreaEnum.CI ]
                , [
                    PermissionRole.UserAdmin
                    , ...SecurityService.setMinRole(PermissionRole.Manager)
                ]))
                this.businessArea = BusinessAreaEnum.CI;
            else if (ba.hasInventoryAccess && this.security.hasModuleAccess(
                [ BusinessAreaEnum.Inventory ]
                , [
                    PermissionRole.UserAdmin
                    , ...SecurityService.setMinRole(PermissionRole.Manager)
                ]))
                this.businessArea = BusinessAreaEnum.Inventory;
            else if(ba.hasMetabolicAccess && this.security.hasModuleAccess(
                [ BusinessAreaEnum.MetabolicFormula ]
                , [
                    PermissionRole.UserAdmin
                    , ...SecurityService.setMinRole(PermissionRole.Manager)
                ]))
                this.businessArea = BusinessAreaEnum.MetabolicFormula;
            this.setSecurity();
        });
    }

    setSecurity() {
        if (this.businessArea !== null && this.businessArea !== undefined) {
            switch (this.businessArea) {
				case BusinessAreaEnum.MetabolicFormula:
                    this.canEditFacilities = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.MetabolicFormula ]
                        , [ 
                            PermissionRole.Manager
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canEditUser = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.MetabolicFormula ]
                        , [
                            PermissionRole.UserAdmin
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
					this.canCreateUser = this.canEditUser;
					break;
				case BusinessAreaEnum.CI:
					this.canEditFacilities = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.CI ]
                        , [
                            PermissionRole.Manager
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canEditUser = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.CI ]
                        , [
                            PermissionRole.UserAdmin
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
					this.canCreateUser = this.canEditUser;
					break;
                case BusinessAreaEnum.Inventory:
                    this.canEditFacilities = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.Inventory ]
                        , [ 
                            PermissionRole.Manager
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canEditUser = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.Inventory ]
                        , [
                            PermissionRole.UserAdmin
                            , ...SecurityService.setMinRole(PermissionRole.Pharmacist)
                        ]
                    );
					this.canCreateUser = this.canEditUser;
                    break;
				case BusinessAreaEnum.Admin:
					this.canEditFacilities = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.Admin ]
                        , [ 
                            PermissionRole.Manager
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canEditUser = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.Admin ]
                        , [
							 ...SecurityService.setMinRole(PermissionRole.Pharmacist)
                        ]
                    );
					this.canCreateUser = this.canEditUser;
					break;
				default: 
					break;
			}
		} else {
			this.canEditFacilities = this.security.hasModuleAccess(
                [
                    BusinessAreaEnum.Admin
                    , BusinessAreaEnum.CI
                    , BusinessAreaEnum.MetabolicFormula
                    , BusinessAreaEnum.Inventory
                ]
                , [
                    PermissionRole.Manager
                    , ...SecurityService.setMinRole(PermissionRole.Manager)
                ]
            );
            this.canEditUser = this.security.hasModuleAccess(
                [
                    BusinessAreaEnum.Admin
                    , BusinessAreaEnum.CI
                    , BusinessAreaEnum.MetabolicFormula
                ]
                , [
                    PermissionRole.UserAdmin
                    , ...SecurityService.setMinRole(PermissionRole.Manager)
                ]
            );
			this.canCreateUser = this.canEditUser;
		}
    }

    getEditUserLink(id: number) {
        if (
            this.businessArea !== null 
            && this.businessArea !== undefined
        ) {
            return decodeURI(
                '/admin/users/user/edit/' 
                + id 
                + "?facilityId=" 
                + this.filters.facilityId 
                + "&businessArea=" 
                + this.businessArea
            );
        }
        return '/admin/users/user/edit/' + id;
    }

    addUserBtn() {
        this.modalService.open(this.modalContent, {
			animation: false, beforeDismiss: () => {
				return true;
			}
		});    
    }

    addExistingUser(userId: number, table: TableComponent<FacilityUserListVm>) {
        this.httpClient.post(
            `api/administration/Facility/AddExistingUser?userId=${userId}&facilityId=${this.filters.facilityId}`
            , null
        ).subscribe((msg: SystemMessage) => { 
            if (msg.isSuccessful) { 
                table.ajaxReload(); 
                this.reloadTable();
            } 
        });            
    }

    createUser() {
        this.facilityId = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
        this.setSecurity();

        this.subscriptions.add(this.httpClient.get(`api/Administration/Users/GetUser/0`)
            .subscribe((vm: EditUserVm) => {
                this.formGroup = this.dynForm.updateFormGroupFields(this.formGroup, vm.fields);
                this.formGroup.patchValue(vm);
                
                this.formGroup.controls["isActive"].setValue(vm.isActive);
                this.formGroup.controls["inactivityTimeout"].setValue(false);
                this.formGroup.controls["isInternalBphpUser"].setValue(false);
                this.formGroup.controls["updateFacilities"].setValue(true);
                if (this.businessArea !== null && this.businessArea !== undefined) {
                    this.formGroup.controls["businessArea"].setValue(this.businessArea);
                }
                
                this.formGroup.controls["facilityIds"].setValue([this.facilityId]);
            }));

        this.isCreate = true;         
    }

    saveUser(msg: SystemMessage) {
        msg.message= msg.message + ` \nPlease set the user's module access to finish associating them to the facility.`;
        msg.link = `<a href='admin/users/user/edit/${this.formGroup.value.userId}?tab=Module%20Access'>Click Here</a>`
        this.ms.setSystemMessage(msg.message, 'warning', 60000, msg.link);   
        
        if (msg.value) {
			this.isCreate = false;
            this.reloadTable();
		}
    }

    cancelAdd() { this.isCreate = false; }
}
