import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faUsbDrive } from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { VerificationTypeEnum } from 'app/core/enums/generated/VerificationTypeEnum';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { VerificationSettingVm } from 'app/shared/generated/Administration/Models/VerificationSettingVm';
import { VerificationVm } from 'app/shared/generated/Administration/Models/VerificationVm';
import { SelectItem } from 'app/shared/models/select-item';

@Component({
    selector: 'pcg-verification-settings',
    templateUrl: './verification-settings.component.html',
    styleUrls: ['./verification-settings.component.scss'],
    standalone: false
})
export class VerificationSettingsComponent implements OnInit {

    @Input() businessArea: BusinessAreaEnum;
    @Input() getUrlAction: string;
    @Input() saveUrlAction: string;

    formGroup = VerificationSettingVm.Form;
	model = VerificationSettingVm.GetModel(this.formGroup);
	model$: Observable<VerificationSettingVm>;
    verificationVm: VerificationVm[] = [];
    amountOfSigs: SelectItem[] = [];
    user: UserJsVm;

    canEdit = false;

    faIcon = { faUsbDrive };

    verificationType = VerificationTypeEnum;

    constructor(
        private httpClient: HttpClient
		, private sms: SystemMessageService
		, public router: Router
		, public sec: SecurityService
    ) { }

    ngOnInit() {
        this.user = this.sec.getUser();
        this.checkSecurity();
        
        this.model$ = this.httpClient
			.get<VerificationSettingVm>(`api/Administration/VerificationSettings/${this.getUrlAction}`)
			.pipe(tap((vm: VerificationSettingVm) => {
                this.addVerificationSettings(vm.verificationSettings.length);
				this.formGroup.setValue(vm); 
				this.amountOfSigs = vm.amountOfChoices;
				this.verificationVm = this.formGroup.get('verificationSettings').value;	
			}));
    }

    checkSecurity() {
        if (this.businessArea === BusinessAreaEnum.CI) {
            this.canEdit = this.sec.hasModuleAccess(
                [ BusinessAreaEnum.CI ]
                , SecurityService.setMinRole(PermissionRole.SystemAdmin)
            );
        } else if (this.businessArea === BusinessAreaEnum.Inventory) {
            this.canEdit = this.sec.hasModuleAccess(
                [ BusinessAreaEnum.Inventory ]
                , SecurityService.setMinRole(PermissionRole.SystemAdmin)
            );
        } else if (this.businessArea === BusinessAreaEnum.Admin) {
            this.canEdit = this.sec.hasModuleAccess(
                [ BusinessAreaEnum.Admin ]
                , SecurityService.setMinRole(PermissionRole.SystemAdmin)
            );
        } else if (this.businessArea === BusinessAreaEnum.SysInv) {
            this.canEdit = this.sec.hasModuleAccess(
                [ BusinessAreaEnum.SysInv ]
                , SecurityService.setMinRole(PermissionRole.SystemAdmin)
            );
        }
        if (!this.canEdit) {
            this.router.navigateByUrl('dashboard');
            this.sms.setSystemMessage("Access Denied. You do not have sufficient permission level.", "error");
        }
    }

    addVerificationSettings(amount: number) {
        for (let i = 0; i < amount; i++) {
			(this.formGroup.controls.verificationSettings as UntypedFormArray)
                .push(new UntypedFormGroup(VerificationVm.FormData));
		}
    }

    numVerificationsChange(val: number, cntrl, item: VerificationVm ) {
		cntrl.setValue(val);
		this.saveForm(item);
	}

    verificationTypeChange(val: number, cntrl, isSelected: boolean, item: VerificationVm) {
		const index = cntrl.value.indexOf(val, 0);
		if (isSelected === true) { cntrl.value.splice(index, 1); } 
		else { cntrl.value.push(val); }
		this.saveForm(item, val, cntrl);
	}

    saveForm(item: VerificationVm, val?: number, cntrl?) {
        this.httpClient.post(
			`api/Administration/VerificationSettings/${this.saveUrlAction}`, 
			this.filterForm(item)
		).subscribe((sm: SystemMessage) => { 
			this.sms.setSystemMessage(sm.message, sm.messageClass);
            if(!sm.isSuccessful){
                cntrl.value.push(val);
            }
		});
    }

    // Filter verificationsSettings list by the item (verificationEnum member) you are changing
    filterForm(item: VerificationVm) : VerificationSettingVm {
        var settingsVm : VerificationSettingVm = this.formGroup.getRawValue();
        settingsVm.verificationSettings = settingsVm.verificationSettings.filter(o => o.verificationEnum == item.verificationEnum);
        return settingsVm;
    }
}
