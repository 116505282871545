import { faBomb } from '@fortawesome/pro-solid-svg-icons';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getErrorReportNav: () => NavRoute = () => {

    const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.

    if (user !== null) {
        return {
            name: 'Error Reporting'
            , canActivate: [ AuthGuard ]
            , moduleAccess: [
                {
					modules: [ BusinessAreaEnum.ErrorReport ]
					, permissionRoles: sec.setMinRole(p.User)
				}
            ]
            , href: 'error-report/error-reports/list'
            , children: [
                {
					name: 'Error Reports'
					, path: 'error-report/error-reports/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ErrorReport ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
                    , activeNavLink: 'error-report/error-reports'
                    , secondaryNav: ['Error Reporting', 'Error Reports']
                }
                , {
					name: 'Admin'
					, href: 'error-report/admin/error-types/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.ErrorReport ]
							, permissionRoles: sec.setMinRole(p.SystemAdmin)
						}
					]
					, children: [
						{
							name: 'Error Types'
							, exact: true
							, path: 'error-report/admin/error-types/list'
							, moduleAccess: [
								{
									modules: [ BusinessAreaEnum.ErrorReport ]
									, permissionRoles: sec.setMinRole(p.SystemAdmin)
								}
							]
                            , activeNavLink: 'error-report/admin/error-types'
				            , secondaryNav: ['Error Reporting', 'Admin', 'Error Types']
						},
                        {
                            name: 'Report Builder'
                            , exact: true
                            , path: 'error-report/admin/report-builder/list'
                            , moduleAccess: [
                                {
                                    modules: [ BusinessAreaEnum.ErrorReport ]
									, permissionRoles: sec.setMinRole(p.SystemAdmin)
                                }
                            ]
                            , activeNavLink: 'error-report/admin/report-builder'
                            , secondaryNav: ['Error Reporting', 'Admin', 'Report Builder']
                        },
                        {
                            name: 'Permission Levels'
                            , exact: true
                            , path: 'error-report/admin/permission-levels/list'
                            , moduleAccess: [
                                {
                                    modules: [ BusinessAreaEnum.ErrorReport ]
									, permissionRoles: sec.setMinRole(p.SystemAdmin)
                                }
                            ]
                            , activeNavLink: 'error-report/admin/permission-levels'
                            , secondaryNav: ['Error Reporting', 'Admin', 'Permission Levels']
                        },
                        {
                            name: 'Metrics'
                            , exact: true
                            , path: 'error-report/admin/metrics'
                            , moduleAccess: [
                                {
                                    modules: [ BusinessAreaEnum.ErrorReport ]
									, permissionRoles: sec.setMinRole(p.SystemAdmin)
                                }
                            ]
                            , activeNavLink: 'error-report/admin/metrics'
                            , secondaryNav: ['Error Reporting', 'Admin', 'Metrics']
                        }
					]
                }
            ]
            , icon: faBomb
            , area: 'error-report'
            , module: BusinessAreaEnum.ErrorReport
        };
    }

    return { name: "" };
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
