import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { faCheck, faFingerprint } from '@fortawesome/pro-solid-svg-icons';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { FingerprintService } from 'app/core/security/multi-factor-auth/fingerprint.service';

@UntilDestroy()
@Component({
    selector: 'pcg-user-fingerprint-authentication',
    templateUrl: './user-fingerprint-authentication.component.html',
    styleUrls: ['./user-fingerprint-authentication.component.scss'],
    standalone: false
})
export class UserFingerprintAuthenticationComponent implements OnInit {

	@Input() userPin: string;

	isError = false;
    hasFingerprint = false;
	errorString = "";
	fingerprintsRegistered = 0;
	user: UserJsVm;

	faIconName = { faCheck, faFingerprint };

	constructor(
		private httpClient: HttpClient
		, private route: ActivatedRoute
		, private ms: SystemMessageService
		, private fingerprint: FingerprintService
		, private sec: SecurityService
	) {}	

    ngOnInit() { this.getFingerprintInfo(); }

	getFingerprintInfo() {
		this.isError = false;
		this.errorString = "";

		this.user = this.sec.getUser();
		this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
			const id = Number(params.id);
			this.httpClient.get(`api/Administration/Users/IsFingerprintRegistered/${id}`)
				.pipe(untilDestroyed(this))
				.subscribe((sm: SystemMessage) => { 
					if (sm.value === true) { 
						this.hasFingerprint = true;  
						this.fingerprintsRegistered = sm.model
					} else { this.hasFingerprint = false; }
					if (sm.isSuccessful === false) { 
						this.isError = true;
						this.errorString = sm.message;
					}
			});
		});
	}

    captureFingerprint() {
		this.register(Number(this.route.snapshot.paramMap.get('id')));
		this.getFingerprintInfo();
	}

    register(id: number) {
		this.isError = false;
		this.errorString = "";

		if (this.userPin === "") {
			this.ms.setSystemMessage(
				"You must set a PIN before you can register a fingerprint.",
				"error"
			);
			return;
		}

		this.fingerprint.captureFingerprint((response: any) => {
			let biometricXmlval: string;
			try { biometricXmlval = response.Images.Fingerprint[0].Base64Image; } 
			catch (error) {
				this.ms.setSystemMessage(
					'Error communicating with fingerprint scanner. ' + 
					'Please ensure you have the correct drivers installed and try again, or contact an administrator.',
					'error'
				);
				return;
			}		

			this.httpClient.post(
				'api/Administration/Users/RegisterFingerprint/'
				, {
					id
					, biometricXml: biometricXmlval
				}
			).subscribe((msg: SystemMessage) => {
				if (!msg.isSuccessful) {
					this.isError = true;
					this.errorString = msg.message;
				} else {
					this.ms.setSystemMessage(msg.message, msg.messageClass);
					this.hasFingerprint = msg.isSuccessful;
					this.fingerprintsRegistered++;
				}
			});
		}, this.user.biometricFingerprintVersion);
	}

	removeFingerprint() {
		this.isError = false;
		this.errorString = "";

		this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
			const id = Number(params.id);
			this.httpClient.get(`api/Administration/Users/RemoveFingerprint/${id}`)
				.pipe(untilDestroyed(this))
				.subscribe((msg: SystemMessage) => {
					if (msg.isSuccessful) {
						this.ms.setSystemMessage('Fingerprint deletion successful', 'success');
						this.hasFingerprint = false;
						this.fingerprintsRegistered = 0;
					} else { 
						this.isError = true;
						this.errorString = msg.message;
					}
				}
			);
		});
	}	
}
