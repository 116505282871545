<div class='input-group'>
	<input ngbDatepicker #dp="ngbDatepicker" type="text" readonly [disabled]="disabled"
		autoClose="outside" [displayMonths]="2" [dayTemplate]="t" [showWeekNumbers]="false" [markDisabled]="isDisabled"
		[firstDayOfWeek]="7" (click)="toggleDateRangePicker()" (dateSelect)="onDateChange($event, dp)"
		outsideDays="collapsed" [footerTemplate]='footTemplate'
		[ngClass]="{ 'is-placeholder-text' : !dateRange.dateBegin || !dateRange.dateEnd }" 
		[class]="cssClass"
		role="button"  />

	<div class="input-group-append">
		<button placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle" class='preset-button btn btn-outline-secondary'>
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
				width="100px" height="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
				<circle cx="18.132" cy="26.545" r="7.131" />
				<rect x="33.619" y="21.486" width="56.292" height="10.118" />
				<circle cx="18.132" cy="51.219" r="7.131" />
				<rect x="33.619" y="46.159" width="56.292" height="10.118" />
				<circle cx="18.132" cy="76.033" r="7.131" />
				<rect x="33.619" y="70.975" width="56.292" height="10.117" />
			</svg>
		</button>
	</div>
</div>

<!--Day template. Used for changing styles of day numbers in calendar.-->
<ng-template #t let-date="date" let-focused="focused" let-disabled="disabled">
	<span class="custom-day" [class.focused]="focused"
		[class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
		[class.faded]="isHovered(date) || isInside(date)" [class.weekend]="isWeekend(date)" [class.disabled]="disabled"
		(mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
		{{ date.day }}
	</span>
</ng-template>

<!--Footer template. Used for adding buttons to the calendar.-->
<ng-template #footTemplate>
	<div class='w-100 text-center p-1'>
		<button (click)="apply()" class='btn btn-outline-secondary'>Apply</button>
		<button (click)="close()" class='btn btn-outline-secondary ml-1'>Cancel</button>
		<button (click)="clear()" class='btn btn-outline-secondary ml-1'>Clear</button>
	</div>
</ng-template>

<!--Preset Templates-->
<ng-template #popContent>
	<ng-container >
		<ul id="presetList">
			<li *ngFor="let preset of presetRanges"
				(click)="setPreset(preset.dateStart(), preset.dateEnd())" class='p-1'>{{ preset.text }}</li>
		</ul>
	</ng-container>
</ng-template>
<ng-template #popTitle>Presets</ng-template>
