import { Directive, ElementRef, Input } from "@angular/core";
import {
	AbstractControl,
	FormControl,
	NG_VALIDATORS,
	ValidationErrors
} from "@angular/forms";
import { formatDate } from "date-fns";

enum Dates {
	MINIMUM, 
	MAXIMUM
}

/** A directive used to validate a form controls date input
 * whenever using the <input type="date"> element.
 *
 * Parameters
 * minDate : Date
 * maxDate : Date
 */
@Directive({
    selector: "[pcgDate]",
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: DateValidationDirective,
            multi: true,
        },
    ],
    standalone: false
})
export class DateValidationDirective {
	private dateType = "date";
	@Input() minDate: Date;
	@Input() maxDate: Date;

	defaultMinDate: Date = new Date(1900, 0, 1); // Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time)
	defaultMaxDate: Date = new Date(2100, 0, 0); // Dec 31 2099 00:00:00 GMT-0500 (Eastern Standard Time)

	 constructor(private el: ElementRef) {	}

	validate(control: AbstractControl): ValidationErrors | null {
		try {
			if (this.el?.nativeElement?.type == this.dateType) {
				// If neither the [min] or [minDate] attributes are set on the input, set the min date to January 1st, 1900 to prevent date validation errors.
				const min: Date = this.getDate(Dates.MINIMUM);
				// If neither the [max] or [maxDate] attributes are set on the input, set the max date to January 1st, 2100 to prevent date validation errors.
				const max: Date = this.getDate(Dates.MAXIMUM);
				// Set the min/max values to value provided or default.
				this.el.nativeElement.min = this.getFormatted((this.minDate = min));
				this.el.nativeElement.max = this.getFormatted((this.maxDate = max));
				if (!control.hasError('invalidDate')) {
					this.el.nativeElement.value = this.getFormatted(control.value);
				}
			}
		} catch(_) { } 
		finally {
			// When element is visible, make sure that the min/max validation is set.
			if (control instanceof FormControl) {
				const inputDate = new Date(control.value);
				if (
					isNaN(inputDate.getTime()) ||
					inputDate < this.minDate ||
					inputDate > this.maxDate
				) {
					return { invalidDate: true };
				}
			}
	
			return null;
		}
	}

	private getFormatted(value : Date) {
		return formatDate(value, "yyyy-MM-dd");
	}

	private getDate(type : Dates) : Date {
		if (type == Dates.MINIMUM) {
			return this.minDate ?? !this.el.nativeElement.min
					? this.defaultMinDate
					: this.el.nativeElement.min;
		}
		if (type == Dates.MAXIMUM) {
			return this.maxDate ?? !this.el.nativeElement.max
				? this.defaultMaxDate
				: this.el.nativeElement.max;
		}
	}
}
