<button 
	class='btn btn-sm btn-outline-secondary legend-btn'
	type='button' 
	[ngbTooltip]="tooltip"
	#legend="ngbTooltip"
	placement="bottom"
	container="body"
	tooltipClass="legendTooltip"
	triggers="manual"
	(click)="legend.toggle()"
>
	<fa-icon [icon]="faIconName.faPalette"></fa-icon>
	Legend
</button>

<ng-template #tooltip>
	@for(legendVm of legendVms; track legendVm.id) {
		<div class='d-flex align-items-center'>
			<div class='square' [ngStyle]="{'background-color': legendVm.colorCode}"></div>
			<span> = {{legendVm?.description}}</span>
		</div>
	}
  </ng-template>
