import { Component, Input, OnInit } from "@angular/core";
import { faLevelDownAlt } from "@fortawesome/pro-solid-svg-icons";

@Component({
    selector: "pcg-page-title",
    templateUrl: "./page-title.component.html",
    styleUrls: ['./page-title.component.scss'],
    standalone: false
})
export class PageTitleComponent implements OnInit {
	/** 
	 * Used to pass through the page title. 
	 * The title can also be implemented by adding "pageTitle" declaration on an element within the pcg-page-title element, see edit-agency.component.html for an example. 
	 * */
	@Input() primaryTitle: string;
	/** Used to pass through additional title declarations that will be appended following a ":". Ex. "Primary Title: {Secondary Title}" */
	@Input() secondaryTitles: string[];
	/** Defaults to 'window.history.back()' function to go the the last page in history. */
	@Input() backBtnLink: string;
	@Input() backParams: any;
	/**
	 *  Defaults to 'Return to List'. If the 'backBtnLink' property is not provided, this will default to 'Return',
	 *  since the default navigation endpoint cannot be reliably determined.
	 */
	@Input() backBtnTooltip: string = "Return to List";
	@Input() disableBackBtn: boolean;
	@Input() backBtnPlacement = 'auto';

	// Back Button Variables
	faLevelDownAlt = faLevelDownAlt;

	constructor() { }
	ngOnInit() { }
	goBack() { window.history.back(); }
}
