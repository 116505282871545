import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CiDashboardService } from 'app/ci-repack/ci-dashboard/ci-dashboard-service.service';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { NavigateToOrderOrJobVm } from 'app/shared/generated/CIRepack/Models/Dashboard/NavigateToOrderOrJobVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { tap } from 'rxjs';

@Component({
    selector: 'pcg-ci-dashboard-actions',
    templateUrl: './ci-dashboard-actions.component.html',
    styleUrls: ['./ci-dashboard-actions.component.scss'],
    standalone: false
})
export class CiDashboardActionsComponent implements OnInit, AfterViewInit {

    @ViewChild('ciDashboardContent', { static: false }) ciDashboardContent: ElementRef;

    @Input() dashboardActionsClicked: boolean;
    @Output() reload = new EventEmitter();

    isOpen = false;
    repackIdhasErrors: boolean = false;
    jobIdHasErrors: boolean = false;
    lotnumHasErrors: boolean = false;

    repackErrorMsg: string = '';
    jobIdErrorMsg:  string = '';
    lotnumErrorMsg:  string = '';

    timeout: any = null;

    constructor(
		private dashboardService: CiDashboardService,
		public ms: SystemMessageService
	) { }

    @HostListener('window:keyup', ['$event'])
    @HostListener('window:change', ['$event']) onChange() { this.positionFilter(); }
    @HostListener('window:resize', ['$event']) onResize() { this.dashboardActionsClicked = false; }

    ngOnInit() { }

    positionFilter() {
        const rect: DOMRect = document.getElementById('pcg-ci-dashboard-actions')?.getBoundingClientRect();
        if (rect) {
            this.ciDashboardContent.nativeElement.style.top = `${parseInt((rect.bottom + 1).toString())}px`;
            this.ciDashboardContent.nativeElement.style.left = `${parseInt((rect.left).toString())}px`;
        }
    }

    ngAfterViewInit(): void {
        window.addEventListener('click', (el: Event) => {
            var classes = el.target['className']?.toString();
            if (this.dashboardActionsClicked === true) {
                if (classes === 'dashboard-actions-overlay') { this.dashboardActionsClicked = false; }
            }
        });
        setTimeout(() => { this.isOpen = true; }, 300);
    }

    clickedOutside(): void { 
		if (this.isOpen) {
			this.dashboardActionsClicked = false; 
			this.isOpen = false;
			this.reload.emit();
		}
    }

    navToRepackOrder(repackId: any) {
		if (!this.isInputValid(repackId)) {
			this.repackIdhasErrors = true;
			this.repackErrorMsg = "Please enter a valid Order Id"
			return;
		}

		if (isNaN(repackId)) {
			this.dashboardService
			.canNavigateToRepackOrderByString(repackId)
			.pipe(
				tap((vm: NavigateToOrderOrJobVm) => {
					if (!FormatHelperService.GetIsNully(vm.orderId) && !vm.isPfs) {
						window.location.href = "/ci-repack/orders/edit/" + vm.orderId;
					} else {
						this.repackIdhasErrors = true;
						this.repackErrorMsg = "Repack Order Not Found"
					}
				}))
			.subscribe();
		} else {
			this.dashboardService.canNavigateToRepackOrderById(repackId)
			.pipe(
				tap((vm: NavigateToOrderOrJobVm) => {
					if (!FormatHelperService.GetIsNully(vm.orderId) && !vm.isPfs) { 
						window.location.href = "/ci-repack/orders/edit/" + vm.orderId;
					} else {
						this.repackIdhasErrors = true;
						this.repackErrorMsg = "Repack Order Not Found";
					}
				}))
			.subscribe();
		}		
	}

	navToJob(jobId: any) {
		if (!this.isInputValid(jobId)) {
			this.jobIdHasErrors = true;
			this.jobIdErrorMsg = "Please enter a valid Job Id";
			return;
		}

		if (isNaN(jobId)) {
			const isHomer = true;
			this.dashboardService
			.canNavigateToJobByString(jobId, isHomer)
			.pipe(
				tap((vm: NavigateToOrderOrJobVm) => {
					if (!FormatHelperService.GetIsNully(vm.jobId) && !vm.isPfs) {
							window.location.href = "/ci-repack/jobs/edit/" + vm.jobId;
					} else {
						this.jobIdHasErrors = true;
						this.jobIdErrorMsg = "Job Not Found";
					}
				}))
			.subscribe();
		}
	}

	navToLotnum(lotnum: any) {
		if (!this.isInputValid(lotnum)) {
			this.lotnumHasErrors = true;
			this.lotnumErrorMsg = "Please enter a valid DOH Lot #"
			return;
		}

		this.dashboardService
		.canNavigateToJobByDohLotNum(lotnum)
		.pipe(
			tap((vm: NavigateToOrderOrJobVm) => {
				if (vm.jobId !== null && vm.dohLotNum !== null) { window.location.href = "/ci-repack/jobs/edit/" + vm.jobId; } 
				else if (vm.jobId === null && vm.dohLotNum !== null) { window.location.href = "/ci-repack/jobs/list/?lotNumber=" + vm.dohLotNum; }
				else {
					this.lotnumHasErrors = true;
					this.lotnumErrorMsg = "Job Not Found";
				}
			}))
		.subscribe();
	}

	clearErrors(inputType : string) {
		switch(inputType) {
			case 'order':
				this.repackIdhasErrors = false;
				this.repackErrorMsg = '';
				break;
			case 'job':
				this.jobIdHasErrors = false;
				this.jobIdErrorMsg = '';
				break;
			case 'lotnum':
				this.lotnumHasErrors = false;
				this.lotnumErrorMsg = '';
				break;
		}
	}

	isInputValid(input: string) {
		if (input === null || input === '') { return false; }
		return true;
	}

	orderKeyDown(event: { key: string; preventDefault: () => void; target: { value: any; }; }) {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.navToRepackOrder(event.target.value);
		}
	}

	jobKeyDown(event: { key: string; preventDefault: () => void; target: { value: any; }; }) {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.navToJob(event.target.value);
		}
	}

	lotnumKeyDown(event: { key: string; preventDefault: () => void; target: { value: any; }; }) {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.navToLotnum(event.target.value);
		}
	}
}
