import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Subscription } from 'rxjs';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { HomeDeliveryPublicVm } from 'app/shared/generated/Inventory/Models/HomeDeliveryPublicVm';
import { RxToFillVm } from 'app/shared/generated/Inventory/Models/RxToFillVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { environment } from 'environments/environment';

export enum RxInputType {
	Rx1 = 1,
	Rx2 = 2,
	Rx3 = 3,
	Rx4 = 4,
	Rx5 = 5,
	Rx6 = 6
}

@Component({
    selector: 'app-home-delivery',
    templateUrl: './home-delivery.component.html',
    styleUrls: ['./home-delivery.component.scss'],
    standalone: false
})
export class HomeDeliveryComponent implements OnInit, AfterViewInit, OnDestroy  {
	// reCaptcha properties
	@ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent ;
	siteKey: string = environment.siteKey; // should pick up environment specific siteKey

	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public recaptcha: any = null;
	public execute: any;

	formGroup = HomeDeliveryPublicVm.Form;
	subscriptions = new Subscription();
	modalModel: HomeDeliveryPublicVm;
	rxRefillValidation: RxToFillVm[];
	submitSuccessful = false;
	rxInputType = RxInputType;
	honeypotControl;

	rx2IsDupe = false;
	rx3IsDupe = false;
	rx4IsDupe = false;
	rx5IsDupe = false;
	rx6IsDupe = false;

	constructor(private ms: SystemMessageService, private modalService: NgbModal, private formatHelper: FormatHelperService
		, private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		this.setUpHoneyPot();
		this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
		this.setUpAdditionalValidators();
	}

	ngAfterViewInit(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	openHomeDeliverySubmitModal(modal: any, $event: SystemMessage): void {
		this.modalModel = $event.model;
		this.modalService.open(modal, {
			animation: false, beforeDismiss: () => {
				if (this.submitSuccessful) { this.resetForm(); }
				return true;
			}
		});
	}

	// callback after sys message returns on error
	highlightErrors(msg: SystemMessage) {
		// Getting the system message settings and adding white-space: pre to show line breaks
		let minWidth = this.ms.settings.centeredStyles.minWidth;
		let left = this.ms.settings.centeredStyles.left;
		this.ms.settings.centeredStyles = { minWidth, left, 'white-space': 'pre' };
		// Updating for to show validation results.
		this.rxRefillValidation = msg.model.rxRefills;
	}

	//honeyPot initialization logic called on init
	setUpHoneyPot() : void {
		this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
	}

	setUpAdditionalValidators() : void {
		this.formGroup.controls.rxNumber1.addValidators(this.requireRxesToBeDifferent());
		this.formGroup.controls.rxNumber2.addValidators(this.requireRxesToBeDifferent());
		this.formGroup.controls.rxNumber3.addValidators(this.requireRxesToBeDifferent());
		this.formGroup.controls.rxNumber4.addValidators(this.requireRxesToBeDifferent());
		this.formGroup.controls.rxNumber5.addValidators(this.requireRxesToBeDifferent());
		this.formGroup.controls.rxNumber6.addValidators(this.requireRxesToBeDifferent());
	}

	//reset form logic
	resetForm() {		
		this.formGroup = HomeDeliveryPublicVm.Form;
		this.resetValidation();
	}

	resetValidation() {
		this.ms.closeSystemMessage();
		this.rxRefillValidation = null;
	}

	//Sets the html classes for each form rx input
	//rxIndex is which rx it is (1 through 6)
	//rxFillList needs to be passed as a parameter instead of using the property on this class so that it is recalculated when the class property changes
	setControlClassValidation(rxIndex: RxInputType, rxFillList: RxToFillVm[]): string {
		// This Bootstrap style makes all the inputs a little rounded and larger and enables bootstrap validation styles
		let classes = 'form-control';
		// Getting value entered.
		const rx = this.formGroup.value[`rxNumber${rxIndex}`];
		// finding result from rxToFillList with matching rx #
		const rxInput = rxFillList?.filter(function (i) { return Number(rx?.substr(0,8)) === Number(i.rxNumber) ? i : null })[0];

		//The first input is the only one that needs this class
		if (rxIndex === RxInputType.Rx1) { classes += ' float-right'; }

		if(this.isDupeRxNums()) { this.findWhichRxInputIsDupe(); } 
		else { this.resetDupeError(); }

		if (this.formatHelper.GetIsNully(rxFillList)) { return classes; } // If not submitted yet

		//If no value has been entered or the value has changed since it was submitted
		if (this.formatHelper.GetIsNullyOrWhitespace(rx) || this.formatHelper.GetIsNully(rxInput)) { return classes; }
		else if (this.formatHelper.GetIsNullyOrWhitespace(rxInput?.errorMessage)) { classes += ' is-valid'; } // Validation successful
		else { classes += ' is-invalid'; } //Valdiation fails

		return classes;
	}

	findWhichRxInputIsDupe() {
		const rx1 = this.formGroup.value['rxNumber1'];
		const rx2 = this.formGroup.value['rxNumber2'];
		const rx3 = this.formGroup.value['rxNumber3'];
		const rx4 = this.formGroup.value['rxNumber4'];
		const rx5 = this.formGroup.value['rxNumber5'];
		const rx6 = this.formGroup.value['rxNumber6'];
		let rxList = [rx1, rx2, rx3, rx4, rx5, rx6];

		rxList.forEach((el, idx) => {
			if (idx === 0 ) { return; } 
			else if (el === null || el === "") { this.hideDupeError(idx); } 
			else {
				let rxCount = rxList.filter(item => item === el).length;
				if (rxCount > 1) { this.showDupeError(idx); } 
				else { this.hideDupeError(idx); }
			}
		});
	}

	showDupeError(rxIndex) {
		switch(rxIndex) {
			//purposefully skip the first index
			case 1: this.rx2IsDupe = true; return;
			case 2: this.rx3IsDupe = true; return;
			case 3: this.rx4IsDupe = true; return;
			case 4: this.rx5IsDupe = true; return;
			case 5: this.rx6IsDupe = true; return;
		}
	}

	hideDupeError(rxIndex) {
		switch(rxIndex) {
			//purposefully skip the first index
			case 1: this.rx2IsDupe = false; return;
			case 2: this.rx3IsDupe = false; return;
			case 3: this.rx4IsDupe = false; return;
			case 4: this.rx5IsDupe = false; return;
			case 5: this.rx6IsDupe = false; return;
		}
	}

	resetDupeError() {
		this.rx2IsDupe = false;
		this.rx3IsDupe = false;
		this.rx4IsDupe = false;
		this.rx5IsDupe = false;
		this.rx6IsDupe = false;
	}

	isDupeRxNums() : boolean {
		const rx1 = this.formGroup.value['rxNumber1'];
		const rx2 = this.formGroup.value['rxNumber2'];
		const rx3 = this.formGroup.value['rxNumber3'];
		const rx4 = this.formGroup.value['rxNumber4'];
		const rx5 = this.formGroup.value['rxNumber5'];
		const rx6 = this.formGroup.value['rxNumber6'];
		let rxList = [rx1, rx2, rx3, rx4, rx5, rx6];

		rxList = rxList.filter(x => x);

		if (rxList.length === new Set(rxList).size) { return false; } 
		else { return true; }
	}

	// Custom Validator for form honeyPot form control
	requireFalse() : ValidatorFn {
		return (control : AbstractControl) : ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true}; }
			return null;
		};
	}

	requireRxesToBeDifferent() : ValidatorFn {
		return (control : AbstractControl) : ValidationErrors | null => {
			if (control.value === null || control.value === '') { return null; }
			else if(this.isDupeRxNums()) { return { duplicateValues : true }; }
		};
	}

	//captcha callbacks
	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
		this.execute = setInterval(() => { this.captchaElem?.execute(); }, 5000);
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void { 
		clearInterval(this.execute);
	}
}
