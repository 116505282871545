<div class="d-flex row text-center px-2 font-weight-bold align-items-center">
    <p class="col-4">Program</p>
    <p class="col-4">Organization<br>Account</p>
    <p class="col-4">Wholesaler<br>Account</p>
</div>
<div class="border">
    <div *ngFor="let item of data" class="mt-2 mb-2">
        <p-tree
            #tree
            [value]="item" 
            [expandable]="false"
            layout="horizontal"
        > 
        </p-tree>
    </div>
</div>
