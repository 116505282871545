import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[pcgNoSpecialChars]',
    standalone: false
})
export class NoSpecialCharsDirective {

    inputElement: ElementRef;

    @Input('pcgNoSpecialChars') pcgNoSpecialChars: string;
    arabicRegex = '[\u0600-\u06FF]';

    constructor(el: ElementRef) { this.inputElement = el; }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        if (this.pcgNoSpecialChars === 'integer') { this.integerOnly(event); } 
        else if (this.pcgNoSpecialChars === 'noSpecialChars') { this.noSpecialChars(event); }
    }

    integerOnly(event) {
        const e = <KeyboardEvent>event;
        if (e.key === 'Tab' || e.key === 'TAB') { return; }
        if ((e.key === '.' || e.key === 'Backspace' || e.key === 'Escape' || e.key === 'Enter' || e.key == 'n') ||
            // Allow: Ctrl+A
            (e.key === 'A' && e.ctrlKey === true) ||
            // Allow: Ctrl+C
            (e.key === 'C' && e.ctrlKey === true) ||
            // Allow: Ctrl+V
            (e.key === 'V' && e.ctrlKey === true) ||
            // Allow: Ctrl+X
            (e.key === 'X' && e.ctrlKey === true)) {
            // let it happen, don't do anything
            return;
        }
        if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) { e.preventDefault(); }
    }

    noSpecialChars(event) {
        const e = <KeyboardEvent>event;

        if (e.key === 'Tab' || e.key === 'TAB') { return; }
   
        var regex = new RegExp("^[a-zA-Z0-9]+$");
        if (!regex.test(e.key)) {
          e.preventDefault();
          return false;
        }
    }

    @HostListener('paste', ['$event']) onPaste(event) {
        let regex;
        if (this.pcgNoSpecialChars === 'integer') { regex = /[0-9]/g; } 
        else if (this.pcgNoSpecialChars === 'noSpecialChars') { regex = /[\w\u0600-\u06FF]/g; }
        const e = <ClipboardEvent>event;
        const pasteData = e.clipboardData.getData('text/plain');
        let m;
        let matches = 0;
        while ((m = regex.exec(pasteData)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) { regex.lastIndex++; }
            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => { matches++; });
        }
        if (matches === pasteData.length) { return; } 
        else { e.preventDefault(); }
    }
}