import { HttpClient } from "@angular/common/http";
import { Component, computed, inject, OnInit, signal, TemplateRef, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { faUnlock, faEnvelope, faTennisBall, faEnvelopesBulk, faPowerOff } from "@fortawesome/pro-solid-svg-icons";

import { ActivationEnum } from "app/core/enums/generated/ActivationEnum";
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessage, SystemMessageService } from "app/core/system-message/system-message-service";
import { DynamicFormService } from "app/shared/form-elements/components/dynamic-form/dynamic-form.service";
import { EditUserVm } from "app/shared/generated/Administration/Models/Users/EditUserVm";
import { DynamicFormFieldVm } from "app/shared/generated/Models/DynamicFormFieldVm";
import { pcgSettings } from "app/shared/generated/pcg-settings";
import { ActivationService } from "app/shared/services/activation.service";
import { LoginTypeEnum } from 'app/core/enums/generated/LoginTypeEnum';
import { ModuleAccessVm } from "app/shared/generated/Administration/Models/Security/ModuleAccessVm";
import { EditUserService } from "./edit-user.service";
import { UserModulesVm } from "app/shared/generated/Administration/Models/Security/UserModulesVm";
import { debounceTime } from "rxjs";
import { GlobalVariablesService } from "app/services/global-variables.service";
import { NavigationService } from "app/shared/navigation/navigation.service";

@Component({
    selector: "pcg-edit-user",
    templateUrl: "./edit-user.component.html",
    styleUrls: ["./edit-user.component.scss"],
    standalone: false
})
export class EditUserComponent implements OnInit {

	editUserService = inject(EditUserService);
	activatedRoute = inject(ActivatedRoute);
	httpClient = inject(HttpClient);
	router = inject(Router);
	ms = inject(SystemMessageService);
	sec = inject(SecurityService);
	dynForm = inject(DynamicFormService);
	activationService = inject(ActivationService);
	gvs = inject(GlobalVariablesService);

	@ViewChild("pinModal", { static: true }) pinModal: TemplateRef<any>;

	navService = inject(NavigationService);

	formGroup = EditUserVm.Form;
	model = EditUserVm.GetModel(this.formGroup);
	fields: DynamicFormFieldVm[];
	userPerms: ModuleAccessVm[] = [];
	businessArea: BusinessAreaEnum;
	orgLoginTypeFg = signal(0)

	customFieldsCount: number;
	id: number;

	activeTab: string;
	mode: string;
	pin: string;
	strError = "";

	isLockedOut = false;
	hasTabParam = false;
	canEdit = false;
	canEditFacilities = false;
	canCreate = false;
	canViewLoginHistory = false;
	canUnlock = false;
	canResetPassword = false;
	canDeactivate = false;
	canViewSecurity = false;
	canSendWelcomeEmail = false;
	canViewReports = false;
	canSeePasswordButtons = false;
	isCurrentUser = false;
	canManageUsers = false;
	canViewAuditHistory = false;
	isSysAdmin = false;
	inactivityTimeoutFg = false;
	isInternalBphpUserFg = false;
	mustActivateMfaAndInactivity = false;
	isError = false;
	showValidateEmail = false;
	useDifferentEmail = false;
	isSSO = false;	

	envMode = pcgSettings.mode;

	faIconName = { faUnlock, faEnvelope, faTennisBall, faEnvelopesBulk, faPowerOff };

	phoneValidation = { pattern: "Invalid phone number" };

	constructor(title: Title) { title.setTitle("Edit User"); }

	ngOnInit() {
		if (this.activatedRoute.snapshot.queryParamMap.get("businessArea")) {
			this.businessArea = BusinessAreaEnum[ this.activatedRoute.snapshot.queryParamMap.get("businessArea").toString() ];
		}
		this.addSecuritySubscription();
		this.addRouteSubscription();
		this.disableFormControlsBasedOnPermission();
		// Set mustActivateMfaAndInactivity from queryParams
		this.mustActivateMfaAndInactivity = this.activatedRoute.snapshot.queryParams["fromCheckCanDecrypt"];
		// Remove fromCheckCanDecrypt from queryParams
		this.router.navigate(
			[]
			, {
				queryParams: { fromCheckCanDecrypt: null }
				, queryParamsHandling: "merge"
			}
		);
		
		this.editUserService.getModuleAccesses(parseInt(this.activatedRoute.snapshot.params["id"])).pipe(
			debounceTime(300) // Debounce to avoid frequent updates
		).subscribe(
			(r: UserModulesVm) => {
				/*
					User module component also grabs module accesses and sometimes this overwrites it,
					if signal already has a value do not set it here
				*/
				if(!this.editUserService.moduleAccessesSignal()) 
					this.editUserService.moduleAccessesSignal.set(r);
			}
		);
	}

	// Checks if the user being edited AND the logged in user has Rx Connect permissions
	hasRxPermsSignal = computed(() => {
		return this.editUserService.moduleAccessesSignal()?.moduleAccess?.
			find(m => m.module == BusinessAreaEnum.RxConnects)?.
			permissionRole >= PermissionRole.RxConnectsPharmacist && this.canViewRx();
	})

	// Checks if logged in user has Rx Connect permissions
	canViewRx = () => {
		return this.sec.hasModuleAccess(
			[BusinessAreaEnum.RxConnects], 
			SecurityService.setMinRole(PermissionRole.RxConnectsPharmacist)
		)
	}

	// #region Security
	/** Update the local dynamic security object when the security updates */
	addSecuritySubscription() {
		this.isSysAdmin = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.SystemAdmin ]
		);
		this.sec.setControlModuleAccess(
			this.formGroup.controls["isActive"]
			, [ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);
		this.sec.setControlModuleAccess(
			this.formGroup.controls["inventoryIds"]
			, [ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);
		this.canEdit = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.canCreate = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		);
		this.canManageUsers = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Pharmacist)
		);
		this.canViewLoginHistory = this.sec.hasModuleAccess(
			SecurityService.anyArea()
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canUnlock = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) 
		|| this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula 
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canResetPassword = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) 
		|| this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canSendWelcomeEmail = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) || this.sec.hasModuleAccess(
			[
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canViewReports = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.SystemAdmin ]
		) || this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canViewSecurity = this.sec.hasModuleAccess(
			SecurityService.anyArea()
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.canDeactivate = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) || this.sec.hasModuleAccess(
			[
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.canViewAuditHistory = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Manager ]
		) ||
		this.sec.hasModuleAccess(
			[ 
				BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, [
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
	}
	// #endregion

	disableFormControlsBasedOnPermission() {
		if (!this.isSysAdmin) {
			this.formGroup.get("isActive").disable();
			this.formGroup.get("inventoryIds").disable();
		}
		if (this.canDeactivate) { this.formGroup.get("isActive").enable(); }
		if (
			this.businessArea !== null 
			&& this.businessArea !== undefined
		) {
			switch (this.businessArea?.toString()) {
				case "MetabolicFormula":
					this.canEditFacilities = this.sec.hasModuleAccess(
						[ BusinessAreaEnum.MetabolicFormula ]
						, [
							PermissionRole.Manager
							, ...SecurityService.setMinRole(PermissionRole.Manager)
						]
					);
					break;
				case "CI":
					this.canEditFacilities = this.sec.hasModuleAccess(
						[ BusinessAreaEnum.CI ]
						, [
							PermissionRole.Manager
							, ...SecurityService.setMinRole(PermissionRole.Manager)
						]
					);
					break;
				case "Admin":
					this.canEditFacilities = this.sec.hasModuleAccess(
						[ BusinessAreaEnum.Admin ]
						, [
							PermissionRole.Manager
							, ...SecurityService.setMinRole(PermissionRole.Manager)
						]
					);
					break;
				default:
					break;
			}
		} else {
			this.canEditFacilities = this.sec.hasModuleAccess(
				[
					BusinessAreaEnum.Admin
					, BusinessAreaEnum.MetabolicFormula
					, BusinessAreaEnum.CI
				]
				, [
					PermissionRole.Manager
					, ...SecurityService.setMinRole(PermissionRole.Manager)
				]
			);
		}
	}

	/** Subscribe to route to pick up id changes and update page accordingly */
	addRouteSubscription() {		
		this.activatedRoute.paramMap.subscribe((map) => {
			// Allow user to view page if it is either their profile
			// or they have admin access to view other people's profiles
			const user = this.sec.getUser();
			let paramId = map.get("id");
			if (paramId === "currentUser") { window.location.replace(this.router.url.replace(paramId, user.id.toString())); } 
			else { this.id = parseInt(paramId, 10); }
			if (
				(
					this.id !== user.id 
					&& !this.canEdit
				) 
				|| (
					this.id === 0 
					&& !this.canCreate
				)
			) { this.router.navigate(["/"]); }

			if (this.activatedRoute.snapshot.queryParamMap.get("tab")) {
				var newTab = this.activatedRoute.snapshot.queryParamMap.get("tab");
				this.activeTab = newTab;
				this.hasTabParam = true;
			}

			this.isCurrentUser = this.id === this.sec.getUser()?.id;
			this.httpClient.get(`api/Administration/Users/GetUser/${this.id}`)
				.subscribe((vm: EditUserVm) => {
					this
					this.mode = vm.mode;
					this.fields = vm.fields;
					this.formGroup = this.dynForm.updateFormGroupFields(
						this.formGroup
						, vm.fields
					);
					this.customFieldsCount = vm.fields?.length ?? 0;
					this.formGroup.patchValue(vm);
					this.model.isActive = vm.isActive;
					this.canSeePasswordButtons = vm.isActive 
						&& !vm.isLockedOut 
						&& vm?.userId !== this.sec.getUser()?.id 
						&& vm?.userId !== 0;
					this.isLockedOut = vm.isLockedOut;
					this.pin = vm.userPin ?? "";
					this.inactivityTimeoutFg = vm.inactivityTimeout;
					this.isInternalBphpUserFg = vm.isInternalBphpUser;
					this.orgLoginTypeFg.set(vm.orgLoginType);

					if (
						(
							vm.orgLoginType === LoginTypeEnum.SSO
							|| vm.orgLoginType == LoginTypeEnum.Both
						)
						&& !this.isSysAdmin
					) { 
						this.isSSO = true; 
						this.formGroup.controls.email.disable();
					} else { 
						this.isSSO = false; 
						this.formGroup.controls.email.enable();
					}
				}
			);
		});
	}

	/** The main save event for the page */
	saveUser(msg: SystemMessage) {
		if (msg.value) {
			const model = this.formGroup.value;
			this.canSeePasswordButtons = model.isActive 
				&& !model.isLockedOut 
				&& model.userId !== this.sec.getUser()?.id 
				&& model.userId !== 0;
			if (msg.isSuccessful && this.id == 0) {
				this.router.navigateByUrl("/", { skipLocationChange: true })
					.then(() => { this.router.navigate([`/admin/users/user/edit/${msg.model?.userId}`]); });
			}
		}
	}

	/** Unlock user, called when the unlock svg clicked on locked user */
	unlockUser() {
		this.ms.getHttpObservable(this, `api/Administration/Users/UnlockUser/${this.id}`)
			.subscribe(() => { this.isLockedOut = false; });
	}

	/** Called on click of the reset password and welcome email buttons */
	resetPassword(isWelcomeEmail: boolean) {
		this.httpClient.post(
			"api/Account/ForgotPassword"
			, {
				email: this.formGroup.value.email
				, isWelcomeEmail
			}
		).subscribe((msg: SystemMessage) => {
			if (msg.isSuccessful) {
				this.ms.setSystemMessage(`An email has been sent to the user with a ${isWelcomeEmail ? "welcome" : "reset password"} link.`);
			} else {
				this.ms.setSystemMessage("An error occurred when attempting to send the email.", "error");
			}
		});
	}

	activateDeactivate() {
		this.activationService.activateDeactivate(
			this.id
			, ActivationEnum.User
			, this.formGroup.controls.isActive.value ? false : true
		);
	}

	validateEmail() {
		this.showValidateEmail = false;
		this.isError = false;
		this.strError = "";

		this.httpClient.post(
			`api/Administration/Users/ValidateEmail?id=${this.model.userId}`
			, {}
		).subscribe((sm: SystemMessage) => {
			if (sm.isSuccessful) {
				this.model.emailConfirmed = true;
				this.formGroup.controls.emailConfirmed.patchValue(true);
				this.ms.setSystemMessage(sm.message, "success");
			} else {
				this.showValidateEmail = true;
				this.isError = true;
				this.strError = sm.message + "\n"
					+ "Email: " + sm.model?.email + "\n"
					+ "Verdict: " + sm.model?.verdict + "\n"
					+ "Score: " + sm.model?.score + "\n"
					+ "Has Mx or a Record: " + sm.model?.checks?.domain?.has_mx_or_a_record + "\n"
					+ "Has Valid Address Syntax: " + sm.model?.checks?.domain?.has_valid_address_syntax + "\n"
					+ "Is Suspected Disposable Address: " + sm.model?.checks?.domain?.is_suspected_disposable_address + "\n"
					+ "Has Known Bounces: " + sm.model?.checks?.additional?.has_known_bounces + "\n"
					+ "Has Suspected Bounces: " + sm.model?.checks?.additional?.has_suspected_bounces;
			}
		});
	}

	overrideAndValidate() {
		this.showValidateEmail = false;
		this.isError = false;
		this.strError = "";

		this.httpClient.post(
			`api/Administration/Users/ValidateEmail?id=${this.model.userId}&overrideValidate=true`
			, {}
		).subscribe((sm: SystemMessage) => {
			if (sm.isSuccessful) {
				this.model.emailConfirmed = true;
				this.formGroup.controls.emailConfirmed.patchValue(true);
				this.ms.setSystemMessage(sm.message, "success");
			} else {
				this.isError = true;
				this.strError = sm.message;
			}
		});
	}

	bounceEmail() {
		this.isError = false;
		this.strError = "";
		this.httpClient.post(
			`api/Administration/Users/RemoveBounceEmail?id=${this.model.userId}`
			, {}
		).subscribe((sm: SystemMessage) => {
			if (sm.isSuccessful) {
				this.model.isEmailBounced = false;
				this.formGroup.controls.isEmailBounced.patchValue(false);
				this.ms.setSystemMessage(sm.message, "success");
			} else {
				this.isError = true;
				this.strError = sm.message;
			}
		});
	}

	undoSpamReport() {
		this.httpClient.get(`api/Administration/Users/RemoveSpamReport?id=${this.model.userId}`)
			.subscribe((sm: SystemMessage) => {
				if (sm.isSuccessful) {
					this.model.isEmailSpamReported = false;
					this.formGroup.controls.isEmailSpamReported.patchValue(false);
					this.ms.setSystemMessage(sm.message, "success");
				} else {
					this.isError = true;
					this.strError = sm.message;
				}
			}
		);
	}

	emailChange() {
		this.isError = false;
		this.strError = "";
		this.showValidateEmail = false;
		this.useDifferentEmail = false;

		if (this.model.email === "") { return; }

		if (this.id === 0) {
			this.httpClient.get(`api/Administration/Users/GetUserByEmailNewUser?email=${this.model.email}`)
				.subscribe((sm: SystemMessage) => {
					if (!sm.isSuccessful) {
						this.isError = true;
						if (sm.model != null) {
							this.strError = sm.message + "\n"
								+ "Email: " + sm.model?.emailError?.email + "\n"
								+ "Verdict: " + sm.model?.emailError?.verdict + "\n"
								+ "Score: " + sm.model?.emailError?.score + "\n"
								+ "Has Mx or a Record: " + sm.model?.emailError?.checks?.domain?.has_mx_or_a_record + "\n"
								+ "Has Valid Address Syntax: " + sm.model?.emailError?.checks?.domain?.has_valid_address_syntax + "\n"
								+ "Is Suspected Disposable Address: " + sm.model?.emailError?.checks?.domain?.is_suspected_disposable_address + "\n"
								+ "Has Known Bounces: " + sm.model?.emailError?.checks?.additional?.has_known_bounces + "\n"
								+ "Has Suspected Bounces: " + sm.model?.emailError?.checks?.additional?.has_suspected_bounces + "\n\n"
								+ "If this is a valid email address, finish filling out the form and click create to continue.";
						} else { this.strError = sm.message; }
					}
				});
		} else {
			this.httpClient.post(
				`api/Administration/Users/ChangeEmail`
				, {
					id: this.model.userId
					, email: this.model.email
				}
			).subscribe((sm: SystemMessage) => {
				if (!sm.isSuccessful) {
					this.isError = true;
					if (sm.value == false) {
						this.strError = sm.message;	
					} else {
						this.showValidateEmail = true;
						this.strError = sm.message + "\n"
							+ "Email: " + sm.model?.email + "\n"
							+ "Verdict: " + sm.model?.verdict + "\n"
							+ "Score: " + sm.model?.score + "\n"
							+ "Has Mx or a Record: " + sm.model?.checks?.domain?.has_mx_or_a_record + "\n"
							+ "Has Valid Address Syntax: " + sm.model?.checks?.domain?.has_valid_address_syntax + "\n"
							+ "Is Suspected Disposable Address: " + sm.model?.checks?.domain?.is_suspected_disposable_address + "\n"
							+ "Has Known Bounces: " + sm.model?.checks?.additional?.has_known_bounces + "\n"
							+ "Has Suspected Bounces: " + sm.model?.checks?.additional?.has_suspected_bounces;
					}
				} else { this.ms.setSystemMessage(sm.message, "success"); }
			});
		}
	}

	logout() {
		this.gvs.isNavFixed.set(false);
		this.navService.collapseAllSubNavs();
		this.sec.setSecurity(null, null);
		localStorage.removeItem("subNavPosition");
		this.deleteAllCookies();
		this.router.navigate(["/"]);
		sessionStorage.removeItem("notificationModalOpened");
		sessionStorage.removeItem("step1Count");
		sessionStorage.removeItem("step2Count");
		sessionStorage.removeItem("step3Count");
		sessionStorage.removeItem("updateRequestCount");
		sessionStorage.removeItem("ciWorkstationModalOpened");
		localStorage.removeItem("herosAgencyFilters");
		localStorage.removeItem("herosApplicantFilters");

        var loggedInUsingSso = localStorage.getItem("loggedInUsingSso") == "true";
        localStorage.setItem("loggedInUsingSso", "false");
        if (loggedInUsingSso) {
            this.httpClient.get(`api/Account/SingleSignOut?userId=${this.id}&entraIdentifierUrl=${localStorage.getItem("entraIdentifierUrl")}&entraLoginUrl=${localStorage.getItem("entraLoginUrl")}`)
                .subscribe((msg: SystemMessage) => {
                    localStorage.setItem("entraIdentifierUrl", "");
                    localStorage.setItem("entraLoginUrl", "");
                    if (msg.isSuccessful) { window.location.href = msg.value; }
                    else { this.ms.setSystemMessage(msg.message, msg.messageClass); }
                }
            )
        }
	}

	deleteAllCookies() {
		document.cookie.split(";").forEach((cookie) => {
			const name = cookie.split("=")[0].trim();
			document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
		});
	}
}
