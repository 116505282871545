import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, DestroyRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { UiSwitchComponent } from 'ngx-ui-switch';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, skip, takeUntil, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { NotificationService } from 'app/notification.service';
import { UserEmailSettingsVm } from 'app/shared/generated/Administration/Models/Users/UserEmailSettingsVm';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { ValueValidationHelper } from 'app/shared/helpers/value-validation.service';
import { EditUserService } from '../edit-user.service';

@Component({
    selector: 'pcg-user-email-settings',
    templateUrl: './user-email-settings.component.html',
    styleUrls: ['./user-email-settings.component.scss'],
    standalone: false
})
export class UserEmailSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('reconciliationEmails', { static: false }) reconciliationEmails: UiSwitchComponent;
	@ViewChild('reconciliationNotifications', { static: false }) reconciliationNotifications: UiSwitchComponent;
	@ViewChild('reorderLowInventory', { static: false }) reorderLowInventory: UiSwitchComponent;
	@ViewChild('overrideReorderSettings', { static: false }) overrideReorderSettings: UiSwitchComponent;
	@ViewChild('fulfillmentOverdue1', { static: false }) fulfillmentOverdue1: UiSwitchComponent;
	@ViewChild('fulfillmentOverdue2', { static: false }) fulfillmentOverdue2: UiSwitchComponent;
	@ViewChild('inventoryChange', { static: false }) inventoryChange: UiSwitchComponent;
	@ViewChild('metabolicFormulaSubmitted', { static: false }) metabolicFormulaSubmitted: UiSwitchComponent;
	@ViewChild('metabolicFormulaComments', { static: false }) metabolicFormulaComments: UiSwitchComponent;
	@ViewChild('newCiInvoicesAvailable', { static: false }) newCiInvoicesAvailable: UiSwitchComponent;
	@ViewChild('newCiInvoicesAvailableNotifications', { static: false }) newCiInvoicesAvailableNotifications: UiSwitchComponent;
	@ViewChild('newCiOrdersAvailable', { static: false }) newCiOrdersAvailable: UiSwitchComponent;
	@ViewChild('ciOrderCommentEmail', { static: false }) ciOrderCommentEmail: UiSwitchComponent;
	@ViewChild('ciOrderNotSubmitted', { static: false }) ciOrderNotSubmitted: UiSwitchComponent;
	@ViewChild('repackInvoiceEmail', { static: false }) repackInvoiceEmail: UiSwitchComponent;
	@ViewChild('facilityAccountRequests', { static: false }) facilityAccountRequests: UiSwitchComponent;
	
	formGroup = UserEmailSettingsVm.Form;
	subscriptions = new Subscription();
	private unsubscribe = new Subject<void>()
	model$: Observable<UserEmailSettingsVm>;
	user: UserJsVm;
	_subscription: PushSubscription;
		
	httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

	pushNotificationsEnabled: boolean;
	disablePushToggle = false;
	iAmMe: boolean;
	canEdit = false; 
	hasReconsAccess = false; 
	hasReplenishmentAccess = false; 
	hasFulfillmentAccess = false;
	hasMetabolicFormulaAccess = false;
	hasHerosAccess = false;
	hasCiModuleAccess = false;
	hasOrganizationAccountRequestAccess = false;
	hasOrganizationAccountUpdateAccess = false;
	//BA Notify still in development
	hasBudgetAccess = false;
	hasBudgetManagerAccess = false;
	hasBudgetAdminAccess = false;
	/////////////////////////////////
	hasSupportAccess = false;
	hasIcqAccess = false;
	isInternalBphpUser = false;
	isOverdue1: Number;
	isOverdue2: Number;
	isMetabolicFormulaCommentsAutoFocus = false;
	isCiNewInvoicesAutoFocus = false;
	isNewCiOrdersAutoFocus = false;
	isCiOrderCommentsAutoFocus = false;
	isCiOrderNotSubmittedAutoFocus = false;
	isRepackInvoiceEmailAutoFocus = false;
    hasAdminAccessInAnyModule = false;

	elementParam = "";
	inventoryIds = [];
	userId: number;	

	constructor(
		title: Title
		, private route: ActivatedRoute
		, private httpClient: HttpClient
		, public router: Router
		, private sec: SecurityService
		, private viewportScroller: ViewportScroller
		, public ms : SystemMessageService
		, private swPush: SwPush
		, private toast: NotificationService
		, public val: ValueValidationHelper
		, public editUserService: EditUserService
		, public destroyRef: DestroyRef
	) { 
		title.setTitle('User Email Settings'); 
		swPush.subscription.subscribe((subscription) => {
			this._subscription = subscription;
			this.pushNotificationsEnabled = (this._subscription === null) ? false : true;
		});
	}

	ngOnInit() {
        this.getAccessLevels();
        this.getModel();
		if (this.route.snapshot.queryParamMap.get('el')) { this.elementParam = this.route.snapshot.queryParamMap.get('el'); }
		this.autoSave(); // Handles auto save
		this.onUserModuleAccessChange();
	}

	ngAfterViewInit() {
		// set scroller offset if 'el' param present in url
		if (!this.val.isNullUndefinedOrEmpty(this.elementParam))this.viewportScroller.setOffset([0,120]);

		if (this.elementParam === "metabolicFormulaComments") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('metabolicFormula'); }, 500);
		} else if (this.elementParam === "metabolicFormulaSubmitted") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('metabolicFormula'); }, 500);
		} else if (this.elementParam === "ciNewInvoices") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciNewOrders") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciOrderCommentEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciOrderNotSubmitted") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "repackInvoiceEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciBatchControlRecord") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "organizationAccountRequestEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('organization'); }, 500);
		} else if (this.elementParam === "organizationAccountExpirationEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('organization'); }, 500);
		} else if (this.elementParam === "inventoryChangeEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('inventory'); }, 500);
		} else if (this.elementParam === "ciEndOfWeekMetrics") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "reconciliationEmails") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('reconciliation'); }, 500);
		} else if (this.elementParam === "reorderLowInventory") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('replenishment');}, 500)
		} else if (this.elementParam === "overrideReorderSettings") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('replenishment');}, 500);
		} else if (this.elementParam === "herosPendingAgencyEmails") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('heros'); }, 500);
		} else if (this.elementParam === "releaseNotes") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('other'); }, 500);
		} else if (this.elementParam === "icq") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('icq'); }, 500);
		}
	}

	// Prevents need to refresh page after changing user module access
	// to see updated notification settings
	onUserModuleAccessChange() {
		this.editUserService.moduleAccesses$
			.pipe(
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(() => { 
				this.getAccessLevels(); 
			});
	}

    getAccessLevels() {
        this.user = this.sec.getUser();
		this.canEdit = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) 
		|| this.sec?.hasModuleAccess(
			[ 
				BusinessAreaEnum.Admin
				, BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula 
			]
			, [ 
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.hasReconsAccess = this.hasReplenishmentAccess 
			= this.hasFulfillmentAccess  
			= this.sec?.hasModuleAccess(
				[ BusinessAreaEnum.Inventory ]
				, SecurityService.setMinRole(PermissionRole.Technician)
			);
		this.hasMetabolicFormulaAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.MetabolicFormula ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.hasHerosAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.HEROS ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.hasCiModuleAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.CI ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.userId = this.route.snapshot.params['id'];
		this.isInternalBphpUser = this.user?.isInternalBphpUser;
		this.hasOrganizationAccountRequestAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Manager)
		);
		this.hasOrganizationAccountUpdateAccess = this.sec?.hasModuleAccess(
			[ 
				BusinessAreaEnum.Admin
				, BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, SecurityService.setMinRole(PermissionRole.UserAdmin)
		);
		//BA Notify still in development
		this.hasBudgetAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.BA ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.hasBudgetManagerAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.BA ]
			, SecurityService.setMinRole(PermissionRole.Manager)
		);
		this.hasBudgetAdminAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.BA ]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
		);
		/////////////////////////////////
		this.hasSupportAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.HelpDesk ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.hasIcqAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.ICQ ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
        this.getHasAdminAccessInAnyModule();
    }

    getModel() {
        this.model$ = this.httpClient
            .get(`api/Administration/Users/GetUserEmailSettings/${this.userId}`)
            .pipe(tap((model: UserEmailSettingsVm) => {
                    this.formGroup.patchValue(model);
                    this.isOverdue1 = model.fulfillmentOverdue1;
                    this.isOverdue2 = model.fulfillmentOverdue2;
                    this.inventoryIds = model.inventoryIds;
                    this.iAmMe = this.user.id === this.formGroup.controls.userId.value
                })
            );
    }

	autoSave() {
		this.formGroup.valueChanges.pipe(
			// On init, this will skip it's backend hit after it binds the form
			skip(1)
			// Waits 200 ms to ignore additional event bindings that occur
			// when a form control value updates
			, debounceTime(200)
			// Passes in the event into the save function
			, map((formValue) => this.save(formValue))
			// Cleans up the subscription that was created
			, takeUntil(this.unsubscribe)
		).subscribe();
	}

	save(formValue: any) {
		this.httpClient.post(`api/Administration/Users/SaveUserEmailSettings`, formValue)
			.subscribe((msg: SystemMessage) => { this.ms.setSystemMessage(msg.message, msg.messageClass); });
		if (
			this.iAmMe 
			&& this._subscription === null 
			&& (
				Object.values(formValue).includes(2) 
				|| Object.values(formValue).includes(3)
			)
		) { this.subscribe(); }
	}

	selectForAll(param: any) {
		const controls = [
			{ access: this.hasFulfillmentAccess, controls: ['fulfillmentOverdue1', 'fulfillmentOverdue2'] },
			{ access: this.hasHerosAccess, controls: ['herosPendingAgencyEmails'] },
			{ access: this.hasMetabolicFormulaAccess, controls: ['metabolicFormulaSubmitted', 'metabolicFormulaComments'] },
			{ access: this.hasReconsAccess, controls: ['reconciliationEmails'] },
			{ access: this.hasReplenishmentAccess, controls: ['reorderLowInventory', 'overrideReorderSettings'] },
			{ access: this.hasCiModuleAccess, controls: ['newCiInvoicesAvailable', 'newCiOrdersAvailable', 'ciOrderCommentEmail', 'ciOrderNotSubmitted', 'ciBatchControlRecord', 'ciEndOfWeekMetrics'] },
			{ access: this.isInternalBphpUser, controls: ['repackInvoiceEmail'] },
			{ access: this.hasOrganizationAccountRequestAccess, controls: ['organizationAccountRequestEmail', 'userEmailBounced'] },
			{ access: this.hasOrganizationAccountUpdateAccess, controls: ['organizationAccountExpirationEmail'] },
			{ access: this.hasSupportAccess, controls: ['ticketUpdate', 'ticketCreated', 'ticketStatusChange', 'ticketCommentAdded', 'ticketAssigned'] },
			{ access: this.hasIcqAccess, controls: ['newSurveyAvailable', 'surveyCompletionReminder', 'overdueSurveyReminder', 'scheduleSiteVisitReminder', 'postSiteVisitReminder'] },
			{ access: this.hasAdminAccessInAnyModule, controls: ['newErrorReportSubmitted'] },
			{ access: true, controls: ['inventoryChange', 'releaseNotes'] },
			//BA Notify still in development
			{ access: this.hasBudgetAccess, controls: ['budgetAllocation'] },
			{ access: this.hasBudgetManagerAccess, controls: ['budgetAllocationManager'] },
			{ access: this.hasBudgetAdminAccess, controls: ['budgetAllocationAdmin'] },
			/////////////////////////////////
		];

		controls.forEach(group => {
			if (group.access || param === 0) {
				group.controls.forEach(control => {
					this.formGroup.get(control).patchValue(param);
					this.formGroup.value[control] = param;
				});
			}
		});

		this.overdue1Change();
		this.overdue2Change();
	}

	selectPushForAll() {
		const controls = [
			{ access: this.hasFulfillmentAccess, controls: ['fulfillmentOverdue1', 'fulfillmentOverdue2'], value: 0 },
			{ access: this.hasHerosAccess, controls: ['herosPendingAgencyEmails'], value: 0 },
			{ access: this.hasMetabolicFormulaAccess, controls: ['metabolicFormulaSubmitted', 'metabolicFormulaComments'], value: 0 },
			{ access: this.hasReconsAccess, controls: ['reconciliationEmails'], value: 0 },
			{ access: this.hasReplenishmentAccess, controls: ['reorderLowInventory', 'overrideReorderSettings'], value: 0 },
			{ access: this.hasCiModuleAccess, controls: ['newCiInvoicesAvailable', 'newCiOrdersAvailable', 'ciOrderCommentEmail', 'ciOrderNotSubmitted', 'ciBatchControlRecord', 'ciEndOfWeekMetrics'], value: 0 },
			{ access: this.isInternalBphpUser, controls: ['repackInvoiceEmail'], value: 0 },
			{ access: this.hasOrganizationAccountRequestAccess, controls: ['organizationAccountRequestEmail', 'userEmailBounced'], value: 0 },
			{ access: this.hasOrganizationAccountUpdateAccess, controls: ['organizationAccountExpirationEmail'], value: 0 },
			{ access: this.hasSupportAccess, controls: ['ticketUpdate', 'ticketCreated', 'ticketStatusChange', 'ticketCommentAdded', 'ticketAssigned'], value: 2 },
			{ access: this.hasIcqAccess, controls: ['newSurveyAvailable', 'surveyCompletionReminder', 'overdueSurveyReminder', 'scheduleSiteVisitReminder', 'postSiteVisitReminder'], value: 2 },
			{ access: this.hasAdminAccessInAnyModule, controls: ['newErrorReportSubmitted'], value: 2 },
			{ access: true, controls: ['inventoryChange', 'releaseNotes'], value: 0 },
			//BA Notify still in development
			{ access: this.hasBudgetAccess, controls: ['budgetAllocation'], value: 2 },
			{ access: this.hasBudgetManagerAccess, controls: ['budgetAllocationManager'], value: 2 },
			{ access: this.hasBudgetAdminAccess, controls: ['budgetAllocationAdmin'], value: 2 },
			/////////////////////////////////
		];

		this.applyToControls(controls);

		this.overdue1Change();
		this.overdue2Change();
	}

	selectBothForAll() {
		const controls = [
			{ access: this.hasFulfillmentAccess, controls: ['fulfillmentOverdue1', 'fulfillmentOverdue2'], value: 1 },
			{ access: this.hasHerosAccess, controls: ['herosPendingAgencyEmails'], value: 1 },
			{ access: this.hasMetabolicFormulaAccess, controls: ['metabolicFormulaSubmitted', 'metabolicFormulaComments'], value: 1 },
			{ access: this.hasReconsAccess, controls: ['reconciliationEmails'], value: 1 },
			{ access: this.hasReplenishmentAccess, controls: ['reorderLowInventory', 'overrideReorderSettings'], value: 1 },
			{ access: this.hasCiModuleAccess, controls: ['newCiInvoicesAvailable', 'newCiOrdersAvailable', 'ciOrderCommentEmail', 'ciOrderNotSubmitted', 'ciBatchControlRecord', 'ciEndOfWeekMetrics'], value: 1 },
			{ access: this.isInternalBphpUser, controls: ['repackInvoiceEmail'], value: 1 },
			{ access: this.hasOrganizationAccountRequestAccess, controls: ['organizationAccountRequestEmail', 'userEmailBounced'], value: 1 },
			{ access: this.hasOrganizationAccountUpdateAccess, controls: ['organizationAccountExpirationEmail'], value: 1 },
			{ access: this.hasSupportAccess, controls: ['ticketCreated', 'ticketStatusChange', 'ticketCommentAdded', 'ticketAssigned'], value: 3 },
			{ access: this.hasSupportAccess, controls: ['ticketUpdate'], value: 1 },
			{ access: this.hasIcqAccess, controls: ['newSurveyAvailable', 'surveyCompletionReminder', 'overdueSurveyReminder', 'scheduleSiteVisitReminder', 'postSiteVisitReminder'], value: 3 },
			{ access: this.hasAdminAccessInAnyModule, controls: ['newErrorReportSubmitted'], value: 3 },
			{ access: true, controls: ['inventoryChange', 'releaseNotes'], value: 1 },
			//BA Notify still in development
			{ access: this.hasBudgetAccess, controls: ['budgetAllocation'], value: 3 },
			{ access: this.hasBudgetManagerAccess, controls: ['budgetAllocationManager'], value: 3 },
			{ access: this.hasBudgetAdminAccess, controls: ['budgetAllocationAdmin'], value: 3 },
			/////////////////////////////////
		];

		this.applyToControls(controls);

		this.overdue1Change();
		this.overdue2Change();
	}

	applyToControls(controls: any){
		controls.forEach(group => {
			if (group.access) {
				group.controls.forEach(control => {
					this.formGroup.get(control).patchValue(group.value);
					this.formGroup.value[control] = group.value;
				});
			}
		});
	}

	overdue1Change() {
		if (this.formGroup.value.fulfillmentOverdue1 !== 0) {
			this.isOverdue1 = 1;
			this.formGroup.get('fulfillmentOverdueFirstInventoryIds').patchValue(this.inventoryIds);
		} else {
			this.isOverdue1 = 0;
			this.formGroup.get('fulfillmentOverdueFirstInventoryIds').patchValue(null);
		}
	}

	overdue2Change() {
		if (this.formGroup.value.fulfillmentOverdue2 !== 0) {
			this.isOverdue2 = 1;
			this.formGroup.get('fulfillmentOverdueSecondInventoryIds').patchValue(this.inventoryIds);
		} else {
			this.isOverdue2 = 0;
			this.formGroup.get('fulfillmentOverdueSecondInventoryIds').patchValue(null);
		}
	}

	operatePushNotification() {
        this._subscription == null
			? this.subscribe() 
			: this.unsubscribePush(this._subscription.endpoint);
    }

    subscribe() {
        // Retrieve public VAPID key from the server
        this.httpClient.get('api/PushSubscriptions/GetPublicKey', { responseType: 'text' }).subscribe(publicKey => {
            // Request subscription with the service worker
            this.swPush.requestSubscription({
                serverPublicKey: publicKey
            }).then(subscription => this.httpClient.post('api/PushSubscriptions/AddSubscription', subscription, this.httpOptions).subscribe(
				() => { },
				error => console.error(error)
			)).catch((error) => {
				console.error(error);
				this.toast.showWarning("Check your browser settings. You may have push notifications disabled for this site (if on iOS, select the 'add to home screen' option");
				
				setTimeout(() => {
					document.getElementById("pushToggle").classList.remove("mat-mdc-slide-toggle-checked");
					document.getElementById("pushToggle").classList.remove("cdk-focused");
					document.getElementById("pushToggle").classList.remove("cdk-mouse-focused");
					document.getElementById("pushToggle-button").classList.remove("mdc-switch--selected");
					this.disablePushToggle = true;
				}, 500)
			});
        },
		error => console.error(error));
    }

    unsubscribePush(endpoint) {
        this.swPush.unsubscribe()
            .then(() => this.httpClient.get(
				`api/PushSubscriptions/DeleteSubscription
				?userId=${this.sec?.getUser().id}
				&endpoint=${encodeURIComponent(endpoint)}`)
			.subscribe(() => { },
                error => console.error(error)
            )).catch(error => console.error(error));
    }

	ngOnDestroy() { 
		this.unsubscribe.next();
		this.subscriptions.unsubscribe(); 
	}

    getHasAdminAccessInAnyModule() {
        var adminAccessModules = this.user?.moduleAccess?.filter(o => o.permissionRole > PermissionRole.Pharmacist);
        this.hasAdminAccessInAnyModule = adminAccessModules?.length > 0;
    }
}
