<div class='modal-header'>
	<h5 
		class='modal-title' 
		id='modal-basic-title'
	>{{ modalTitle }}&nbsp;</h5>
	<h5 
		class='modal-title' 
		id='modal-basic-title'
	>Roles</h5>
	<button 
		class='close' 
		aria-label='Close' 
		(click)='activeModal.dismiss()'
	><span aria-hidden='true'>&times;</span></button>
</div>

<div class='d-flex flex-column px-5 m-auto'>
	<div 
		[innerHTML]='optionalText' 
		class='mt-2 trix-content'
	></div>

	<!-- Admin -->
	<pcg-role-info [module]='module'></pcg-role-info>

	<div class='mx-auto p-3'>
		<button 
			type='button' 
			class='btn btn-cancel btn-lg' 
			(click)='activeModal.dismiss()'
		>Cancel</button>
	</div>
</div>