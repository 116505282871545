import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectItem } from '@pcg/pcg-shared/models/select-item';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { DynamicFormService } from 'app/shared/form-elements/components/dynamic-form/dynamic-form.service';
import { OrganizationUsersVm } from 'app/shared/generated/Administration/Models/Organizations/OrganizationUsersVm';
import { EditUserVm } from 'app/shared/generated/Administration/Models/Users/EditUserVm';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-associated-users',
    templateUrl: './associated-users.component.html',
    styleUrls: ['./associated-users.component.scss'],
    standalone: false
})
export class AssociatedUsersComponent implements OnInit {

    @ViewChild(TableComponent, { static: false }) table: TableComponent<OrganizationUsersVm>;
    @ViewChild('modalExistingUsersModal', { static: true }) modalContent: TemplateRef<any>;

    @Input() businessArea: BusinessAreaEnum;
    @Input() orgId : number;
    @Input() facilityIds: number[] = [];

    formGroup = EditUserVm.Form;
	model = EditUserVm.GetModel(this.formGroup);
    allOrganizations: string[] = [];
	associatedOrganizations: string[] = [];
	organizations: any[];

    canCreateUser = false;
	canDeleteUser = false;
	canEditUser = false;
    canAddUser = false;
    canEditOrganizations = false; 
    isCreate = false;
    userId = 0;
    organizationId: number;
    newUser = "";

    columns = OrganizationUsersVm.ColumnDefs;
    params = {
        organizationId : 0
        , businessArea: null
    };

    constructor(
        private activatedRoute: ActivatedRoute
        , public modalService: NgbModal
        , private security: SecurityService
        , private httpClient: HttpClient
        , private ms: SystemMessageService
        , private dynForm: DynamicFormService
    ) {
        this.canCreateUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.Pharmacist ]
        ) 
        || this.security.hasModuleAccess(
            [ 
                BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [
                PermissionRole.UserAdmin
                , ...SecurityService.setMinRole(PermissionRole.Manager)
            ]
        );
        this.canDeleteUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.Pharmacist ]
        ) 
        || this.security.hasModuleAccess(
            [
                BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [
                PermissionRole.UserAdmin
                , ...SecurityService.setMinRole(PermissionRole.Manager)
            ]
        );
        this.canEditUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.Pharmacist ]
        ) 
        || this.security.hasModuleAccess(
            [ 
                BusinessAreaEnum.CI
                , BusinessAreaEnum.MetabolicFormula
            ]
            , [
                PermissionRole.UserAdmin
                , ...SecurityService.setMinRole(PermissionRole.Manager)
            ]
        );
        this.canAddUser = this.security.hasModuleAccess(
            [ BusinessAreaEnum.Admin ]
            , [ PermissionRole.SystemAdmin ]
        );
    }

    ngOnInit(): void {
        this.setSecurity();
        this.params.organizationId = this.activatedRoute.snapshot.params['id'];
        this.newUser = "/admin/users/user/edit/0?organizationId=" + this.params.organizationId;
        if (
            this.businessArea !== null 
            && this.businessArea !== undefined
        ) {
            this.params.businessArea = this.businessArea; 
            this.newUser += "&businessArea=" + this.businessArea;
        }
    }
  
    reloadTable() { this.table.ajaxReload(); }

    setSecurity() {
        if (
            this.businessArea !== null 
            && this.businessArea !== undefined
        ) {
            switch (this.businessArea) {
                case BusinessAreaEnum.MetabolicFormula:
                    this.canEditOrganizations = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.MetabolicFormula ]
                        , [ 
                            PermissionRole.Manager
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canEditUser = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.MetabolicFormula ]
                        , [
                            PermissionRole.UserAdmin
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canCreateUser = this.canEditUser;
                    break;
                case BusinessAreaEnum.CI:
                    this.canEditOrganizations = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.CI ]
                        , [
                            PermissionRole.Manager
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canEditUser = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.CI ]
                        , [
                            PermissionRole.UserAdmin
                                , ...SecurityService.setMinRole(PermissionRole.Manager)
                            ]
                    );
                    this.canCreateUser = this.canEditUser;
                    break;
                case BusinessAreaEnum.Admin:
                    this.canEditOrganizations = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.Admin ]
                        , [ 
                            PermissionRole.Manager
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    );
                    this.canEditUser = this.security.hasModuleAccess(
                        [ BusinessAreaEnum.Admin ]
                        , [
                            ...SecurityService.setMinRole(PermissionRole.Pharmacist)
                        ]
                    );
                    this.canCreateUser = this.canEditUser;
                    break;
                default: 
                    break;
            }
        } else {
            this.canEditOrganizations = this.security.hasModuleAccess(
                [
                    BusinessAreaEnum.Admin
                    , BusinessAreaEnum.CI
                    , BusinessAreaEnum.MetabolicFormula
                ]
                , [
                    PermissionRole.Manager
                    , ...SecurityService.setMinRole(PermissionRole.Manager)
                ]
            );
            this.canEditUser = this.security.hasModuleAccess(
                [
                    BusinessAreaEnum.Admin
                    , BusinessAreaEnum.CI
                    , BusinessAreaEnum.MetabolicFormula
                ]
                , [
                    PermissionRole.UserAdmin
                    , ...SecurityService.setMinRole(PermissionRole.Manager)
                ]
            );
		    this.canCreateUser = this.canEditUser;
        }
    }

    getEditUserLink(id: number) { return '/admin/users/user/edit/' + id; }
    addUserBtn() { this.modalService.open(this.modalContent, { animation: false, beforeDismiss: () => { return true; } }); }

    addExistingUser(userId: number, table: TableComponent<OrganizationUsersVm>) {
        this.httpClient.post(
            `api/administration/UserOrganization/AddExistingUser?userId=${userId}&organizationId=${this.params.organizationId}`
            , null
        ).subscribe((msg: SystemMessage) => { 
            if (msg.isSuccessful) { 
                table.ajaxReload(); 
                this.reloadTable();
            } 
        });            
    }

    createUser() {
        this.organizationId = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
        this.setSecurity();

        this.httpClient.get(`api/Administration/Users/GetUser/0`)
            .subscribe((vm: EditUserVm) => {
                this.formGroup = this.dynForm.updateFormGroupFields(this.formGroup, vm.fields);
                this.formGroup.patchValue(vm);
                this.populateOrganizations();
                
                this.formGroup.controls["isActive"].setValue(vm.isActive);
                this.formGroup.controls["inactivityTimeout"].setValue(false);
                this.formGroup.controls["isInternalBphpUser"].setValue(false);
                this.formGroup.controls["updateOrgs"].setValue(true);
                this.formGroup.controls["updateFacilities"].setValue(true);
                if (this.businessArea !== null && this.businessArea !== undefined) {
                    this.formGroup.controls["businessArea"].setValue(this.businessArea);
                }
                this.formGroup.controls["organizationIds"].setValue([this.organizationId]);
                this.formGroup.controls["facilityIds"].setValue(this.facilityIds);            
            });

        this.isCreate = true;         
    }

    populateOrganizations() {
        var userId = null;
        this.httpClient.get(`api/Select/Organizations?userId=${userId}`).subscribe((items: SelectItem[]) => {
            this.organizations = items;
            this.allOrganizations = items.reduce((accum, current) => {
                accum.push(current.text);
                return accum;
            }, []);
            if (this.formGroup.value.organizations.length > 0) {
                this.associatedOrganizations = this.formGroup.value.organizations.filter((i: string) => items.find(o => o.text === i));
            } else {
                this.associatedOrganizations = this.allOrganizations;
            }
        });
    }

    handleOrganizations() {
        this.formGroup.controls.organization.setValue(this.associatedOrganizations);
        this.formGroup.controls.organizationIds.setValue(
            this.organizations.reduce((accum, current) => {
                if (this.associatedOrganizations?.includes(current.text)) { accum.push(current.value); }
                return accum;
            }, [])
        );
    }

    saveUser(msg: SystemMessage) {
        msg.message= msg.message + ` \nPlease set the user's module access to associate the user to the organization's facilities.`;
        msg.link = `<a href='admin/users/user/edit/${this.formGroup.value.userId}?tab=Module%20Access'>Click Here</a>`
        this.ms.setSystemMessage(msg.message, 'warning', 60000, msg.link);
        
        if (msg.value) {
            this.isCreate = false;
            this.reloadTable();
        }
    }

    cancelAdd() { this.isCreate = false; }
}
