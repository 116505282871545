<div class='w-100 mb-1 table-top-container clearfix no-print'>
	
	<div class="top-left-container form-inline">
		<!-- Non-mobile button container -->
		<div class="btn-container hide-on-print d-flex align-items-start">
			<ng-content select="[table-actions]" class='table-actions mr-2'></ng-content>

			<!-- Show/Hide button -->
			<pcg-button-hide-show 
				*ngIf="canShowHideColumns"
				class="hidden-sm mr-2" 
				[columnDefs]="columnDefs"
			></pcg-button-hide-show>

			<!-- Color Legend -->
			@if(legend?.length > 0) {
				<pcg-table-top-legend 
					class="hidden-sm mr-2"
					[legendVms]="legend"
				></pcg-table-top-legend>
			} 

			<!-- Apply Filters button -->
			<pcg-button-filter 
				*ngIf='filterId?.length' 
				id="pcg-button-filter" 
				class="mr-2 hidden-sm" 
				[filterId]="filterId"
			></pcg-button-filter>

			<!-- Reset Filters button -->
			<pcg-button-reset-filter 
				*ngIf='showResetFilterBtn()' 
				class="mr-2 hidden-sm"
			></pcg-button-reset-filter>
		</div>
		
		<!-- Mobile button container -->
		<div class="btn-container hidden-md-plus d-flex align-items-start mb-2">
			<ng-content select="[table-actions]" class='table-actions mr-2'></ng-content>

			<pcg-button-hide-show 
				*ngIf="canShowHideColumns"
				class="mr-2" 
				[columnDefs]="columnDefs" 				
			></pcg-button-hide-show>

			<!-- Color Legend -->
			@if(legend?.length > 0) {
				<pcg-table-top-legend 
					class="mr-2"
					[legendVms]="legend"
				></pcg-table-top-legend>
			} 

			<pcg-button-filter 
				*ngIf='filterId' 
				id="pcg-button-filter" 
				[filterId]="filterId" 
				class="mr-2"
			></pcg-button-filter>

			<pcg-button-reset-filter 
				*ngIf='showResetFilterBtn()' 
				class="mr-2"
			></pcg-button-reset-filter>
		</div>

		<!-- Applied Filters -->
		<div
			*ngIf="
				filterMap 
				&& btnFilterService.getActiveFilters(ajaxData, filterMap, emptyFilters, useEmptyFilters)
			"
			class="applied-filters" 
		>
			<div 
				class="applied-filters top-left-container form-inline alert-success d-flex flex-row align-items-start form-control-sm" 
				role="alert"
			>Applied Filters: {{ btnFilterService.getActiveFilters(ajaxData, filterMap, emptyFilters, useEmptyFilters) }}</div>
		</div>

		<!-- Table-Alert -->
		<div
			*ngIf="
				alert
			"
			class="table-alert" 
		>
			<div 
				class="table-alert top-left-container form-inline alert-warning d-flex flex-row align-items-start form-control-sm" 
				role="alert"
				(click)="tableTopAlertService.onClick()"
			>
			<fa-icon [icon]='faIconName.faTriangleExclamation' class='mr-1'></fa-icon>
			<span class="border-bottom-0" [ngbTooltip]="alert.tooltip">{{alert.message}}</span>
			</div>
		</div>
	</div>

	<div 
		*ngIf="canGlobalSearch" 
		class='search-container form-inline no-print'
	>
		<div 
			*ngIf="canExactMatchSearch" 
			class='exact-match-container'
		>
			<div class='form-check form-check-inline exactMatch'>
				<label 
					[for]='"exactMatch" + uniqueId' 
					class='form-check-label mr-2'
				>Exact Match</label>
				<ui-switch 
					#exactMatch 
					[id]='"exactMatch" + uniqueId'
					(click)='exactMatchSource.next(exactMatch.checked)'
				></ui-switch>
			</div>
		</div>

		<div class='order-first order-md-last'>
			<div class='hidden-md-plus'>
				<label class='for'>Search:</label>
			</div>
			<input 
				type='search' 
				class='form-control form-control-sm' 
				placeholder="Search..." 
				(input)='filterSource.next($event.target.value)'
			/>
		</div>
	</div>

	<div class="inline-page-actions">
		<div id="pageActions">
			<!-- Tiles/List toggle -->
			<mat-button-toggle-group
				*ngIf="
					!isMobile 
					&& showTilesBtn
				" 
				class="vial-unit-toggle" 
			>
				<mat-button-toggle (click)="tableViewClick(true)">
					<fa-icon 
						[icon]="faIconName.faTableCellsLarge" 
						class="table-action-icon"							
					></fa-icon>
				</mat-button-toggle>

				<mat-button-toggle (click)="tableViewClick(false)">
					<fa-icon 
						[icon]="faIconName.faTableList" 
						class="table-action-icon"
					></fa-icon>
				</mat-button-toggle>
			</mat-button-toggle-group>

			<!-- Column tooltips button -->
			<button 
				*ngIf="
					hasTooltips 
					&& (
						isMobile 
						|| showTooltipsBtn
					)
				"
				(click)="tooltipButtonClicked()" 
				class="new-page-action table-action-tooltip-btn" 					
			>
				<fa-icon 
					[icon]="faIconName.faQuestion" 
					class="table-action-tooltip-icon"
				></fa-icon>
			</button>

			<button 
				*ngIf="
					canExportTableToExcel 
					|| excelUrl
				" 
				[ngbTooltip]="excelTooltip" 
				(click)="getExcel()" 
				class="new-page-action"
			><img src="assets/images/actions/xlsxgreen.svg" /></button>

			<button 
				*ngIf="
					canPrintTable 
					|| printUrl
				" 
				[ngbTooltip]="printTooltip" 
				(click)="print()" 
				class="new-page-action"
			><img src="assets/images/actions/printBlue.svg" /></button>

			<button 
				*ngIf="
					canExportTableToPdf 
					|| pdfUrl
				" 
				[ngbTooltip]="pdfTooltip" 
				(click)="getPdf()" 
				class="new-page-action"
			><img src="assets/images/actions/PDF.svg" /></button>
		</div>
	</div>
	
	<!-- Column tooltip modal -->
	<ng-template 
		#tooltipModal 
		let-modal
	>
		<div class="modal-header">
			<h5>Column Tooltips</h5>
			<button 
				type="button" 
				class="close" 
				aria-label="Close" 
				(click)="modal.dismiss()"
			><span aria-hidden="true">&times;</span></button>
		</div>

		<div class="modal-body flex-body">
			<pcg-table-top-tooltip [innerHTML]="tooltipContent"></pcg-table-top-tooltip>
		</div>
	</ng-template>
</div>