import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'pcg-ellipsis',
    templateUrl: './ellipsis.component.html',
    styleUrls: ['./ellipsis.component.scss'],
    standalone: false
})
export class EllipsisComponent implements OnInit {

	@Input() size = 50;
	@Input() data: any;
	@Input() rowExpanded: boolean;

	lessData: string;
	hasClicked = false;

	constructor() { }

	ngOnInit() {
		if (this.data) { this.lessData = this.data.substr(0, this.size); }
		this.hasClicked = !this.data || this.lessData.length === this.data.length;
	}
}
