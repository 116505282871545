import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild, signal} from '@angular/core';
import { faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { InventoryTypeEnum } from 'app/core/enums/generated/InventoryTypeEnum';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { FacilityInventoryService } from 'app/shared/facilities/facility-inventories/services/facility-inventory.service';
import { EditOrganizationFacilityVm } from 'app/shared/generated/Administration/Models/Organizations/EditOrganizationFacilityVm';
import { SysInvInventoryVm } from 'app/shared/generated/SysInv/Models/SysInvInventoryVm';
import { SelectItem } from 'app/shared/models/select-item';
import { UserStateService } from 'app/state/user/user-state.service';

@Component({
    selector: 'pcg-org-facility-inventories',
    templateUrl: './org-facility-inventories.component.html',
    styleUrls: ['./org-facility-inventories.component.scss'],
    standalone: false
})
export class OrgFacilityInventoriesComponent implements OnInit {
  
    @Input() facilityId = 0;
    @Input() organizationId = 0;

    @ViewChild("modalProgram") modalProgram: TemplateRef<any>;

    faIcon = { faPlus, faXmark };

  showEditInventory = false;
  showInactiveInventories = false;
  inventoryList: SysInvInventoryVm[] = [];
  activeInventoryList: SysInvInventoryVm[] = [];
  inactiveInventoryList: SysInvInventoryVm[] = [];
  inv : SysInvInventoryVm;
  fg = SysInvInventoryVm.Form;
  model$: Observable<SysInvInventoryVm[]>;
  types: SelectItem[];
    allPrograms: boolean = false;
    orgFacility: EditOrganizationFacilityVm;

  constructor(
    private http: HttpClient, 
    private sm: SystemMessageService, 
    public fi: FacilityInventoryService, 
    private userState: UserStateService,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getOrgFacility();
    this.setSelectItemTypes();
    this.getInventoryList();
  }

  getOrgFacility() {
    this.http.get(`api/Administration/Organization/GetOrganizationFacility/?id=${this.facilityId}&organizationId=${this.organizationId}`)
			.subscribe((vm: EditOrganizationFacilityVm) => {
        this.orgFacility = vm;
      });
  }

  getInventoryList() {
    this.http.get(`api/Facility/Inventory/GetInventoryList?facilityId=${this.facilityId}`)
      .subscribe((list: SysInvInventoryVm[]) => {
        this.updateInventoryLists(list);
      });
  }

  editInventory(id: number) {
    this.showEditInventory = !this.showEditInventory;
    if (id == 0) {
      let newModel = new SysInvInventoryVm();
      newModel.sysInvInventoryId = 0;
      newModel.facilityId = this.facilityId;
      newModel.organizationId = this.organizationId;
      this.fg.patchValue(newModel)
    } else {
      let model = this.inventoryList.find(o => o.sysInvInventoryId == id);
      this.fg.patchValue(model)
    }
    if(this.orgFacility.facilityTypeId != null) this.fg.get('programIds')?.disable();
  }

    parseEnum = (val: number) => InventoryTypeEnum.toDisplay(val);

    updateInventoryLists(invList: SysInvInventoryVm[]){
        this.inventoryList = invList;
        this.activeInventoryList = this.inventoryList.filter(o => o.active === true);
        this.inactiveInventoryList = this.inventoryList.filter(o => o.active === false);
        if (this.inactiveInventoryList.length == 0) this.showInactiveInventories = false; 
    }


  // Form methods
  handleSave(sm: SystemMessage) {
    this.fg.patchValue(sm.model);
    this.updateInventoryLists(sm.value);    
    this.showEditInventory = !this.showEditInventory;
    this.userState.updateUser(UserStateService.getCurrentUser());
  }

    setSelectItemTypes() {
        this.types = Object.values(InventoryTypeEnum)
            .filter(value => !isNaN(Number(value)))
            .filter(value => value != InventoryTypeEnum.Both)
            .map((value: number) => ({
                text: InventoryTypeEnum.toDisplay(value),
                value: value
            })
        );
    }

    AddAllPrograms = () => this.http.get('api/Select/InvConfigPrograms')
        .subscribe((items: SelectItem[]) => { this.fg.controls.programIds.setValue(items.map(item => item.value)) });

    activateDeactivate() {
        const action$ = this.fi.activateDeactivate(parseInt(this.fg.get('sysInvInventoryId').value));

        action$.subscribe((sm: SystemMessage) => {
            if (sm.isSuccessful) {
                this.fg.patchValue(sm.model);
                this.updateInventoryLists(sm.value);
                this.sm.setSystemMessage(sm.message, sm.messageClass);
            }
        });
    }

    showPrograms(inv : SysInvInventoryVm) {
        this.fg.patchValue(inv);
        this.inv = this.fg.value;
        this.allPrograms = false;
        this.modalService.open(this.modalProgram, {animation: false});
    }

    toggleProgramView = () => this.allPrograms = !this.allPrograms;
}
