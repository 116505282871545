// tslint:disable: quotemark
export enum BusinessAreaEnum {
	Unassigned = 0,
	HEROS = 1,
	PharmLog = 2,
	NDC = 3,
	Inventory = 4,
	MetabolicFormula = 5,
	CI = 6,
	Issuance = 7,
	Bulk = 8,
	Reports = 9,
	Admin = 10,
	RxConnects = 11,
	HelpDesk = 12,
	PFS = 13,
	Other = 14,
	ICQ = 15,
	SysInv = 16,
	ErrorReport = 17,
	BA = 18
}

export namespace BusinessAreaEnum {
	export function toDescription(val: BusinessAreaEnum) {
		switch (val) {
			case 0:
				return "Unassigned";
			case 1:
				return "HEROS";
			case 2:
				return "Pharm Log";
			case 3:
				return "NDCs";
			case 4:
				return "Pharmacy";
			case 5:
				return "Metabolic Formula";
			case 6:
				return "CI Repack";
			case 7:
				return "Issuance";
			case 8:
				return "Bulk";
			case 9:
				return "Reports";
			case 10:
				return "Admin";
			case 11:
				return "Rx Connects";
			case 12:
				return "Support";
			case 13:
				return "PFS";
			case 14:
				return "Other";
			case 15:
				return "ICQ";
			case 16:
				return "System Inventory";
			case 17:
				return "Error Reporting";
			case 18:
				return "Budget & Allocations";
		}
		return null;
	}
	export function toDisplay(val: BusinessAreaEnum) {
		switch (val) {
			case 0:
				return "Unassigned";
			case 1:
				return "HEROS";
			case 2:
				return "Pharm Log";
			case 3:
				return "NDCs";
			case 4:
				return "Pharmacy";
			case 5:
				return "Metabolic Formula";
			case 6:
				return "CI Repack";
			case 7:
				return "Issuance";
			case 8:
				return "Bulk";
			case 9:
				return "Reports";
			case 10:
				return "Admin";
			case 11:
				return "Rx Connects";
			case 12:
				return "Support";
			case 13:
				return "PFS";
			case 14:
				return "Other";
			case 15:
				return "ICQ";
			case 16:
				return "System Inventory";
			case 17:
				return "Error Reporting";
			case 18:
				return "Budget & Allocations";
		}
		return null;
	}
}
