import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getHelpDeskNav: () => NavRoute = () => {
	const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole;

	if (user !== null) {
		var navItems: NavRoute[] = [
			{
				name: 'Dashboard'
				, exact: true
				, path: 'support/dashboard'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'support/dashboard'
				, secondaryNav: ['Support', 'Dashboard']
			}				
			, {
				name: 'Support Desk'
				, exact: true
				, path: 'support/tickets/list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'support/tickets'
				, secondaryNav: ['Support', 'Support Desk']
			}
			, {
				name: 'Knowledge Base'
				, exact: true
				, path: 'support/knowledge-base'
				, moduleAccess: [
					{
						modules: sec.anyArea()
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'support/knowledge-base'
				, secondaryNav: ['Support', 'Knowledge Base']
			}				
			, {
				name: 'Release Notes'
				, exact: true
				, path: 'support/web-release-notes'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.HelpDesk ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, activeNavLink: 'support/web-release-notes'
				, secondaryNav: ['Support', 'Release Notes']
			}
		];

		if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.HelpDesk)) {
			navItems.push({
				name: 'Error Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.ErrorReport ]
						, permissionRoles: sec.setMinRole(p.User)
					}
				]
				, exact: true
				, path: `error-report/error-reports/support/list/${BusinessAreaEnum.HelpDesk}`
				, activeNavLink: 'error-report/error-reports/support'
				, secondaryNav: ['Support', 'Error Reporting']
			});
		}

		if (user?.reportBusinessAreas?.includes(BusinessAreaEnum.HelpDesk)) {
			navItems.push({
				name: 'Reporting'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Reports ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.SystemAdmin)
					}
				]
				, exact: true
				, path: `reporting/list/${BusinessAreaEnum.HelpDesk}`
				, activeNavLink: 'reporting/list'
				, secondaryNav: ['Support', 'Reporting']
			});
		}

		navItems.push({
			name: 'Admin'
			, href: 'support/admin/categories/list'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HelpDesk ]
					, permissionRoles: sec.setMinRole(p.SystemAdmin)
				}
			]
			, activeNavLink: 'support/admin/categories'
			, secondaryNav: ['Support', 'Admin', 'Categories']
			, children: [
				{
					name: 'Categories'
					, exact: true
					, path: 'support/admin/categories/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: sec.setMinRole(p.SystemAdmin)
						}
					]
					, activeNavLink: 'support/admin/categories'
					, secondaryNav: ['Support', 'Admin', 'Categories']
				}
				, {
					name: 'Priorities'
					, exact: true
					, path: 'support/admin/priorities/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: sec.setMinRole(p.SystemAdmin)
						}
					]
					, activeNavLink: 'support/admin/priorities'
					, secondaryNav: ['Support', 'Admin', 'Priorities']
				}
				, {
					name: 'Statuses'
					, exact: true
					, path: 'support/admin/statuses/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: sec.setMinRole(p.SystemAdmin)
						}
					]
					, activeNavLink: 'support/admin/statuses'
					, secondaryNav: ['Support', 'Admin', 'Statuses']
				}
				, {
					name: 'Template Responses'
					, exact: true
					, path: 'support/admin/template-responses/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'support/admin/template-responses'
					, secondaryNav: ['Support', 'Admin', 'Template Responses']
				}
			]
		});

		navItems.push({
			name: 'Dashboard Settings'
			, href: 'support/dashboard-settings/support-settings'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HelpDesk ]
					, permissionRoles: sec.setMinRole(p.SystemAdmin)
				}
			] 
			, activeNavLink: 'support/dashboard-settings/support-settings'
			, secondaryNav: ['Support', 'Dashboard Settings', 'Support Settings']
			, children: [
				{
					name: 'Support Settings'
					, exact: true
					, path: 'support/dashboard-settings/support-settings'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'support/dashboard-settings/support-settings'
					, secondaryNav: ['Support', 'Dashboard Settings', 'Support Settings']
				}
				, {
					name: 'Upcoming Features'
					, exact: true
					, path: 'support/dashboard-settings/upcoming-features/list'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.HelpDesk ]
							, permissionRoles: [ p.SystemAdmin ]
						}
					]
					, activeNavLink: 'support/dashboard-settings/upcoming-features'
					, secondaryNav: ['Support', 'Dashboard Settings', 'Upcoming Features']
				}
			]
		});

		return {
			name: 'Support'
			, href: 'support/dashboard'
			, canActivate: [ AuthGuard ]
			, moduleAccess: [
				{
					modules: sec.anyArea()
					, permissionRoles: sec.setMinRole(p.User)
				}
			]
			, children: navItems
			, icon: faCircleInfo
			, area: 'support'
			, module: BusinessAreaEnum.HelpDesk
		};
	}

	return { name: "" };
};

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}