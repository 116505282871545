import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';

import { EligibilityStatusEnum } from 'app/core/enums/generated/EligibilityStatusEnum';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { PatientApplicationStatusEnum } from 'app/core/enums/generated/PatientApplicationStatusEnum';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { PatientApplicationVm } from 'app/shared/generated/Models/Public/PatientApplicationVm';
import { environment } from 'environments/environment';

@Component({
    selector: 'patient-application',
    templateUrl: './patient-application.component.html',
    styleUrls: ['./patient-application.component.scss'],
    standalone: false
})
export class PatientApplicationComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;
	siteKey: string = environment.siteKey; // should pick up environment specific siteKey

	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public recaptcha: any = null;
	public execute: any;
	emptyCaptcha: boolean = true;
	honeypotControl;

	id: number = 0;
	formGroup = PatientApplicationVm.Form;
	model = new PatientApplicationVm();
	currentDate: any = new Date();
	canManualEntry = true;
	isSuccess = false;
	disableSave = false;
	EligibilityStatusEnum = EligibilityStatusEnum;
	EligibilityDisplayText = "Please indicate if you are: (check boxes that apply)";

	phoneValidation = { pattern: "Invalid phone number" };

	constructor(
		private route: ActivatedRoute
		, private httpClient: HttpClient
		, public router: Router
		, title: Title
		, private ms: SystemMessageService
		, private cdr: ChangeDetectorRef
	) { title.setTitle('Patient Application'); }

	ngOnInit() {
		this.getPatient();

		if (isNaN(this.id) || this.id == 0) {
			this.setUpHoneyPot();
			this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
			this.formGroup.get('dateSigned').patchValue(this.currentDate.toLocaleString());
			this.formGroup.get('applicationStatus').patchValue(PatientApplicationStatusEnum.New);
			this.formGroup.get('eligibilityStatusId').setValue(null);
		}

	}

	ngAfterViewInit(): void {
		if (isNaN(this.id) || this.id == 0) {
			this.captchaIsLoaded = true;
			this.cdr.detectChanges();
		}
	}

	setUpHoneyPot() {
		this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
	}

	requireFalse(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true }; }
			return null;
		}
	}

	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
		this.execute = setInterval(() => {
			this.captchaElem?.execute();
			this.captchaElem?.getResponse() !== '' ? this.emptyCaptcha = false : this.emptyCaptcha = true;
		}, 5000);
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

	ngOnDestroy() {
		clearInterval(this.execute);
	}

	getPatient() {
		this.route.paramMap.subscribe(map => {
			this.id = parseInt(map.get('id'), 10);

			if (!isNaN(this.id) && this.id !== 0) {
				this.httpClient.get(`api/RxConnects/Patient/GetPatientApplication/${this.id}`).subscribe((model: PatientApplicationVm) => {
					this.formGroup.patchValue(model);
					this.model = model;
				});
				this.disableForm();
			} else {
				this.formGroup.get('dateSigned').patchValue(this.currentDate.toLocaleString());
			}
		});
	}

	savePatient() {
		if (this.formGroup.valid) {
			this.ms.getHttpObservable(this, 'api/PatientApplication/SavePatient', this.formGroup).subscribe(msg => {
				if (msg.isSuccessful) {
					this.isSuccess = true;
					this.disableForm();
				} else { this.isSuccess = false; }
			});
		} else { validateForm(this.formGroup); }
	}

	disableForm() {
		this.formGroup.disable();
		this.disableSave = true;
		clearInterval(this.execute);
	}

	updateCounty() {
		const address = this.formGroup.value.smartyAddress;

		if (address === null || address.countyName === null) { return; }
		this.httpClient.get(`api/PatientApplication/GetCountyByName?countyName=${address.countyName}`).subscribe((countyId: number) => {
			if (countyId !== 0) {
				this.formGroup.get('countyId').patchValue(countyId)
				document.getElementById('homePhone').focus();
			} else {
				this.formGroup.get('countyId').patchValue(0)
				document.getElementById('countyId').focus();
			}
		});
	}
}
