import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import { IcqFeedbackVm } from 'app/shared/generated/ICQ/Models/User/IcqFeedbackVm';
import { environment } from 'environments/environment';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';

@Component({
    selector: 'pcg-icq-feedback',
    standalone: false,
    templateUrl: './icq-feedback.component.html',
    styleUrl: './icq-feedback.component.scss'
})
export class IcqFeedbackComponent {

    // reCaptcha properties
    @ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;

    siteKey: string = environment.siteKey; // should pick up environment specific siteKey
    public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public recaptcha: any = null;
	public execute: any;

    formGroup = IcqFeedbackVm.Form;
    starRating: number = 0;
    stars = document.getElementsByClassName("star-icon");
    faIconName = { faStar };
    isError = false;

    constructor(
        private cdr: ChangeDetectorRef
        , private http: HttpClient
        , private route: ActivatedRoute
        , private sm: SystemMessageService
    ) { }

    ngOnInit() {
        this.getFeedback();
        this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
    }

    getFeedback() {
        this.http.get(`api/ICQ/IcqReview/GetIcqFeedback?feedbackId=${this.route.snapshot.params.id}&guid=${this.route.snapshot.params.guid}`)
            .subscribe((msg: SystemMessage) => { this.formGroup.patchValue(msg.model); });
    }

    addStars(starNum) {
        this.removeStars();
        for (let i=0; i < starNum; i++) { this.stars[i]?.classList?.add("star-icon-filled"); }
        this.starRating = starNum;
    }

    removeStars() { for (let i=0; i < 5; i++) { this.stars[i]?.classList?.remove("star-icon-filled"); } }

    saveForm() {
        this.isError = false;
        if (this.starRating > 0) { this.formGroup.controls.rating.setValue(this.starRating); }
        else {
            this.isError = true;
            return;
        }
            
        this.http.post(`api/Icq/IcqReview/SaveFeedback`, this.formGroup.getRawValue())
            .subscribe((msg: SystemMessage) => {
                if (!msg.isSuccessful) { 
                    this.sm.setSystemMessage(msg.message, msg.messageClass);
                    this.formGroup.controls.rating.setValue(0); 
                    this.addStars(0);
                }
            }
        );
    }

    // Captcha callbacks
	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
		this.execute = setInterval(() => { this.captchaElem?.execute(); }, 5000);
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}
}
