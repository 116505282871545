<div class='clearfix page-title-container d-flex w-100'>
    <div class='mr-auto'>
        <h2>
            <span class='d-flex align-items-center'>
                @if (
                    backBtnLink 
                    && !disableBackBtn
                ) {
                    <button 
                        class='btn btn-sm align-items-center mr-2' 
                        [routerLink]='backBtnLink'
                        [ngbTooltip]='backBtnTooltip' 
                        [queryParams]="backParams" 
                        [placement]="backBtnPlacement"
                    >
                        <fa-icon 
                            rotate='90' 
                            [icon]='faLevelDownAlt' 
                            class='mr-2'
                        ></fa-icon>
                        Back
                    </button>
                }
                @if (
                    !backBtnLink 
                    && !disableBackBtn
                ) {
                    <button 
                        class='btn btn-sm align-items-center mr-2' 
                        (click)='goBack()' 
                        ngbTooltip='Return' 
                        [placement]="backBtnPlacement"
                    >
                        <fa-icon 
                            rotate='90' 
                            [icon]='faLevelDownAlt' 
                            class='mr-2'
                        ></fa-icon>
                        Back
                    </button>
                }
                @if (primaryTitle) {
                    <span>
                        {{ primaryTitle }}
                        <span *ngFor='let secondaryTitle of secondaryTitles'>
                            @if (secondaryTitle) { <span>: {{ secondaryTitle }}</span> }
                        </span>
                    </span>
                }
                
                <!-- Content passed through with the pageTitle decoration will be placed here. This is intended to be used for custom page titles. -->
                <ng-content select="[pageTitle]"></ng-content>
                <ng-content select="[titleAction]"></ng-content>
            </span>
        </h2>
    </div>
    <!-- Content passed through with the pageActions decoration will be placed here. This is intended to be used for page actions. -->
    <ng-content select="[pageActions]"></ng-content>
</div>