import { Component, OnInit } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[pcg-svg-history]',
    templateUrl: './svg-history.component.html',
    styleUrls: ['./svg-history.component.scss'],
    standalone: false
})
export class SvgHistoryComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
