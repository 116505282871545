<img
	alt="HOMER - Health Operations & Medical Emergency Response" 
	class='system-logo mt-5 clearfix mb-2' 
	src='assets/images/logos/logo.png'
/>

<div class='login-container clearfix'>
	@if (isError) { <div class="text-danger text-center">{{ errorString }}</div> }
	@if (!isUsingSsoLogin) { 
		<form 
			#myForm 
			(submit)="onSubmit()" 
			[formGroup]="loginForm"
		>
			<div class='row'>
				<pcg-control-group 
					class="col-sm-12" 
					label="Username"
				>
					<input 
						pcgControlRef 
						formControlName="userName" 
						type="text" 
						class="form-control" 
					/>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-show-hide-password
					class="col-sm-12" 
					fcName="password" 
					[parentForm]="loginForm" 
					label="Password" 
					inputCssClass="form-control"
				></pcg-show-hide-password>
			</div>

			<button 
				type="submit" 
				class="btn btn-secondary btn-lg"
			>Login</button>
			<a 
				class='float-left' 
				href="/help-ticket?id=register"
			>Register new account</a>
			<a 
				class='float-right' 
				routerLink='/forgot-password'
			>Forgot password</a>
			<div>
				<a
					class="anchor"
					(click)="switchSso()"
				>{{ isUsingSsoLogin ? 'Regular Login' : 'Login With SSO' }}</a>
			</div>
		</form>
	} @else { 
		<form (ngSubmit)="ssoLogin()">
			<div class="row">
				<pcg-control-group 
					class="col-sm-12" 
					label="Email"
				>
						<input 
							type="text" 
							maxlength="51"
							pcgControlRef 
							[(ngModel)]="ssoEmail"
							class="form-control" 
							id="ssoEmail"
							name="ssoEmail"
							autocomplete="on"
						/>
				</pcg-control-group>
			</div>

			<button 
				type="submit" 
				class="btn btn-secondary btn-lg"
				(click)="ssoLogin()"
			>Login</button>
			<a 
				class="anchor"
				(click)="switchSso()"
			>{{ isUsingSsoLogin ? 'Regular Login' : 'Login With SSO' }}</a>
		</form>
	}
</div>

@if (showMfa) {
	<div class="mfa-container">
		<br />
		<label class="for text-danger">Please Select a Multi-Factor Authentication Type</label>
		<button 
			class="btn btn-outline-dark btn-md mr-2" 
			(click)='fidoMfa();'
		>
			<span>
				<fa-icon 
					[icon]="faUsbDrive" 
					class="mr-2"
				></fa-icon> Device
			</span>
		</button>

		<button 
			class="btn btn-outline-dark btn-md mr-2" 
			(click)='fingerprintMfa();'
		>
			<span>
				<fa-icon 
					[icon]="faFingerprint" 
					class="mr-2"
				></fa-icon> SecuGen								
			</span>
		</button>
	</div>
}

@if (showInactiveMessage) {
	<div class="mt-3 alert alert-warning text-center">
		You must sign back in because you had a period of inactivity during your session.
	</div>
}