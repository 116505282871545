<div class='text-right d-flex flex-row'>
	<label 
		*ngIf="labelText" 
		class="pinLabel bold align-self-center"
		[for]="labelForId"
	>{{ labelText }}</label>

	<input
		*ngIf="hasVerificationSettings ? isPin : true"
		pcgControlRef
		pcgNumeric
		inputmode="numeric"
		autocomplete="off"
		pattern="\d+"
		placeholder="PIN"
		[type]="isFirefox ? 'password' : 'text'"
		[id]="labelForId"
		[name]="labelForId"
    	[(ngModel)]="value"
		[value]="value"
		[inputAutofocus]="autofocus"
		[class]="inputClass"
		[disabled]="isDisabled"
		(touch)="(onTouched)"
		(change)="
			(onChange); 
			onSubmitPin(submitOnChange);
		"
		(keydown.enter)="onSubmitPin()"
	/>
	<button 
		*ngIf="
			hasVerificationSettings 
			&& isSecuGen
		"
		class="btn btn-outline-dark btn-md ml-2" 
		(click)='scanSecuGen();'
	>
		<span>
			<fa-icon 
				[icon]="faFingerprint" 
				class="mr-2" 
			></fa-icon>
		</span>
	</button>

	<button 
		*ngIf="
			hasVerificationSettings 
			&& isFido
		"
		class="btn btn-outline-dark btn-md ml-2" 
		(click)='usbDevice();'
	>
		<span>
			<fa-icon 
				[icon]="faUsbDrive" 
				class="mr-2"
			></fa-icon>
		</span>
	</button>

	<button 
		*ngIf="!hideSubmit" 
		type='button' 
		class='btn btn-save ml-2 mb-1' 
		(click)='onSubmitPin()'
	>Submit Pin</button>
</div>