import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AuditHistoryVm } from 'app/shared/generated/Models/AuditHistoryVm';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-shared-facility-audit-history',
    templateUrl: './facility-audit-history.component.html',
    styleUrls: ['./facility-audit-history.component.scss'],
    standalone: false
})
export class FacilityAuditHistoryComponent implements OnInit {

    @ViewChild(TableComponent, { static: true }) table: TableComponent<AuditHistoryVm>;
	columns = AuditHistoryVm.ColumnDefs;
	facilityName: string = null;
	filters = {
		facilityId: null
	};

	constructor(title: Title, private activatedRoute: ActivatedRoute) { 
		title.setTitle('Edit Facility');
	}

	ngOnInit() {
		this.filters.facilityId = this.activatedRoute.snapshot.params['id'];
	}
}
