<div *ngIf="hasImages" class="slider"
(wheel)="onScroll($event)">
    <div class="d-flex align-items-center">
        <div *ngIf="slides?.length > 1">
            <div (click)="goToPrevious()" class="cursor-click">❰</div>
        </div>
        <div class="slide">
            <img 
                [src]="getCurrentSlideSrc()" 
                class='img' 
            />
        </div>
        <div *ngIf="slides?.length > 1">
            <div (click)="goToNext()" class="cursor-click">❱</div>
        </div>
    </div>
    
    @if(useThumbnails && slides?.length > 1) {
        <div class="thumbnail-container">
            @for(slide of slides; let slideIndex = $index; track slide.url) {
                <img 
                [src]="getSlidesScr(slideIndex)" 
                (click)="goToSlide(slideIndex)" 
                class="mr-1 p-0 thumbnail"
                [ngClass]="{'selected': slideIndex == currentIndex}"
            />            
            }    
        </div>
    }

    <!--Uses dots instead of thumbnails-->
    @if(!useThumbnails && slides?.length > 1) {
    <div class="dots-container">
        @for(slide of slides; let slideIndex = $index; track slide.url) {
            <div 
            class="dot" 
            (click)="goToSlide(slideIndex)"
            [ngClass]="{'selected': slideIndex == currentIndex}"
        >●</div>
        }
    </div>
    }
</div>

    
