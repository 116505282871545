import { faGears } from '@fortawesome/pro-solid-svg-icons';
import { NavRoute } from "app/shared/navigation/nav.route.interface";
import { BusinessAreaEnum } from "../enums/generated/BusinessAreaEnum";
import { PermissionRole } from "../enums/generated/PermissionRole";

/** Returns `NavRoute` that is only accesible to 
 * Paul Consulting users. 
 * 
 * The client doesn't necessarily have purposes for these
 * features so we're hiding them behind the feature flag.
 * 
 * This is also a space for developers to hide features that
 * you are waiting to release.
 */
export const getPcgNav: () => NavRoute = () => {
    const pr = PermissionRole;

    const facilityTypes : NavRoute = {
        name: 'Facility Types'
        , exact: true
        , path: 'admin/facility-types/list'
        , activeNavLink: 'admin/facility-types'
        , secondaryNav: ['PCG', 'Facility Types']
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]        
    };

    const datadog : NavRoute = {
        name: 'Datadog Sandbox'
        , exact: true
        , path: 'admin/datadog/sandbox'
        , activeNavLink: 'admin/datadog/sandbox'
		, secondaryNav: ['PCG', 'Datadog Sandbox']
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };
    
    const epcis : NavRoute = {
        name: 'EPCIS Sandbox'
        , exact: true
        , path: 'admin/epcis/sandbox'
        , activeNavLink: 'admin/epcis/sandbox'
		, secondaryNav: ['PCG', 'EPCIS Sandbox']
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };

    const sysInv : NavRoute = {
        name: 'SysInv Sandbox'
        , exact: true
        , path: 'admin/sysInv/sandbox'
        , activeNavLink: 'admin/sysInv/sandbox'
		, secondaryNav: ['PCG', 'SysInv Sandbox']
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };

    const releaseNotes : NavRoute = {
        name: 'Release Notes'
        , exact: true
        , path: 'admin/web-release-notes/list'
        , activeNavLink: 'admin/web-release-notes'
		, secondaryNav: ['PCG', 'Release Notes']
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ PermissionRole.SystemAdmin ]
            }
        ]
    };

    const quartz : NavRoute = {
        name: 'Quartz'
        , exact: true
        , path: 'quartz'
        , openNewTab: true
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };

    const children : NavRoute[] = [ 
        facilityTypes
        , datadog
        , epcis
        , sysInv
        , releaseNotes
        , quartz
    ]

    // If you see this code, you're the target audience for this nav item!
    const navItems: NavRoute = {
        name: 'PCG'
        , featureFlag: true
        , path: 'admin/facility-types/list'
        , children : children 
        , moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ pr.SystemAdmin ]
			}
		]
        , activeNavLink: 'admin/facility-types'
        , secondaryNav: ['PCG', 'Facility Types']
		, icon: faGears
		, area: 'admin'
		, module: BusinessAreaEnum.Unassigned        
    }

    return navItems;
}