import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class OrganizationAccountVm {
	organizationAccountId: number;
	facilityAccountNameId: number;
	facilityAccountNamePfsId?: number;
	facilityAccountNameStr?: string;
	organizationId: number;
	pfsIds?: string;
	pfsFacilityAccountIds?: number[];
	organizationStr?: string;
	accountNumber?: string;
	expirationDate?: Date;
	expirationDateStr?: string;
	notes?: string;
	file?: any;
	uploadFiles?: any;
	uploadFilePath?: string;
	fileName?: string;
	isActive: boolean;
	isGracePeriod: boolean;
	isNewRequest: boolean;
	friendlyId?: string;
	actions?: string;
	actionsSelected?: boolean;
	lastUpdatedDate?: Date;
	lastUpdatedDateStr?: string;
	lastUpdatedUserId?: number;
	lastUpdatedBy?: string;
	hasPendingRequest: boolean;
	daysRemaining?: string;
	organizationLink?: string;
	pfsRequestIds?: string[];
	isRemoveDoc: boolean;
	homerEmailSent: boolean;
	pfsRequestedByEmail?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'organizationAccountId': new UntypedFormControl(0, [...(validators['organizationAccountId'] ?? []), ...[Validators.required]]),
			'facilityAccountNameId': new UntypedFormControl(0, [...(validators['facilityAccountNameId'] ?? []), ...[Validators.required]]),
			'facilityAccountNamePfsId': new UntypedFormControl(null, [...(validators['facilityAccountNamePfsId'] ?? []), ...[]]),
			'facilityAccountNameStr': new UntypedFormControl(null, [...(validators['facilityAccountNameStr'] ?? []), ...[]]),
			'organizationId': new UntypedFormControl(0, [...(validators['organizationId'] ?? []), ...[Validators.required]]),
			'pfsIds': new UntypedFormControl(null, [...(validators['pfsIds'] ?? []), ...[]]),
			'pfsFacilityAccountIds': new UntypedFormControl([], [...(validators['pfsFacilityAccountIds'] ?? []), ...[]]),
			'organizationStr': new UntypedFormControl(null, [...(validators['organizationStr'] ?? []), ...[]]),
			'accountNumber': new UntypedFormControl(null, [...(validators['accountNumber'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.pattern("^[A-Za-z0-9 ]*")]]),
			'expirationDate': new UntypedFormControl(null, [...(validators['expirationDate'] ?? []), ...[]]),
			'expirationDateStr': new UntypedFormControl(null, [...(validators['expirationDateStr'] ?? []), ...[]]),
			'notes': new UntypedFormControl(null, [...(validators['notes'] ?? []), ...[Validators.maxLength(1000)]]),
			'file': new UntypedFormControl(null, [...(validators['file'] ?? []), ...[]]),
			'uploadFiles': new UntypedFormControl([], [...(validators['uploadFiles'] ?? []), ...[]]),
			'uploadFilePath': new UntypedFormControl(null, [...(validators['uploadFilePath'] ?? []), ...[Validators.maxLength(250)]]),
			'fileName': new UntypedFormControl(null, [...(validators['fileName'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isGracePeriod': new UntypedFormControl(false, [...(validators['isGracePeriod'] ?? []), ...[]]),
			'isNewRequest': new UntypedFormControl(false, [...(validators['isNewRequest'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'actions': new UntypedFormControl(null, [...(validators['actions'] ?? []), ...[]]),
			'actionsSelected': new UntypedFormControl(null, [...(validators['actionsSelected'] ?? []), ...[]]),
			'lastUpdatedDate': new UntypedFormControl(null, [...(validators['lastUpdatedDate'] ?? []), ...[]]),
			'lastUpdatedDateStr': new UntypedFormControl(null, [...(validators['lastUpdatedDateStr'] ?? []), ...[]]),
			'lastUpdatedUserId': new UntypedFormControl(null, [...(validators['lastUpdatedUserId'] ?? []), ...[]]),
			'lastUpdatedBy': new UntypedFormControl(null, [...(validators['lastUpdatedBy'] ?? []), ...[]]),
			'hasPendingRequest': new UntypedFormControl(false, [...(validators['hasPendingRequest'] ?? []), ...[]]),
			'daysRemaining': new UntypedFormControl(null, [...(validators['daysRemaining'] ?? []), ...[]]),
			'organizationLink': new UntypedFormControl(null, [...(validators['organizationLink'] ?? []), ...[]]),
			'pfsRequestIds': new UntypedFormControl([], [...(validators['pfsRequestIds'] ?? []), ...[]]),
			'isRemoveDoc': new UntypedFormControl(false, [...(validators['isRemoveDoc'] ?? []), ...[]]),
			'homerEmailSent': new UntypedFormControl(false, [...(validators['homerEmailSent'] ?? []), ...[]]),
			'pfsRequestedByEmail': new UntypedFormControl(null, [...(validators['pfsRequestedByEmail'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['actions', {"orderId":0,"sortColumn":"actions","searchColumn":"actions","includeInExcel":true,"exportColumns":null,"displayName":"Actions","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['facilityAccountNameStr', {"orderId":1,"sortColumn":"facilityAccountNameStr","searchColumn":"facilityAccountNameStr","includeInExcel":true,"exportColumns":null,"displayName":"Account Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['accountNumber', {"orderId":2,"sortColumn":"accountNumber","searchColumn":"accountNumber","includeInExcel":true,"exportColumns":null,"displayName":"Account #","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['expirationDateStr', {"orderId":3,"sortColumn":"expirationDate","searchColumn":"expirationDateStr","includeInExcel":true,"exportColumns":null,"displayName":"Exp Date","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['uploadFilePath', {"orderId":4,"sortColumn":"uploadFilePath","searchColumn":"uploadFilePath","includeInExcel":true,"exportColumns":null,"displayName":"Related Document","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['isActive', {"orderId":5,"sortColumn":"isActive","searchColumn":"isActive","includeInExcel":true,"exportColumns":null,"displayName":"Is Active","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['lastUpdatedDateStr', {"orderId":6,"sortColumn":"lastUpdatedDate","searchColumn":"lastUpdatedDateStr","includeInExcel":true,"exportColumns":null,"displayName":"Updated Date","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['lastUpdatedBy', {"orderId":7,"sortColumn":"lastUpdatedBy","searchColumn":"lastUpdatedBy","includeInExcel":true,"exportColumns":null,"displayName":"Updated By","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): OrganizationAccountVmFromFg {
		return new OrganizationAccountVmFromFg(fg);
	}
}

export class OrganizationAccountVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get organizationAccountId(): number {
		return this.fg.get('organizationAccountId').value;
	}
	set organizationAccountId(val) {
		this.fg.get('organizationAccountId').setValue(val);
	}

	get facilityAccountNameId(): number {
		return this.fg.get('facilityAccountNameId').value;
	}
	set facilityAccountNameId(val) {
		this.fg.get('facilityAccountNameId').setValue(val);
	}

	get facilityAccountNamePfsId(): number {
		return this.fg.get('facilityAccountNamePfsId').value;
	}
	set facilityAccountNamePfsId(val) {
		this.fg.get('facilityAccountNamePfsId').setValue(val);
	}

	get facilityAccountNameStr(): string {
		return this.fg.get('facilityAccountNameStr').value;
	}
	set facilityAccountNameStr(val) {
		this.fg.get('facilityAccountNameStr').setValue(val);
	}

	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get pfsIds(): string {
		return this.fg.get('pfsIds').value;
	}
	set pfsIds(val) {
		this.fg.get('pfsIds').setValue(val);
	}

	get organizationStr(): string {
		return this.fg.get('organizationStr').value;
	}
	set organizationStr(val) {
		this.fg.get('organizationStr').setValue(val);
	}

	get accountNumber(): string {
		return this.fg.get('accountNumber').value;
	}
	set accountNumber(val) {
		this.fg.get('accountNumber').setValue(val);
	}

	get expirationDate(): Date {
		return this.fg.get('expirationDate').value;
	}
	set expirationDate(val) {
		this.fg.get('expirationDate').setValue(val);
	}

	get expirationDateStr(): string {
		return this.fg.get('expirationDateStr').value;
	}
	set expirationDateStr(val) {
		this.fg.get('expirationDateStr').setValue(val);
	}

	get notes(): string {
		return this.fg.get('notes').value;
	}
	set notes(val) {
		this.fg.get('notes').setValue(val);
	}

	get file(): any {
		return this.fg.get('file').value;
	}
	set file(val) {
		this.fg.get('file').setValue(val);
	}

	get uploadFilePath(): string {
		return this.fg.get('uploadFilePath').value;
	}
	set uploadFilePath(val) {
		this.fg.get('uploadFilePath').setValue(val);
	}

	get fileName(): string {
		return this.fg.get('fileName').value;
	}
	set fileName(val) {
		this.fg.get('fileName').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isGracePeriod(): boolean {
		return this.fg.get('isGracePeriod').value;
	}
	set isGracePeriod(val) {
		this.fg.get('isGracePeriod').setValue(val);
	}

	get isNewRequest(): boolean {
		return this.fg.get('isNewRequest').value;
	}
	set isNewRequest(val) {
		this.fg.get('isNewRequest').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get actions(): string {
		return this.fg.get('actions').value;
	}
	set actions(val) {
		this.fg.get('actions').setValue(val);
	}

	get actionsSelected(): boolean {
		return this.fg.get('actionsSelected').value;
	}
	set actionsSelected(val) {
		this.fg.get('actionsSelected').setValue(val);
	}

	get lastUpdatedDate(): Date {
		return this.fg.get('lastUpdatedDate').value;
	}
	set lastUpdatedDate(val) {
		this.fg.get('lastUpdatedDate').setValue(val);
	}

	get lastUpdatedDateStr(): string {
		return this.fg.get('lastUpdatedDateStr').value;
	}
	set lastUpdatedDateStr(val) {
		this.fg.get('lastUpdatedDateStr').setValue(val);
	}

	get lastUpdatedUserId(): number {
		return this.fg.get('lastUpdatedUserId').value;
	}
	set lastUpdatedUserId(val) {
		this.fg.get('lastUpdatedUserId').setValue(val);
	}

	get lastUpdatedBy(): string {
		return this.fg.get('lastUpdatedBy').value;
	}
	set lastUpdatedBy(val) {
		this.fg.get('lastUpdatedBy').setValue(val);
	}

	get hasPendingRequest(): boolean {
		return this.fg.get('hasPendingRequest').value;
	}
	set hasPendingRequest(val) {
		this.fg.get('hasPendingRequest').setValue(val);
	}

	get daysRemaining(): string {
		return this.fg.get('daysRemaining').value;
	}
	set daysRemaining(val) {
		this.fg.get('daysRemaining').setValue(val);
	}

	get organizationLink(): string {
		return this.fg.get('organizationLink').value;
	}
	set organizationLink(val) {
		this.fg.get('organizationLink').setValue(val);
	}

	get isRemoveDoc(): boolean {
		return this.fg.get('isRemoveDoc').value;
	}
	set isRemoveDoc(val) {
		this.fg.get('isRemoveDoc').setValue(val);
	}

	get homerEmailSent(): boolean {
		return this.fg.get('homerEmailSent').value;
	}
	set homerEmailSent(val) {
		this.fg.get('homerEmailSent').setValue(val);
	}

	get pfsRequestedByEmail(): string {
		return this.fg.get('pfsRequestedByEmail').value;
	}
	set pfsRequestedByEmail(val) {
		this.fg.get('pfsRequestedByEmail').setValue(val);
	}
}
