import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { SelectItem } from 'app/shared/models/select-item';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { UserStateService } from 'app/state/user/user-state.service';

@Component({
    selector: 'pcg-ci-workstation',
    templateUrl: './ci-workstation.component.html',
    styleUrls: ['./ci-workstation.component.scss'],
    standalone: false
})
export class CiWorkstationComponent implements OnInit {

    @ViewChild('ciWorkstation', { static: false }) ciWorkstation: PcgSelectComponent;

    private userState = inject(UserStateService);
    readonly user = this.userState.user;

    constructor(
        public activeModal: NgbActiveModal
        , private httpClient: HttpClient
        , public ms: SystemMessageService
    ) { }

    ngOnInit() { this.updateSelect(); }

    updateSelect() {
        this.httpClient.get(`api/Select/CiActiveWorkstations`)
            .subscribe((items: SelectItem[]) => {
                this.ciWorkstation.items = items;
                this.ciWorkstation.value = this.user()?.ciWorkstationId;
            }
        );
    }

    workstationChange(workstation: number) {
        this.ms.getHttpObservable(this, `api/CIRepack/CiWorkstation/SetUserWorkstation/${workstation}`)
            .subscribe(msg => {
                if (msg.isSuccessful) {
                    this.userState.updateUser(msg.value);
                    this.updateSelect();
                    this.activeModal.dismiss();
                }
            }
        );
    }
}
