<nav 
	mat-tab-nav-bar 
	[tabPanel]="tabPanel"
	class="p-1"
>
	@if (navService.currNav()?.parentNav) {
		<a 
			class="back" 
			href="#" 
			(click)="clickDropdown(navService.currNav()?.parentNav)"
		>
			<fa-icon 
				[icon]="faIconName.faArrowLeft" 
				class="back-arrow"
			></fa-icon>
		</a>
	}
	@if (
		!loopNavRoutes?.length 
		&& navService.currSelectedMainNavMenu()
	) {
		<a 
			mat-tab-link
			class="subnav-item link-active"
			#navLink
		>
			<span>{{ navService.currOpenPrimaryNavMenu() }}</span>
		</a>
	}
	@for (route of loopNavRoutes; track route; let i = $index) {
		@if (
			route.ellipseBefore 
			&& !navService.shouldShowRouteLink(route, navRoutes, route.index) 
			&& !navService.shouldShowRouteLink(navRoutes[route.index - 1], navRoutes, route.index)
		) { <a mat-tab-link	class="ellipse">...</a> }
		@if (
			route.ellipseBefore 
			|| route.ellipseAfter 
			|| navService.shouldShowRouteLink(route, navRoutes)
		) {
			<a 
				mat-tab-link
				class="subnav-item"
				#navLink
				[class.link-active]="hrefLocation?.includes(route?.activeNavLink)"
				[routerLinkActiveOptions]="{ exact: route.exact }" 
				[queryParams]="route.queryParams" 
				(click)="
					clickedNav.emit(true); 
					redirect(route);
				"
				[routerLink]="route.path"
			>
				@if (route.name !== 'Back') { <span>{{ route.name }}</span> }
				@else {
					<span>
						<fa-icon 
							[icon]="faIconName.faArrowLeft" 
							class="back-arrow"
						></fa-icon>
					</span>
				}
			</a>
		}
		@if (
			route.ellipseAfter 
			&& !navService.shouldShowRouteLink(route, navRoutes) 
			&& !navService.shouldShowRouteLink(navRoutes[route.index + 1], navRoutes)
		) { <a mat-tab-link class="ellipse">...</a> }
		@if (
			route 
			&& route.children 
			&& !route.hasSideNav
		) {
			<a 
				mat-tab-link
				class="dropdown"
				class="subnav-item" 
				href="#" 
				(click)="clickDropdown(route)"
			>{{ route.name }} ▼</a>
		}
	}
</nav>