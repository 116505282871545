import { Injectable } from "@angular/core";

//Class used to format things in the system
@Injectable({
	providedIn: 'root'
})
export class FormatHelperService{
    //Keep these static where anyone can use them without DI
    static FormatNdc = (ndc: string): string => ndc?.replace(/(\d{5})(\d{4})(\d{2})/, '$1-$2-$3');
    static GetIsNully = (value: any): boolean => value === undefined || value === null;
    static GetIsNullyOrWhitespace = (value: string): boolean => FormatHelperService.GetIsNully(value) || FormatHelperService.IsWhitespace(value);
    static ToTitleCase = (value: string) => !FormatHelperService.GetIsNullyOrWhitespace(value) 
        ? value.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substring(1); })
        : '';
    static IsWhitespace = (value : any) : boolean => typeof value === 'string' && value.trim() === '';

    static GetPropertyName<T extends object>(o: T, expression: (x: { [Property in keyof T]: string }) => string) {
        const res = {} as { [Property in keyof T]: string };
        Object.keys(o).map(k => res[k as keyof T] = k);
        return expression(res);
    }
    
    static ClearAllWhitespace = (value: string): string => value?.replace(/\s/g, "");

    //Keep these non-static so legacy places that were using DI can still do so
    FormatNdc = (ndc: string): string => FormatHelperService.FormatNdc(ndc);
    GetIsNully = (value: any): boolean => FormatHelperService.GetIsNully(value);
    GetIsNullyOrWhitespace = (value: string): boolean => FormatHelperService.GetIsNullyOrWhitespace(value);
    ToTitleCase = (value: string) => FormatHelperService.ToTitleCase(value);

    static replaceAfterChar(
        str: string
        , char: string
        , replacement: string
    ): string {
        const index = str.indexOf(char);
        if (index === -1) { return str; }
        return str.substring(0, index + 1) + replacement;
    }
}
