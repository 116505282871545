export const AI_LOT_NUMBER = '10';
export const AI_SERIAL_NUMBER = '21';
export const AI_GTIN = '01';
export const AI_EXPIRATION_DATE = '17';

/**
* Dictionary mapping fixed length application identifiers to their length
* Variable length identifiers are null and their max length is defined in AI_OPTIONAL_MAX_DICT
* https://en.wikipedia.org/wiki/GS1-128
* */
export const AI_DICT = {
	'00': 18,
	'01': 14,
	'02': 14,
	'10': null,
	'11': 6,
	'12': 6,
	'13': 6,
	'15': 6,
	'16': 6,
	'17': 6,
	'20': 2,
	'21': null,
	'22': null,
	'23': null,
	'240': null,
	'241': null,
	'242': null,
	'243': null,
	'250': null,
	'251': null,
	'253': null,
	'254': null,
	'255': null,
	'30': null,
	'310': 7,
	'311': 7,
	'312': 7,
	'313': 7,
	'314': 7,
	'315': 7,
	'316': 7,
	'320': 7,
	'321': 7,
	'322': 7,
	'323': 7,
	'324': 7,
	'325': 7,
	'326': 7,
	'327': 7,
	'328': 7,
	'329': 7,
	'330': 7,
	'331': 7,
	'332': 7,
	'333': 7,
	'334': 7,
	'335': 7,
	'336': 7,
	'337': 7,
	'340': 7,
	'341': 7,
	'342': 7,
	'343': 7,
	'344': 7,
	'345': 7,
	'346': 7,
	'347': 7,
	'348': 7,
	'349': 7,
	'350': 7,
	'351': 7,
	'352': 7,
	'353': 7,
	'354': 7,
	'355': 7,
	'356': 7,
	'357': 7,
	'360': 7,
	'361': 7,
	'362': 7,
	'363': 7,
	'364': 7,
	'365': 7,
	'366': 7,
	'367': 7,
	'368': 7,
	'369': 7,
	'37': null,
	'390': null,
	'391': null,
	'392': null,
	'393': null,
	'400': null,
	'401': null,
	'402': 17,
	'403': null,
	'410': 13,
	'411': 13,
	'412': 13,
	'413': 13,
	'414': 13,
	'420': null,
	'421': null,
	'422': 3,
	'423': null,
	'424': 3,
	'425': 3,
	'426': 3,
	'7001': 13,
	'7002': null,
	'7003': 10,
	'7004': null,
	'703': null,
	'8001': 14,
	'8002': null,
	'8003': null,
	'8004': null,
	'8005': 6,
	'8006': 18,
	'8007': null,
	'8008': null,
	'8018': 18,
	'8020': null,
	'8100': 6,
	'8101': 10,
	'8102': 2,
	'8110': null,
	'8200': null,
	'90': null,
	'91': null,
	'92': null,
	'93': null,
	'94': null,
	'95': null,
	'96': null,
	'97': null,
	'98': null,
	'99': null,
};

/**
 * Dictionary mapping optional application identifiers to their max lengths
 * */
export const AI_OPTIONAL_MAX_DICT = {
	'10': 20,
	'21': 20,
	'22': 29,
	'23': 20,
	'240': 30,
	'241': 30,
	'242': 6,
	'243': 20,
	'250': 30,
	'251': 30,
	'253': 17,
	'254': 20,
	'255': 25,
	'30': 8,
	'37': 8,
	'390': 16,
	'391': 19,
	'392': 16,
	'393': 19,
	'400': 30,
	'401': 30,
	'403': 30,
	'420': 20,
	'421': 15,
	'423': 15,
	'7002': 30,
	'7004': 4,
	'703': 31,
	'8002': 21,
	'8003': 31,
	'8004': 30,
	'8007': 30,
	'8008': 12,
	'8020': 25,
	'8110': 30,
	'8200': 70,
	'90': 90,
	'91': 90,
	'92': 90,
	'93': 90,
	'94': 90,
	'95': 90,
	'96': 90,
	'97': 90,
	'98': 90,
	'99': 90
};
