import { Component, EventEmitter, IterableDiffers, Input, Output, OnInit, ElementRef } from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
    selector: 'pcg-dual-list-box',
    templateUrl: './dual-list-box.component.html',
    styleUrls: ['./dual-list-box.component.scss'],
    standalone: false
})
export class DualListBoxComponent extends DualListComponent implements OnInit {

	@Input() sourceName = '';
	@Input() targetName = '';
	@Input() key = 'id';
	@Input() display = 'name';
	@Input() height = '200px';
	@Input() sort = true;
	@Input() filter = true;
	@Input() leftHeader = '';
	@Input() rightHeader = '';
	@Input() required = false;

	@Output() selectChange = new EventEmitter();

	private mySorter = (a: any, b: any) => (a._name < b._name) ? -1 : ((a._name > b._name) ? 1 : 0);

	constructor(differs: IterableDiffers, private element: ElementRef) {
		super(differs);
	}

	ngOnInit() {
		if (this.sort) { this.compare = this.mySorter; }
	}

	triggerChanged() {
		const event = new CustomEvent('change', { bubbles: true });
		this.element.nativeElement.dispatchEvent(event);
	}

	moveAll() {
		this.selectAll(this.available);
		this.moveItem(this.available, this.confirmed);
	}

	removeAll() {
		this.selectAll(this.confirmed);
		this.moveItem(this.confirmed, this.available);
	}

	// Override function in DualListComponent to add custom selectChange event.
	selectItem(list: Array<any>, item: any) {
		const pk = list.filter( (e: any) => { return Object.is(e, item); });
		if (pk.length > 0) {
			// Already in list, so deselect.
			for (let i = 0, len = pk.length; i < len; i += 1) {
				const idx = list.indexOf(pk[i]);
				if (idx !== -1) {
					list.splice(idx, 1);
					this.selectChange.emit( { key: item._id, selected: false });
					this.triggerChanged();
				}
			}
		} else {
			list.push(item);
			this.selectChange.emit( { key: item._id, selected: true });
			this.triggerChanged();
		}
	}

	moveTo(fromList, toList, item) {
		this.selectItem(fromList.pick, item);
		this.moveItem(fromList, toList);
		return false;
	}
}
