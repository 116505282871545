import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { DonorRegistrationVm } from 'app/shared/generated/Models/Public/DonorRegistrationVm';
import { environment } from 'environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Subscription } from 'rxjs';

@Component({
    selector: 'donor-registration',
    templateUrl: './donor-registration.component.html',
    styleUrls: ['./donor-registration.component.scss'],
    standalone: false
})

export class DonorRegistrationComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;
	siteKey: string = environment.siteKey; // should pick up environment specific siteKey

	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public recaptcha: any = null;
	public execute: any;
	emptyCaptcha: boolean = true;
	honeypotControl;

	id: number;
	formGroup = DonorRegistrationVm.Form;
	subscriptions = new Subscription();
	model = new DonorRegistrationVm();
	currentDate: any = new Date();
	canManualEntry = true;
	isSuccess = false;
	disableSave = false;

	phoneValidation = { pattern: "Invalid phone number" };

	constructor(public router: Router, private httpClient: HttpClient, title: Title, private ms: SystemMessageService, private cdr: ChangeDetectorRef) {
		title.setTitle('Donor Registration');
	}

	ngOnInit() {
		if (isNaN(this.id) || this.id == 0) {
			this.setUpHoneyPot();
			this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
			this.formGroup.get('dateSigned').patchValue(this.currentDate.toLocaleString());
		}
	}

	ngAfterViewInit(): void {
		if (isNaN(this.id) || this.id == 0) {
			this.captchaIsLoaded = true;
			this.cdr.detectChanges();
		}
	}

	setUpHoneyPot() {
		this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
	}

	requireFalse(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true }; }
			return null;
		}
	}

	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
		this.execute = setInterval(() => {
			this.captchaElem?.execute();
			this.captchaElem?.getResponse() !== '' ? this.emptyCaptcha = false : this.emptyCaptcha = true;
		}, 5000);
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		clearInterval(this.execute);
	}

	saveDonor() {
		if (this.formGroup.valid) {
			this.subscriptions.add(
				this.ms.getHttpObservable(this, 'api/PublicDonorRegistration/SaveDonor/', this.formGroup).subscribe(msg => {
					if (msg.isSuccessful) {
						this.isSuccess = true;
						this.disableForm();
						this.captchaSuccess = true;
					} else { this.isSuccess = false; }
				}));
		} else { validateForm(this.formGroup); }
	}

	disableForm() {
		this.formGroup.disable();
		this.disableSave = true;
	}

	updateCounty() {
		const address = this.formGroup.value.smartyAddress;

		if (address === null || address.countyName === null) { return; }
		this.httpClient.get(`api/PublicDonorRegistration/GetCountyByName?countyName=${address.countyName}`).subscribe((countyId: number) => {
			if (countyId !== 0) {
				this.formGroup.get('countyId').patchValue(countyId)
				document.getElementById('contactName').focus();
			}
			else {
				this.formGroup.get('countyId').patchValue(0)
				document.getElementById('countyId').focus();
			}
		});
	}
}
