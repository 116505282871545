import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { UserFacilityAgencyListVm } from 'app/shared/generated/Administration/Models/Users/UserFacilityAgencyListVm';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-user-associated-facilities',
    templateUrl: './user-associated-facilities.component.html',
    styleUrls: ['./user-associated-facilities.component.scss'],
    standalone: false
})
export class UserAssociatedFacilitiesComponent implements OnInit {

    @ViewChild(TableComponent) facTable: TableComponent<UserFacilityAgencyListVm>;
    @Input() id: number;
    @Input() canEditFacilities: boolean = false;
    @Output() reload = new EventEmitter();

    columns = UserFacilityAgencyListVm.ColumnDefs;

    hasData = false;

    filters = { 
        userId: 0
        , countyId: null
        , businessArea: null  
    };
    filterMap = { 
        "countyId": "County"
        , "businessArea": "Business Area"
    };
    initFilters = Object.assign({}, this.filters);

    faIcon = { faXmark }

    constructor(
        private httpClient: HttpClient
        , private ms: SystemMessageService
    ) { }

    ngOnInit() {
        this.filters.userId = this.id;
        this.configureColumns();
    }

    public configureColumns() {
        if (!this.canEditFacilities) { this.columns.delete('canDelete'); }
        this.columns.delete('canAdd');
      }

    tblReceive(event) {
		if (event.value === true) { this.hasData = true; }
        else { this.hasData = false; }
	}

    resetFilters() {
		this.filters = Object.assign(this.filters, this.initFilters);
		this.reloadTable();
	}

    reloadTable() {
        this.facTable.ajaxData = { 
            userId: this.id
            , countyId: this.filters.countyId
            , businessArea: this.filterMap.businessArea
        };
		this.facTable?.ajaxReload();
    }

    removeFac(row : UserFacilityAgencyListVm) {
        if (!this.canEditFacilities) { return; }
        var source = "";
        var rowId: number;
        if (row.facilityType === "HEROS") {
            source = "api/Administration/Users/RemoveAgencyFromUser";
            rowId = row.userHEROSAgencyId;
        } else {
            this.ms.setSystemMessage("This facility does not have a type therefore it cannot be removed", "error");
            return;
        }

        this.httpClient.post(
            source
            , {}
            , { params: new HttpParams().set('id', rowId) }
        ).subscribe((sm: SystemMessage) => {
            if (!sm.isSuccessful) { this.ms.setSystemMessage(sm.message, 'error'); return; } 
            else { this.reload.emit(); }
        });
    }  

    removeAll() {
        const pcgTableInput = this.facTable.getPcgTableInput();
        const tableInput = JSON.stringify(pcgTableInput);

        this.httpClient.post('api/Administration/Users/RemoveAllFacilitiesAndAgenciesFromUser'
            , tableInput
            , { params: new HttpParams().set('userId', this.id) }
        ).subscribe(async (sm: SystemMessage) => {
            if (!sm.isSuccessful) { this.ms.setSystemMessage(sm.message, 'error'); return; } 
            else { this.reload.emit(); }
        });
    }
}
