<pcg-page-title [primaryTitle]='model?.name ?? "Create Custom Field"' [backBtnLink]='backUrl' [backParams]="backParams"
	backBtnTooltip='Return to {{backPageTitle}}'>
	<ng-content select="[pageActions]" pageActions></ng-content>
</pcg-page-title>

<div class='mat-tab-container'>
	<form [formGroup]="formGroup" (submit)='saveField()'>
		<fieldset [disabled]="!canEdit">
			<pcg-collapsible-divider header="Custom Field Options">
				<div class="row">
					<div class="col-md-12">
						<div class="activate">
							<p [class.text-danger]="!formGroup.value.isActive" [class.d-none]="formGroup.value.isActive"
								*ngIf="formGroup ">This custom field is deactivated.</p>
							<a *ngIf="formGroup.value.customFieldId!== 0" [class.btn-danger]="formGroup.value.isActive"
								[class.btn-save]="!formGroup.value.isActive" class="btn btn-sm"
								(click)="activateDeactivate(); formGroup.value.isActive = !formGroup.value.isActive;">
								{{ formGroup.value.isActive ? 'Deactivate' : 'Reactivate' }}
							</a>
							<input *ngIf="formGroup.value.customFieldId === 0" type="hidden"
								formControlName="isActive" />
						</div>
					</div>
				</div>

				<div class="row">
					<pcg-control-group class="col-md-4" label="Field Name">
						<input pcgControlRef formControlName="name" class="form-control" type="text" />
					</pcg-control-group>

					<pcg-control-group class="col-md-4" label="Tooltip">
						<input pcgControlRef formControlName="tooltip" class="form-control" type="text" />
					</pcg-control-group>
				</div>

				<div class="row">
					<pcg-control-group class="col-md-6" label="Control Type">
						<pcg-select pcgControlRef formControlName="customFieldType" placeholder='Select a Control Type'
							dataSource='api/Select/CustomFieldTypes'>
						</pcg-select>
					</pcg-control-group>

					<pcg-control-group class="col-md-6"
						[class.d-none]='model.customFieldType !== CustomFieldTypeEnum.TextBox' label="Mask">
						<pcg-select pcgControlRef formControlName="customFieldMask" placeholder='Select a Mask'
							dataSource='api/Select/CustomFieldMasks'>
						</pcg-select>
					</pcg-control-group>
				</div>

				<div *ngIf='model.customFieldType === CustomFieldTypeEnum.DropDown'>
					<div class='row'>
						<div class='col-md-3'>
							<pcg-control-group label='Option 1' class='required-field'>
								<input pcgControlRef formControlName="selectOption1" class="form-control" />
							</pcg-control-group>
						</div>

						<div class='col-md-3'>
							<pcg-control-group label='Option 2'>
								<input pcgControlRef formControlName="selectOption2" class="form-control" />
							</pcg-control-group>
						</div>

						<div class='col-md-3'>
							<pcg-control-group label='Option 3'>
								<input pcgControlRef formControlName="selectOption3" class="form-control" />
							</pcg-control-group>
						</div>

						<div class='col-md-3'>
							<pcg-control-group label='Option 4'>
								<input pcgControlRef formControlName="selectOption4" class="form-control" />
							</pcg-control-group>
						</div>
					</div>
					<br />

					<div class='row'>
						<div class='col-md-3'>
							<pcg-control-group label='Option 5'>
								<input pcgControlRef formControlName="selectOption5" class="form-control" />
							</pcg-control-group>
						</div>

						<div class='col-md-3'>
							<pcg-control-group label='Option 6'>
								<input pcgControlRef formControlName="selectOption6" class="form-control" />
							</pcg-control-group>
						</div>

						<div class='col-md-3'>
							<pcg-control-group label='Option 7'>
								<input pcgControlRef formControlName="selectOption7" class="form-control" />
							</pcg-control-group>
						</div>

						<div class='col-md-3'>
							<pcg-control-group label='Option 8'>
								<input pcgControlRef formControlName="selectOption8" class="form-control" />
							</pcg-control-group>
						</div>
					</div>
					<br />

					<div class='row'>
						<div class='col-md-3'>
							<pcg-control-group label='Option 9'>
								<input pcgControlRef formControlName="selectOption9" class="form-control" />
							</pcg-control-group>
						</div>

						<div class='col-md-3'>
							<pcg-control-group label='Option 10'>
								<input pcgControlRef formControlName="selectOption10" class="form-control" />
							</pcg-control-group>
						</div>
					</div>
				</div>
			</pcg-collapsible-divider>

			<pcg-collapsible-divider header="Validation">
				<div class='row'>
					<pcg-control-group class="col-md-4 col-lg-2 pt-4">
						<div class="d-flex align-items-center">
							<ui-switch class="mr-2" id="isRequired" pcgControlRef
								formControlName="isRequired"></ui-switch>
							<label class='for' for='isRequired'>Is Required?</label>
						</div>
					</pcg-control-group>

					<pcg-control-group *ngIf='model.customFieldType === CustomFieldTypeEnum.Number'
						class='col-md-4 col-lg-2' label='Min Value'>
						<input pcgControlRef formControlName="numericMin" class="form-control" type="number" />
					</pcg-control-group>

					<pcg-control-group *ngIf='model.customFieldType === CustomFieldTypeEnum.Number'
						class='col-md-4 col-lg-2' label='Max Value'>
						<input pcgControlRef formControlName="numericMax" class="form-control" type="number" />
					</pcg-control-group>

					<pcg-control-group *ngIf='model.customFieldType === CustomFieldTypeEnum.TextBox'
						class="col-md-4 col-lg-2 pt-4">
						<div class="d-flex align-items-center">
							<ui-switch class="mr-2" id="isEmail" pcgControlRef formControlName="isEmail"></ui-switch>
							<label class='for' for='isEmail'>Is Email?</label>
						</div>
					</pcg-control-group>

					<pcg-control-group *ngIf='model.customFieldType === CustomFieldTypeEnum.TextBox'
						class="col-md-3 col-lg-4" label="Regular Expression">
						<input pcgControlRef formControlName="regex" class="form-control" type="text" />
					</pcg-control-group>
				</div>

				<div class='row' *ngIf='model.customFieldType === CustomFieldTypeEnum.TextBox'>
					<pcg-control-group class='col-md-4 col-lg-2' label='Min Length'>
						<input pcgControlRef formControlName="stringLengthMin" class="form-control" type="number" />
					</pcg-control-group>

					<pcg-control-group class='col-md-4 col-lg-2' label='Max Length'>
						<input pcgControlRef formControlName="stringLengthMax" class="form-control" type="number" />
					</pcg-control-group>
				</div>
			</pcg-collapsible-divider>

			<pcg-fixed-buttons>
				<button type='submit' class="btn btn-save btn-lg">{{ id === 0 ? "Create" : "Save" }}</button>
			</pcg-fixed-buttons>
		</fieldset>
	</form>
</div>