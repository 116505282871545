import { Component, Input, OnInit, viewChild, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { OrganizationFacilityListVm } from 'app/shared/generated/Administration/Models/Organizations/OrganizationFacilityListVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { TableComponent } from 'app/shared/table/table.component';
import { OrgFacilityInventoriesComponent } from '../org-facility-inventories/org-facility-inventories.component';

@Component({
    selector: 'pcg-shared-organization-facility-list',
    templateUrl: './organization-facility-list.component.html',
    styleUrls: ['./organization-facility-list.component.scss'],
    standalone: false
})
export class OrganizationFacilityListComponent implements OnInit {

    @ViewChild(TableComponent, { static: false }) table: TableComponent<OrganizationFacilityListVm>;
    @ViewChild(OrgFacilityInventoriesComponent, { static: false }) orgFacilityInventories:  OrgFacilityInventoriesComponent;

    @Input() id: number;
    @Input() businessArea: BusinessAreaEnum;

    filters = { 
        id: null
        , isActive: true 
    };
    initFilters = Object.assign({}, this.filters);
    filterMap = { "isActive": "Is Active" };

    canEdit = false;
    canDelete = false;
    showBusinessArea = true;
    columns = OrganizationFacilityListVm.ColumnDefs;
    showEditOrganizationFacility = false;
    selectedFacilityId = 0;
    faIcon = { faExternalLink }

    constructor(
        private route: ActivatedRoute
        , public btnFilterService: ButtonFilterService
        , public sec: SecurityService
    ) { }

    ngOnInit() {
        this.filters.id = this.id;
        this.canEdit = this.sec.hasModuleAccess([this.businessArea], [PermissionRole.UserAdmin]);
        this.canDelete = this.sec.hasModuleAccess([BusinessAreaEnum.Admin], [PermissionRole.SystemAdmin]);
        this.initFilters = Object.assign({}, this.filters);
        this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters(); });

        //Check if directed here from SysInv Inventory List Screen edit button
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            if (params.has('facilityId')) {
                this.selectedFacilityId = parseInt(params.get('facilityId'), 10);
                this.showEditOrganizationFacility = true;
            }
        });
    }

    reloadTable() { this.table?.ajaxReload(); }

    resetFilters() {
        this.filters.id = this.id;
        this.filters.isActive = true;
        this.filters = Object.assign(this.filters, this.initFilters);
        this.table.hideShowColumn('isActive', FormatHelperService.GetIsNully(this.filters.isActive));
        this.table?.ajaxReload();
    }
    
    openFacility(row: OrganizationFacilityListVm) {
        this.selectedFacilityId = row.facilityId;
        this.showEditOrganizationFacility = !this.showEditOrganizationFacility;
    }

    hideEditOrganizationFacility() {
        this.selectedFacilityId = 0;
        this.showEditOrganizationFacility = !this.showEditOrganizationFacility;
    }
    
    canDeleteFacility = (): boolean => this.sec.hasModuleAccess(
        [ this.businessArea ]
        , [ PermissionRole.SystemAdmin ]
    );
}
