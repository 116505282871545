import { formatDate } from "@angular/common";
import {
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnInit,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PcgSelectComponent } from "@pcg/pcg-shared";

import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessageService } from "app/core/system-message/system-message-service";
import { validateForm } from "app/shared/form-elements/form-validateForm.function";
import { OrganizationAccountVm } from "app/shared/generated/Administration/Models/Organizations/OrganizationAccountVm";
import { GenericFilesVm } from "app/shared/generated/Models/GenericFilesVm";
import { FormatHelperService } from "app/shared/helpers/format-helper.service";
import { AccountRequestModalStateService } from "app/shared/state/account-request-modal-state.service";
import { ButtonFilterService } from "app/shared/table/table-top/button-filter/button-filter.service";
import { TableComponent } from "app/shared/table/table.component";

@Component({
    selector: "pcg-shared-organization-accounts",
    templateUrl: "./organization-accounts.component.html",
    styleUrls: ["./organization-accounts.component.scss"],
    standalone: false
})
export class OrganizationAccountsComponent implements OnInit {
	router = inject(ActivatedRoute);

	@ViewChild(TableComponent, { static: false }) table: TableComponent<OrganizationAccountVm>;
	@ViewChild("accountUpdateOptions", { static: true }) accountUpdateOptions: TemplateRef<any>;

	@Input() id: number;
	@Input() businessArea: BusinessAreaEnum;
	accountId?: number;

	columns = OrganizationAccountVm.ColumnDefs;
	newModel = new OrganizationAccountVm();
	formGroup = OrganizationAccountVm.Form;
	initFormGroup = OrganizationAccountVm.Form;

	filters = {
		id: null
		, isActive: true
	};
	initFilters = Object.assign({}, this.filters);
	filterMap = { isActive: "Is Active" };

	canEdit = false;
	canDelete = false;

	accountQueueModalRef: NgbModalRef | null;

	// Account Update properties
	isOpen = false;
	editClicked = false;
	deleteClicked = false;
	hasFile = false;
	clearFileClick = false;
	accountNameClick = false;
	expDateRequired: boolean = false;

	facilityFile: GenericFilesVm[] = [];
	maxFiles = 1;

	strAction = "Edit";

	saveBtnTooltip: string = "A change must be made to submit an update request.";
	saveBtnDisabled: boolean = true;

	constructor(
		public btnFilterService: ButtonFilterService
		, title: Title
		, public sec: SecurityService
		, public arms: AccountRequestModalStateService
		, private modalService: NgbModal
		, private sm: SystemMessageService
		, private cdr: ChangeDetectorRef
	) { title.setTitle("Organization Accounts"); }

	ngOnInit() {
		this.filters.id = this.id;

		this.canEdit = this.sec.hasModuleAccess(
			[this.businessArea],
			[
				PermissionRole.Manager
				, ...SecurityService.setMinRole(PermissionRole.User)
			]
		);
		this.canDelete = this.sec.hasModuleAccess(
			[BusinessAreaEnum.Admin],
			[PermissionRole.SystemAdmin]
		);
		this.initFilters = Object.assign({}, this.filters);
		this.btnFilterService.resetFilterData.subscribe(() => { this.resetFilters(); });

		// Initializes the component's ability to react to behavior changes
		this.reloadTablePromise();

		this.formGroup.patchValue(this.newModel);
		// Listen for formGroup changes
		this.formGroup.valueChanges.subscribe(() => { this.isSaveDisabled(); });
		this.accountId = parseInt(this.router.snapshot.paramMap.get('accountId'));
		if(this.accountId) this.getAccount(this.accountId);

	}

	resetFilters() {
		this.filters.id = this.id;
		this.filters.isActive = true;
		this.filters = Object.assign(this.filters, this.initFilters);
		this.table.hideShowColumn("isActive", FormatHelperService.GetIsNully(this.filters.isActive));
		this.table?.ajaxReload();
	}

	getAccount(id: number) {
		let sub = this.table.tableReceive.subscribe(
			r => {
				let row = this.table.data.filter(d => d.facilityAccountNameId == id)[0];
				this.actionClick(row);
				this.editClick(null);
				this.formGroup.controls.facilityAccountNameId.setValue(id);
				sub.unsubscribe();
			}
		);
	}

	/** When called, this method sets up a subscription that will
	 * update the table only if an active modal ref is stored in state.
	 * NOTE: Leave formatting of code alone.
	 */
	reloadTablePromise() {
		this.cdr.detectChanges();
		// Reloads table whenever user closes table.
		this.arms.getModalRefObservable().subscribe((modalRef) => {
			this.accountQueueModalRef = modalRef;
			if (this.accountQueueModalRef) {
				this.accountQueueModalRef.result
					// Called when user approves or denies
					.then(() => { this.table.ajaxReload(); })
					// Called when user closes the modal
					.catch((e) => { this.table.ajaxReload(); });
			}
		});
	}

	openFile(filePath : string) {
		// Change route if document was uploaded in PFS
		if (filePath.includes('uploads\\facilityAccounts')) { window.open(filePath, "_blank"); }
		else { window.open(window.location.origin + "/" + filePath, "_blank"); }	
	}

	actionClick(row: OrganizationAccountVm = null) {
		let isNew = row == null;
		this.setModelValues(row);
		if (!isNew) { this.modalService.open(this.accountUpdateOptions, { animation: false }); }
		else {
			this.newModel.actionsSelected = true;
      		this.newModel.isActive = true;
			this.formGroup.patchValue(this.newModel);
		}
		// Set initFormGroup values for comparison
		this.initFormGroup.reset();
		this.initFormGroup.patchValue(this.newModel);
	}

	setModelValues(row: OrganizationAccountVm = null) {
		this.newModel = row ?? new OrganizationAccountVm();
        this.newModel.organizationId = this.id;
		this.hasFile = FormatHelperService.GetIsNullyOrWhitespace(row?.uploadFilePath) 
			? false 
			: true;
		this.formGroup.reset();
		this.formGroup.patchValue(this.newModel);
		
		this.isOpen = this.editClicked 
			= this.deleteClicked 
			= this.clearFileClick 
			= this.accountNameClick 
			= this.expDateRequired 
			= false;
	}

	cancel() {
		this.setModelValues();
		this.modalService.dismissAll();
	}

	editClick(select: PcgSelectComponent) {
		this.newModel.actionsSelected = true;
		this.formGroup.patchValue(this.newModel);
		this.initFormGroup.patchValue(this.newModel);
		this.setExpDateRequired(select);
		this.editClicked = true;
		this.modalService.dismissAll();
	}

	setExpDateRequired(select: PcgSelectComponent) {
		if (select?.additionalData["isExpDateRequired"]) {
			this.formGroup.controls.expirationDate.enable();
			this.expDateRequired = true;
		} else {
			this.expDateRequired = false;
		}

		this.expDateRequired 
			? this.formGroup.controls.expirationDate.addValidators([Validators.required]) 
			: this.formGroup.controls.expirationDate.removeValidators([Validators.required]);

		this.formGroup.controls.expirationDate.updateValueAndValidity();
	}

	deleteClick() { this.deleteClicked = true; }

	performDelete() {
		this.sm.getHttpObservable(this, `api/Administration/OrganizationAccount/Delete/${this.newModel.organizationAccountId}`)
			.subscribe(() => {
				this.table.ajaxReload();
				this.modalService.dismissAll();
			}
		);
	}

	clearFile() {
		this.clearFileClick = true;
		this.formGroup.controls.uploadFilePath.patchValue(null);
		this.formGroup.controls.file.patchValue(null);
		this.hasFile = false;
	}

	editSave() {
		validateForm(this.formGroup);
		if (this.canEdit === true) {
			this.sm.getHttpObservable(this, `api/Administration/OrganizationAccount/Save`, this.formGroup)
				.subscribe((msg) => {
					if (msg.isSuccessful) {
						this.newModel.actionsSelected = false;
						this.cdr.detectChanges();
					}
				}
			);
		}
	}

	//Prevents actions dropdown from closing when facility account name select is changed by temporarily
	//flipping accountNameClick bool
	accountNameClicked() {		
		this.accountNameClick = !this.accountNameClick;
		setTimeout(() => { this.accountNameClick = !this.accountNameClick; }, 50);
	}

	updateExpStr() {
		let expDateStr = formatDate(
			this.formGroup.controls.expirationDate.value
			, "M/dd/yyyy"
			, "en-US"
		);
		this.formGroup.controls.expirationDateStr.patchValue(expDateStr);
	}

	isSaveDisabled() {
		if (!FormatHelperService.GetIsNullyOrWhitespace(this.formGroup.controls.expirationDate.value))
			this.initFormGroup.controls.expirationDate.patchValue(
				formatDate(
					this.formGroup.controls.expirationDate.value
					, "yyyy-MM-dd"
					, "en-US"
			));
		this.saveBtnDisabled = JSON.stringify(this.formGroup.value) === JSON.stringify(this.initFormGroup.value); 
		this.saveBtnTooltip = this.saveBtnDisabled ? "A change must be made to submit an update request." : null;
	}
}
