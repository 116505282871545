import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { OrganizationAccountUpdateComponent } from '../organizations/organization-account-update/organization-account-update.component';
import { CiDashboardActionsComponent } from './ci-dashboard-actions/ci-dashboard-actions.component';
import { NotificationNavComponent } from './notification-nav/notification-nav.component';
import { OrganizationAccountUpdateNavComponent } from './organization-account-update-nav/organization-account-update-nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TopClickNavComponent } from './top-click-nav/top-click-nav.component';
import { TopSecondaryNavComponent } from './top-secondary-nav/top-secondary-nav.component';
import { WorkstationBannerComponent } from './workstation-banner/workstation-banner.component';
import { MatSideNavComponent } from './mat-side-nav/mat-side-nav.component';
import { MaterialsModule } from '../material.module';

@NgModule({
	declarations: [ 
		TopClickNavComponent
		, TopSecondaryNavComponent
		, SideNavComponent
		, NotificationNavComponent
		, WorkstationBannerComponent
		, CiDashboardActionsComponent
		, OrganizationAccountUpdateNavComponent
		, OrganizationAccountUpdateComponent
		, MatSideNavComponent
	],
	imports: [ 
		RouterModule
		, CommonModule
		, FormsModule
		, ReactiveFormsModule
		, FontAwesomeModule
		, NgbTooltipModule
		, FormElementsModule
		, UiSwitchModule
		, MaterialsModule
	],
	exports: [
		TopClickNavComponent
		, TopSecondaryNavComponent
		, SideNavComponent
		, NotificationNavComponent
		, FontAwesomeModule
		, WorkstationBannerComponent
		, CiDashboardActionsComponent
		, OrganizationAccountUpdateComponent
		, MatSideNavComponent
	]
})
export class NavigationModule { }
