<div 
	*ngIf="!hasFacilities" 
	class="alert alert-danger text-center"
>
	In order to see this list you must be associated to at least one HEROS Agency or HOMER Facility. 
	Please contact an administrator for assistance.
</div>

<pcg-table-filter id='facilityListFilters'>
    <div (change)="reloadTable();">
        <div class="additional-info mt-1">
            <div class='mt-2 filter-width'>
                <label class='for'>Is Active?</label>
				<mat-button-toggle-group 
					[(ngModel)]='filters.isActive' 
					[value]='filters.isActive' 
					(change)="table.ajaxReload();"
				>
					<mat-button-toggle 
						class="flex-fill" 
						[value]="true"
					>Yes</mat-button-toggle>
					<mat-button-toggle 
						class="flex-fill mx-2" 
						value="false"
					>No</mat-button-toggle>
					<mat-button-toggle 
						class="flex-fill" 
						value="null"
					>All</mat-button-toggle>
				</mat-button-toggle-group>
            </div>

			<div 
				*ngIf="showBusinessArea" 
				class='mt-2 filter-width'
			>
				<label class='for'>Business Area</label>
				<pcg-select 
					[multiple]='false' 
					[(ngModel)]='filters.businessArea' 
					autocomplete='off'
					dataSource='api/Select/BusinessAreasForFacilities' 
					placeholder='Select...'
				></pcg-select>
			</div>

			<div class='mt-2 filter-width'>
				<label class='for'>Medical Director</label>
				<pcg-select 
					[multiple]='false' 
					[(ngModel)]='filters.medicalDirectorId' 
					autocomplete='off'
					dataSource='api/Select/MedicalDirectors' 
					placeholder='Select...'
					class="select"
				></pcg-select>
			</div>

			<div class='mt-2 filter-width'>
				<label class='for'>Organization</label>
				<pcg-select 
					[multiple]='false' 
					[(ngModel)]='filters.organizationId' 
					autocomplete='off'
					dataSource='api/Select/UserOrganizations' 
					placeholder='Select...'
					class="select"
				></pcg-select>
			</div>
        </div>
    </div>
</pcg-table-filter>

<pcg-table 
	#table 
	[ajaxData]='filters' 
	[multipleSearch]='false' 
	[pcgSort]='[[ "facilityName", "asc" ]]' 
	[filterMap]="filterMap"
	dataSource='api/Administration/Facility/GetFacilityList' 
	[columnDefs]='columns' 
	filterId="facilityListFilters"
	[canShowHideColumns]="true" 
	[canExactMatchSearch]="false" 
	[canResetFilters]="true"
	[emptyFilters]="initFilters"
	(tableReceive)="onTableReturn($event.value)"
>

	<ng-container matColumnDef="facilityName">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('facilityName').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>
			<pcg-edit-link 
				[hasAccess]='canEditFacility()' 
				[url]="editFacilityLink() + row.facilityId"
			>{{ row.facilityName }}</pcg-edit-link>
		</td>
	</ng-container>

	
	<ng-container matColumnDef="address">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('address').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.address }}, {{ row.city }}, {{row.state}} {{row.zip}}</td>
	</ng-container>

	<ng-container matColumnDef="facilityIdentifier">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('facilityIdentifier').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.facilityIdentifier }}</td>
	</ng-container>

	<ng-container matColumnDef="city">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('city').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.city }}</td>
	</ng-container>

	<ng-container matColumnDef="county">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('county').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.county }}</td>
	</ng-container>

	<ng-container matColumnDef="isActive">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('isActive').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.isActive }}</td>
	</ng-container>

	<ng-container matColumnDef="canDelete">
		<th 
			class='text-center' 
			mat-header-cell 
			*matHeaderCellDef
		>Delete</th>
		<td 
			class='text-center' 
			mat-cell 
			*matCellDef='let row'
		>
			<pcg-delete-button 
				*ngIf="canDelete" 
				[table]='table' 
				[confirmMessage]="'Are you sure you want to delete this Facility?'"
				url='api/Administration/Facility/DeleteFacility' 
				[id]='row.facilityId'
			></pcg-delete-button>
		</td>
	</ng-container>

</pcg-table>
