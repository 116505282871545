import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class MetabolicFormulaInsuranceInfoVm {
	insuranceInfoId: number;
	insuranceType?: any;
	insuranceTypeStr?: string;
	insuranceName?: string;
	groupNumber?: string;
	insuranceNumber?: string;
	dateInsuranceClaim?: string;
	singleClaimOutcome?: any;
	singleClaimOutcomeStr?: string;
	singleClaimOutcomeComment?: string;
	friendlyId?: string;
	stepName?: string;
	canEdit?: boolean;
	orderId: number;
	stepTitle?: string;
	stepDescription?: string;
	isActive: boolean;
	isComplete: boolean;
	orderIndex: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'insuranceInfoId': new UntypedFormControl(0, [...(validators['insuranceInfoId'] ?? []), ...[Validators.required]]),
			'insuranceType': new UntypedFormControl(null, [...(validators['insuranceType'] ?? []), ...[Validators.required]]),
			'insuranceTypeStr': new UntypedFormControl(null, [...(validators['insuranceTypeStr'] ?? []), ...[]]),
			'insuranceName': new UntypedFormControl(null, [...(validators['insuranceName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'groupNumber': new UntypedFormControl(null, [...(validators['groupNumber'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'insuranceNumber': new UntypedFormControl(null, [...(validators['insuranceNumber'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'dateInsuranceClaim': new UntypedFormControl(null, [...(validators['dateInsuranceClaim'] ?? []), ...[Validators.required]]),
			'singleClaimOutcome': new UntypedFormControl(null, [...(validators['singleClaimOutcome'] ?? []), ...[Validators.required]]),
			'singleClaimOutcomeStr': new UntypedFormControl(null, [...(validators['singleClaimOutcomeStr'] ?? []), ...[]]),
			'singleClaimOutcomeComment': new UntypedFormControl(null, [...(validators['singleClaimOutcomeComment'] ?? []), ...[Validators.required, Validators.maxLength(500)]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'stepName': new UntypedFormControl(null, [...(validators['stepName'] ?? []), ...[]]),
			'canEdit': new UntypedFormControl(null, [...(validators['canEdit'] ?? []), ...[]]),
			'orderId': new UntypedFormControl(0, [...(validators['orderId'] ?? []), ...[Validators.required]]),
			'stepTitle': new UntypedFormControl(null, [...(validators['stepTitle'] ?? []), ...[]]),
			'stepDescription': new UntypedFormControl(null, [...(validators['stepDescription'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isComplete': new UntypedFormControl(false, [...(validators['isComplete'] ?? []), ...[]]),
			'orderIndex': new UntypedFormControl(0, [...(validators['orderIndex'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): MetabolicFormulaInsuranceInfoVmFromFg {
		return new MetabolicFormulaInsuranceInfoVmFromFg(fg);
	}
}

export class MetabolicFormulaInsuranceInfoVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get insuranceInfoId(): number {
		return this.fg.get('insuranceInfoId').value;
	}
	set insuranceInfoId(val) {
		this.fg.get('insuranceInfoId').setValue(val);
	}

	get insuranceType(): any {
		return this.fg.get('insuranceType').value;
	}
	set insuranceType(val) {
		this.fg.get('insuranceType').setValue(val);
	}

	get insuranceTypeStr(): string {
		return this.fg.get('insuranceTypeStr').value;
	}
	set insuranceTypeStr(val) {
		this.fg.get('insuranceTypeStr').setValue(val);
	}

	get insuranceName(): string {
		return this.fg.get('insuranceName').value;
	}
	set insuranceName(val) {
		this.fg.get('insuranceName').setValue(val);
	}

	get groupNumber(): string {
		return this.fg.get('groupNumber').value;
	}
	set groupNumber(val) {
		this.fg.get('groupNumber').setValue(val);
	}

	get insuranceNumber(): string {
		return this.fg.get('insuranceNumber').value;
	}
	set insuranceNumber(val) {
		this.fg.get('insuranceNumber').setValue(val);
	}

	get dateInsuranceClaim(): string {
		return this.fg.get('dateInsuranceClaim').value;
	}
	set dateInsuranceClaim(val) {
		this.fg.get('dateInsuranceClaim').setValue(val);
	}

	get singleClaimOutcome(): any {
		return this.fg.get('singleClaimOutcome').value;
	}
	set singleClaimOutcome(val) {
		this.fg.get('singleClaimOutcome').setValue(val);
	}

	get singleClaimOutcomeStr(): string {
		return this.fg.get('singleClaimOutcomeStr').value;
	}
	set singleClaimOutcomeStr(val) {
		this.fg.get('singleClaimOutcomeStr').setValue(val);
	}

	get singleClaimOutcomeComment(): string {
		return this.fg.get('singleClaimOutcomeComment').value;
	}
	set singleClaimOutcomeComment(val) {
		this.fg.get('singleClaimOutcomeComment').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get stepName(): string {
		return this.fg.get('stepName').value;
	}
	set stepName(val) {
		this.fg.get('stepName').setValue(val);
	}

	get canEdit(): boolean {
		return this.fg.get('canEdit').value;
	}
	set canEdit(val) {
		this.fg.get('canEdit').setValue(val);
	}

	get orderId(): number {
		return this.fg.get('orderId').value;
	}
	set orderId(val) {
		this.fg.get('orderId').setValue(val);
	}

	get stepTitle(): string {
		return this.fg.get('stepTitle').value;
	}
	set stepTitle(val) {
		this.fg.get('stepTitle').setValue(val);
	}

	get stepDescription(): string {
		return this.fg.get('stepDescription').value;
	}
	set stepDescription(val) {
		this.fg.get('stepDescription').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isComplete(): boolean {
		return this.fg.get('isComplete').value;
	}
	set isComplete(val) {
		this.fg.get('isComplete').setValue(val);
	}

	get orderIndex(): number {
		return this.fg.get('orderIndex').value;
	}
	set orderIndex(val) {
		this.fg.get('orderIndex').setValue(val);
	}
}
