<pcg-table-filter id="tableFilters">
    <div (change)="table.ajaxReload()">
        <div class="additional-info mt-1">
            <div class='mt-2 filter-width'>
                <label class='for'>Is Active?</label>
                <mat-button-toggle-group 
                    [(ngModel)]='filters.isActive' 
                    [value]='filters.isActive' 
                    (change)="table.ajaxReload();"
                >
                    <mat-button-toggle 
                    class="flex-fill" 
                    [value]="true"
                    >Yes</mat-button-toggle>
                    <mat-button-toggle 
                    class="flex-fill mx-2" 
                    value="false"
                    >No</mat-button-toggle>
                    <mat-button-toggle 
                    class="flex-fill" 
                    value="null"
                    >All</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

        </div>
    </div>
</pcg-table-filter>

<pcg-table
    #table 
    dataSource="api/Facility/Inventory/GetInventories"
    [ajaxData]="filters"
    [multipleSearch]="false"
    [pcgSort]='[[ "", "" ]]'
    [columnDefs]="columns"
    filterId="tableFilters"
    [filterMap]="filterMap"
>
    <ng-container matColumnDef="name">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('name').displayName }}
        </th>
        <td mat-cell *matCellDef="let row">
            <span>
                {{ row.name }}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('type').displayName }}
        </th>
        <td mat-cell *matCellDef="let row">
            {{ parseEnum(row.type) }}
        </td>   
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('active').displayName }}
        </th>
        <td mat-cell *matCellDef="let row">
            {{ row.active ? "Yes" : "No" }}
        </td>   
    </ng-container>

    <ng-container matColumnDef="canDelete">
        <th class="text-center" mat-header-cell *matHeaderCellDef>
            {{ columns.get('canDelete').displayName }}
        </th>

        <td class='text-center' mat-cell *matCellDef='let row'>
			<pcg-delete-button 
				[table]='table' 
				[confirmMessage]="'Are you sure you want to delete this inventory?'"
				[id]='row.id'
				url='api/Facility/Inventory/Delete' 
			></pcg-delete-button>
		</td>
    </ng-container>
</pcg-table>