<div class="row align-items-center">
	<!-- User Information -->
	<pcg-control-group 
		class="col-md-3" 
		label="Username" 
		[formGroup]="formGroup"
	>
		<input
			pcgControlRef
			formControlName="userName"
			class="form-control"
			type="text"
			(change)="saveUsername()"
		/>
	</pcg-control-group>

	<div class="col-md-3">
		@if (
			isCurrentUser
			&& this.orgLoginType() != loginTypeEnum.SSO
		) {
			<input
				type="button"
				value="Change Password"
				id="passwordBtn"
				class="btn btn-save btn-sm"
				(click)="openChangePasswordModal()"
			/>
		} @else if (
			!isCurrentUser 
			&& canResetPassword
			&& this.orgLoginType() != loginTypeEnum.SSO
		) {
			<input 
				type="button" 
				value="Reset Password" 
				id="passwordBtn"
				class="btn btn-save btn-sm"
				ngbTooltip="Send user a reset password email with their username and the ability to choose a new password."
				(click)="resetPassword(false)" 
			/>
		}		
	</div>
	
	<!-- PIN -->
	@if (
	 	isCurrentUser 
		|| canManageUsers
	) {
		<div class="col-md-3">
			<label class="for">
				PIN
				@if (pin !== '') {
					<fa-icon 
						[icon]="faIconName.faCheck" 
						class="fa-sm" 				
					></fa-icon>
				}
			</label>
			<button
				class="security-btn"
				(click)="
					openPINModal(); 
					$event.preventDefault();
				"
			>
				<fa-icon 
					[icon]="faIconName.faGrid" 
					class="fa-lg"
				></fa-icon>
				{{ pin === '' ? 'Add' : 'Change' }} PIN
			</button>
		</div>
	}	

	<!-- Fingerprint -->
	@if (
		isCurrentUser 
		|| canAccessOtherUsersFingerprints
	) {
		<div class="col-md-3">
			<pcg-user-fingerprint-authentication [userPin]="pin"></pcg-user-fingerprint-authentication>
		</div>
	}
</div>

<br /><br class="hide-on-md" />

<div class="row align-items-center">
	<pcg-control-group 
		class="col-md-2 form-check-label" 
		label="Is Internal BPHP User?"
		labelTooltip="Determines if the user is an Internal BPHP user or an External CHD/HEROS/Facility User.
		External users can create orders while internal users can fulfill orders."
		[formGroup]="formGroup"
	>
		@if (isSysAdmin) {
			<ui-switch 
				pcgControlRef 
				#isInternalBphpUser 
				color="#007bff"  
				defaultBgColor="#EEE"
				class="ml-2 mt-2" 
				size="small" 
				formControlName="isInternalBphpUser"
				(change)="setBPHPuser()"
			></ui-switch>
		} @else { <span>{{ isInternalBphpUserFg }}</span> }
	</pcg-control-group>

	<pcg-control-group 
		class="col-md-2 form-check-label" 
		label="Inactitvity Timeout?"
		labelTooltip="Determines if the user will be automatically logged out after a set time on information sensitive pages."
		[formGroup]="formGroup"
	>
		@if (isSysAdmin) {
			<ui-switch 
				pcgControlRef 
				#inactivityTimeout 
				color="#007bff"  
				defaultBgColor="#EEE"
				class="ml-2 mt-2" 
				size="small" 
				formControlName="inactivityTimeout"
				(change)="setInactivityTimeout()"
			></ui-switch>
		} @else { <span>{{ inactivityTimeoutFg }}</span> }
	</pcg-control-group>
</div>

<br /><br class="hide-on-md" />

<div class="row align-items-center">
	<pcg-control-group 
		class="col-md-6" 
		label="Main Organization (SSO)" 
		[formGroup]="formGroup"
		labelTooltip="The user's main organization they are associated to. (For SSO purposes)"
	>
		@if (isSysAdmin) {
			<pcg-select 
				#orgs
				[multiple]="false"
				placeholder="Select..."
				[sendFormData]="true"
				pcgControlRef
				[dataSource]="'api/Select/OrganizationsByUser?userId=' + id"
				formControlName="organizationId" 
				(change)="saveMainOrg()"
			></pcg-select>
		} @else { <span>{{ editUserVm.organizationName }}</span> }
	</pcg-control-group>
</div>

<br /><br class="hide-on-md" />

<!-- MFA -->
<label class="for">
	Multi-factor Authentication
	@if (hasMFADevice) {
		<fa-icon 
			[icon]="faIconName.faCheck" 
			class="fa-sm"
		></fa-icon>
	}
</label>

<pcg-page-info>Physical authentication devices which are U2F/FIDO compatible can be added here.</pcg-page-info>

<div id="actionElements">
	<a 
		class="btn btn-save btn-sm inline-add-btn" 
		(click)="openMultiFactorDeviceModal()"
	>Register MFA Device</a>
</div>

<pcg-table
	#credentialTable
	[ajaxData]="{ userId: id }"
	[serverSide]="false"
	[pcgSort]="[[ 'displayName', 'asc' ]]"
	dataSource="api/Administration/Users/GetCredentialList"
	[columnDefs]="credColumns"
>

	<ng-container matColumnDef="displayName">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>Display Name</th>
		<td 
			mat-cell 
			*matCellDef="let row"
		>{{ row.displayName }}</td>
	</ng-container>

	<ng-container matColumnDef="dateGenerated">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>Date Generated</th>
		<td 
			mat-cell 
			*matCellDef="let row"
		>{{ row.dateGeneratedFormatted }}</td>
	</ng-container>

	<ng-container matColumnDef="delete">
		<th 
			class="text-center" 
			mat-header-cell 
			*matHeaderCellDef
		>Delete</th>
		<td 
			class="text-center" 
			mat-cell 
			*matCellDef="let row"
		>
			<button 
				(click)="deleteRecord({ userId: id, id: row.credentialId })" 
				class="delete"
			>
				<svg 
					version="1.1" 
					xmlns="http://www.w3.org/2000/svg" 
					xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 300 300"
				>
					<g id="layer1">
						<path 
							d="M 100,60 L 60,100 L 230,270 L 270,230 L 100,60 z" 
							id="path4950"
							style="fill:#e00;fill-opacity:1;" 
						/>
						<path 
							d="M 60,230 L 230,60 L 270,100 L 100,270 L 60,230 z" 
							id="path4952"
							style="fill:#e00;stroke-opacity:1" 
						/>
					</g>
				</svg>
			</button>
		</td>
	</ng-container>

</pcg-table>

<ng-template 
	#pinModal 
	let-modal
>
	<pcg-register-pin-modal 
		(emitPin)="setPin($event)" 
		(dismiss)="dismissPinModal()"
	></pcg-register-pin-modal>
</ng-template>