<h1>{{ title.getTitle() }}</h1>
<hr />

<pcg-table 
	[canGlobalSearch]='false' 
	[multipleSearch]='false' 
	[pcgSort]='[[ "goLiveDateString", "desc" ]]' 
	dataSource='api/ReleaseNotes/GetWebReleaseNotesList' 
	[columnDefs]='columns'
>

	<ng-container matColumnDef='versionNumber'>
		<th 
			class='text-center' 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('versionNumber').displayName }}</th>
		<td 
			class='text-center' 
			mat-cell 
			*matCellDef='let row'
		>{{ row.versionNumber }}</td>
	</ng-container>

	<ng-container matColumnDef='goLiveDateString'>
		<th 
			class='text-center' 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('goLiveDateString').displayName }}</th>
		<td 
			class='text-center' 
			mat-cell 
			*matCellDef='let row'
		>{{ row.goLiveDateString }}</td>
	</ng-container>

	<ng-container matColumnDef='releaseNotes'>
		<th 
			class='text-center' 
			mat-header-cell 
			*matHeaderCellDef
		>{{ columns.get('releaseNotes').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row' 
			class="trix-content" 
			[innerHTML]='row.releaseNotes'
		></td>
	</ng-container>

</pcg-table>