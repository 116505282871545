import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ButtonFilterService } from '../button-filter/button-filter.service';

@Component({
    selector: 'pcg-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
    standalone: false
})
export class TableFilterComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() id: string;

    @ViewChild('filter', { static: false }) filter: ElementRef;
    @ViewChild('filterBody', { static: false }) filterBody: ElementRef;

    public element: any;
    isVisible = false;

    @HostListener('window:keyup', ['$event'])
    @HostListener('window:change', ['$event']) onChange() { this.positionFilter(); }
    @HostListener('window:resize', ['$event']) onResize() { this.close(); }

    constructor(
        @Inject(DOCUMENT) private document: Document
        , private buttonFilterService: ButtonFilterService
        , el: ElementRef
    ) { this.element = el.nativeElement; }

    ngOnInit(): void {
        // Ensure id attribute exists
        // Add self (this modal instance) to the modal service so it's accessible from controllers
        if (this.id) { this.buttonFilterService.addFilter(this); }
    }

    ngAfterViewInit(): void {
        // Add filter component to DOM after pcg-root
        document.body.firstElementChild.appendChild(this.element);

        // Close modal on background click
        window.addEventListener('click', (el: Event) => {
            // Classes associated to the target element
            var classes = el.target['className']?.toString();
            // If filters are open
            if (this.isVisible) {
                if (classes === 'pcg-table-filter-overlay') { this.close(); } // If target element is filter overlay, close filter
                if (classes.includes('filter-reset-btn')) // If filter button is click, adjust filter position after page is updated,
                    setTimeout(() => { this.positionFilter(); }, 100);
            }
        });
    }

    // Open modal
    open(): void {
        this.isVisible = true;
        this.positionFilter();
    }

    // Make sure filters are lined up with the filter toggle button
    positionFilter() {
        // Position and dimensions of the filter dropdown button
        const filterElements : NodeListOf<Element> = this.document.querySelectorAll('#pcg-button-filter');
        const element = this.getVisibleFilterButton(filterElements);
        const rect: DOMRect = element.getBoundingClientRect();
        this.filterBody.nativeElement.style.top = `${parseInt((rect.bottom + 1).toString())}px`;
        this.filterBody.nativeElement.style.left = `${parseInt((rect.left).toString())}px`;
    }

    getVisibleFilterButton(elements : NodeListOf<Element>) : Element {
        let i = 0;
        elements.forEach((el, index) => { if (el.clientHeight != 0 && el.clientWidth != 0 ) { i = index; } });

        return elements.item(i);
    }
    // Close modal
    close() { this.isVisible = false; }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.buttonFilterService.removeFilter(this.id);
        this.element.remove();
    }
}