import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditFacilityCiDetailVm {
	facilityAccountInfoId: number;
	facilityId: number;
	facilityName?: string;
	ciPrimaryContactIds?: number[];
	ciPrimaryContactNames?: string;
	facilityColor?: string;
	accountCode?: string;
	org?: string;
	eo?: string;
	oca?: string;
	bfObjCode?: string;
	bfCategory?: string;
	interAgencyAgreementNumber?: string;
	canGenerateCiInvoices: boolean;
	friendlyId?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'facilityAccountInfoId': new UntypedFormControl(0, [...(validators['facilityAccountInfoId'] ?? []), ...[Validators.required]]),
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'facilityName': new UntypedFormControl(null, [...(validators['facilityName'] ?? []), ...[]]),
			'ciPrimaryContactIds': new UntypedFormControl([], [...(validators['ciPrimaryContactIds'] ?? []), ...[]]),
			'ciPrimaryContactNames': new UntypedFormControl(null, [...(validators['ciPrimaryContactNames'] ?? []), ...[]]),
			'facilityColor': new UntypedFormControl(null, [...(validators['facilityColor'] ?? []), ...[Validators.maxLength(50)]]),
			'accountCode': new UntypedFormControl(null, [...(validators['accountCode'] ?? []), ...[Validators.maxLength(50)]]),
			'org': new UntypedFormControl(null, [...(validators['org'] ?? []), ...[Validators.maxLength(50)]]),
			'eo': new UntypedFormControl(null, [...(validators['eo'] ?? []), ...[Validators.maxLength(50)]]),
			'oca': new UntypedFormControl(null, [...(validators['oca'] ?? []), ...[Validators.maxLength(50)]]),
			'bfObjCode': new UntypedFormControl(null, [...(validators['bfObjCode'] ?? []), ...[Validators.maxLength(50)]]),
			'bfCategory': new UntypedFormControl(null, [...(validators['bfCategory'] ?? []), ...[Validators.maxLength(50)]]),
			'interAgencyAgreementNumber': new UntypedFormControl(null, [...(validators['interAgencyAgreementNumber'] ?? []), ...[Validators.maxLength(50)]]),
			'canGenerateCiInvoices': new UntypedFormControl(false, [...(validators['canGenerateCiInvoices'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditFacilityCiDetailVmFromFg {
		return new EditFacilityCiDetailVmFromFg(fg);
	}
}

export class EditFacilityCiDetailVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get facilityAccountInfoId(): number {
		return this.fg.get('facilityAccountInfoId').value;
	}
	set facilityAccountInfoId(val) {
		this.fg.get('facilityAccountInfoId').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get facilityName(): string {
		return this.fg.get('facilityName').value;
	}
	set facilityName(val) {
		this.fg.get('facilityName').setValue(val);
	}

	get ciPrimaryContactNames(): string {
		return this.fg.get('ciPrimaryContactNames').value;
	}
	set ciPrimaryContactNames(val) {
		this.fg.get('ciPrimaryContactNames').setValue(val);
	}

	get facilityColor(): string {
		return this.fg.get('facilityColor').value;
	}
	set facilityColor(val) {
		this.fg.get('facilityColor').setValue(val);
	}

	get accountCode(): string {
		return this.fg.get('accountCode').value;
	}
	set accountCode(val) {
		this.fg.get('accountCode').setValue(val);
	}

	get org(): string {
		return this.fg.get('org').value;
	}
	set org(val) {
		this.fg.get('org').setValue(val);
	}

	get eo(): string {
		return this.fg.get('eo').value;
	}
	set eo(val) {
		this.fg.get('eo').setValue(val);
	}

	get oca(): string {
		return this.fg.get('oca').value;
	}
	set oca(val) {
		this.fg.get('oca').setValue(val);
	}

	get bfObjCode(): string {
		return this.fg.get('bfObjCode').value;
	}
	set bfObjCode(val) {
		this.fg.get('bfObjCode').setValue(val);
	}

	get bfCategory(): string {
		return this.fg.get('bfCategory').value;
	}
	set bfCategory(val) {
		this.fg.get('bfCategory').setValue(val);
	}

	get interAgencyAgreementNumber(): string {
		return this.fg.get('interAgencyAgreementNumber').value;
	}
	set interAgencyAgreementNumber(val) {
		this.fg.get('interAgencyAgreementNumber').setValue(val);
	}

	get canGenerateCiInvoices(): boolean {
		return this.fg.get('canGenerateCiInvoices').value;
	}
	set canGenerateCiInvoices(val) {
		this.fg.get('canGenerateCiInvoices').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}
}
