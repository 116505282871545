import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { GenericOrderCommentVm } from 'app/shared/generated/Models/GenericOrder/GenericOrderCommentVm';
import { GenericOrderDetailsVm } from 'app/shared/generated/Models/GenericOrder/GenericOrderDetailsVm';
import { CiOrderInfoVm } from 'app/shared/generated/CIRepack/Models/CiRepackOrder/CiOrderInfoVm';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';

@Component({
    selector: 'pcg-shared-comments-modal',
    templateUrl: './comments-modal.component.html',
    styleUrls: ['./comments-modal.component.scss'],
    standalone: false
})
export class SharedCommentsModalComponent implements OnInit, OnDestroy {

    @Input() orderId: number;
    @Input() businessArea: BusinessAreaEnum;
    @Input() modal: any;
    @Input() GenericOrder: GenericOrderDetailsVm = new GenericOrderDetailsVm();
	@Input() CiOrder: CiOrderInfoVm = new CiOrderInfoVm();
    @Output() updateCommentCount = new EventEmitter<number>();

	user: UserJsVm;
	formGroup = GenericOrderCommentVm.Form;
  	canEdit: boolean;
	subscriptions = new Subscription();
	area: string; 
    controller: string; 
    securityString: string;   
	isMetabolic: boolean=false;
    
    constructor(private sec: SecurityService) { }

    ngOnInit() {
        this.subscriptions.add(this.sec.user$.subscribe(user => { this.user = user; }));
        if (this.businessArea === BusinessAreaEnum.CI) {
			this.canEdit = true;
			this.area = 'CIRepack';
			this.controller = 'CiOrder';
		} else if (this.businessArea === BusinessAreaEnum.MetabolicFormula) {
			this.area = "MetabolicFormula";
            this.controller = 'MetabolicFormula';
            this.securityString = "Pku";
			this.isMetabolic=true;
			this.canEdit = this.sec.hasModuleAccess(
				[ BusinessAreaEnum.MetabolicFormula ]
				, SecurityService.setMinRole(PermissionRole.User)
			);
		}	
	}

	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
