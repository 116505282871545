<div *ngIf="isScan" class="app-wrapper">

    <div class="scan-header" *ngIf="showHeader">
        <span *ngIf="errorMessage; else title">{{ errorMessage }}</span>
        <ng-template #title></ng-template>
    </div>

    <div class="scanner-display">
        <div id="scanner" [style]="'height: ' + height"></div>
    </div>

    <div *ngIf="showResults" class="scan-results">
        <div class="scan-detection" *ngIf="codeDetection" (click)="removeCodeDetection()">
            <div class="scan-results-type-name">{{ codeDetection.typeName }}</div>
            <div class="scan-results-data">{{ codeDetection.data }}</div>
        </div>
    </div>
</div>

<br />

<div *ngIf="initialized && !barcodeReader" class="scan-actions">
    <button 
        [disabled]="busy" 
        class="btn btn-save btn-lg" 
        (click)="startScanning()"
    >{{ scanningBtnText }}</button>
</div>
<div 
    *ngIf="initialized && barcodeReader && showCloseButton" 
    class="scan-actions"
>
    <button 
        [disabled]="busy" 
        class="btn btn-save btn-lg" 
        id="closeScanner" 
        (click)="closeScanning()"
    >Close Camera</button>
</div>
