import { Injectable, NgZone } from '@angular/core';
import { timer, Observable, Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SecurityService } from '../security/security.service';
import { InactivityTimeoutComponent } from './inactivity-timeout.component';
import { addMinutes } from 'date-fns';
import { UserJsVm } from '../../shared/generated/Administration/Models/Users/UserJsVm';

@Injectable({
	providedIn: 'root'
})
export class InactivityTimeoutService{
	private destroy$ = new Subject<void>();
	private lastActivity: Date;
	private modalOpen = false;
	private inactivityTimer: Observable<number>;
	private user: UserJsVm;

	/**
	 * The following are URLs that are tracked for inactivity timeout.
	 * When a user navigates to any of these URLs, the inactivity timer will start counting down.
	 * If the user is inactive for 7.5 minutes, the inactivity modal will be shown.
	 * If the user dismisses the modal, the inactivity timer will be reset.
	 * If the user is inactive for another 7.5 minutes, the user will be logged out.
	 */
	private trackedUrls = [
		"pharmacy",
		"metabolic-formula"
	];


	constructor(
		private ngZone: NgZone,
		private securityService: SecurityService,
		private modalService: NgbModal,
	) {
		if(this.securityService.getUser()?.inactivityTimeout) {
			console.log("initActivityTracking on");
			this.initActivityTracking();
			this.initInactivityTimer();
		} else {
			console.log("initActivityTracking off");
		}
	}

	private initActivityTracking(): void {
		this.ngZone.runOutsideAngular(() => {
			const events = [
				{ name: 'mousemove', target: document },
				{ name: 'mousedown', target: document },
				{ name: 'click', target: document },
				{ name: 'keydown', target: document },
				{ name: 'scroll', target: window },
				{ name: 'touchstart', target: document },
				{ name: 'pointermove', target: document }
			];
			const observables = events.map(event =>
				fromEvent(event.target, event.name)
			);
			merge(...observables)
				.pipe(
					debounceTime(300),
					filter(() => this.isTrackedUrl()),
					takeUntil(this.destroy$)
				)
				.subscribe(() => {
					this.ngZone.run(() => this.resetLastAccess());
				});
		});
	}

	private isTrackedUrl(): boolean {
		const currentUrl = window.location.href;
		return this.trackedUrls.some(url => currentUrl.includes(url));
	}

	private initInactivityTimer(): void {
		this.inactivityTimer = timer(0, 1000).pipe(
			tap(() => this.checkInactivity()),
			takeUntil(this.destroy$)
		);
		this.inactivityTimer.subscribe();
	}

	private resetLastAccess(): void {
		this.lastActivity = new Date();
		this.securityService.setLocalStorage('lastAccess', this.lastActivity);
	}

	private checkInactivity(): void {
		if (this.modalOpen || !this.isTrackedUrl() || !this.securityService.getUser()?.inactivityTimeout) return;
		const user = this.securityService.getUser();
		if (!user) return;

		const lastAccessStr = localStorage.getItem('lastAccess');
		if (!lastAccessStr) {
			this.securityService.setSecurity(null, null);
			return;
		}

		const lastAccessedDate = new Date(JSON.parse(lastAccessStr));
		const endDate = addMinutes(lastAccessedDate, 7);

		if (new Date() >= endDate) {
			this.showInactivityModal();
		}
	}

	private showInactivityModal(): void {
		if (!this.modalOpen) {
			this.ngZone.run(() => {
				this.modalOpen = true;
				this.modalService.dismissAll();
				this.modalService.open(
					InactivityTimeoutComponent
					, {
						beforeDismiss: () => {
							this.modalOpen = false;
							this.resetLastAccess();
							return true;
						}
					}
				);
			});
		}
	}

	stopTracking(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
