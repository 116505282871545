import { Injectable, signal } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class GlobalService {
	constructor() {}

	static setIsMobile(getScreenWidth: any) {
		/* Previously was getScreenWidth < 1025px
		 * Needs to be 993 because 993px matches the bootstrap 'lg' breakpoint
		 */
		if (getScreenWidth < 993) { return true; } 
		else { return false; }
	}

	isNavFixed: BehaviorSubject<boolean> = new BehaviorSubject(null); // Should convert to signal
	isNavOpen = signal<boolean>(null);
}