<div id='informativeContent'>
	<p>
		This tab contains business area specific information. Assigning access to certain business areas may require
		additional details.
		<br />
		Updates may only be performed by User Admin level users and above for either the related business area or the
		"Admin" business area.
		<br />
		To view your roles, visit the <a [routerLink]="['/admin/users/user/edit/' + userId()]" [queryParams]="{ tab: 'Module Access' }">module access section</a> of your profile.
	</p>
</div>

<hr>
<form [formGroup]="formGroup" (ngSubmit)="submitForms();" *ngIf="(this.model$ | async)">
	<!---------------------------------------------------------------------------------------------------------------------------->
	<!-------------------------------------------------------- CI Repack --------------------------------------------------------->
	<!---------------------------------------------------------------------------------------------------------------------------->
	<div class='d-flex flex-row mx-0 px-0 my-1 col-md-12' *ngIf="!hideBusinessArea(businessAreaEnum.CI)">
		<div class="col-md-2">
			<div class='btn btn-lg btn-{{(!model.hasCiAccess ? "secondary" : "save")}} module-button form-control'>
				{{ getDisplayName(businessAreaEnum.CI) }}
			</div>
		</div>

		<div class='col-md-2'>
			<pcg-control-group>
				<pcg-select pcgControlRef [items]="items" [(ngModel)]="model.hasCiAccess"
					formControlName='hasCiAccess'></pcg-select>
			</pcg-control-group>
		</div>
	</div>

	<div class='d-flex flex-row-reverse col-md-12 mx-0 px-0'>
		<div *ngIf="model.hasCiAccess" [hidden]="hideBusinessArea(businessAreaEnum.CI)"
			class="col-md-10 pt-2 pl-3 pb-2">
			<!-- ngIf prevents facility account info from being created until facility is associated to CI. Otherwise, creating unnecessary hits to db.-->
			<pcg-ci-business-area #ciBusinessArea [(model)]="model.ciDetailsVm"
				[hidden]="!model.hasCiAccess"></pcg-ci-business-area>
		</div>
	</div>
	<!---------------------------------------------------------------------------------------------------------------------------->
	<!-------------------------------------------------------- Pharmacy ---------------------------------------------------------->
	<!---------------------------------------------------------------------------------------------------------------------------->
	<div class='d-flex flex-row mx-0 px-0 my-1 col-md-12' *ngIf="!hideBusinessArea(businessAreaEnum.Inventory)">
		<div class="col-md-2">
			<div
				class='btn btn-lg btn-{{(!model.hasInventoryAccess ? "secondary" : "save")}} module-button form-control'>
				{{ getDisplayName(businessAreaEnum.Inventory) }}
			</div>
		</div>

		<div class='col-md-2'>
			<pcg-control-group class="h-100">
				<pcg-select pcgControlRef class="h-100" [items]="items" [(ngModel)]="model.hasInventoryAccess"
					formControlName='hasInventoryAccess'></pcg-select>
			</pcg-control-group>
		</div>
	</div>

	<div class='d-flex flex-row-reverse col-md-12 mx-0 px-0'>
		<div *ngIf="model.hasInventoryAccess" [hidden]="hideBusinessArea(businessAreaEnum.Inventory)"
			class="col-md-10 pt-2 pl-3 pb-2">
			<pcg-inventory-business-area
			 	#inventoryBusinessArea 
				[(model)]="model.inventoryDetailsVm"
				[hidden]="!model.hasInventoryAccess"
			></pcg-inventory-business-area>
		</div>
	</div>
	<!---------------------------------------------------------------------------------------------------------------------------->
	<!---------------------------------------------------- Metabolic Formula ----------------------------------------------------->
	<!---------------------------------------------------------------------------------------------------------------------------->
	<div class='d-flex flex-row mx-0 px-0 my-1 col-md-12' *ngIf="!hideBusinessArea(businessAreaEnum.MetabolicFormula)">
		<div class="col-md-2">
			<div
				class='btn btn-lg btn-{{(!model.hasMetabolicAccess ? "secondary" : "save")}} module-button form-control'>
				{{ getDisplayName(businessAreaEnum.MetabolicFormula) }}
			</div>
		</div>

		<div class='col-md-2'>
			<pcg-control-group>
				<pcg-select pcgControlRef [items]="items" [(ngModel)]="model.hasMetabolicAccess"
					formControlName='hasMetabolicAccess'></pcg-select>
			</pcg-control-group>
		</div>
	</div>

	<div class='d-flex flex-row-reverse col-md-12 mx-0 px-0'>
		<div *ngIf="model.hasMetabolicAccess" [hidden]="hideBusinessArea(businessAreaEnum.Metabolic)"
			class="col-md-10 pt-2 pl-4 pb-4">
			<pcg-metabolic-business-area></pcg-metabolic-business-area>
		</div>
	</div>

	<div class='bottom-buttons'>
		<button *ngIf='canEdit' class='btn btn-save btn-lg'>Save</button>
	</div>
</form>