<pcg-prevent-browser-autofill/>

<div class="d-flex align-items-center">
	<div class="input-group">
		<input 
			id='scanInput' 
			inputAutofocus 
			[placeholder]="inputPlaceholder" 
			(keydown)="keyDown($event)" 
			#txtBarcode 
			type="text" 
			class="form-control" 
			[disabled]="isDisabled || isProcessing"
			autocomplete='off' 
		/>
		<div class="input-group-append">
			<button 
				(click)="scanBarcode()" 
				type="button" 
				class="btn btn-save" 
				[disabled]="isDisabled || isProcessing"
			>Scan</button>
		</div>
	</div>
	<fa-icon 
		*ngIf="showHelp" 
		[icon]="faIcon.faSquareQuestion" 
		class="icon-size help-icon" 
		(click)="helpButtonClicked()"
	></fa-icon>
</div>

<!-- Help Button Clicked Modal -->
<ng-template 
	#helpModal 
	let-modal
>
	<div class="modal-header">
		<h5>Scanner Help</h5>
		<button 
			type="button" 
			class="close" 
			aria-label="Close" 
			(click)="modal.dismiss()"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	
	<div class="modal-body flex-body">
		<h5>
			<img src="/assets/images/informational/qr-code.png" alt="QR Code"/>
		</h5>
		<p>
			Please locate the GS1 DataMatrix barcode that looks similar to this one. <br /><br />
			Standard barcodes will not work. <br /><br />
			Simply use your barcode scanner to scan this making sure you have first clicked into the input field to the
			left of the help button. <br /><br />
			<b>Tip: </b>If your barcode and QR matrix are close to each other cover up the barcode with your other hand.
		</p>
	</div>

	<div class="modal-footer">
		<button 
			type="submit" 
			class="btn btn-save btn-lg" 
			(click)="modal.dismiss()"
		>Finished</button>
	</div>
</ng-template>