/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { CdkStepper, StepContentPositionState } from '@angular/cdk/stepper';
import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pcg-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
    animations: [
        trigger('stepTransition', [
            state('previous', style({ opacity: 0 })),
            state('current', style({ opacity: 1 })),
            state('next', style({ opacity: 0 })),
            transition('* => *', animate('500ms ease-out')),
        ]),
    ],
    standalone: false
})
export class StepperComponent extends CdkStepper implements AfterContentInit {
	/** If true, non-visible step content will be removed from DOM. Otherwise it will just be hidden. */
	@Input() destroyOnHide = false;

	@Input() canMoveForward: (index: number) => boolean;

	@Input() isChild: boolean;

	@Input() parentStep: number;

	/** Disable specific steps from being clicked by the user*/
	@Input() disableSpecificStepClicks: number[] = [];

	/** Event emitted when step swapping animation is complete. */
	@Output() readonly animationDone: EventEmitter<void> = new EventEmitter<void>();

	/** Emits the new step number on step change. */
	@Output() current: EventEmitter<number> = new EventEmitter<number>();

	/** Emits the new step number only on manual click of step. */
	@Output() stepperNumberManuallyClicked: EventEmitter<number> = new EventEmitter<number>();

	/** Maintain a getter list of what the display attribute should be for each step
	 *  Should be block for current step, hidden for everything else **/
	get stepDisplay() {
		return Array.apply(null, new Array(this._steps.length)).map((o, i) =>
			i === this.selectedIndex ? 'block' : 'none'
		);
	}

	/** Keep track of the previous step */
	lastIndex: number;
	currentStep = 0;
	get selectedIndex() {
		if (this.currentStep < super.selectedIndex) {
			this.currentStep = super.selectedIndex
		}
		return super.selectedIndex;
	}
	set selectedIndex(index: number) {
		if (!this.canMoveForward || this.canMoveForward(index)) {
			this.lastIndex = this.selectedIndex;
			super.selectedIndex = index;
		}
	}

	_animationDone = new Subject<AnimationEvent>();

	/**
	 * Main function to swap to a new step
	 *
	 * @param index The number of the step being selected
	 */
	selectStepByIndex(index: number): void {
		this.selectedIndex = index;
		this.current.emit(index);
	}

	/**
	 * Called on manual select of step to emit event.
	 *
	 * @param index The number of the step being selected
	 */
	manualClick(index: number) {
		if (!this.disableSpecificStepClicks.includes(index)) {
			this.selectStepByIndex(index);
			this.stepperNumberManuallyClicked.emit(index);
		}
	}

	ngAfterContentInit() {
		super.ngAfterContentInit();

		this._steps.changes.pipe(takeUntil(this._destroyed)).subscribe(() => {
			this._stateChanged();
		});

		this._animationDone
			.pipe(
				// This needs a `distinctUntilChanged` in order to avoid emitting the same event twice due
				// to a bug in animations where the `.done` callback gets invoked twice on some browsers.
				// See https://github.com/angular/angular/issues/24084
				distinctUntilChanged((x, y) => x.fromState === y.fromState && x.toState === y.toState),
				takeUntil(this._destroyed)
			)
			.subscribe(event => {
				if ((event.toState as StepContentPositionState) === 'current') {
					this.animationDone.emit();
				}
			});
	}
}
