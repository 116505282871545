<br />
<img 
	alt="HOMER - Health Operations & Medical Emergency Response" 
	class='system-logo' 
	src='assets/images/logos/logo.png' 
/>
<br />
<div class='forgot-password-container clearfix'>
	<form 
		*ngIf='!checkEmail' 
		(ngSubmit)="onSubmit()" 
		[formGroup]="forgotPasswordForm"
	>
		<h2 class="forgot-password-header">Forgot Password</h2>
		<div class='row'>
			<pcg-control-group class="col-sm-12" label="Email">
				<input 
					pcgControlRef 
					formControlName="email" 
					type="text" 
					class="form-control email" 
				/>
			</pcg-control-group>
		</div>
		<button 
			type="submit" 
			class="btn btn-secondary btn-lg"
		>Send Email</button>
		<a class='float-right' routerLink='/login'>Back to Login</a>
	</form>
	<div *ngIf='checkEmail'>
		<h2 class="forgot-password-header">Forgot Password Confirmation</h2>
		<p>Please check your email to reset your password</p>
		<a routerLink='/login' class="d-block text-center">Back to Login</a>
	</div>
</div>
<div #footerEl id="divisionFooter" class="fdepcs-footer">Florida Division of Emergency Preparedness and Community Support</div>
