import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'pcg-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: false
})
export class DashboardComponent implements OnInit, OnDestroy {

	@ViewChild('img', { static: true }) img: ElementRef;

	origImgHeight: string;

	constructor(public title: Title) { title.setTitle('Dashboard'); }

	ngOnInit() {}

	setImgHeight() {
		const height = this.img.nativeElement.offsetHeight;
		const navHeight = document.getElementById('primaryNav').offsetHeight;
		const footerHeight = document.getElementById('footer').offsetHeight;
		const maxHeight = document.body.offsetHeight - navHeight - footerHeight;		
		if (
			this.img !== null 
			&& this.img !== undefined
		) {
			this.origImgHeight = this.img.nativeElement.style.height;
			if (height > maxHeight) { this.img.nativeElement.style.height = `${maxHeight}px`; }
		}
	}

	@HostListener('window:resize', ['$event'])
	onBlur() {
		if (
			this.img !== null 
			&& this.img !== undefined
		) {
			this.img.nativeElement.style.height = this.origImgHeight;
			this.setImgHeight();
		}
	}

	ngOnDestroy() {}
}