import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class DynamicFormFieldVm {
	customFieldId: number;
	linkId?: number;
	isActive: boolean;
	key?: string;
	name?: string;
	tooltip?: string;
	customFieldType?: any;
	customFieldMask?: any;
	selectOption1?: string;
	selectOption2?: string;
	selectOption3?: string;
	selectOption4?: string;
	selectOption5?: string;
	selectOption6?: string;
	selectOption7?: string;
	selectOption8?: string;
	selectOption9?: string;
	selectOption10?: string;
	selectItems?: string[];
	selectItemJson?: string;
	isRequired: boolean;
	regex?: string;
	isEmail: boolean;
	stringLengthMax?: number;
	stringLengthMin?: number;
	numericMax?: number;
	numericMin?: number;
	order: number;
	friendlyId?: string;
	hasBeenUsed: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'customFieldId': new UntypedFormControl(0, [...(validators['customFieldId'] ?? []), ...[Validators.required]]),
			'linkId': new UntypedFormControl(null, [...(validators['linkId'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'key': new UntypedFormControl(null, [...(validators['key'] ?? []), ...[]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.maxLength(50), Validators.minLength(0), Validators.required]]),
			'tooltip': new UntypedFormControl(null, [...(validators['tooltip'] ?? []), ...[Validators.maxLength(500), Validators.minLength(0)]]),
			'customFieldType': new UntypedFormControl(null, [...(validators['customFieldType'] ?? []), ...[Validators.required]]),
			'customFieldMask': new UntypedFormControl(null, [...(validators['customFieldMask'] ?? []), ...[]]),
			'selectOption1': new UntypedFormControl(null, [...(validators['selectOption1'] ?? []), ...[]]),
			'selectOption2': new UntypedFormControl(null, [...(validators['selectOption2'] ?? []), ...[]]),
			'selectOption3': new UntypedFormControl(null, [...(validators['selectOption3'] ?? []), ...[]]),
			'selectOption4': new UntypedFormControl(null, [...(validators['selectOption4'] ?? []), ...[]]),
			'selectOption5': new UntypedFormControl(null, [...(validators['selectOption5'] ?? []), ...[]]),
			'selectOption6': new UntypedFormControl(null, [...(validators['selectOption6'] ?? []), ...[]]),
			'selectOption7': new UntypedFormControl(null, [...(validators['selectOption7'] ?? []), ...[]]),
			'selectOption8': new UntypedFormControl(null, [...(validators['selectOption8'] ?? []), ...[]]),
			'selectOption9': new UntypedFormControl(null, [...(validators['selectOption9'] ?? []), ...[]]),
			'selectOption10': new UntypedFormControl(null, [...(validators['selectOption10'] ?? []), ...[]]),
			'selectItems': new UntypedFormControl([], [...(validators['selectItems'] ?? []), ...[]]),
			'selectItemJson': new UntypedFormControl(null, [...(validators['selectItemJson'] ?? []), ...[]]),
			'isRequired': new UntypedFormControl(false, [...(validators['isRequired'] ?? []), ...[]]),
			'regex': new UntypedFormControl(null, [...(validators['regex'] ?? []), ...[Validators.maxLength(500), Validators.minLength(0)]]),
			'isEmail': new UntypedFormControl(false, [...(validators['isEmail'] ?? []), ...[]]),
			'stringLengthMax': new UntypedFormControl(null, [...(validators['stringLengthMax'] ?? []), ...[]]),
			'stringLengthMin': new UntypedFormControl(null, [...(validators['stringLengthMin'] ?? []), ...[]]),
			'numericMax': new UntypedFormControl(null, [...(validators['numericMax'] ?? []), ...[]]),
			'numericMin': new UntypedFormControl(null, [...(validators['numericMin'] ?? []), ...[]]),
			'order': new UntypedFormControl(0, [...(validators['order'] ?? []), ...[Validators.required]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'hasBeenUsed': new UntypedFormControl(false, [...(validators['hasBeenUsed'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): DynamicFormFieldVmFromFg {
		return new DynamicFormFieldVmFromFg(fg);
	}
}

export class DynamicFormFieldVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get customFieldId(): number {
		return this.fg.get('customFieldId').value;
	}
	set customFieldId(val) {
		this.fg.get('customFieldId').setValue(val);
	}

	get linkId(): number {
		return this.fg.get('linkId').value;
	}
	set linkId(val) {
		this.fg.get('linkId').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get key(): string {
		return this.fg.get('key').value;
	}
	set key(val) {
		this.fg.get('key').setValue(val);
	}

	get name(): string {
		return this.fg.get('name').value;
	}
	set name(val) {
		this.fg.get('name').setValue(val);
	}

	get tooltip(): string {
		return this.fg.get('tooltip').value;
	}
	set tooltip(val) {
		this.fg.get('tooltip').setValue(val);
	}

	get customFieldType(): any {
		return this.fg.get('customFieldType').value;
	}
	set customFieldType(val) {
		this.fg.get('customFieldType').setValue(val);
	}

	get customFieldMask(): any {
		return this.fg.get('customFieldMask').value;
	}
	set customFieldMask(val) {
		this.fg.get('customFieldMask').setValue(val);
	}

	get selectOption1(): string {
		return this.fg.get('selectOption1').value;
	}
	set selectOption1(val) {
		this.fg.get('selectOption1').setValue(val);
	}

	get selectOption2(): string {
		return this.fg.get('selectOption2').value;
	}
	set selectOption2(val) {
		this.fg.get('selectOption2').setValue(val);
	}

	get selectOption3(): string {
		return this.fg.get('selectOption3').value;
	}
	set selectOption3(val) {
		this.fg.get('selectOption3').setValue(val);
	}

	get selectOption4(): string {
		return this.fg.get('selectOption4').value;
	}
	set selectOption4(val) {
		this.fg.get('selectOption4').setValue(val);
	}

	get selectOption5(): string {
		return this.fg.get('selectOption5').value;
	}
	set selectOption5(val) {
		this.fg.get('selectOption5').setValue(val);
	}

	get selectOption6(): string {
		return this.fg.get('selectOption6').value;
	}
	set selectOption6(val) {
		this.fg.get('selectOption6').setValue(val);
	}

	get selectOption7(): string {
		return this.fg.get('selectOption7').value;
	}
	set selectOption7(val) {
		this.fg.get('selectOption7').setValue(val);
	}

	get selectOption8(): string {
		return this.fg.get('selectOption8').value;
	}
	set selectOption8(val) {
		this.fg.get('selectOption8').setValue(val);
	}

	get selectOption9(): string {
		return this.fg.get('selectOption9').value;
	}
	set selectOption9(val) {
		this.fg.get('selectOption9').setValue(val);
	}

	get selectOption10(): string {
		return this.fg.get('selectOption10').value;
	}
	set selectOption10(val) {
		this.fg.get('selectOption10').setValue(val);
	}

	get selectItems(): string[] {
		return this.fg.get('selectItems').value;
	}
	set selectItems(val) {
		this.fg.get('selectItems').setValue(val);
	}

	get selectItemJson(): string {
		return this.fg.get('selectItemJson').value;
	}
	set selectItemJson(val) {
		this.fg.get('selectItemJson').setValue(val);
	}

	get isRequired(): boolean {
		return this.fg.get('isRequired').value;
	}
	set isRequired(val) {
		this.fg.get('isRequired').setValue(val);
	}

	get regex(): string {
		return this.fg.get('regex').value;
	}
	set regex(val) {
		this.fg.get('regex').setValue(val);
	}

	get isEmail(): boolean {
		return this.fg.get('isEmail').value;
	}
	set isEmail(val) {
		this.fg.get('isEmail').setValue(val);
	}

	get stringLengthMax(): number {
		return this.fg.get('stringLengthMax').value;
	}
	set stringLengthMax(val) {
		this.fg.get('stringLengthMax').setValue(val);
	}

	get stringLengthMin(): number {
		return this.fg.get('stringLengthMin').value;
	}
	set stringLengthMin(val) {
		this.fg.get('stringLengthMin').setValue(val);
	}

	get numericMax(): number {
		return this.fg.get('numericMax').value;
	}
	set numericMax(val) {
		this.fg.get('numericMax').setValue(val);
	}

	get numericMin(): number {
		return this.fg.get('numericMin').value;
	}
	set numericMin(val) {
		this.fg.get('numericMin').setValue(val);
	}

	get order(): number {
		return this.fg.get('order').value;
	}
	set order(val) {
		this.fg.get('order').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get hasBeenUsed(): boolean {
		return this.fg.get('hasBeenUsed').value;
	}
	set hasBeenUsed(val) {
		this.fg.get('hasBeenUsed').setValue(val);
	}
}
