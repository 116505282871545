<!-- 
    When using this component wrap your parent component in this tag
    <mat-sidenav-container class="conversations-container">
        
    and then call this component from in this tag
    <mat-sidenav class="conversations-sidenav"></mat-sidenav>
 -->

 @if (usesSidenav) {
    <div 
        id="divConvoHead" 
        class="convo-box-head"    
    >
        <div class="d-flex align-items-center">
            <fa-icon 
                [icon]="faIconName.faComments" 
                class="mr-2"
            ></fa-icon>
            <h5>{{ header }}</h5>
        </div>
        <fa-icon 
            [icon]="faIconName.faXmark" 
            class="fa-xl"
            (click)="toggleConversationsMenu()" 
        ></fa-icon>
    </div>
 }
 
<div 
    id="divConvoMessages" 
    [ngClass]="{
        'convo-box-messages-with-sidenav': usesSidenav
        , 'convo-box-messages': !usesSidenav
        , 'heros-height': isHeros
    }"
>
    @if (isCommentError) {
        <div class="row">
            <div class="col-md-12 alert alert-danger text-center">
                {{ commentErrorString }}
            </div>
        </div>
    }
    
    <div 
        *ngFor="let row of commentList; let i = index;" 
        class="msg-ctnr ctnr-sent"        
        (mouseleave)="
            row.showEditDelete = false;
            row.showCommentDate = false;
        "
    >
        @if (row?.commentUserId !== user.id) {
            <div>
                @if (
                    commentList[i + 1]?.commentUserId !== row?.commentUserId
                    || row.showCommentDate === true
                ) {
                    <p class="msg-stamp-received">
                        @if (
                            isSysAdmin 
                            || isAssignee 
                            || showCommenterInfo
                        ) { <span>{{ row?.commentorInfo }} | </span> }
                        
                        <span>{{ row?.commentDateStr }}</span>

                        @if (
                            fromFulfillment 
                            && row.pfsFormID !== null 
                            && row.pfsFormID !== 0
                        ) {
                            <span> 
                                &nbsp;|&nbsp;
                                <a 
                                    [href]="row.pfsUrl" 
                                    target="_blank"
                                >{{ row.pfsFormID }}</a>
                            </span>
                        }                        
                    </p>
                }
                
                @if (!row?.isEdit) {
                    <p 
                        class="message received"
                        [innerHtml]="row?.commentText"
                        (pointerenter)="row.showCommentDate = true;"
                    ></p>
                }                
            </div>
        }        

        @if (row?.commentUserId === user.id) {
            <div>
                @if (
                    commentList[i + 1]?.commentUserId !== row?.commentUserId
                    || row.showCommentDate === true
                ) {
                    <p class="msg-stamp-sent">
                        <span>Me | {{ row?.commentDateStr }}</span>
                        @if (
                            fromFulfillment 
                            && row.pfsFormID !== null 
                            && row.pfsFormID !== 0
                        ) {
                            <span>
                                &nbsp;|&nbsp;
                                <a 
                                    [href]="row.pfsUrl" 
                                    target="_blank"
                                >{{ row.pfsFormID }}</a>
                            </span>
                        }                        
                    </p>
                }
                
                @if (!row?.isEdit) {
                    <p 
                        class="message sent"
                        (pointerenter)="
                            row.showEditDelete = true; 
                            row.showCommentDate = true;
                        "
                        [innerHtml]="row?.commentText"
                    ></p>
                }                
            </div>
        }        

        @if (row?.isEdit) {
            <textarea 
                [(ngModel)]="row.commentText" 
                type="text" 
                class="message-input"
                maxlength="3000"
                (keydown.enter)="saveEditComment(row)"
            ></textarea>
        }

        @if (row?.commentUserId === user.id) {
            <div class="msg-btn-ctnr">
                @if (
                    row.showEditDelete
                    && !row?.isEdit 
                    && !row?.isDelete
                    && !statusClosed
                ) {
                    <div>
                        <span (click)="editComment(row)">
                            <fa-icon 
                                [icon]="faIconName.faPen" 
                                class="msg-btn ml-2"
                                [class]="isMobile 
                                    ? 'massive-mobile-buttons' 
                                    : 'fa-sm'
                                "
                            ></fa-icon>
                        </span>
                        <span (click)="deleteComment(row)">
                            <fa-icon 
                                [icon]="faIconName.faTrash" 
                                class="msg-btn ml-2"
                                [class]="isMobile 
                                    ? 'massive-mobile-buttons' 
                                    : 'fa-sm'
                                "
                            ></fa-icon>
                        </span>  
                    </div>
                }
                
                @if (
                    !row?.isEdit 
                    && row?.isDelete
                ) {
                    <div class="row">
                        <div class="col-md-12 alert alert-danger text-center">
                            Are you sure you want to delete this comment?
                            <br />
                            <button 
                                type="button" 
                                class="btn btn-cancel btn-sm"
                                (click)="noDeleteClick(row)"
                            >No</button>
                            &nbsp;&nbsp;
                            <button 
                                type="button" 
                                class="btn btn-save btn-sm"
                                (click)="yesDeleteClick(row)"
                            >Yes</button>
                        </div>
                    </div>
                }
                
                @if (row?.isEdit) {
                    <fa-icon 
                        [icon]="faIconName.faSave" 
                        class="msg-btn ml-2"
                        [class]="isMobile 
                            ? 'massive-mobile-buttons' 
                            : 'fa-sm'
                        "
                        (click)="saveEditComment(row)"
                    ></fa-icon>
                    <fa-icon 
                        [icon]="faIconName.faCancel" 
                        class="msg-btn ml-2"
                        [class]="isMobile 
                            ? 'massive-mobile-buttons' 
                            : 'fa-sm'
                        "
                        (click)="cancelEditComment(row)"
                    ></fa-icon>
                }
            </div>        
        }
    </div>
</div>

<div 
    id="divConvoFoot" 
    [class]="usesSidenav 
        ? 'convo-box-foot-with-sidenav' 
        : 'convo-box-foot'
    "
>
    <div class="foot-message-row">
        <textarea 
            [(ngModel)]="newCommentText" 
            type="text" 
            class="message-input"
            maxlength="3000"
            [placeholder]="useTemplateResponses 
                ? 'Type message here or select a template response' 
                : 'Type message here'
            "
            (keydown.enter)="saveNewComment()"
            [disabled]="statusClosed"
        ></textarea>
        <fa-icon 
            [icon]="faIconName.faPaperPlaneTop" 
            class="fa-lg"
            (click)="saveNewComment()" 
        ></fa-icon>
    </div> 
    
    @if (
        useTemplateResponses 
        && templateResponseApi !== ''
    ) {
        <div class="template-ctnr">
            <select 
                #templateSelect
                class="message-input" 
                ngDefaultControl
                [(ngModel)]="newTemplateId"
                [ngModelOptions]="{ standalone: true }"
                (change)="templateChange($event.target.value)"
                [disabled]="statusClosed"
            >
                <option value="">Select a template response...</option>
                <option 
                    *ngFor="let item of templateItems" 
                    [value]="item.value" 
                    class="form-control"
                >{{ item.text }}</option>   
            </select>
        </div>
    }    
</div>