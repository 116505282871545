<div>
	<div class="modal-header">
		<h5 class="modal-title" id="modal-basic-title">{{title}}</h5>
		<button type="button" class="close" aria-label="Close" (click)="this.modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<!--Initial Rx Scan before choices-->
	<div class="modal-body" *ngIf="!this.hasMultipleInvChoices">
		<p class="text-danger">Warning: Scanning an Rx here will <u>create</u> a fulfillment.</p>
		<label class="control-label for ng-star-inserted">Rx Number:</label>
		<input type='hidden' />
		<pcg-scan-rx (scanSuccessInventoryList)="scanSuccessInventoryList($event)" [autofocus]="true"></pcg-scan-rx>
	</div>

	<!--Only used if Rx comes back with multiple choices-->
	<div class="modal-body" *ngIf="this.hasMultipleInvChoices">
		<p>Multiple inventories contain the NDC for this Rx. Please choose the inventory to fill the prescription from.</p>
		<div id="InventoryChoices" *ngFor="let inv of model?.inventories; index as i; first as isFirst" class="d-flex justify-content-around">
			<input type="button" class="btn btn-save btn-lg m-1" (click)="chooseInventory(inv.inventorySiteId, inv.inventoryName)" [value]="inv.inventoryName" />
		</div>
	</div>

	<div class="modal-footer">
		<input type="button" class="btn btn-cancel btn-lg" (click)="this.modal.dismiss();" value="Close" />
	</div>
</div>