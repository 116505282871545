<br />

<div class='clearfix'>
	<div class='float-left'>
		<h1>RX Refill - Home Delivery Only Form</h1>
	</div>
</div>

<hr />

<div id='RxRefillForm'>
	<div id='informativeContent'>
		<p>
			Our online service lets you refill any valid prescription currently on file at your DOH Central Pharmacy.
			It's quick and easy; all
			you need is your DOH Pharmacy prescription numbers. Order refills any time of the day or night,
			from work or home. You don't even have to enter a password. Your request is transmitted directly to our
			Central Pharmacy
			and your refill will be mailed directly to the address on file. If you do not wish the Rx to be sent to the
			address on file, please
			contact your County Health Department prescriber. Thank you.
		</p>
	</div>

	<form 
		pcgSubmitUrl='api/PublicHomeDelivery/VerifyAndSubmitHomeDeliveryRefill' 
		[formGroup]='formGroup'
		(messageSuccess)='openHomeDeliverySubmitModal(homeDeliverySubmitModal, $event)'
		(messageError)='highlightErrors($event)'
	>
		<div class='clearfix'>
			<div class='col-md-4 float-left'>
				<b>Enter&nbsp;Prescription&nbsp;Number</b>
				<div id='PrescriptionNumbers'>

					<pcg-control-group class='form-inline'>
						<label class='for'>1</label>
						<input 
							pcgControlRef 
							formControlName='rxNumber1' 
							maxlength="8"
							[className]="setControlClassValidation(this.rxInputType.Rx1, this.rxRefillValidation)"
							pcgNumeric 
							inputmode="numeric" 
						/>
					</pcg-control-group>

					<pcg-control-group class='form-inline'>
						<label class='for'>2</label>
						<input 
							pcgControlRef 
							formControlName='rxNumber2' 
							maxlength="8"
							[className]="setControlClassValidation(this.rxInputType.Rx2, this.rxRefillValidation)"
							pcgNumeric 
							inputmode="numeric" 
						/>
						<span 
							class="text-danger" 
							*ngIf="rx2IsDupe"
						>Please enter a unique Rx Number</span>
					</pcg-control-group>

					<pcg-control-group class='form-inline'>
						<label class='for'>3</label>
						<input 
							pcgControlRef 
							formControlName='rxNumber3' 
							maxlength="8"
							[className]="setControlClassValidation(this.rxInputType.Rx3, this.rxRefillValidation)"
							pcgNumeric 
							inputmode="numeric" 
						/>
						<span 
							class="text-danger" 
							*ngIf="rx3IsDupe"
						>Please enter a unique Rx Number</span>
					</pcg-control-group>

					<pcg-control-group class='form-inline'>
						<label class='for'>4</label>
						<input 
							pcgControlRef 
							formControlName='rxNumber4' 
							maxlength="8"
							[className]="setControlClassValidation(this.rxInputType.Rx4, this.rxRefillValidation)"
							pcgNumeric 
							inputmode="numeric" 
						/>
						<span 
							class="text-danger" 
							*ngIf="rx4IsDupe"
						>Please enter a unique Rx Number</span>
					</pcg-control-group>

					<pcg-control-group class='form-inline'>
						<label class='for'>5</label>
						<input 
							pcgControlRef 
							formControlName='rxNumber5' 
							maxlength="8"
							[className]="setControlClassValidation(this.rxInputType.Rx5, this.rxRefillValidation)"
							pcgNumeric 
							inputmode="numeric" 
						/>
						<span 
							class="text-danger" 
							*ngIf="rx5IsDupe"
						>Please enter a unique Rx Number</span>
					</pcg-control-group>
					<pcg-control-group class='form-inline'>
						<label class='for'>6</label>
						<input 
							pcgControlRef 
							formControlName='rxNumber6' 
							maxlength="8"
							[className]="setControlClassValidation(this.rxInputType.Rx6, this.rxRefillValidation)"
							pcgNumeric 
							inputmode="numeric" 
						/>
						<span 
							class="text-danger" 
							*ngIf="rx6IsDupe"
						>Please enter a unique Rx Number</span>
					</pcg-control-group>
				</div>
			</div>

			<div class='col-md-8 float-right'>
				<div>
					<b>Where can I find my prescription number?</b>
				</div>
				<div>
					<p>
						You can find your prescription number on your refill slip or prescription bottle. Remember to
						enter all 8 digits listed as shown in
						the highlighted box below. Do not enter any spaces or dashes between the 8 digits you enter,
						regardless of whether a space or a dash
						appears on your refill slip or bottle.
					</p>
					<div id='images'>
						<a 
							href='assets/images/informational/rx-bottle-instructions.jpg' 
							target='_blank'
						>
							<img 
								src='assets/images/informational/rx-bottle-instructions.jpg' 
								width='210' 
								alt='' 
							/>
						</a>
						<div 
							id='imageHelper' 
							class='float-right'
						>
							<p>Please click on the images for a larger view.</p>
							<p>The refill slip and prescription label displayed are samples only.</p>
							<p>Your refill slip and prescription label may vary by design.</p>
							<a 
								href='assets/images/informational/rx-bag-instructions.jpg' 
								target='_blank'
							>
								<img 
									src='assets/images/informational/rx-bag-instructions.jpg' 
									width='210' 
									alt='' 
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-12">
			<pcg-control-group label='Enter a phone number so our pharmacist can contact you if there is a problem with this order.'>
				<input 
					type='text' 
					maxlength='21' 
					pcgControlRef 
					formControlName='phoneNumber' 
					class="form-control"
					pcgPhoneExt 
				/>
			</pcg-control-group>
		</div>

		<pcg-control-group 
			class='form-inline' 
			hidden
		>
			<input 
				id="control-pot" 
				type="checkbox" 
				pcgControlRef 
				formControlName='honeyPot' 
			/>
		</pcg-control-group>

		<pcg-fixed-buttons>
			<button 
				class='btn btn-save btn-lg' 
				(click)='resetValidation()'
			>Submit</button>
			<button 
				type='button' 
				class='btn btn-cancel btn-lg' 
				(click)='resetForm()'
			>Reset</button>
		</pcg-fixed-buttons>

		<ngx-invisible-recaptcha 
			#captchaElem 
			[siteKey]="siteKey" 
			(reset)="handleReset()" 
			(ready)="handleReady()"
			(load)="handleLoad()" 
			(success)="handleSuccess($event)" 
			[useGlobalDomain]="false" 
			theme="light" 
			type="image"
			badge="bottomright" 
			formControlName="recaptcha"
		></ngx-invisible-recaptcha>
	</form>

	<br><br><br>

	<div class='bottom-buttons'>
		<b>
			Experiencing any issues? Contact Central Pharmacy at 1-800-554-4584<br />Press 0: To Speak to a member of the
			pharmacy staff 
		</b>
	</div>

	<!-- Result modal -->
	<ng-template 
		#homeDeliverySubmitModal 
		let-modal
	>
		<app-home-delivery-submit-modal 
			[modal]="modal" 
			[model]="this.modalModel"
			[(submitSuccessful)]="this.submitSuccessful"
		></app-home-delivery-submit-modal>
	</ng-template>
</div>