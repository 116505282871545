<form [formGroup]="formGroup" (change)="modelChange.emit(formGroup.value)">
    <input type='hidden' formControlName='facilityAccountInfoId' />
    <input type='hidden' formControlName='facilityId' />

    <div class="row">
        <!-- Invoice Contacts -->
        <pcg-control-group class="col-md-10" *ngIf="canEditPrimaryContacts" label="Invoice Contacts" 
            labelTooltip="These are the CI Repack users that will be contacted about invoices. A user must be associated to the facility in their user profile (Selected Facilities) to be present on this dropdown.">
            <pcg-select 
                pcgControlRef 
                [multiple]='true' 
                [dataSource]="'api/Select/CiFacilityUsers?facilityId=' + id"
                formControlName='ciPrimaryContactIds' 
                placeholder="Select contacts..."
                [selectedItemIds]="formGroup.controls.ciPrimaryContactIds.value"
            ></pcg-select>
        </pcg-control-group>
        <pcg-control-group class="col-md-2" label="Facility Color">
            <input class="form-control col-md-6 py-0 px-1" type="color" formControlName="facilityColor">
        </pcg-control-group>
    </div>
    
    <div class="row">
        <pcg-control-group class="col-md-4" label="Account Code">
            <input required pcgControlRef formControlName="accountCode" class="form-control" type="text" maxlength="50" />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="Org Code">
            <input required pcgControlRef formControlName="org" class="form-control" type="text" maxlength="50" />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="EO Code">
            <input required pcgControlRef formControlName="eo" class="form-control" type="text" maxlength="50" />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="OCA Code">
            <input required pcgControlRef formControlName="oca" class="form-control" type="text" maxlength="50" />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="BF Obj Code">
            <input required pcgControlRef formControlName="bfObjCode" class="form-control" type="text" maxlength="50" />
        </pcg-control-group>

        <pcg-control-group class="col-md-4" label="BF Category">
            <input required pcgControlRef formControlName="bfCategory" class="form-control" type="text" maxlength="50" />
        </pcg-control-group>
    </div>

    <div class="row">
        <pcg-control-group class="col-md-4" label="Inter Agency Agreement #">
            <input required pcgControlRef formControlName="interAgencyAgreementNumber" class="form-control" type="text" maxlength="50" />
        </pcg-control-group>

        <pcg-control-group class="col-md-2 form-check-label" label="Can Generate CI Invoices?" labelTooltip='Enabling this setting will generate a CI Invoice when an order is shipped/invoiced, notifying all invoice contacts.'>
            <ui-switch #canGenerateCiInvoices pcgControlRef formControlName="canGenerateCiInvoices" (change)="canGenerateCiInvoiceChange()"></ui-switch>
        </pcg-control-group>
    </div>
</form>
