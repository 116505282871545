<!--#region FIXED TOGGLE -->
@if (!navService.isMobile()) {
    <button 
        class="d-flex align-items-center justify-content-center mt-2 fixed-toggle-btn"
        (click)="navService.toggleNavFixed()"
    >
        @if (navService.isNavFixed()) {
            <fa-icon 
                [icon]="faIcons.faArrowLeft" 
                class="fa-lg"
            ></fa-icon>
        } @else {
            <fa-icon 
                [icon]="faIcons.faArrowRight" 
                class="fa-lg"
            ></fa-icon>
        }
    </button>
}
<!--#endregion-->

<!--#region Nav Logo-->
<div 
    (click)="redirect('/dashboard')" 
    class="nav-header"
>
    <img 
        alt="HOMER" 
        src="assets/images/logos/logo_small.png" 
    />
    @if (mode !== 'Production') { <div class="beta-text"></div> }
</div>
<!--#endregion-->

<mat-nav-list>
    <!-- Nav routes from getMainNavRoutes() in nav.service.ts -->
    @for (nav of navService.navRoutes(); track nav.name) {
        <ng-container *ngTemplateOutlet='navItem; context: { nav: nav, level: 0 }' />
    }

    <!--#region User Profile-->
    <mat-list-item 
        [class.active-nav-parent-item]="navService.selectedMainNav() === 'profile'"
        (click)="toggleUserProfileSubNav()"
        class='px-2'
    >
        <span class="d-flex flex-row">
            <div class="parent-nav-icon-container">
                <fa-icon 
                    [icon]="faIcons.faUser" 
                    class="parent-nav-icon"
                ></fa-icon>
            </div>
            <span class='mr-auto'>{{ user?.firstName + " " + user?.lastName }}</span>
            <fa-icon 
                [icon]="faIcons.faAngleDown" 
                [class.rotate]="navService.userProfileExpanded()"
                class="dropdown-icon"
            ></fa-icon>
        </span>
    </mat-list-item>

    @if (navService.userProfileExpanded()) {
        <div class="sub-menu expanded">
            <a 
                mat-list-item 
                [routerLink]="'admin/users/user/edit/' + user?.id"
                class='px-2'
            ><span class='ml-4'>My Profile</span></a>
            <mat-list-item 
                (click)="logout()"
                class='px-2'
            >
                <span class='ml-4'>Logout</span>
            </mat-list-item>
        </div>
    }
    <!--#endregion-->

    <!-- Anchor element used to check for any off screen nav items -->
    <a id='navAnchor'></a>
</mat-nav-list>

<!-- 
    This Angular template is responsible for rendering a navigation item in the side navigation menu. 
    It checks if the current navigation item can be viewed using the `navService.canViewNav(nav)` function, 
    and then renders either the `navItemTree` template (for navigation items with child items) or the `navItemNode` template (for navigation items without children).
    The `nav` and `level` parameters are passed to the template context, allowing the child templates to access the current navigation item and its nesting level. 
-->
<ng-template 
    #navItem 
    let-nav='nav' 
    let-level='level'
>
    @if (navService.canViewNav(nav)) {
        <ng-container
            *ngTemplateOutlet="nav.children?.length ? navItemTree : navItemNode; 
            context: { nav: nav, level: level }" 
        />
    }
</ng-template>

<!-- This is an Angular template for a navigation item in a side navigation menu with child records. -->
<ng-template 
    #navItemTree 
    let-nav='nav' 
    let-level='level'
>
    <mat-list-item 
        [class.active-nav-parent-item]="nav.name === navService.selectedMainNav()"
        (click)="navService.toggleSubNav(nav)" 
        class='px-2 cursor-pointer'
    >
        <span 
            class="d-flex flex-row" 
            [class.ml-4]='level > 0'
        >
            @if (nav.icon) {
                <div class="parent-nav-icon-container">
                    <fa-icon 
                        [icon]="nav.icon" 
                        class="parent-nav-icon"
                    ></fa-icon>
                </div>
            }
            <a><span>{{nav.shortName ?? nav.name}}</span></a>
            <fa-icon 
                [icon]="faIcons.faAngleDown" 
                [class.rotate]="nav.expanded" 
                class="dropdown-icon ml-auto"
            ></fa-icon>
        </span>
    </mat-list-item>
    @if (nav.expanded) {
        <div class="sub-menu expanded">
            @for (subNav of nav.children; track subNav) {
                <ng-container *ngTemplateOutlet='navItem; context: { nav: subNav, level: level + 1 }' />
            }
        </div>
    }
</ng-template>

<!-- 
    This is an Angular template for an end navigation item in a side navigation menu. 
    It displays a list item with an icon, label, and optional loading spinner. 
    The list item is clickable and can navigate to a specific route or URL. 
    The `navLinkClicked` function is called when the list item is clicked, passing the name and level of the navigation item. 
    The `navLinkIsLoading` function is used to determine whether to display a loading spinner. 
 -->
<ng-template 
    #navItemNode 
    let-nav='nav' 
    let-level='level'
>
    <a 
        mat-list-item
        (click)='navLinkClicked(nav, level)'
        [class.active-nav-parent-item]="nav.name === navService.selectedMainNav()"
        [disabled]="navLinkIsLoading(nav.name, level)"
        [routerLink]="!nav.href?.length ? nav.path : nav.href"
        class='px-2 cursor-pointer'
    >
        <span 
            class="d-flex flex-row" 
            [class.ml-4]='level > 0'
        >
            @if (nav.icon) {
                <div class="parent-nav-icon-container">
                    <fa-icon 
                        [icon]="nav.icon" 
                        class="parent-nav-icon"
                    ></fa-icon>
                </div>
            }
            <span>{{nav.shortName ?? nav.name}}</span>
            @if (navLinkIsLoading(nav.name, level)) {
                <span 
                    class="spinner-border spinner-border-sm align-middle ml-2 my-1" 
                    role="status" 
                    aria-hidden="true"
                ></span>
            }
        </span>
    </a>
</ng-template>

@if (navContentOffscreen) {
    <div 
        class='bg-secondary rounded px-3 text-center cursor-pointer' 
        (click)="scrollToBottom()"
    >
        <fa-icon 
            [icon]="faIcons.faAngleDown" 
            class="text-white"
        ></fa-icon>
    </div>
}

<hr />
<div class='ml-auto align-items-center nav-header mt-3'>
    @if (
        fulfillmentNavAccess
        && user
    ) {
        <a 
            id='btnStep1Count' 
            #btnStep1Count 
            (click)='openFulfillmentList(0)' 
            ngbTooltip="Rx's in the Product Prep Status"
            class="icon-btn"
        >
            <fa-icon 
                [icon]="faIcons.faPrescription" 
                [style.color]="iconColorName"
            ></fa-icon>
            @if (step1Count > 0) { <span class="badge badge-pill badge-success countNum step-1">{{ step1Count }}</span> }
        </a>
        <a 
            id='btnStep2Count' 
            #btnStep2Count 
            (click)='openFulfillmentList(1)' 
            ngbTooltip="Rx's in the Shipping Prep Status"
        >
            <fa-icon 
                [icon]="faIcons.faBoxOpen" 
                [style.color]="iconColorName"
            ></fa-icon>
            @if (step2Count > 0) { <span class="badge badge-info badge-pill countNum step-1">{{ step2Count }}</span> }
        </a>
        <a 
            id='btnStep3Count' 
            #btnStep3Count 
            (click)='openFulfillmentList(2)' 
            ngbTooltip="Rx's in the QC Check Status"
        >
            <fa-icon 
                [icon]="faIcons.faUserMd" 
                [style.color]="iconColorName"
            ></fa-icon>
            @if (step3Count > 0) { <span class="badge badge-pill badge-warning countNum step-1">{{ step3Count }}</span> }
        </a>
    }
    @if (
        accountRequestAccess
        && user
    ) {
        <a 
            id="btnAccountUpdate" 
            #btnOrgAccountUpdte 
            (click)="openOrgAccountUpdateModal()"
            ngbTooltip="Account Request Queue"
        >
            <fa-icon 
                [icon]="faIcons.faArrowRightArrowLeft" 
                [style.color]="iconColorName"
            ></fa-icon>
            @if (updateRequestCount > 0) { <span class="badge badge-pill badge-warning countNum step-1">{{ updateRequestCount }}</span> }
        </a>
    }
    @if (
        (
            reconNavAccess
            || invoiceNavAccess
            || icqAccess
        )
        && user
    ) {
        <a 
            id='btnReconciliations' 
            #btnReconciliations 
            (click)='openNotificationModal()'
        >
            <fa-icon 
                [icon]="faIcons.faBell" 
                [style.color]="iconColorName"
            ></fa-icon>
            @if (notificationCount > 0) { <span class="badge badge-pill badge-danger countNum step-1">{{ notificationCount }}</span> }
        </a>
    }
</div>