import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SecurityService } from '../../security/security.service';
import { Title } from '@angular/platform-browser';

/**
 * This is the landing page when you first access the website.
 */
@Component({
    selector: 'pcg-home',
    templateUrl: './home.component.html',
    standalone: false
})
export class HomeComponent implements OnInit {
	
	constructor(private sec: SecurityService, private router: Router, public title: Title) {
		title.setTitle('HOMER');
	}

	ngOnInit() {
		if (this.sec.isLoggedOn()) { this.router.navigate(['/dashboard']); }
		else { this.router.navigate(['/login']); }
	}
}
