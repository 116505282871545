import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'pcg-svg-envelope',
    templateUrl: './svg-envelope.component.html',
    styleUrls: ['./svg-envelope.component.scss'],
    standalone: false
})
export class SvgEnvelopeComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}
