<!-- Scan Rx and Inventory Choice Modal -->
<ng-template 
	#inventoryChoiceModal 
	let-modal
>
	<pcg-inv-choice-modal [modal]='modal'></pcg-inv-choice-modal>
</ng-template>

<!-- Rx List Modal -->
<ng-template 
	#shipmentListRxModal 
	let-modal
>
	<pcg-shipment-list-rx-modal 
		[modal]="modal" 
		[shipmentId]="rxListShipmentId" 
		(reloadTable)="table.ajaxReload()"
	></pcg-shipment-list-rx-modal>
</ng-template>

<!-- Comments modal -->
<ng-template 
	#commentsModal 
	let-modal
>
	<pcg-shipment-comments-modal 
		[modal]="modal" 
		[shipmentId]="rxListShipmentId" 
		id="commentsModal" 
		(reloadTable)="table.ajaxReload()"
	></pcg-shipment-comments-modal>
</ng-template>

<!-- Header + Action btns -->
<div class="d-flex flex-row justify-content-between align-items-center">
	<h1>
		@if (inventorySiteName) { <span>{{ inventorySiteName }} - </span> }
		Rx Fulfillment
	</h1>
	@if (canCreateFulfillments) {
		<div>
			<!--Inventory Screen Buttons-->
			@if (!isCentralized) {
				<div class="d-flex flex-row flex-wrap justify-content-end">
					<a 
						(click)='newFulfillment(true)' 
						class='btn btn-save btn-lg ml-2 mt-1'
					>New CI/CHD Delivery</a>
					<a 
						(click)='newFulfillment(false)' 
						class='btn btn-save btn-lg ml-2 mt-1 homeDelivery'
					>New Home Delivery</a>
				</div>
			} @else {
				<!--Centralized Modal Buttons-->
				<div class="d-flex flex-row flex-wrap justify-content-end">
					<a 
						(click)='openInventoryChoiceModal(inventoryChoiceModal, true)' 
						class='btn btn-save btn-lg ml-2 mt-1'
					>New CI/CHD Delivery</a>
					<a 
						(click)='openInventoryChoiceModal(inventoryChoiceModal, false)' 
						class='btn btn-save btn-lg ml-2 mt-1 homeDelivery'
					>New Home Delivery</a>
				</div>
			}
		</div>
	}	
</div>
<hr />

<!-- Table description + scan btn -->
<div 
	class="d-flex flex-row justify-content-between p-0" 
	id='informativeContent'
>
	<div class='col-md-8 p-0'>
		@if (!isCentralized) {
			<p>
				Records highlighted in red indicate that there are one or more rejected Rx numbers on a Fulfillment. Fulfillments older than two weeks that have
				no Products added in Step 1 are automatically deleted from this list.
			</p>
		} @else { <p>Records highlighted in red indicate that there are one or more rejected Rx numbers on a Fulfillment.</p> }
		<ng-template #thresholdTooltip>
			The Fulfillment Overdue Thresholds can be set per inventory via the Edit Inventory page.
			<br />
			@if (!isCentralized) { <span> Select the icon to navigate to the edit page for {{ currentSiteName }}.</span> }
		</ng-template>
	</div>
	@if (canEdit) {
		<div class="row mr-0 align-items-center">
			<fa-icon 
				[icon]='faExclamationCircle' 
				class='description-icon' 
				ngbTooltip="When using the Scan Rx filter, remember to adjust your date filter to see historic records."
			></fa-icon>
			<div class="scan-btn">
				<span class='form-inline'>
					<!--Rx # Filter Scan-->
					<pcg-scan-rx 
						(scanSuccessFromList)='scanSuccessFromList($event)' 
						[autofocus]='true' 
						(scanSuccess)='scanRxNumber($event)'
						[navigateToShipment]='true' 
						[inventoryId]='inventorySiteId'
						[inputPlaceholder]='inputPlaceholder'
					></pcg-scan-rx>
				</span>
			</div>
		</div>
	}
</div>

<!--Tooltips for table icons-->
<ng-template #dateCreatedTooltip>
	<div class='d-flex flex-column align-items-start'>
		<div class="mb-1">
			<pcg-fa-num-icon 
				[iconName]='faIconName.faHourglassHalf' 
				[iconColor]='warningColor' 
				(click)='navigateToInventoryEdit()' 
				[ngbTooltip]='thresholdTooltip' 
				class='overdueIcon'
			></pcg-fa-num-icon> = In progress fulfillments that are close to being overdue
		</div>
		
		<div>
			<pcg-fa-num-icon 
				[iconName]='faIconName.faHourglassEnd' 
				[iconColor]='dangerColor' 
				(click)='navigateToInventoryEdit()' 
				[ngbTooltip]='thresholdTooltip' 
				class='overdueIcon'
			></pcg-fa-num-icon> = In progress fulfillments that are overdue
		</div>
	</div>
</ng-template>

<ng-template #fulfillmentStatusTooltip>
	<div class='d-flex flex-column align-items-start flex-nowrap'>
		<div class="mb-1">
			<fa-icon [icon]="faIconName.faUser"></fa-icon> 
			= Fulfillment is open and a user is working on it. The name and how long it has been open will be on the tooltip.
		</div>
		<div>
			<pcg-edit-link [linkStyle]='getIsFulfillmentOpenedLink(true)'>
				Fulfillment Step
			</pcg-edit-link> 
			= Fulfillment that has been opened before by a user. Record of who opened it is in Fulfillment Audit History.
		</div>
	</div>
</ng-template>

<!-- Filter dropdown -->
<pcg-table-filter id='fulfillmentFilters'>
	<div (change)="table.ajaxReload(); filterChange();">
		<div class="patient-info">
			<label class="for">Patient Information</label>
			<div class='d-flex flex-row'>
				<input 
					class='form-control col-md-6 mr-2' 
					type='text' 
					[(ngModel)]='filters.firstName' 
					autocomplete='off' 
					placeholder="First Name"
				/>
				<input 
					class='form-control' 
					type='text' 
					[(ngModel)]='filters.lastName' 
					autocomplete='off' 
					placeholder="Last Name"
				/>
			</div>

			<div class='d-flex flex-row mt-2'>
				<input 
					type="date" 
					placeholder="MM/dd/yyyy" 
					class="form-control col-md-4" 
					maxlength="10" 
					(ngModelChange)="table.ajaxReload();" 
					[(ngModel)]='filters.dob' 
					max="{{maxDate | date:'yyyy-MM-dd'}}" 
					min="{{minDate | date:'yyyy-MM-dd'}}" 
				/>
				<p class='mt-2 mr-2 col-md-2'>DOB</p>
				<input 
					class='form-control zipInput' 
					type='text' 
					pcgNumeric 
					inputmode="numeric" 
					[(ngModel)]='filters.zip' 
					autocomplete='off' 
					placeholder="Zip Code" 
				/>
			</div>
		</div>

		<div class='d-flex flex-row align-items-center mt-2 mb-2'>
			<div class="mr-2">
				<label class='for'>Date Created</label>
				<pcg-date-range 
					[(dateRange)]='filters.date' 
					[maxDate]='null'
				></pcg-date-range>
			</div>
			
			<div class='ml-4 mt-3'>
				<ui-switch 
					class='form-check-input' 
					[(ngModel)]='filters.isNotActive' 
					(click)='table.ajaxReload(); filterChange();'
				></ui-switch>
				<label class='form-check-label ml-5 mt-2'>Inactive Fulfillments</label>
			</div>
		</div>

		<div class="additional-info mt-1">
			<label class='for'>Additional Information</label>
			<div class='d-flex flex-row'>
				<input 
					class='form-control mr-2' 
					type='text' 
					[(ngModel)]='filters.facilityName' 
					autocomplete='off' 
					placeholder='Facility Name'
				/>
				<input 
					class='form-control' 
					type='text' 
					pcgNumeric 
					inputmode="numeric" 
					[(ngModel)]='filters.pfsFormId' 
					autocomplete='off' 
					min='1' 
					max='999999999' 
					placeholder='PFS Refill Form' 
					(ngModelChange)="table.ajaxReload();" 
				/>
			</div>

			@if (isCentralized) {
				<div class='mt-2'>
					<pcg-select 
						[(ngModel)]='filters.inventorySiteId' 
						dataSource='api/Select/UserInventories' 
						placeholder='Select an Inventory'
					></pcg-select>
				</div>
			}

			<div class='mt-2'>
				<label class='for'>Fulfillment Status</label>
				<pcg-select 
					#status 
					[multiple]='true' 
					[(ngModel)]='selectedStatus' 
					dataSource='api/Select/FulfillmentStatus' 
					placeholder='Select a Fulfillment Status' 
					(change)='serializeStatus()'
				></pcg-select>
			</div>
		</div>
	</div>
</pcg-table-filter>
	
<pcg-table 
	#table 
	[ajaxData]='filters' 
	[emptyFilters]="initFilters"
	[multipleSearch]='false' 
	[pcgSort]='[[ "dateCreatedStr", sortDir ]]' 
	dataSource='api/Inventory/Shipping/GetShipmentList' 
	[columnDefs]='columns' 
	[canExactMatchSearch]="false"
	filterId="fulfillmentFilters" 
	[filterMap]="filterMap"
>
	
	<ng-container matColumnDef='fulfillmentStatus'>
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>
			<span 
				container='body' 
				ngbTooltip="fulfillmentStatusTooltip" 
				[ngbTooltip]="fulfillmentStatusTooltip"
			>{{ columns.get('fulfillmentStatus').displayName }}</span>
		</th>
		<td 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected'
		>
			<pcg-edit-link 
				[hasAccess]='canEdit' 
				[url]='"/pharmacy/inventories/fulfillment/edit/" + row.shipmentId + "/" + row.inventoryId'
				[linkStyle]='getIsFulfillmentOpenedLink(row.hasFulfillmentBeenOpened)'
			>
				{{ row.fulfillmentStatus }}
			</pcg-edit-link>
			@if (row.isOpen) {
				<span>
					<fa-icon 
						[icon]="faIconName.faUser" 
						[ngbTooltip]='row.openUserTooltip'
					></fa-icon>
				</span>
			}
		</td>
	</ng-container>

	<ng-container matColumnDef='inventory'>
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef 
			style='width: 150px;'
		>{{ columns.get('inventory').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected'
		>
			@if (isCentralized) {
				<a 
					class='fulfillment-link' 
					(click)='navigateToFulfillment(row.inventoryId)'
				>{{ row.inventory }}</a>
			} @else { <span>{{ row.inventory }}</span> }
		</td>
	</ng-container>

	<ng-container matColumnDef='deliveryType'>
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef 
			style='width: 150px;'
		>{{ columns.get('deliveryType').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected'
		>{{ row.deliveryType }}</td>
	</ng-container>

	<ng-container matColumnDef='commentCount'>
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef 
			style="width: 100px; text-align: center;"
		>
			<span 
				container='body' 
				ngbTooltip="The date and time in the comments tooltip is for the most recent comment date and time stamp."
			>{{ columns.get('commentCount').displayName }}</span>
		</th>
		<td 
			class='text-center hoverLarger' 
			mat-cell 
			*matCellDef='let row;' 
			style="text-align: center;"
		>
			<span 
				[hasAccess]='canEdit || isReadOnly' 
				(click)="openCommentsModal(commentsModal, row.shipmentId)"
			>
				<pcg-fa-num-icon 
					[iconName]="faIconName.faCommentsRegular" 
					[num]="row.commentCount" 
					[ngbTooltip]="row.lastCommentDate"
					[numColor]="faNumColor.success" 
					[numPosition]='commentPosition'
				></pcg-fa-num-icon>
			</span>
		</td>
	</ng-container>

	<ng-container matColumnDef='dateCreatedStr'>
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>
			<span 
				container='body' 
				ngbTooltip="dateCreatedTooltip" 
				[ngbTooltip]="dateCreatedTooltip"
			>{{ columns.get('dateCreatedStr').displayName }}</span>
		</th>
		<td 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected' 
			style='white-space: nowrap;'
		>
			{{ row.dateCreatedStr }}
			@if (
				row.isOverdue1 
				|| row.isOverdue2
			) {
				<pcg-fa-num-icon 
					[iconName]='row.isOverdue1 ? faIconName.faHourglassHalf : faIconName.faHourglassEnd' 
					[iconColor]='row.isOverdue1 ? warningColor : dangerColor'
				></pcg-fa-num-icon>
			}
			@if (row.pfsLink) {
				<br />
				<a 
					[href]='row.pfsLink' 
					target="_blank" 
				>{{ row.createdBy }}</a>
			} @else { <br /><span>{{ row.createdBy }}</span> }
		</td>
	</ng-container>

	<ng-container matColumnDef='rxNumberCount'>
		<th 
			class='text-right' 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef 
			style="width: 90px;"
		>{{ columns.get('rxNumberCount').displayName }}</th>
		<td 
			class='text-right hoverLarger' 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected' 
			(click)="openRxListModal(shipmentListRxModal, row.shipmentId)"
		>
			<pcg-fa-num-icon 
				[num]="row.rxNumberCount" 
				[iconName]="faIconName.faPrescriptionBottleAlt" 
				[numColor]="faNumColor.success"
				[ngClass] = "{'prev-selected': rxListShipmentId == row.shipmentId}"
			></pcg-fa-num-icon>
		</td>
	</ng-container>

	<ng-container matColumnDef='prescriptionCount'>
		<th 
			class='text-right' 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef 
			style='width: 90px;'
		>{{ columns.get('prescriptionCount').displayName }}</th>
		<td 
			class='text-right' 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected'
		>{{ row.prescriptionCount }}</td>
	</ng-container>

	<ng-container matColumnDef='shipmentTypeStr'>
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('shipmentTypeStr').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected'
		>
			{{ row.shipmentTypeStr }}
			@if (
				row.shipmentTypeStr === "Fedex Ground" 
				|| row.shipmentTypeStr === "Fedex Express"
			) {
				<br />
				<a 
					[href]='fedexLink + row.masterTrackingNumber' 
					target='_blank' 
				>{{ row.masterTrackingNumber }}</a>
			} @else if (
				row.shipmentTypeStr === "UPS Ground" 
				|| row.shipmentTypeStr === "UPS Next Day Air"
			) {
				<br />
				<a 
					[href]='upsLink + row.masterTrackingNumber' 
					target='_blank'
				>{{ row.masterTrackingNumber }}</a>
			}
			@if (row.weightPounds) { <br />{{ row.weightPounds }}&nbsp;lbs }
			@if (row.packageStatus) { <br />{{ row.packageStatus }} }
			@if (row.dateDeliveredStr) { <br />{{ row.dateDeliveredStr }} }
		</td>
	</ng-container>

	<ng-container matColumnDef='currentStep'>
		<th 
			class='text-right' 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{columns.get('currentStep').displayName }}</th>
		<td 
			class='text-right' 
			mat-cell 
			*matCellDef='let row;' 
			[class.table-danger]='row.isRejected'
		>{{ row.currentStep }}</td>
	</ng-container>

	<ng-container matColumnDef='canDelete'>
		<th 
			class='text-center' 
			mat-header-cell 
			*matHeaderCellDef
		>{{ columns.get('canDelete').displayName }}</th>
		<td 
			class='text-center' 
			mat-cell 
			*matCellDef='let row' 
			[class.table-danger]='row.isRejected'
		>
			@if (row.canDelete) {
				<pcg-delete-button 
					[disabled]='!inventoryEditAccess'
					[ngbTooltip] = "!inventoryEditAccess? 'Missing adequate permissions to delete' : null"
					[table]='table' 
					[confirmMessage]='"Are you sure you want to delete this Fulfillment?"'
					url='api/Inventory/Shipping/DeleteShipment' 
					[id]='row.shipmentId'
				></pcg-delete-button>		
			}
		</td>		
	</ng-container>

</pcg-table>
