import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Subscription } from "rxjs";

import { EditUserVm } from "app/shared/generated/Administration/Models/Users/EditUserVm";
import { UserAssociatedFacilitiesComponent } from "./user-associated-facilities/user-associated-facilities.component";
import { UserNonAssociatedFacilitiesComponent } from "./user-non-associated-facilities/user-non-associated-facilities.component";
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";

@UntilDestroy()
@Component({
    selector: "pcg-user-facilities",
    templateUrl: "./user-facilities.component.html",
    styleUrls: ["./user-facilities.component.scss"],
    standalone: false
})
export class UserFacilitiesComponent implements OnInit {

	@ViewChild("nonAssociatedFac") nonAssociatedFac: UserNonAssociatedFacilitiesComponent;
	@ViewChild("associatedFac") associatedFac: UserAssociatedFacilitiesComponent;

	@Input() editUserVm: EditUserVm;
	@Output() reload = new EventEmitter();

	subscriptions: Subscription = new Subscription();
	id: number;
	canEditFacilities = false;

	filters = { id: 0 };

	constructor(
		private activatedRoute: ActivatedRoute,
		public router: Router,
		private sec: SecurityService
	) {}

	ngOnInit() {
		this.id = Number(this.activatedRoute.snapshot.params.id);
		if (isNaN(this.id)) {
			this.id = Number(this.activatedRoute?.snapshot?.params?.id?.split("?")[0]);
			if (isNaN(this.id)) {
				this.id = Number(this.activatedRoute?.snapshot?.params?.id?.split("%")[0]);
			}
		}
		this.filters.id = this.id;
		this.canEditFacilities = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ ...SecurityService.setMinRole(PermissionRole.Manager) ]);
	}

	reloadTables() {
		this.nonAssociatedFac.nonTable.ajaxReload();
		this.associatedFac.facTable.ajaxReload();
	}
}
