import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { HelpDeskFeedbackVm } from 'app/shared/generated/HelpDesk/Models/HelpDeskFeedbackVm';
import { environment } from 'environments/environment';

@Component({
    selector: 'pcg-help-desk-ticket-feedback',
    templateUrl: './help-desk-ticket-feedback.component.html',
    styleUrls: ['./help-desk-ticket-feedback.component.scss'],
    standalone: false
})
export class HelpDeskTicketFeedbackComponent implements OnInit, OnDestroy {

    // reCaptcha properties
	@ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent ;
	siteKey: string = environment.siteKey; // should pick up environment specific siteKey

	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public recaptcha: any = null;
	public execute: any;

    formGroup = HelpDeskFeedbackVm.Form;

    starRating: number = 0;
    isError = false;

    stars = document.getElementsByClassName("star-icon");
    faIconName = { faStar };

    constructor(
        private activatedRoute: ActivatedRoute
        , private httpClient: HttpClient
        , public sm: SystemMessageService
        , private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.getFeedback();
        this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
    }

    getFeedback() {
        this.httpClient.get(
            `api/HelpDesk/HelpDeskFeedback/GetModel?helpDeskTicketId=${this.activatedRoute.snapshot.params.id}&guid=${this.activatedRoute.snapshot.params.guid}`
        ).subscribe((vm: HelpDeskFeedbackVm) => { 
            this.formGroup.patchValue(vm);
            this.addStars(this.formGroup.controls.rating.value);
        });
    }

    saveForm() {
        this.isError = false;
        if (this.starRating > 0) { this.formGroup.controls.rating.setValue(this.starRating); }
        else {
            this.isError = true;
            return;
        }
            
        this.httpClient.post(`api/HelpDesk/HelpDeskFeedback/Save`, this.formGroup.getRawValue())
            .subscribe((msg: SystemMessage) => {
                this.sm.setSystemMessage(msg.message, msg.messageClass);
                if (!msg.isSuccessful) { 
                    this.formGroup.controls.rating.setValue(0); 
                    this.addStars(0);
                }
            });
    }

    addStars(starNum) {
        this.removeStars();
        for (let i=0; i < starNum; i++) { this.stars[i]?.classList?.add("star-icon-filled"); }
        this.starRating = starNum;
    }

    removeStars() {
        for (let i=0; i < 5; i++) { this.stars[i]?.classList?.remove("star-icon-filled"); }
    }

    // Captcha callbacks
	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
		this.execute = setInterval(() => { this.captchaElem?.execute(); }, 5000);
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

    ngOnDestroy(): void { clearInterval(this.execute); }
}
